import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import styles from './mounted-tire-details.module.scss';
import {
    MountedTireDetailsCustomReportParameters,
    MountedTireDetailsScheduledReportParameters,
    ReportPeriodType,
    ScheduledReportPeriod,
} from '../../../../../../store/reports';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import LocationSelectorComponent from '../../../../components/location-selector.component';
import {FleetCustomerWithConfiguration} from '../../../../../../store/fleet-customers';
import Dropdown, {DropdownOption} from '../../../../../../components/dropdown/dropdown';
import {PeriodSelectionOptions} from '../../../../types/date-selection';
import {endOfDay, endOfMonth, startOfMonth, startOfYear, subMonths} from '../../../../../../utils/date-helper';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';

class MountedTireDetailsComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters, t} = this.props;

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        let locations: FunctionalLocationSelection[] = [];
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.period) {
            period = parameters.period;
        }

        const periodOptions = this.buildPeriodSelectionOptions(t);
        this.state = {
            period,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
            periodOptions,
            selectedPeriodOption: periodOptions[0],
        };
    }

    public render(): JSX.Element {
        const {periodType, fleetCustomer, t} = this.props;
        const {period, functionalLocationSelection, selectedPeriodOption, periodOptions} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.info}>{t('Select a customer')}</div>
                        <LocationSelectorComponent
                            defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                            onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                    </div>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.info}>{t('Specify a period')}</div>
                            <div className={styles.select}>
                                <Dropdown options={periodOptions} width='340px'
                                          selection={selectedPeriodOption}
                                          onSelectionChanged={st => this.onPeriodOptionChanged(st)}/>
                            </div>
                            {selectedPeriodOption.id===PeriodSelectionOptions.Period
                                ? <DateRangeSelectorComponent
                                    earliestDate={fleetCustomer?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}/>
                                :''}
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    fromDate,
                    toDate,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    period,
                    locations: controlValue.value,
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;

        onScheduledParametersChanged({
            period,
            locations: functionalLocationSelection!.value,
        });

        this.setState({
            period,
        });
    }

    private onPeriodOptionChanged(option: DropdownOption) {
        const {onCustomParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;
        const [fromDate, toDate] = this.findDatesByPeriodSelection(option);

        onCustomParametersChanged({
            functionalLocationSelection,
            fromDate,
            toDate,
        });

        this.setState({
            selectedPeriodOption: option,
            fromDate,
            toDate,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, toDate} = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, fromDate} = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }

    private buildPeriodSelectionOptions(t: TFunction): DropdownOption[] {
        return [
            {id: PeriodSelectionOptions.Period, label: t('Custom period')},
            {id: PeriodSelectionOptions.PriorMonth, label: t('Prior month')},
            {id: PeriodSelectionOptions.YTDPreviousMonth, label: t('YTD to previous month')},
            {id: PeriodSelectionOptions.MTDAndPrior3Months, label: t('MTD and prior 3 months')},
        ];
    }

    private findDatesByPeriodSelection(option: DropdownOption): [Date | undefined, Date | undefined] {
        switch (option.id) {
            case PeriodSelectionOptions.Period:
                return [undefined, undefined];
            case PeriodSelectionOptions.PriorMonth:
                return [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))];
            case PeriodSelectionOptions.YTDPreviousMonth:
                return [startOfYear(new Date()), endOfMonth(subMonths(new Date(), 1))];
            case PeriodSelectionOptions.MTDAndPrior3Months:
                return [startOfMonth(subMonths(new Date(), 3)), endOfDay(new Date())]
            default:
                return [undefined, undefined];
        }
    }
}

export default withTranslation()(MountedTireDetailsComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    fleetCustomer?: FleetCustomerWithConfiguration;
    parameters?: MountedTireDetailsScheduledReportParameters;
    onCustomParametersChanged: (parameters: MountedTireDetailsCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: MountedTireDetailsScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    period: ScheduledReportPeriod;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
    selectedPeriodOption: DropdownOption;
    periodOptions: DropdownOption[];
    fromDate?: Date;
    toDate?: Date;
}

type AllState = OwnState;


