import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {
    ReusableOrderType,
    TopReusableTiresFittedByServiceProvidersRequest
} from "../../../../store/analytics/types/top-tires-fitted-by-service-providers-types";

export function buildReusableRequest(snapshot: ControlsSnapshot, orderType: ReusableOrderType): TopReusableTiresFittedByServiceProvidersRequest {
    const request = Object.assign(createDateRangeWithFunctionalLocationsRequest(snapshot), {
        orderType,
    });
    return request;
}
