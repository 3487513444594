import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { Color } from '@amcharts/amcharts4/core';
import { faChartBar, faTable } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import WidgetTable, { WidgetTableColumnType } from '../../../../components/widget-table/widget-table';
import { ApplicationState } from '../../../../store';
import {
    DateRangeWithFunctionalLocationsRequest,
    fetchTiresFittedBrandDistributionRequest
} from '../../../../store/analytics';
import { BrandType } from '../../../../store/analytics/types/brand-distribution.types';
import { TireBrandDistributionData } from '../../../../store/analytics/types/tire-brand-distribution.types';
import { ViewMode } from '../../../../types/view-mode';
import { generateUUID } from '../../../../utils/uuid-helpers';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import { SingleChartWidgetBase } from '../single-chart-widget-base';
import {colorBlue5, colorGrey1, colorYellow5} from '../utils/chart-colors';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {
    createDateRangeWithFunctionalLocationsRequest,
    hasInspectionPeriod
} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import {brandField, buildChartData, ownBrandField, unitsField} from './chart-data-adapter';
import styles from './tires-fitted-brand-distribution-widget.module.scss';

class TiresFittedBrandDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, TireBrandDistributionData[]> {
    private chartId: string;

    private chartColors: Map<string, Color>;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.chartColors = new Map<string, Color>();
        this.chartColors.set(BrandType.Goodyear, am4core.color(colorYellow5));
        this.chartColors.set(BrandType.NonGoodyear, am4core.color(colorBlue5));
        this.chartColors.set(BrandType.Unknown, am4core.color(colorGrey1));

        this.state = {
            viewMode: ViewMode.chart,
        };
    }

    public render(): JSX.Element {
        const { t, data } = this.props;
        const { viewMode } = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div className={styles.headerTitle}>{t('Tires Fitted Brand Distribution')}</div>
                            <div className={styles.interactions}>
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable} />
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar} />
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{ width: '100%' }} />
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{
                                        columns: [
                                            {
                                                title: 'Brand',
                                                property: brandField,
                                                type: WidgetTableColumnType.upperText,
                                                width: '480px',
                                            },
                                            {
                                                title: '# Units',
                                                property: unitsField,
                                                type: WidgetTableColumnType.number,
                                            },
                                        ],
                                    }}
                                    data={data}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        const { dispatchFetchTiresFittedBrandDistributionRequest } = this.props;
        dispatchFetchTiresFittedBrandDistributionRequest(createDateRangeWithFunctionalLocationsRequest(this.props.controlsSnapshot));
    }

    private toggleViewMode(): void {
        const { viewMode } = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({ viewMode: updatedViewMode }, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TireBrandDistributionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = brandField;
        categoryAxis.title.text = this.props.t('Brand');
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 15;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Units');

        this.addFlatSeries(chart);
        chart.data = buildChartData(data, this.props.t);

        return chart;
    }

    public addFlatSeries(chart: any): void {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = unitsField;
        series.dataFields.categoryY = brandField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${this.props.t('units')}[/]`;
        series.columns.template.fillOpacity = 0.8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
        dynamicallySetColorByType(series, ownBrandField, (categoryY: string) => {
            if (!this.chartColors.has(categoryY)) {
                return am4core.color(colorBlue5);
            }
            return this.chartColors.get(categoryY)!;
        });
    }
}

const mapStateToProps = ({ analytics }: ApplicationState) => ({
    data: analytics.tiresFittedBrandDistributionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchFetchTiresFittedBrandDistributionRequest: (request: DateRangeWithFunctionalLocationsRequest) =>
        dispatch(fetchTiresFittedBrandDistributionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TiresFittedBrandDistributionWidget));

interface PropsFromState {
    data: TireBrandDistributionData[];
    fleetCustomerId: string;
}

interface PropsFromDispatch {
    dispatchFetchTiresFittedBrandDistributionRequest: typeof fetchTiresFittedBrandDistributionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    viewMode: ViewMode;
}

type AllState = OwnState;
