import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import logo from '../../../assets/images/logo-yellow.png';
import PrivacyPolicyPopup from '../../privacy-policy/privacy-policy-popup';
import { RouteUrl } from '../../routes';
import { ApplicationState } from '../../store';
import {
    confirmResetPasswordRequest,
    ConfirmResetPasswordRequestPayload,
    forgotPasswordRequest,
    ForgotPasswordRequestPayload,
} from '../../store/authentication';
import * as layoutActions from '../../store/layout/actions';
import { closePopup, Popup, PopupType, showPopup } from '../../store/popup';
import TermsOfUsePopup from '../../terms-of-use/terms-of-use-popup';
import { Regex } from '../../types/regex';
import { conditionalClassLister } from '../../utils/class-helpers';
import { getLastPrivacyPolicy } from '../../utils/privacy-policy-helper';
import ToggleDisplay from '../../utils/toggle-display';
import { isGoodyearEmail } from '../../utils/user-helper';
import styles from './reset-password.module.scss';

class ResetPasswordPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const { dispatchToggleSidebar } = this.props;

        dispatchToggleSidebar(false);

        this.state = {
            email: '',
            inProgress: false,
            showErrors: false,
            newPassword: '',
            enteredGoodyearEmail: false,
            verificationCode: '',
            newPasswordValid: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const { resetCodeRequired, resetFailureMessage } = this.props;

        if (resetCodeRequired && !prevProps.resetCodeRequired) {
            this.setState({ inProgress: false });
        }

        if (resetFailureMessage && !prevProps.resetFailureMessage) {
            this.setState({ inProgress: false });
        }
    }

    public render(): JSX.Element {
        const { t, authenticated, resetCodeRequired, resetFailureMessage } = this.props;
        const { inProgress, showErrors, newPasswordValid, enteredGoodyearEmail } = this.state;

        const buttonClasses = conditionalClassLister(styles)({
            loginButton: true,
            loginButtonDisabled: inProgress,
        });

        let errorMessage = ``;

        if (resetCodeRequired) {
            errorMessage =
                showErrors && !newPasswordValid
                    ? `Password should have a minimum length of 10, contain at least one uppercase, one lowercase, one numeric and one special character`
                    : '';
            errorMessage = showErrors && resetFailureMessage ? resetFailureMessage : '';
        } else {
            errorMessage = showErrors && enteredGoodyearEmail ? `You can not reset Goodyear internal passwords` : '';
            errorMessage = showErrors && resetFailureMessage ? resetFailureMessage : '';
        }

        return (
            <div className={styles.pageContainer}>
                <img className={styles.logo} src={logo} alt={t('Goodyear logo')}/>
                <div className={styles.formContainer}>
                    <div className={styles.formPanel}>
                            {!authenticated ? (
                                <>
                                    <div className={styles.title}>{t('Reset Password')}</div>
                                    <div className={styles.subtitle}>{t('Fleet Online Solutions')}</div>
                                    <div className={styles.caption}>
                                        {resetCodeRequired
                                            ? t('Please provide the reset code received over email')
                                            : t('We will sent you your reset code shortly')}
                                    </div>
                                    <div className={styles.form} onKeyDown={(e) => this.onKeyPressed(e)}>
                                        {resetCodeRequired ? (
                                            <>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('Email')}</div>
                                                    <input
                                                        type='text'
                                                        key='EmailField'
                                                        autoFocus
                                                        className={styles.fieldInput}
                                                        placeholder='james@organization.com'
                                                        onChange={(e) => this.onEmailChange(e)}
                                                        onBlur={() => this.onCheckIsGoodyear()}
                                                    />
                                                </div>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('Verification Code')}</div>
                                                    <input
                                                        type='verificationCode'
                                                        key='VerificationCodeField'
                                                        className={styles.fieldInput}
                                                        placeholder={t('Enter your verification code')}
                                                        onChange={(e) => this.onVerificationCodeChange(e)}
                                                    />
                                                </div>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('New password')}</div>
                                                    <input
                                                        type='password'
                                                        key='NewPasswordField'
                                                        className={styles.fieldInput}
                                                        pattern={Regex.Password}
                                                        placeholder={t('Enter your new password')}
                                                        onChange={(e) => this.onNewPasswordChange(e)}
                                                        onBlur={() => this.onCheckPasswordPattern()}
                                                    />
                                                </div>
                                                <div className={styles.errorMessage}>{errorMessage}</div>
                                                <button
                                                    type='submit'
                                                    className={buttonClasses}
                                                    onClick={() => this.onResetPasswordConfirmationClick()}>
                                                    <ToggleDisplay if={inProgress}>
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    </ToggleDisplay>
                                                    <ToggleDisplay if={!inProgress}>
                                                        {t('Confirm password')}
                                                    </ToggleDisplay>
                                                </button>
                                                <div
                                                    onClick={() => this.onReturnToLoginClick()}
                                                    className={styles.returnToLogin}>
                                                    Return to Login
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('Email')}</div>
                                                    <input
                                                        type='text'
                                                        key='EmailField'
                                                        autoFocus
                                                        className={styles.fieldInput}
                                                        placeholder="james@organization.com"
                                                        onChange={(e) => this.onEmailChange(e)}
                                                        onBlur={() => this.onCheckIsGoodyear()}
                                                    />
                                                </div>
                                                <div className={styles.errorMessage}>{errorMessage}</div>
                                                <button
                                                    type='submit'
                                                    className={buttonClasses}
                                                    onClick={() => this.onResetPasswordClick()}>
                                                    <ToggleDisplay if={inProgress}>
                                                        <FontAwesomeIcon icon={faSpinner} spin />
                                                    </ToggleDisplay>
                                                    <ToggleDisplay if={!inProgress}>{t('Send my code')}</ToggleDisplay>
                                                </button>
                                                <div
                                                    onClick={() => this.onReturnToLoginClick()}
                                                    className={styles.returnToLogin}>
                                                    Return to Login
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    <div className={styles.footerPanel}>
                        <div className={styles.knowMore}>
                            <span onClick={() => this.openPrivacyPolicyPopup()} className={styles.highlight}>
                                    {' '}
                                {t('Privacy Policy')}
                                </span>
                            {' - '}
                            <span onClick={() => this.openTermsOfUsePopup()} className={styles.highlight}>
                                    {' '}
                                {t('Terms of use')}
                                </span>
                        </div>
                    </div>
                    </div>
            </div>
        );
    }

    private onKeyPressed(e): void {
        const { resetCodeRequired } = this.props;

        if (e.keyCode === 13) {
            if (resetCodeRequired) {
                this.onResetPasswordConfirmationClick();
            } else {
                this.onResetPasswordClick();
            }
        }
    }

    private onEmailChange(e): void {
        this.setState({ showErrors: false, email: e.target.value });
    }

    private onReturnToLoginClick(): void {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: RouteUrl.Login });
    }

    private openPrivacyPolicyPopup(): void {
        const { dispatchShowPopup, dispatchClosePopup } = this.props;

        getLastPrivacyPolicy('EN').then((policyDocument) => {
            if (policyDocument) {
                dispatchShowPopup({
                    type: PopupType.PrivacyPolicy,
                    content: (
                        <PrivacyPolicyPopup
                            shouldConfirm={false}
                            showTermsOfUse={false}
                            policyDocument={policyDocument}
                            onConfirm={async (): Promise<void> => {
                                dispatchClosePopup();
                            }}
                            onClose={(): void => {
                                dispatchClosePopup();
                            }}
                        />
                    ),
                });
            }
        });
    }

    private onResetPasswordClick(): void {
        const { email } = this.state;
        const { dispatchForgotPassword } = this.props;

        const isGoodyear = isGoodyearEmail(email);
        if (isGoodyear) {
            this.setState({ enteredGoodyearEmail: isGoodyear, showErrors: true });
        } else {
            this.setState({ inProgress: true, showErrors: true });
            dispatchForgotPassword({ email });
        }
    }

    private onResetPasswordConfirmationClick(): void {
        const { email, verificationCode, newPassword, newPasswordValid } = this.state;
        const { dispatchConfirmResetPassword } = this.props;

        if (newPasswordValid) {
            this.setState({ inProgress: true, showErrors: true });
            dispatchConfirmResetPassword({
                email,
                verificationCode,
                newPassword,
            });
        } else {
            this.setState({ showErrors: true });
        }
    }

    private onNewPasswordChange(e): void {
        this.setState({ showErrors: false, newPassword: e.target.value });
    }

    private onCheckIsGoodyear(): void {
        const { email } = this.state;
        this.setState({
            enteredGoodyearEmail: isGoodyearEmail(email),
            showErrors: true,
        });
    }

    private onCheckPasswordPattern(): void {
        const { newPassword } = this.state;
        const regex = new RegExp(Regex.Password);
        this.setState({
            newPasswordValid: regex.test(newPassword),
            showErrors: true,
        });
    }

    private onVerificationCodeChange(e): void {
        this.setState({ verificationCode: e.target.value });
    }

    private openTermsOfUsePopup(): void {
        const { dispatchShowPopup, dispatchClosePopup } = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.TermsOfUse,
            content: (
                <TermsOfUsePopup
                    onClose={async (): Promise<void> => {
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }
}

const mapStateToProps = ({ authentication }: ApplicationState) => ({
    cognitoErrors: authentication.loginFailureMessage,
    authenticated: authentication.authenticated,
    resetCodeRequired: authentication.resetCodeRequired,
    resetFailureMessage: authentication.resetFailureMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchForgotPassword: (request: ForgotPasswordRequestPayload) => dispatch(forgotPasswordRequest(request)),
    dispatchConfirmResetPassword: (request: ConfirmResetPasswordRequestPayload) =>
        dispatch(confirmResetPasswordRequest(request)),
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));

interface PropsFromState {
    cognitoErrors?: string;
    resetCodeRequired: boolean;
    authenticated: boolean;
    resetFailureMessage: string;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchForgotPassword: typeof forgotPasswordRequest;
    dispatchConfirmResetPassword: typeof confirmResetPasswordRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    showErrors: boolean;
    inProgress: boolean;
    enteredGoodyearEmail: boolean;
    newPassword: string;
    verificationCode: string;
    newPasswordValid: boolean;
}

type AllState = OwnState;
