import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './extended-service.module.scss';
import {
    ExtendedServiceCustomReportParameters,
    ExtendedServiceScheduledReportParameters,
    InspectionStatus,
    ReportPeriodType,
} from '../../../../../../store/reports';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import LocationSelectorComponent from '../../../../components/location-selector.component';

class ExtendedServiceComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);

        const {parameters} = this.props;

        let inspectionStatus = InspectionStatus.All;
        let locations: FunctionalLocationSelection[] = [];
        if (parameters && parameters.inspectionStatus) {
            inspectionStatus = parameters.inspectionStatus;
        }
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }

        this.state = {
            selectedInspectionStatus: inspectionStatus,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {selectedInspectionStatus, functionalLocationSelection} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.info}>{t('Select a customer')}</div>
                    <div className={styles.formRow}>
                        <LocationSelectorComponent
                            defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                            onFunctionalLocationChange={(locations) => this.onFunctionalLocationChanged(locations)}/>
                    </div>
                    <div className={styles.info}>{t('Select Vehicle Status')}</div>
                    <div className={styles.formRow}>
                        <div className={styles.select}>
                            <label htmlFor="allVehiclesInspection">
                                <input type="radio" id="allVehiclesInspection"
                                       checked={selectedInspectionStatus === InspectionStatus.All}
                                       onChange={() => this.onInspectionStatusChanged(InspectionStatus.All)}
                                />{t('Show all vehicles')}
                            </label>
                            <label htmlFor="notInspectedLastThreeMonths">
                                <input type="radio" id="notInspectedLastThreeMonths"
                                       checked={selectedInspectionStatus === InspectionStatus.NotSeenLastThreeMonths}
                                       onChange={() => this.onInspectionStatusChanged(InspectionStatus.NotSeenLastThreeMonths)}
                                />{t('Only vehicles not seen in the last 3 months')}
                            </label>
                            <label htmlFor="notInspectedLastMonth">
                                <input type="radio" id="notInspectedLastMonth"
                                       checked={selectedInspectionStatus === InspectionStatus.NotSeenLastMonth}
                                       onChange={() => this.onInspectionStatusChanged(InspectionStatus.NotSeenLastMonth)}
                                />{t('Only vehicles not seen last month')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, selectedInspectionStatus} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    selectedInspectionStatus
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    locations: controlValue.value,
                    inspectionStatus: selectedInspectionStatus
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onInspectionStatusChanged(status: InspectionStatus) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                functionalLocationSelection,
                selectedInspectionStatus: status
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                locations: functionalLocationSelection!.value,
                inspectionStatus: status
            });
        }

        this.setState({
            selectedInspectionStatus: status,
        });
    }
}

export default withTranslation()(ExtendedServiceComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: ExtendedServiceScheduledReportParameters;
    onCustomParametersChanged: (parameters: ExtendedServiceCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: ExtendedServiceScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    selectedInspectionStatus: InspectionStatus;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
