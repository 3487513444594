import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    createDateTodayWithFunctionalLocationsRequest,
} from '../utils/widget-helper';
import {
    InspectionStatusGroupingType,
    InspectionStatusRequest,
} from '../../../../store/analytics/types/inspection-status.types';

export function buildRequest(snapshot: ControlsSnapshot, grouping: InspectionStatusGroupingType): InspectionStatusRequest {
    return Object.assign(
        createDateTodayWithFunctionalLocationsRequest(snapshot), {
            grouping: grouping === InspectionStatusGroupingType.GLOBAL ? [] : [grouping],
        });
}
