import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import NumberFormat from 'react-number-format';
import {ApplicationState} from '../../../../store';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    fetchUnderInflationKPIRequest,
} from '../../../../store/analytics';
import {buildRequest, isValidSnapshot} from './request-builder';
import styles from './under-inflation-widget.module.scss';
import WidgetLoading from '../widget-loading';
import {NumberFormatter} from '../kpi-box/kpi-meta-store';
import {
    UnderInflationKPIData, UnderInflationKPIRequest
} from '../../../../store/analytics/types/key-performance-indicator.types';
import {CurrencyInfo} from '../../../../store/fleet-customers';
import {conditionalClassLister} from '../../../../utils/class-helpers';


class UnderInflationWidget extends React.Component<AllProps> {
    public constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        const { controlsSnapshot, dispatchUnderInflationRequest } = this.props;
        if (controlsSnapshot && isValidSnapshot(controlsSnapshot)) {
            dispatchUnderInflationRequest(buildRequest(controlsSnapshot));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { controlsSnapshot, dispatchUnderInflationRequest } = this.props;
        if (controlsSnapshot && !controlsSnapshot.equals(prevProps.controlsSnapshot) && isValidSnapshot(controlsSnapshot)) {
            dispatchUnderInflationRequest(buildRequest(controlsSnapshot));
        }
    }

    public render(): JSX.Element {
        const { t, data, activeCurrency } = this.props;

        const formatter = new NumberFormatter('.', ',', true, 2);
        const currency = activeCurrency ? activeCurrency.name : '';
        const targetMet = data && data.tirePressureTarget < data.tirePressureAverage;

        const comparisonValueClasses = conditionalClassLister(styles)({
            comparisonValue: true,
            positive: targetMet,
            negative: !targetMet,
        });

        const diff = data && data.tirePressureTarget !== 0 ?  (data.tirePressureAverage - data.tirePressureTarget) / data.tirePressureTarget : 0;

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartColumn}>
                            <div className={styles.chartRow}>
                                <div className={styles.titlePanel}>
                                    {t('Underinflation Effect').toUpperCase()}
                                </div>
                                <div className={styles.valuePanel}>
                                    <div className={styles.ratioValues}>
                                        <div className={styles.value}>
                                            <NumberFormat
                                                value={data.tirePressureAverage}
                                                displayType='text'
                                                decimalSeparator={formatter.decimalSeparator}
                                                thousandSeparator={formatter.thousandsSeparator}
                                                fixedDecimalScale={formatter.fixedDecimalScale}
                                                decimalScale={formatter.decimalScale}
                                            />
                                            <span className={styles.unit}>bar</span>
                                        </div>
                                        <div className={comparisonValueClasses}>
                                            <NumberFormat
                                                value={Math.abs(diff * 100)}
                                                displayType='text'
                                                decimalSeparator={formatter.decimalSeparator}
                                                thousandSeparator={formatter.thousandsSeparator}
                                                fixedDecimalScale={formatter.fixedDecimalScale}
                                                decimalScale={1}
                                            />
                                            <span>% {t(targetMet ? 'above target' : 'below target')}</span>
                                        </div>
                                    </div>
                                    <div className={styles.label}>
                                        {t('Average tire pressure upon inspection')}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.chartRow}>
                                <div className={styles.titlePanel}>
                                    {targetMet ? t('Currently Saving').toUpperCase() : t('Savings Potential').toUpperCase()}
                                </div>
                                <div className={styles.valuePanel}>
                                    <div className={styles.value}>
                                        <NumberFormat
                                            value={Math.abs(data.savingsPotential)}
                                            displayType='text'
                                            decimalSeparator={formatter.decimalSeparator}
                                            thousandSeparator={formatter.thousandsSeparator}
                                            fixedDecimalScale={formatter.fixedDecimalScale}
                                            decimalScale={2}
                                        />
                                        <span className={styles.unit}>{currency}</span>
                                    </div>
                                    <div className={styles.label}>
                                        {t('Potential savings by mitigating the underinflation effects')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.chartColumn}>
                            <div className={styles.infoSecondary}>{t('These {{percentage}}% has following impact on your fleet', {percentage: Math.abs(diff * 100).toFixed(1)})}</div>
                            <div className={styles.infoSecondary}>
                                <div className={styles.labelSecondary}>{t('Yearly average cost related to mileage loss by vehicle')}</div>
                                <div className={styles.valueSecondary}>
                                    <NumberFormat
                                        value={data.yearlyAverageCostRelatedToMileageLossByVehicle}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    /><span className={styles.unit}>{currency}</span>
                                </div>
                            </div>
                            <div className={styles.infoSecondary}>
                                <div className={styles.labelSecondary}>{t('Yearly estimated cost related to increased fuel consumption by vehicle')}</div>
                                <div className={styles.valueSecondary}>
                                    <NumberFormat
                                        value={data.yearlyEstimatedCostRelatedToIncreasedFuelConsumptionByVehicle}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    />
                                    <span className={styles.unit}>{currency}</span>
                                </div>
                            </div>
                            <div className={styles.infoSecondary}>
                                <div className={styles.labelSecondary}>{t('Total yearly cost caused by underinflation effect by vehicle')}</div>
                                <div className={styles.valueSecondary}>
                                    <NumberFormat
                                        value={data.yearlyTotalCostCausedByUnderInflationByVehicle}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    />
                                    <span className={styles.unit}>{currency}</span>
                                </div>
                            </div>
                            <div className={styles.infoText}>{t('Note: estimate calculations, based on GYR internal data and finding - outcomes may vary based on specific vehicle configuration and/or application type.')}</div>
                        </div>

                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        )
    }

}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.underInflationKPI,
    activeCurrency: authentication.fleetCustomer?.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchUnderInflationRequest: (request: UnderInflationKPIRequest) =>
        dispatch(fetchUnderInflationKPIRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UnderInflationWidget));

interface PropsFromState {
    data: UnderInflationKPIData;
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    dispatchUnderInflationRequest: typeof fetchUnderInflationKPIRequest;
}

export interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;
