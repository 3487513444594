import {ParamKey} from '../../../../store/dashboard';
import {
    DateRange,
    DateRangeRequest, DateRangeWithFunctionalLocationsAndLanguageRequest,
    DateRangeWithFunctionalLocationsRequest, DateWithFunctionalLocationsAndLanguageRequest,
    DateWithFunctionalLocationsRequest,
    FunctionalLocationSelection,
} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {forceDateToBrusselsTime} from '../../../../utils/date-helper';
import {FleetCustomerWithConfiguration} from '../../../../store/fleet-customers';

export function createDateRangeWithFunctionalLocationsRequest(snapshot: ControlsSnapshot): DateRangeWithFunctionalLocationsRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();
    let includeUnlinkedBillingLines;
    if (snapshot.has(ParamKey.AdditionalOptionsSelections)) {
        includeUnlinkedBillingLines = snapshot.getValueForParam<boolean>(ParamKey.AdditionalOptionsSelections);
    }

    return {
        start,
        end,
        locations: functionalLocationSelections,
        includeUnlinkedBillingLines,
    };
}

export function createDateRangeWithFunctionalLocationsAndLanguageRequest(snapshot: ControlsSnapshot, fleetCustomerWithConfiguration:FleetCustomerWithConfiguration): DateRangeWithFunctionalLocationsAndLanguageRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();
    let includeUnlinkedBillingLines;
    if (snapshot.has(ParamKey.AdditionalOptionsSelections)) {
        includeUnlinkedBillingLines = snapshot.getValueForParam<boolean>(ParamKey.AdditionalOptionsSelections);
    }

    return {
        start,
        end,
        locations: functionalLocationSelections,
        language:fleetCustomerWithConfiguration.activeLanguage.type,
        includeUnlinkedBillingLines,
    };
}

export function createDateRangeRequest(snapshot: ControlsSnapshot): DateRangeRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();

    return {
        start,
        end,
    };
}

export function createDateWithFunctionalLocationsRequest(snapshot: ControlsSnapshot): DateWithFunctionalLocationsRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const date = forceDateToBrusselsTime(period.end).toISOString();

    return {
        date,
        locations: functionalLocationSelections,
    };
}

export function createDateWithFunctionalLocationsAndLanguageRequest(snapshot: ControlsSnapshot, fleetCustomerWithConfiguration:FleetCustomerWithConfiguration): DateWithFunctionalLocationsAndLanguageRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const date = forceDateToBrusselsTime(period.end).toISOString();

    return {
        date,
        locations: functionalLocationSelections,
        language:fleetCustomerWithConfiguration.activeLanguage.type
    };
}

export function createDateTodayWithFunctionalLocationsRequest(snapshot: ControlsSnapshot): DateWithFunctionalLocationsRequest {
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const date = forceDateToBrusselsTime(new Date()).toISOString();

    return {
        date,
        locations: functionalLocationSelections,
    };
}

export function createMaxDateTodayWithFunctionalLocationsRequest(snapshot: ControlsSnapshot): DateWithFunctionalLocationsRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const functionalLocationSelections = snapshot.getValueForParam<FunctionalLocationSelection[]>(ParamKey.FunctionalLocationSelections);
    const date = period.end > new Date() ? forceDateToBrusselsTime(new Date()).toISOString() : forceDateToBrusselsTime(period.end).toISOString();

    return {
        date,
        locations: functionalLocationSelections,
    };
}

export function hasInspectionPeriod(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
