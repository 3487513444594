import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {
    fetchEnhancedActiveFleetReportFailure,
    fetchEnhancedActiveFleetReportRequest,
    fetchEnhancedActiveFleetReportSuccess,
    fetchExtendedInspectionReportFailure,
    fetchExtendedInspectionReportRequest,
    fetchExtendedInspectionReportSuccess,
    fetchExtendedServiceReportFailure,
    fetchExtendedServiceReportRequest,
    fetchExtendedServiceReportSuccess,
    fetchMountedTireDetailsReportFailure,
    fetchMountedTireDetailsReportRequest,
    fetchMountedTireDetailsReportSuccess,
    fetchVehiclesDueForInspectionReportFailure,
    fetchVehiclesDueForInspectionReportRequest,
    fetchVehiclesDueForInspectionReportSuccess,
    fetchVehicleJobListReportFailure,
    fetchVehicleJobListReportRequest,
    fetchVehicleJobListReportSuccess,
    fetchRemovedAndFittedTiresReportRequest,
    fetchRemovedAndFittedTiresReportSuccess,
    fetchRemovedAndFittedTiresReportFailure,
    fetchUnmountedTiresReportRequest,
    fetchUnmountedTiresReportSuccess,
    fetchUnmountedTiresReportFailure,
    fetchTireStockReportSuccess,
    fetchTireStockReportFailure,
    fetchTireStockReportRequest,
    fetchTireStockCompaniesRequest,
    fetchTireStockCompaniesSuccess,
    fetchVehiclesCurrentStatusReportRequest,
    fetchVehiclesCurrentStatusReportFailure,
    fetchVehiclesCurrentStatusReportSuccess,
    fetchInvoiceByVehicleReportFailure,
    fetchInvoiceByVehicleReportRequest,
    fetchInvoiceByVehicleReportSuccess,
    fetchBreakdownListReportFailure,
    fetchBreakdownListReportSuccess,
    fetchBreakdownListReportRequest,
    fetchMasterFleetListReportRequest,
    fetchMasterFleetListReportFailure,
    fetchMasterFleetListReportSuccess,
    fetchTireHistoryReportRequest,
    fetchTireHistoryReportFailure,
    fetchTireHistoryReportSuccess,
    fetchDamagedTireDetailsReportRequest,
    fetchDamagedTireDetailsReportSuccess,
    fetchDamagedTireDetailsReportFailure,
    fetchScheduledReportsRequest,
    fetchScheduledReportsFailure,
    fetchScheduledReportsSuccess,
    createScheduledReportsRequest,
    createScheduledReportsFailure,
    createScheduledReportsSuccess,
    updateScheduledReportsRequest,
    updateScheduledReportsSuccess,
    updateScheduledReportsFailure,
    deleteScheduledReportsRequest,
    deleteScheduledReportsFailure,
    deleteScheduledReportsSuccess,
    fetchInvoiceItemByVehicleReportRequest,
    fetchInvoiceItemByVehicleReportFailure,
    fetchInvoiceItemByVehicleReportSuccess,
    fetchInspectionDetailsReportRequest,
    fetchInspectionDetailsReportFailure,
    fetchInspectionDetailsReportSuccess,
    fetchUnbilledJobsReportRequest,
    fetchUnbilledJobsReportFailure,
    fetchUnbilledJobsReportSuccess,
    fetchInvoiceByJobReportRequest,
    fetchInvoiceByJobReportFailure,
    fetchInvoiceByJobReportSuccess,
    fetchTirePerformanceReportRequest,
    fetchTirePerformanceReportFailure,
    fetchTirePerformanceReportSuccess,
    fetchTirePerformanceOptionsRequest,
    fetchTirePerformanceOptionsFailure,
    fetchTirePerformanceOptionsSuccess,
    fetchReportInfoRequest,
    fetchReportInfoFailure,
    fetchReportInfoSuccess,
    fetchReportDownloadRequest,
    fetchReportDownloadFailure,
    fetchReportDownloadSuccess,
    fetchJobVerificationReportRequest,
    fetchJobVerificationReportFailure,
    fetchJobVerificationReportSuccess,
    fetchMileageBillingReportRequest,
    fetchMileageBillingReportFailure,
    fetchMileageBillingReportSuccess,
    fetchBreakdownAndServicesBillingReportRequest,
    fetchBreakdownAndServicesBillingReportSuccess,
    fetchBreakdownAndServicesBillingReportFailure,
    fetchTCUSensorFitmentsReportRequest,
    fetchTCUSensorFitmentsReportFailure,
    fetchTCUSensorFitmentsReportSuccess,
    fetchDamagedTiresBillingReportSuccess,
    fetchDamagedTiresBillingReportFailure,
    fetchDamagedTiresBillingReportRequest,
    fetchOdometerListReportRequest,
    fetchOdometerListReportFailure,
    fetchOdometerListReportSuccess,
} from './actions';
import {
    EnhancedActiveFleetReportRequest,
    ExtendedInspectionReportRequest,
    MountedTireDetailsReportRequest,
    ReportsActionTypes,
    VehiclesDueForInspectionReportRequest,
    VehicleJobListReportRequest,
    RemovedAndFittedTiresReportRequest,
    TireStockReportRequest,
    TireStockCompany,
    ReportInfo,
    VehiclesCurrentStatusReportRequest,
    InvoiceByVehicleReportRequest,
    MasterFleetListReportRequest,
    TireHistoryReportRequest,
    DamagedTireDetailsReportRequest,
    ScheduledReport,
    InvoiceItemByVehicleReportRequest,
    InspectionDetailsReportRequest,
    UnmountedTiresReportRequest,
    UnbilledJobsReportRequest,
    InvoiceByJobReportRequest,
    TirePerformanceReportRequest,
    TirePerformanceOptions,
    ReportDownloadUrl,
    BreakdownAndServicesBillingReportRequest,
    JobVerificationReportRequest,
    MileageBillingReportRequest,
    TCUSensorFitmentsReportRequest,
    DamagedTiresBillingReportRequest, OdometerListReportRequest
} from './types';
import {ApplicationState} from '../index';
import ReportsApi from '../../api/reports-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApiResult} from '../../types/api-result';
import {PollingResult} from '../../pages/reports/polling-result';
import {ReportDownloadHandler} from '../../pages/reports/report-download-handler';

const fetchFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetchEnhancedActiveFleetReport(action: ActionType<typeof fetchEnhancedActiveFleetReportRequest>) {
    try {
        const request: EnhancedActiveFleetReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getEnhancedActiveFleetReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchEnhancedActiveFleetReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchEnhancedActiveFleetReportSuccess, fetchEnhancedActiveFleetReportFailure);
        }
    } catch (err) {
        yield put(fetchEnhancedActiveFleetReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchMasterFleetListReport(action: ActionType<typeof fetchMasterFleetListReportRequest>) {
    try {
        const request: MasterFleetListReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getMasterFleetListReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchMasterFleetListReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchMasterFleetListReportSuccess, fetchMasterFleetListReportFailure);
        }
    } catch (err) {
        yield put(fetchMasterFleetListReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchVehicleJobListReport(action: ActionType<typeof fetchVehicleJobListReportRequest>) {
    try {
        const request: VehicleJobListReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getVehicleJobListReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchVehicleJobListReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchVehicleJobListReportSuccess, fetchVehicleJobListReportFailure);
        }
    } catch (err) {
        yield put(fetchVehicleJobListReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchUnbilledJobsReport(action: ActionType<typeof fetchUnbilledJobsReportRequest>) {
    try {
        const request: UnbilledJobsReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getUnbilledJobsReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchUnbilledJobsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchUnbilledJobsReportSuccess, fetchUnbilledJobsReportFailure);
        }
    } catch (err) {
        yield put(fetchUnbilledJobsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchDamagedTireDetailsReport(action: ActionType<typeof fetchDamagedTireDetailsReportRequest>) {
    try {
        const request: DamagedTireDetailsReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getDamagedTireDetailsReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchDamagedTireDetailsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchDamagedTireDetailsReportSuccess, fetchDamagedTireDetailsReportFailure);
        }
    } catch (err) {
        yield put(fetchDamagedTireDetailsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchMountedTireDetailsReport(action: ActionType<typeof fetchMountedTireDetailsReportRequest>) {
    try {
        const request: MountedTireDetailsReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getMountedTireDetailsReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchMountedTireDetailsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchMountedTireDetailsReportSuccess, fetchMountedTireDetailsReportFailure);
        }
    } catch (err) {
        yield put(fetchMountedTireDetailsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchExtendedInspectionReport(action: ActionType<typeof fetchExtendedInspectionReportRequest>) {
    try {
        const request: ExtendedInspectionReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getExtendedInspectionReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchExtendedInspectionReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchExtendedInspectionReportSuccess, fetchExtendedInspectionReportFailure);
        }
    } catch (err) {
        yield put(fetchExtendedInspectionReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchExtendedServiceReport(action: ActionType<typeof fetchExtendedServiceReportRequest>) {
    try {
        const request: ExtendedInspectionReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getExtendedServiceReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchExtendedServiceReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchExtendedServiceReportSuccess, fetchExtendedServiceReportFailure);
        }
    } catch (err) {
        yield put(fetchExtendedServiceReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchVehiclesDueForInspectionReport(action: ActionType<typeof fetchVehiclesDueForInspectionReportRequest>) {
    try {
        const request: VehiclesDueForInspectionReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getVehiclesDueForInspectionReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchVehiclesDueForInspectionReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchVehiclesDueForInspectionReportSuccess, fetchVehiclesDueForInspectionReportFailure);
        }
    } catch (err) {
        yield put(fetchVehiclesDueForInspectionReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchRemovedAndFittedTiresReport(action: ActionType<typeof fetchRemovedAndFittedTiresReportRequest>) {
    try {
        const request: RemovedAndFittedTiresReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getRemovedAndFittedTiresReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchRemovedAndFittedTiresReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchRemovedAndFittedTiresReportSuccess, fetchRemovedAndFittedTiresReportFailure);
        }
    } catch (err) {
        yield put(fetchRemovedAndFittedTiresReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchMileageBillingReport(action: ActionType<typeof fetchMileageBillingReportRequest>) {
    try {
        const request: MileageBillingReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getMileageBillingReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchMileageBillingReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchMileageBillingReportSuccess, fetchMileageBillingReportFailure);
        }
    } catch (err) {
        yield put(fetchMileageBillingReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchBreakdownAndServicesBillingReport(action: ActionType<typeof fetchBreakdownAndServicesBillingReportRequest>) {
    try {
        const request: BreakdownAndServicesBillingReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getBreakdownAndServicesBillingReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchBreakdownAndServicesBillingReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchBreakdownAndServicesBillingReportSuccess, fetchBreakdownAndServicesBillingReportFailure);
        }
    } catch (err) {
        yield put(fetchBreakdownAndServicesBillingReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchDamagedTiresBillingReport(action: ActionType<typeof fetchDamagedTiresBillingReportRequest>) {
    try {
        const request: DamagedTiresBillingReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getDamagedTiresBillingReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchDamagedTiresBillingReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchDamagedTiresBillingReportSuccess, fetchDamagedTiresBillingReportFailure);
        }
    } catch (err) {
        yield put(fetchDamagedTiresBillingReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


export function* handleFetchUnmountedTiresReport(action: ActionType<typeof fetchUnmountedTiresReportRequest>) {
    try {
        const request: UnmountedTiresReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getUnmountedTiresReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchUnmountedTiresReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchUnmountedTiresReportSuccess, fetchUnmountedTiresReportFailure);
        }
    } catch (err) {
        yield put(fetchUnmountedTiresReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTirePerformanceReport(action: ActionType<typeof fetchTirePerformanceReportRequest>) {
    try {
        const request: TirePerformanceReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTirePerformanceReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchTirePerformanceReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTirePerformanceReportSuccess, fetchTirePerformanceReportFailure);
        }
    } catch (err) {
        yield put(fetchTirePerformanceReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTireStockReport(action: ActionType<typeof fetchTireStockReportRequest>) {
    try {
        const request: TireStockReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTireStockReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchTireStockReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTireStockReportSuccess, fetchTireStockReportFailure);
        }
    } catch (err) {
        yield put(fetchTireStockReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTireHistoryReport(action: ActionType<typeof fetchTireHistoryReportRequest>) {
    try {
        const request: TireHistoryReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTireHistoryReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchTireHistoryReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTireHistoryReportSuccess, fetchTireHistoryReportFailure);
        }
    } catch (err) {
        yield put(fetchTireHistoryReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTirePerformanceOptions(action: ActionType<typeof fetchTirePerformanceOptionsRequest>) {
    try {
        const fleetCustomerId = action.payload;
        const result: ApiResult<TirePerformanceOptions> = yield call(ReportsApi.getTirePerformanceOptions, fleetCustomerId);
        if (result.error) {
            yield put(fetchTirePerformanceOptionsFailure(result.error));
        } else {
            yield put(fetchTirePerformanceOptionsSuccess(result.data!));
        }
    } catch (err) {
        yield put(fetchTirePerformanceOptionsFailure(err));
    }
}

export function* handleFetchTireStockCompanies(action: ActionType<typeof fetchTireStockCompaniesRequest>) {
    try {
        const fleetCustomerId = action.payload;
        const result: ApiResult<TireStockCompany[]> = yield call(ReportsApi.getTireStockCompanies, fleetCustomerId);
        if (result.error) {
            yield put(fetchTireStockReportFailure(result.error));
        } else {
            yield put(fetchTireStockCompaniesSuccess(result.data!));
        }
    } catch (err) {
        yield put(fetchTireStockReportFailure(err));
    }
}

export function* handleFetchVehiclesCurrentStatusReport(action: ActionType<typeof fetchVehiclesCurrentStatusReportRequest>) {
    try {
        const request: VehiclesCurrentStatusReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getVehiclesCurrentStatusReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchVehiclesCurrentStatusReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchVehiclesCurrentStatusReportSuccess, fetchVehiclesCurrentStatusReportFailure);
        }
    } catch (err) {
        yield put(fetchVehiclesCurrentStatusReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchInvoiceByJobReport(action: ActionType<typeof fetchInvoiceByJobReportRequest>) {
    try {
        const request: InvoiceByJobReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getInvoiceByJobReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchInvoiceByJobReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchInvoiceByJobReportSuccess, fetchInvoiceByJobReportFailure);
        }
    } catch (err) {
        yield put(fetchInvoiceByJobReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchInvoiceByVehicleReport(action: ActionType<typeof fetchInvoiceByVehicleReportRequest>) {
    try {
        const request: InvoiceByVehicleReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getInvoiceByVehicleReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchInvoiceByVehicleReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchInvoiceByVehicleReportSuccess, fetchInvoiceByVehicleReportFailure);
        }
    } catch (err) {
        yield put(fetchInvoiceByVehicleReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchInvoiceItemByVehicleReport(action: ActionType<typeof fetchInvoiceItemByVehicleReportRequest>) {
    try {
        const request: InvoiceItemByVehicleReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getInvoiceItemByVehicleReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchInvoiceItemByVehicleReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchInvoiceItemByVehicleReportSuccess, fetchInvoiceItemByVehicleReportFailure);
        }
    } catch (err) {
        yield put(fetchInvoiceItemByVehicleReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchInspectionDetailsReport(action: ActionType<typeof fetchInspectionDetailsReportRequest>) {
    try {
        const request: InspectionDetailsReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getInspectionDetailsReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchInspectionDetailsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchInspectionDetailsReportSuccess, fetchInspectionDetailsReportFailure);
        }
    } catch (err) {
        yield put(fetchInspectionDetailsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTCUSensorFitmentsReport(action: ActionType<typeof fetchTCUSensorFitmentsReportRequest>) {
    try {
        const request: TCUSensorFitmentsReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTCUSensorFitmentsReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchTCUSensorFitmentsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTCUSensorFitmentsReportSuccess, fetchTCUSensorFitmentsReportFailure);
        }
    } catch (err) {
        yield put(fetchTCUSensorFitmentsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchBreakdownListReport(action: ActionType<typeof fetchBreakdownListReportRequest>) {
    try {
        const request: InvoiceByVehicleReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getBreakdownListReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchBreakdownListReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchBreakdownListReportSuccess, fetchBreakdownListReportFailure);
        }
    } catch (err) {
        yield put(fetchBreakdownListReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchJobVerificationReport(action: ActionType<typeof fetchJobVerificationReportRequest>) {
    try {
        const request: JobVerificationReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getJobVerificationReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchJobVerificationReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchJobVerificationReportSuccess, fetchJobVerificationReportFailure);
        }
    } catch (err) {
        yield put(fetchJobVerificationReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchOdometerListReport(action: ActionType<typeof fetchOdometerListReportRequest>) {
    try {
        const request: OdometerListReportRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getOdometerListReport, request, fleetCustomerId);

        if (result.error) {
            yield put(fetchOdometerListReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, fleetCustomerId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchOdometerListReportSuccess, fetchOdometerListReportFailure);
        }
    } catch (err) {
        yield put(fetchOdometerListReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchScheduledReports(action: ActionType<typeof fetchScheduledReportsRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ScheduledReport[]> = yield call(ReportsApi.getScheduledReports, fleetCustomerId);

        if (result.error) {
            yield put(fetchScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(fetchScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleCreateScheduledReport(action: ActionType<typeof createScheduledReportsRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ScheduledReport> = yield call(ReportsApi.addScheduledReport, action.payload, fleetCustomerId);

        if (result.error) {
            yield put(createScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(createScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleUpdateScheduledReport(action: ActionType<typeof updateScheduledReportsRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<ScheduledReport> = yield call(ReportsApi.updateScheduledReport, action.payload, fleetCustomerId);

        if (result.error) {
            yield put(updateScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(updateScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}
export function* handleDeleteScheduledReport(action: ActionType<typeof deleteScheduledReportsRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<string> = yield call(ReportsApi.deleteScheduledReport, action.payload, fleetCustomerId);

        if (result.error) {
            yield put(deleteScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(deleteScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetReportInfo(action: ActionType<typeof fetchReportInfoRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const reportId = action.payload;

        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getReportInfo, reportId, fleetCustomerId);

        if (result.error) {
            yield call(fetchReportInfoFailure, result.error);
        } else {
            const reportInfo = result.data!
            yield put(fetchReportInfoSuccess(reportInfo));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetReportDownloadUrl(action: ActionType<typeof fetchReportDownloadRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const reportInfo = action.payload;

        const result: ApiResult<ReportDownloadUrl> = yield call(ReportsApi.getReportDownloadUrl, reportInfo, fleetCustomerId);

        if (result.error) {
            yield call(fetchReportDownloadFailure, result.error);
        } else {
            yield put(fetchReportDownloadSuccess());
            ReportDownloadHandler.downloadReport(result.data!);
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchRequests() {
    yield takeEvery(ReportsActionTypes.FETCH_ENHANCED_ACTIVE_FLEET_REPORT_REQUEST, handleFetchEnhancedActiveFleetReport);
    yield takeEvery(ReportsActionTypes.FETCH_MASTER_FLEET_LIST_REPORT_REQUEST, handleFetchMasterFleetListReport);
    yield takeEvery(ReportsActionTypes.FETCH_VEHICLE_JOB_LIST_REPORT_REQUEST, handleFetchVehicleJobListReport);
    yield takeEvery(ReportsActionTypes.FETCH_UNBILLED_JOBS_REPORT_REQUEST, handleFetchUnbilledJobsReport);
    yield takeEvery(ReportsActionTypes.FETCH_DAMAGED_TIRE_DETAILS_REPORT_REQUEST, handleFetchDamagedTireDetailsReport);
    yield takeEvery(ReportsActionTypes.FETCH_MOUNTED_TIRE_DETAILS_REPORT_REQUEST, handleFetchMountedTireDetailsReport);
    yield takeEvery(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_REQUEST, handleFetchExtendedInspectionReport);
    yield takeEvery(ReportsActionTypes.FETCH_EXTENDED_SERVICE_REPORT_REQUEST, handleFetchExtendedServiceReport);
    yield takeEvery(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST, handleFetchVehiclesDueForInspectionReport);
    yield takeEvery(ReportsActionTypes.FETCH_REMOVED_AND_FITTED_TIRES_REPORT_REQUEST, handleFetchRemovedAndFittedTiresReport);
    yield takeEvery(ReportsActionTypes.FETCH_MILEAGE_BILLING_REPORT_REQUEST, handleFetchMileageBillingReport);
    yield takeEvery(ReportsActionTypes.FETCH_BREAKDOWN_AND_SERVICES_BILLING_REPORT_REQUEST, handleFetchBreakdownAndServicesBillingReport);
    yield takeEvery(ReportsActionTypes.FETCH_DAMAGED_TIRES_BILLING_REPORT_REQUEST, handleFetchDamagedTiresBillingReport);
    yield takeEvery(ReportsActionTypes.FETCH_UNMOUNTED_TIRES_REPORT_REQUEST, handleFetchUnmountedTiresReport);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_REQUEST, handleFetchTireStockReport);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_REQUEST, handleFetchTireStockCompanies);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_HISTORY_REPORT_REQUEST, handleFetchTireHistoryReport);
    yield takeEvery(ReportsActionTypes.FETCH_VEHICLES_CURRENT_STATUS_REPORT_REQUEST, handleFetchVehiclesCurrentStatusReport);
    yield takeEvery(ReportsActionTypes.FETCH_INVOICE_BY_JOB_REPORT_REQUEST, handleFetchInvoiceByJobReport);
    yield takeEvery(ReportsActionTypes.FETCH_INVOICE_BY_VEHICLE_REPORT_REQUEST, handleFetchInvoiceByVehicleReport);
    yield takeEvery(ReportsActionTypes.FETCH_INVOICE_ITEM_BY_VEHICLE_REPORT_REQUEST, handleFetchInvoiceItemByVehicleReport);
    yield takeEvery(ReportsActionTypes.FETCH_BREAKDOWN_LIST_REPORT_REQUEST, handleFetchBreakdownListReport);
    yield takeEvery(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_REQUEST, handleFetchInspectionDetailsReport);
    yield takeEvery(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST, handleFetchTCUSensorFitmentsReport);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_REPORT_REQUEST, handleFetchTirePerformanceReport);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_OPTIONS_REQUEST, handleFetchTirePerformanceOptions);
    yield takeEvery(ReportsActionTypes.FETCH_JOB_VERIFICATION_REPORT_REQUEST, handleFetchJobVerificationReport);
    yield takeEvery(ReportsActionTypes.FETCH_ODOMETER_LIST_REPORT_REQUEST, handleFetchOdometerListReport);
    yield takeEvery(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_REQUEST, handleFetchScheduledReports);
    yield takeEvery(ReportsActionTypes.CREATE_SCHEDULED_REPORT_REQUEST, handleCreateScheduledReport);
    yield takeEvery(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_REQUEST, handleUpdateScheduledReport);
    yield takeEvery(ReportsActionTypes.DELETE_SCHEDULED_REPORT_REQUEST, handleDeleteScheduledReport);
    yield takeEvery(ReportsActionTypes.FETCH_REPORT_INFO_REQUEST, handleGetReportInfo);
    yield takeEvery(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_REQUEST, handleGetReportDownloadUrl);
}

function* reportsSaga() {
    yield all([fork(watchRequests)]);
}

export default reportsSaga;
