import {
    TopRetreadTiresFittedByServiceProvidersData,
} from "../../../../store/analytics/types/top-tires-fitted-by-service-providers-types";
import {onlyUnique} from "../../../../utils/collection-helper";

export const serviceProviderIdField = 'serviceProviderIdField';
export const serviceProviderField = 'serviceProviderField';
export const retreadNewTiresField = 'retreadNewTiresField';
export const retreadTiresField = 'retreadTiresField';

export function buildRetreadChartData(data: TopRetreadTiresFittedByServiceProvidersData[]): any[] {
    const datapoints: any[] = [];
     const duplicateNames = data.filter(d => data.filter(dd => dd.name.toUpperCase() === d.name.toUpperCase()).length > 1)
         .map(d => d.name.toUpperCase())
         .filter(onlyUnique);

     data.forEach((row) => {
         const datapoint = {};
         datapoint[serviceProviderIdField] = row.id;
         datapoint[serviceProviderField] = duplicateNames.includes(row.name.toUpperCase())
             ? `${row.name.toUpperCase()}, ${row.city.toUpperCase()}`
             : row.name.toUpperCase();
         datapoint[retreadNewTiresField] = row.newCount;
         datapoint[retreadTiresField] = row.retreadCount;
         datapoints.push(datapoint);
     });
     return datapoints.reverse();
}