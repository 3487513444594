import {ParamKey} from '../../../store/dashboard';

export class ControlsSnapshot {

    private readonly paramValues: Map<ParamKey, ControlValue>;

    constructor(values?: Map<ParamKey, ControlValue>) {
        this.paramValues = values || new Map<ParamKey, ControlValue>();
    }

    public getValueForParam<T>(key: ParamKey): T {
        const value = this.paramValues.get(key);
        return value!.value as T;
    }

    public getControlValueForParam(key: ParamKey): ControlValue | undefined {
        return this.paramValues.get(key);
    }

    public getSummary(key: ParamKey): string {
        const value = this.paramValues.get(key);
        return value!.displayValue;
    }

    public has(key: ParamKey) :boolean{
        return this.paramValues.has(key);
    }

    public get size(): number {
        return this.paramValues.size;
    }

    public equalsOnKey(key: ParamKey, otherControlValue?: ControlValue): boolean {
        if (otherControlValue && this.paramValues.has(key)) {
            return this.paramValues.get(key)!.equals(otherControlValue);
        }
        return false;
    }

    public equals(snapshot: ControlsSnapshot): boolean {
        let equal = this.size === snapshot.size;
        if (equal) {
            const keys = Array.from(this.paramValues.keys());
            keys.forEach((key) => {
                if (equal) {
                    equal = this.equalsOnKey(key, snapshot.getControlValueForParam(key));
                }
            });
        }
        return equal;
    }
}

export interface ControlValue {
    value: any;
    displayValue: string;
    equals(other: ControlValue);
}
