import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {Component, MouseEvent as ReactMouseEvent} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import ReactTooltip from 'react-tooltip';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import * as layoutActions from '../../../store/layout/actions';
import {Paged, RequestStatus, SortDirection} from '../../../store/shared/types';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './scheduled-report-management.module.scss';
import tableStyles from '../../shared/components/table.module.scss';
import {
    AdminScheduledReport,
    AdminScheduledReportRequest,
    getAdminScheduledReportsRequest, ScheduledReportRunStatus,
    ScheduledReportsSortColumn
} from '../../../store/scheduled-report-management';
import Paging from '../../jobs/jobs-table/paging/paging';
import {getReportName} from '../../reports/report-catalog';
import {getRecipientsDisplay, getReportScheduleDisplay} from '../../reports/utils/scheduled-reports-helper';
import TableHeader, {TableHeaderFilterType} from '../../shared/components/table-header';
import {buildSearchParameters} from '../../../utils/query-parameter-helpers';
import ReportsApi from '../../../api/reports-api';
import {unselectFleetCustomer} from '../../../store/shared/actions';
import {FleetCustomerWithConfiguration} from '../../../store/fleet-customers';
import {SelectionOption} from "../../shared/components/types/selection-option";

class ScheduledReportManagementPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar, adminScheduledReports, t} = this.props;
        dispatchToggleSidebar(false);

        const lastRunStatusOptions = [{
            key: ScheduledReportRunStatus.SUCCEEDED,
            label: t('Succeeded')
        }, {key: ScheduledReportRunStatus.FAILED, label: t('Failed')}];

        this.state = {
            page: 1,
            itemsPerPage: 10,
            sortDirection: SortDirection.Ascending,
            sortColumn: ScheduledReportsSortColumn.None,
            lastRunStatusOptions,
            lastRunStatusExcludedOptions: []
        }

        if (!adminScheduledReports) {
            this.fetchData();
        }
    }

    public render(): JSX.Element {
        const {
            t,
            dispatchNavigateTo,
            getAdminScheduledReportsRequestStatus,
            adminScheduledReports
        } = this.props;

        const {
            page,
            itemsPerPage,
            sortColumn,
            sortDirection,
            fleetCustomerNameContains,
            nameContains,
            reportTypeContains,
            recipientsContains,
            lastRunStatusOptions,
            lastRunStatusExcludedOptions
        } = this.state;

        return <div className={styles.container}>
            <AdminPageHeader
                title={t('Scheduled Report Management')}
                onBack={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}
            />
            <div className={styles.content}>
                <div className={styles.contentPanel}>
                    <div className={styles.table}>
                        <div className={`${styles.row} ${styles.header}`}>
                            <TableHeader
                                title={t('Fleet')}
                                column={ScheduledReportsSortColumn.fleetCustomerName}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(ScheduledReportsSortColumn.fleetCustomerName, text)}
                                filterText={fleetCustomerNameContains}
                            />
                            <TableHeader
                                title={t('Schedule Name')}
                                column={ScheduledReportsSortColumn.name}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(ScheduledReportsSortColumn.name, text)}
                                filterText={nameContains}
                            />
                            <TableHeader
                                title={t('Report Name')}
                                column={ScheduledReportsSortColumn.reportType}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(ScheduledReportsSortColumn.reportType, text)}
                                filterText={reportTypeContains}
                            />
                            <TableHeader
                                title={t('Granularity')}
                                column={ScheduledReportsSortColumn.granularity}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.NONE}
                            />
                            <TableHeader
                                title={t('Recipients')}
                                column={ScheduledReportsSortColumn.recipients}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(ScheduledReportsSortColumn.recipients, text)}
                                filterText={recipientsContains}
                            />
                            <TableHeader
                                title={t('Status')}
                                column={ScheduledReportsSortColumn.lastRunStatus}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SELECT}
                                onApplySelectFilter={(options) => this.onApplySelectFilter(ScheduledReportsSortColumn.lastRunStatus, options)}
                                filterOptions={lastRunStatusOptions}
                                filterExcludedOptions={lastRunStatusExcludedOptions}
                                filterText={recipientsContains}
                            />
                            <div className={`${tableStyles.tableColumn} ${tableStyles.tableColumnTiny}`}/>
                        </div>
                        {adminScheduledReports && !getAdminScheduledReportsRequestStatus?.isInProgress ?
                            <div className={styles.rows}>
                                {getAdminScheduledReportsRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    </div>
                                ) : (
                                    adminScheduledReports.pageData?.map((adminScheduledReport) => (
                                        <div
                                            className={styles.row}
                                            key={adminScheduledReport.id}
                                            onClick={() => this.onScheduledReportClick(adminScheduledReport)}>
                                            <div className={tableStyles.tableColumn}>
                                                {adminScheduledReport.fleetCustomerName}
                                            </div>
                                            <div className={tableStyles.tableColumn}>
                                                {adminScheduledReport.name}
                                            </div>
                                            <div className={tableStyles.tableColumn}>
                                                {getReportName(adminScheduledReport.reportType, t)}
                                            </div>
                                            <div className={tableStyles.tableColumn}>
                                                {getReportScheduleDisplay(adminScheduledReport, t)}
                                            </div>
                                            <div className={tableStyles.tableColumn} data-tip
                                                 data-for={adminScheduledReport.id}>
                                                <ReactTooltip className={styles.tooltip} border effect="solid"
                                                              backgroundColor="#000000" id={adminScheduledReport.id}>
                                                    {adminScheduledReport.recipients.map((rec) => (
                                                        <div className={styles.recipient}
                                                             key={adminScheduledReport.id + rec}>{rec}</div>
                                                    ))}
                                                </ReactTooltip>
                                                {getRecipientsDisplay(adminScheduledReport.recipients, t)}
                                            </div>
                                            <div className={tableStyles.tableColumn}>
                                                {adminScheduledReport.lastRunStatus}
                                            </div>
                                            <div
                                                className={`${tableStyles.tableColumn} ${tableStyles.tableColumnLink} ${tableStyles.tableColumnTiny} ${tableStyles.tableColumnCentered}`}
                                                onClick={(event) => this.onDownloadLog(event, adminScheduledReport)}> {t('Logs')}</div>
                                        </div>
                                    ))
                                )}
                            </div>
                            :
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            </div>
                        }
                    </div>
                </div>
                {adminScheduledReports ? (
                    <div className={styles.pager}>
                        <Paging
                            currentPage={page}
                            initialItemsPerPage={itemsPerPage}
                            onPreviousPage={() => this.onPreviousPage()}
                            onNextPage={() => this.onNextPage()}
                            onJumpToPage={(index: number) => this.onJumpToPage(index)}
                            total={adminScheduledReports!.totalCount}
                            onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                            itemsPerPageBackground="white"
                        />
                    </div>
                ) : null}
            </div>
        </div>;
    }


    private onItemsPerPageChanged(items: number): void {
        this.setState({itemsPerPage: items}, () => {
            this.fetchData()
        });
    }

    private onPreviousPage(): void {
        const {page} = this.state;
        this.setState({page: page - 1}, () => {
            this.fetchData()
        });
    }

    private onNextPage(): void {
        const {page} = this.state;
        this.setState({page: page + 1}, () => {
            this.fetchData()
        });
    }

    private onJumpToPage(pageIndex: number): void {
        this.setState({page: pageIndex}, () => {
            this.fetchData()
        });
    }

    private onToggleSort(newSortColumn: ScheduledReportsSortColumn): void {
        const {sortColumn, sortDirection} = this.state;
        const newSortDirection =
            newSortColumn === sortColumn ? this.toggleSortDirection(sortDirection) : SortDirection.Descending;
        this.setState({sortColumn: newSortColumn, sortDirection: newSortDirection}, () => {
            this.fetchData()
        });
    }

    private toggleSortDirection(direction: SortDirection): SortDirection {
        switch (direction) {
            case SortDirection.Descending:
                return SortDirection.Ascending;
            case SortDirection.Ascending:
                return SortDirection.None;
            case SortDirection.None:
            default:
                return SortDirection.Descending;
        }
    }

    private onApplySearchFilter(column: ScheduledReportsSortColumn, text: string): void {
        const {
            fleetCustomerNameContains,
            reportTypeContains,
            recipientsContains,
            nameContains,
            lastRunStatusExcludedOptions
        } = this.state;

        const fleetCustomerNameContainsState =
            column === ScheduledReportsSortColumn.fleetCustomerName ? text : fleetCustomerNameContains;
        const reportTypeContainsState = column === ScheduledReportsSortColumn.reportType ? text : reportTypeContains;
        const nameContainsState = column === ScheduledReportsSortColumn.name ? text : nameContains;
        const recipientsContainsState = column === ScheduledReportsSortColumn.recipients ? text : recipientsContains;
        const lastRunStatusExcludedOptionsState = lastRunStatusExcludedOptions;

        this.setState({
            fleetCustomerNameContains: fleetCustomerNameContainsState,
            nameContains: nameContainsState,
            reportTypeContains: reportTypeContainsState,
            recipientsContains: recipientsContainsState,
            lastRunStatusExcludedOptions: lastRunStatusExcludedOptionsState
        }, () => {
            this.fetchData()
        });
    }

    private onApplySelectFilter(column: ScheduledReportsSortColumn, options: SelectionOption[]): void {
        const {
            fleetCustomerNameContains,
            reportTypeContains,
            recipientsContains,
            nameContains,
            lastRunStatusExcludedOptions
        } = this.state;

        const fleetCustomerNameContainsState =
            fleetCustomerNameContains;
        const reportTypeContainsState = reportTypeContains;
        const nameContainsState = nameContains;
        const recipientsContainsState = recipientsContains;
        const lastRunStatusExcludedOptionsState = column === ScheduledReportsSortColumn.lastRunStatus ? options : lastRunStatusExcludedOptions;

        this.setState({
            fleetCustomerNameContains: fleetCustomerNameContainsState,
            nameContains: nameContainsState,
            reportTypeContains: reportTypeContainsState,
            recipientsContains: recipientsContainsState,
            lastRunStatusExcludedOptions: lastRunStatusExcludedOptionsState
        }, () => {
            this.fetchData()
        });
    }

    private fetchData() {
        const {dispatchGetAdminScheduledReports} = this.props;
        const {
            page,
            itemsPerPage,
            sortColumn,
            sortDirection,
            fleetCustomerNameContains,
            nameContains,
            reportTypeContains,
            recipientsContains,
            lastRunStatusExcludedOptions
        } = this.state;
        dispatchGetAdminScheduledReports({
            page,
            itemsPerPage,
            fleetCustomerNameContains,
            nameContains,
            reportTypeContains,
            recipientsContains,
            lastRunStatusExcludedOptions:lastRunStatusExcludedOptions? lastRunStatusExcludedOptions.map(o => o.key as ScheduledReportRunStatus) : [],
            sort: {
                direction: sortDirection,
                column: sortColumn
            },
        });
    }

    private onScheduledReportClick(report: AdminScheduledReport) {
        const {dispatchNavigateTo, dispatchUnselectFleetCustomer, currentLocation, fleetCustomer} = this.props;
        const returnUrl = currentLocation.search ? `${currentLocation.pathname}${currentLocation.search}` : currentLocation.pathname;
        const returnUrlEncoded = encodeURIComponent(returnUrl);
        const queryParameters = buildSearchParameters(
            {
                scheduledReportId: report.id,
                returnUrl: returnUrlEncoded
            },
        );

        // If there currently is a fleet customer selected in the state, and it is different form the one of the report, unselect it
        if (fleetCustomer && fleetCustomer.id !== report.fleetCustomerId) {
            dispatchUnselectFleetCustomer();
        }

        dispatchNavigateTo({
            pathname: `/${report.fleetCustomerId}${RouteUrl.ReportScheduler}`,
            search: queryParameters
        });
    }

    private onDownloadLog(event: ReactMouseEvent<HTMLDivElement, MouseEvent>, report: AdminScheduledReport): void {
        event.stopPropagation();
        event.preventDefault();
        // eslint-disable-next-line react/destructuring-assignment
        ReportsApi.downloadScheduledReportLog(report.id, report.fleetCustomerId).then(() => {
        });
    }
}

const mapStateToProps = ({scheduledReports, router, authentication}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    adminScheduledReports: scheduledReports.adminScheduledReports,
    getAdminScheduledReportsRequestStatus: scheduledReports.getAdminScheduledReportsRequestStatus,
    fleetCustomer: authentication.fleetCustomer
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchGetAdminScheduledReports: (request: AdminScheduledReportRequest) => dispatch(getAdminScheduledReportsRequest(request)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchUnselectFleetCustomer: () => dispatch(unselectFleetCustomer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ScheduledReportManagementPage));

interface PropsFromState {
    adminScheduledReports?: Paged<AdminScheduledReport>;
    getAdminScheduledReportsRequestStatus?: RequestStatus;
    currentLocation: Location;
    fleetCustomer?: FleetCustomerWithConfiguration;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchGetAdminScheduledReports: typeof getAdminScheduledReportsRequest;
    dispatchUnselectFleetCustomer: typeof unselectFleetCustomer;
}

interface OwnProps {
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    page: number;
    itemsPerPage: number;
    sortColumn: ScheduledReportsSortColumn;
    sortDirection: SortDirection;
    fleetCustomerNameContains?: string;
    nameContains?: string;
    reportTypeContains?: string;
    recipientsContains?: string;
    lastRunStatusOptions: SelectionOption[];
    lastRunStatusExcludedOptions: SelectionOption[];
}

type AllState = OwnState;
