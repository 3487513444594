import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {FleetCustomer, FleetCustomerAction, FleetCustomerActionTypes, FleetCustomersState} from './types';

function initialState(): FleetCustomersState {
    return {
        fleetCustomers: [],
    };
}

const sortByName = (a: FleetCustomer, b: FleetCustomer) => a.name.localeCompare(b.name)

const reducer: Reducer<FleetCustomersState, FleetCustomerAction> = (
    state = initialState(),
    action,
): FleetCustomersState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case FleetCustomerActionTypes.CREATE_FLEET_CUSTOMER_REQUEST: {
            return { ...state };
        }
        case FleetCustomerActionTypes.CREATE_FLEET_CUSTOMER_SUCCESS: {
            const fleetCustomers = state.fleetCustomers.concat(action.payload);
            return { ...state, fleetCustomers: fleetCustomers.sort(sortByName) };
        }
        case FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_REQUEST: {
            return { ...state, deleteFleetCustomerRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_SUCCESS: {
            const fleetCustomers = state.fleetCustomers.filter((fc) => fc.id !== action.payload.id);
            return {
                ...state,
                fleetCustomers,
                deleteFleetCustomerRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_FAILURE: {
            return {
                ...state,
                deleteFleetCustomerRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_REQUEST: {
            return {
                ...state,
                fleetCustomers: [],
                getFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                fleetCustomers: action.payload.sort(sortByName),
                getFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_FAILURE: {
            return {
                ...state,
                getFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case FleetCustomerActionTypes.UPDATE_FLEET_CUSTOMER_REQUEST: {
            return { ...state };
        }
        case FleetCustomerActionTypes.UPDATE_FLEET_CUSTOMER_SUCCESS: {
            const result = action.payload;
            const fleetCustomers = JSON.parse(JSON.stringify(state.fleetCustomers));
            fleetCustomers.forEach((fc) => {
                if (fc.id === result.fleetCustomerId) {
                    fc.name = result.fleetCustomerName;
                    fc.allowedDomains = result.fleetCustomerAllowedDomains;
                }
            });

            return { ...state, fleetCustomers: fleetCustomers.sort(sortByName) };
        }
        case FleetCustomerActionTypes.CLEAR_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS: {
            return {
                ...state,
                getFleetCustomerWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.New),
                fleetCustomerWithConfigurationAndUsers:undefined
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_REQUEST: {
            return {
                ...state,
                getFleetCustomerWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_SUCCESS: {
            return {
                ...state,
                fleetCustomerWithConfigurationAndUsers: action.payload,
                getFleetCustomerWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_FAILURE: {
            return {
                ...state,
                getFleetCustomerWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_REQUEST: {
            return {
                ...state, searchFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                pagedFleetCustomers: action.payload,
                searchFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_FAILURE: {
            return {
                ...state, searchFleetCustomersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as fleetCustomersReducer };
export { initialState as initialFleetCustomersState };
