import {TFunction} from 'i18next';
import {BillingItemsEvolutionData} from '../../../../store/analytics/types/invoice-items-evolution.types';
import {BillingItemDetailsType} from '../../../../store/analytics/types/invoice-items-distribution.types';
import {getMonthLabel} from '../../../../utils/translations/date-translation-helper';

export const monthField = 'month';
export const breakdownServicesNetPriceField = 'breakdownServicesNetPriceField';
export const regularServicesNetPriceField = 'regularServicesNetPriceField';
export const newTiresNetPriceField = 'newTiresNetPriceField';
export const retreadTiresNetPriceField = 'retreadTiresNetPriceField';
export const breakdownServicesNetPricePreviousYearField = 'breakdownServicesNetPricePreviousYearField';
export const regularServicesNetPricePreviousYearField = 'regularServicesNetPricePreviousYearField';
export const newTiresNetPricePreviousYearField = 'newTiresNetPricePreviousYearField';
export const retreadTiresNetPricePreviousYearField = 'retreadTiresNetPricePreviousYearField';
export const totalNetPricePreviousYearField = 'totalNetPricePreviousYearField';

export function buildStackedChartData(data: BillingItemsEvolutionData[], translate: TFunction, hiddenFields: string[]): any[] {
    const chartData: any[] = [];

    data.forEach((row) => {
        const month = `${getMonthLabel(new Date(row.date).getMonth(), translate)} ${new Date(row.date).getFullYear()}`;
        let datapoint = chartData.find(d => d.month === month);
        if (!datapoint) {
            datapoint = {};
            datapoint[monthField] = month;
            chartData.push(datapoint);
        }

        switch (row.billingItemDetailsType) {
            case BillingItemDetailsType.RetreadTires:
                datapoint[retreadTiresNetPriceField] = row.netPrice;
                datapoint[retreadTiresNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            case BillingItemDetailsType.NewTires:
                datapoint[newTiresNetPriceField] = row.netPrice;
                datapoint[newTiresNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            case BillingItemDetailsType.BreakdownServices:
                datapoint[breakdownServicesNetPriceField] = row.netPrice;
                datapoint[breakdownServicesNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            case BillingItemDetailsType.RegularServices:
                datapoint[regularServicesNetPriceField] = row.netPrice;
                datapoint[regularServicesNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            default:
                throw Error(`Encountered unknown invoice item type when building chart data ${row.billingItemDetailsType}`);
        }
    });

    calculateTotalNetPricePreviousYearField(chartData, hiddenFields);

    return chartData;
}

export function calculateTotalNetPricePreviousYearField(chartData: any[], hiddenFields: string[]):void {
    chartData.forEach((datapoint) => {
        let newValue = 0;
        if (!hiddenFields.includes(retreadTiresNetPriceField)) {
            newValue += datapoint[retreadTiresNetPricePreviousYearField];
        }
        if (!hiddenFields.includes(newTiresNetPriceField)) {
            newValue += datapoint[newTiresNetPricePreviousYearField];
        }
        if (!hiddenFields.includes(breakdownServicesNetPriceField)) {
            newValue += datapoint[breakdownServicesNetPricePreviousYearField];
        }
        if (!hiddenFields.includes(regularServicesNetPriceField)) {
            newValue += datapoint[regularServicesNetPricePreviousYearField];
        }
        datapoint[totalNetPricePreviousYearField] = newValue;
    });
}
