import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import styles from './controls-settings-item.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';

class ControlsSettingsItem extends Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {isFirst, isLast, isOpen, isAnyOpen, icon} = this.props;

        const settingsClasses = conditionalClassLister(styles)({
            isFirst,
            isLast,
            isOpen,
            isAnyOpen,
            settingsPanel: true,
        });

        return (
            <div className={settingsClasses} onClick={() => this.onToggleOpen()}>
                <div className={styles.settingsIcon}><FontAwesomeIcon icon={icon}/></div>
            </div>
        );
    }

    private onToggleOpen() {
        const {onToggleOpen} = this.props;
        onToggleOpen();
    }
}

export default withTranslation()(ControlsSettingsItem);

interface OwnProps {
    isFirst?: boolean;
    isLast?: boolean;
    isOpen: boolean;
    isAnyOpen: boolean;
    onToggleOpen: () => void;
    icon: IconDefinition;
}

interface PropsFromState {
}

interface PropsFromDispatch {
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
