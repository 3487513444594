import {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import {LocationDescriptorObject} from "history";
import {push} from "connected-react-router";
import * as layoutActions from '../../store/layout/actions';
import {ApplicationState} from '../../store';
import styles from './fleet-customer-no-data.module.scss';
import {RouteUrl} from "../../routes";

class FleetCustomerNoDataPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {toggleSidebar} = this.props;
        toggleSidebar(false);
    }

    public render(): JSX.Element {
        const {t} = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.panel}>
                    <div className={styles.icon}><FontAwesomeIcon size="3x" icon={faExclamationCircle} /></div>
                    <div>{t('There is currently no data available for this fleet customer.')}</div>
                    <div>{t('Please try again tomorrow.')}</div>
                    <div className={styles.link} onClick={() => this.onReturnToCustomerSelection()}>{t('Return to customer selection')}</div>
                </div>
            </div>
        );
    }

    public onReturnToCustomerSelection() {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: RouteUrl.FleetCustomerSelection });
    }
}

const mapStateToProps = ({authentication}: ApplicationState) => ({
    user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FleetCustomerNoDataPage));

interface PropsFromState {
}

interface PropsFromDispatch {
    toggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchNavigateTo: typeof push;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
