import { Http } from '../http/http';
import {
    CreateFleetCustomerRequest,
    FleetCustomer,
    FleetCustomerConfiguration, FleetCustomersSearchRequest,
    FleetCustomerWithConfiguration,
    UpdateFleetCustomerRequest,
} from '../store/fleet-customers';
import { ApiResult } from '../types/api-result';
import { ApiUrl, UrlParamBuilder } from './api-urls';
import {Paged} from "../store/shared/types";

class FleetCustomersApi {
    public static async createFleetCustomer(
        fleetCustomerRequest: CreateFleetCustomerRequest,
    ): Promise<ApiResult<FleetCustomer>> {
        const result = await Http.post<CreateFleetCustomerRequest, FleetCustomer>(ApiUrl.FleetCustomerCreate, fleetCustomerRequest);
        return { data: result };
    }

    public static async deleteFleetCustomer(fleetCustomer: FleetCustomer): Promise<ApiResult<string>> {
        const result = await Http.delete<string>(
            ApiUrl.FleetCustomer,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomer.id).build(),
        );
        return { data: result };
    }

    public static async getFleetCustomers(): Promise<ApiResult<FleetCustomer[]>> {
        const result = await Http.get<FleetCustomer[]>(ApiUrl.FleetCustomers);
        return { data: result };
    }

    public static async searchFleetCustomers(request: FleetCustomersSearchRequest): Promise<ApiResult<Paged<FleetCustomer>>> {
        const result = await Http.post<FleetCustomersSearchRequest, Paged<FleetCustomer>>(ApiUrl.FleetCustomersSearch, request);
        return { data: result };
    }

    public static async getFleetCustomerWithConfiguration(
        fleetCustomerId: string,
    ): Promise<ApiResult<FleetCustomerWithConfiguration>> {
        const result = await Http.get<FleetCustomerWithConfiguration>(
            ApiUrl.FleetCustomer,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return { data: result };
    }

    public static async updateFleetCustomer(
        fleetCustomerId: string,
        request: UpdateFleetCustomerRequest,
    ): Promise<ApiResult<FleetCustomerConfiguration>> {
        const result = await Http.put<UpdateFleetCustomerRequest, FleetCustomerConfiguration>(
            ApiUrl.FleetCustomer,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return { data: result };
    }
}

export default FleetCustomersApi;
