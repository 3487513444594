import {TFunction} from 'i18next';
import {KpiTypes} from '../../../store/analytics';
import {
    ControlType,
    Dashboard,
    DashboardControl,
    DashboardWidget,
    ParamKey,
    WidgetType,
} from '../../../store/dashboard';
import {generateUUID} from '../../../utils/uuid-helpers';

function createStandardControls(t: TFunction): DashboardControl[] {
    return [
        {
            type: ControlType.DateRange,
            key: ParamKey.InspectionPeriod,
            summaryTitle: t('Date Range'),
            configuration: {}
        },
        {
            type: ControlType.FunctionalLocations,
            key: ParamKey.FunctionalLocationSelections,
            summaryTitle: t('Depots'),
            configuration: {},
        },
        {
            type: ControlType.AdditionalOptions,
            key: ParamKey.AdditionalOptionsSelections,
            summaryTitle: t('Options'),
            configuration: {},
        }
    ];
}

function createFinancialWidgets(): DashboardWidget[] {
    const totalInvoicedId = generateUUID();
    const invoiceTrendId = generateUUID();
    const totalFleetSizeId = generateUUID();
    const fleetGrowthId = generateUUID();
    const invoiceItemDistributionId = generateUUID();
    const invoiceItemEvolutionId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInvoiced},
            id: totalInvoicedId,
            layout: {i: totalInvoicedId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.YoYFinancialComparison},
            id: invoiceTrendId,
            layout: {i: invoiceTrendId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalFleetSize},
            id: totalFleetSizeId,
            layout: {i: totalFleetSizeId, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.FleetGrowth},
            id: fleetGrowthId,
            layout: {i: fleetGrowthId, w: 3, h: 3, x: 3, y: 3},
        },
        {
            type: WidgetType.BillingItemsDistribution,
            configuration: {},
            id: invoiceItemDistributionId,
            layout: {i: invoiceItemDistributionId, w: 6, h: 6, x: 6, y: 0},
        },
        {
            type: WidgetType.BillingItemsEvolution,
            configuration: {},
            id: invoiceItemEvolutionId,
            layout: {i: invoiceItemEvolutionId, w: 12, h: 7, x: 0, y: 6},
        },
    ];
}

function createJobDetailsWidgets(): DashboardWidget[] {
    const totalJobCountId = generateUUID();
    const totalBreakdowns = generateUUID();
    const totalInspections = generateUUID();
    const inspectionRatioId = generateUUID();
    const jobTypeDistributionId = generateUUID();
    const jobEvolutionId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalJobCount},
            id: totalJobCountId,
            layout: {i: totalJobCountId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalBreakdowns},
            id: totalBreakdowns,
            layout: {i: totalBreakdowns, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInspections},
            id: totalInspections,
            layout: {i: totalInspections, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.InspectionRatio},
            id: inspectionRatioId,
            layout: {i: inspectionRatioId, w: 3, h: 3, x: 3, y: 3},
        },
        {
            type: WidgetType.JobTypesDistribution,
            configuration: {},
            id: jobTypeDistributionId,
            layout: {i: jobTypeDistributionId, w: 6, h: 6, x: 6, y: 0},
        },
        {
            type: WidgetType.JobsEvolution,
            configuration: {},
            id: jobEvolutionId,
            layout: {i: jobEvolutionId, w: 12, h: 7, x: 0, y: 6},
        },
    ];
}

function createFleetCompositionWidgets(): DashboardWidget[] {
    const totalFleetSizeId = generateUUID();
    const fleetGrowthId = generateUUID();
    const fleetEvolutionId = generateUUID();
    const vehiclesDistributionId = generateUUID();
    const fleetMovementId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalFleetSize},
            id: totalFleetSizeId,
            layout: {i: totalFleetSizeId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.FleetGrowth},
            id: fleetGrowthId,
            layout: {i: fleetGrowthId, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.VehiclesEvolution,
            configuration: {},
            id: fleetEvolutionId,
            layout: {i: fleetEvolutionId, w: 9, h: 6, x: 3, y: 0},
        },
        {
            type: WidgetType.VehicleTypesDistribution,
            configuration: {},
            id: vehiclesDistributionId,
            layout: {i: vehiclesDistributionId, w: 6, h: 8, x: 0, y: 6},
        },
        {
            type: WidgetType.FleetMovement,
            configuration: {},
            id: fleetMovementId,
            layout: {i: fleetMovementId, w: 6, h: 8, x: 6, y: 6},
        },
    ];
}

function createInspectionsWidgets(): DashboardWidget[] {
    const totalInspections = generateUUID();
    const scheduledInspections = generateUUID();
    const unscheduledInspections = generateUUID();
    const inspectionStatus = generateUUID();
    const inspectionHistogram = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInspections},
            id: totalInspections,
            layout: {i: totalInspections, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.ScheduledInspections},
            id: scheduledInspections,
            layout: {i: scheduledInspections, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.UnscheduledInspections},
            id: unscheduledInspections,
            layout: {i: unscheduledInspections, w: 3, h: 3, x: 0, y: 6},
        },
        {
            type: WidgetType.InspectionStatus,
            configuration: {},
            id: inspectionStatus,
            layout: {i: inspectionStatus, w: 9, h: 9, x: 3, y: 0},
        },
        {
            type: WidgetType.InspectionHistogram,
            configuration: {},
            id: inspectionHistogram,
            layout: {i: inspectionHistogram, w: 12, h: 7, x: 0, y: 9},
        },
    ];
}

function createServiceProvidersPayGoWidgets(): DashboardWidget[] {
    const topServiceProvidersId = generateUUID();
    const totalJobCountId = generateUUID();
    const totalInvoicedId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInvoiced},
            id: totalInvoicedId,
            layout: {i: totalInvoicedId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalJobCount},
            id: totalJobCountId,
            layout: {i: totalJobCountId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.TopServiceProviders,
            configuration: {},
            id: topServiceProvidersId,
            layout: {i: topServiceProvidersId, w: 12, h: 12, x: 0, y: 3},
        },
    ];
}


function createServiceProvidersPPKWidgets(): DashboardWidget[] {
    const topServiceProvidersId = generateUUID();

    return [
        {
            type: WidgetType.TopServiceProviders,
            configuration: {},
            id: topServiceProvidersId,
            layout: {i: topServiceProvidersId, w: 11, h: 12, x: 0, y: 0},
        },
    ];
}

function createTireAnalysisWidgets(): DashboardWidget[] {
    const tireFitmentsBrandDistributionId = generateUUID();
    const topTireFitmentsPatternsId = generateUUID();

    return [
        {
            type: WidgetType.TireFitmentsBrandDistribution,
            configuration: {},
            id: tireFitmentsBrandDistributionId,
            layout: {i: tireFitmentsBrandDistributionId, w: 6, h: 15, x: 0, y: 0},
        },
        {
            type: WidgetType.TopTireFitmentsPatterns,
            configuration: {},
            id: topTireFitmentsPatternsId,
            layout: {i: topTireFitmentsPatternsId, w: 6, h: 15, x: 6, y: 0},
        },
    ];
}

function createFittedTiresAnalysisWidgets(): DashboardWidget[] {
    const tiresFittedBrandDistributionId = generateUUID();
    const topTiresFittedPatternsId = generateUUID();
    const topRetreadTiresFittedByServiceProviderId = generateUUID();
    const topReusableTiresFittedByServiceProviderId = generateUUID();

    return [
        {
            type: WidgetType.TiresFittedBrandDistribution,
            configuration: {},
            id: tiresFittedBrandDistributionId,
            layout: {i: tiresFittedBrandDistributionId, w: 6, h: 15, x: 0, y: 0},
        },
        {
            type: WidgetType.TopTiresFittedPatterns,
            configuration: {},
            id: topTiresFittedPatternsId,
            layout: {i: topTiresFittedPatternsId, w: 6, h: 15, x: 6, y: 0},
        },
        {
            type: WidgetType.TopRetreadTiresFittedByServiceProvider,
            configuration: {},
            id: topRetreadTiresFittedByServiceProviderId,
            layout: {i: topRetreadTiresFittedByServiceProviderId, w: 6, h: 18, x: 0, y: 15},
        },
        {
            type: WidgetType.TopReusableTiresFittedByServiceProvider,
            configuration: {},
            id: topReusableTiresFittedByServiceProviderId,
            layout: {i: topReusableTiresFittedByServiceProviderId, w: 6, h: 18, x: 6, y: 15},
        },
    ];
}

export function createDefaultPayGoDashboardConfig(t: TFunction): Dashboard[] {
    const financialOverview: Dashboard = {
        id: 'financial-overview',
        name: t('Financial overview').toUpperCase(),
        index: 0,
        controls: createStandardControls(t),
        widgets: createFinancialWidgets(),
        isConfigurable: false
    };

    const jobDetails: Dashboard = {
        id: 'job-details',
        name: t('Job details').toUpperCase(),
        index: 1,
        controls: createStandardControls(t),
        widgets: createJobDetailsWidgets(),
        isConfigurable: false
    };

    const fleetComposition: Dashboard = {
        id: 'fleet-composition',
        name: t('Fleet composition').toUpperCase(),
        index: 2,
        controls: createStandardControls(t),
        widgets: createFleetCompositionWidgets(),
        isConfigurable: false
    };

    const tireFitmentsAnalysis: Dashboard = {
        id: 'tire-fitments-analysis',
        name: t('Tire analysis').toUpperCase(),
        index: 3,
        controls: createStandardControls(t),
        widgets: createTireAnalysisWidgets(),
        isConfigurable: false
    };

    const fittedTireAnalysis: Dashboard = {
        id: 'fitted-tire-analysis',
        name: t('Mounted tires').toUpperCase(),
        index: 4,
        controls: createStandardControls(t),
        widgets: createFittedTiresAnalysisWidgets(),
        isConfigurable: false
    };

    const inspections: Dashboard = {
        id: 'inspections',
        name: t('Inspections').toUpperCase(),
        index: 5,
        controls: createStandardControls(t),
        widgets: createInspectionsWidgets(),
        isConfigurable: false
    };

    const serviceProviders: Dashboard = {
        id: 'service-providers',
        name: t('Service providers').toUpperCase(),
        index: 6,
        controls: createStandardControls(t),
        widgets: createServiceProvidersPayGoWidgets(),
        isConfigurable: false
    };


    return [financialOverview, jobDetails, fleetComposition, tireFitmentsAnalysis, fittedTireAnalysis,inspections, serviceProviders];
}


export function createDefaultPPKDashboardConfig(t: TFunction): Dashboard[] {
    const jobDetails: Dashboard = {
        id: 'job-details',
        name: t('Job details').toUpperCase(),
        index: 1,
        controls: createStandardControls(t),
        widgets: createJobDetailsWidgets(),
        isConfigurable: false
    };

    const fleetComposition: Dashboard = {
        id: 'fleet-composition',
        name: t('Fleet composition').toUpperCase(),
        index: 2,
        controls: createStandardControls(t),
        widgets: createFleetCompositionWidgets(),
        isConfigurable: false
    };

    const tireFitmentsAnalysis: Dashboard = {
        id: 'tire-fitments-analysis',
        name: t('Tire analysis').toUpperCase(),
        index: 3,
        controls: createStandardControls(t),
        widgets: createTireAnalysisWidgets(),
        isConfigurable: false
    };

    const fittedTireAnalysis: Dashboard = {
        id: 'fitted-tire-analysis',
        name: t('Mounted tires').toUpperCase(),
        index: 4,
        controls: createStandardControls(t),
        widgets: createFittedTiresAnalysisWidgets(),
        isConfigurable: false
    };

    const inspections: Dashboard = {
        id: 'inspections',
        name: t('Inspections').toUpperCase(),
        index: 5,
        controls: createStandardControls(t),
        widgets: createInspectionsWidgets(),
        isConfigurable: false
    };

    const serviceProvider: Dashboard = {
        id: 'service-providers',
        name: t('Service providers').toUpperCase(),
        index: 6,
        controls: createStandardControls(t),
        widgets: createServiceProvidersPPKWidgets(),
        isConfigurable: false
    };

    return [jobDetails, fleetComposition, tireFitmentsAnalysis, fittedTireAnalysis, inspections, serviceProvider];
}
