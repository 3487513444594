import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './tire-history.module.scss';
import {
    ReportPeriodType,
    TireHistoryCustomReportParameters,
    TireHistoryScheduledReportParameters,
} from '../../../../../../store/reports';

class TireHistoryComponent extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {parameters, initialSerialNumber} = this.props;

        let serialNumber = '';
        if (parameters && parameters.serialNumber) {
            serialNumber = parameters.serialNumber;
        }

        if (initialSerialNumber) {
            serialNumber = initialSerialNumber;
            this.onSerialNumberChanged(initialSerialNumber);
        }

        this.state = {
            serialNumber,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {parameters, initialSerialNumber} = this.props;
        if (parameters !== prevProps.parameters) {
            if (parameters && parameters.serialNumber) {
                this.setState({
                    serialNumber: parameters.serialNumber,
                });
            }
        }

        if (initialSerialNumber !== prevProps.initialSerialNumber) {
            if (initialSerialNumber) {
                this.setState({
                    serialNumber: initialSerialNumber,
                });
                this.onSerialNumberChanged(initialSerialNumber);
            }
        }
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {serialNumber} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.info}>{t('Enter a serial number')}</div>
                    <input type="text"
                           value={serialNumber}
                           className={styles.fieldInput}
                           onChange={(event: any) => this.onSerialNumberChanged(event.target.value)}/>
                </div>
            </div>
        );
    }

    private onSerialNumberChanged(serialNumber: string) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                serialNumber
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                serialNumber
            });
        }

        this.setState({
            serialNumber,
        });
    }

}

export default withTranslation()(TireHistoryComponent);

interface OwnProps {
    initialSerialNumber?: string;
    periodType: ReportPeriodType;
    parameters?: TireHistoryScheduledReportParameters;
    onCustomParametersChanged: (parameters: TireHistoryCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: TireHistoryScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    serialNumber?: string;
}

type AllState = OwnState;
