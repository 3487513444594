import {action} from 'typesafe-actions';
import {DepotSelectionActionTypes} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const filterSoldTos = (filter: string) =>
    action(DepotSelectionActionTypes.FILTER_SOLD_TOS, filter);
export const filterDivisions = (filter: string) =>
    action(DepotSelectionActionTypes.FILTER_DIVISIONS, filter);
export const filterDepots = (filter: string) =>
    action(DepotSelectionActionTypes.FILTER_DEPOTS, filter);
export const resetFilter = () =>
    action(DepotSelectionActionTypes.FILTER_RESET);
