export enum DateSelection {
    Fixed = 'Fixed',
    Date = 'Date',
}

export enum PeriodSelectionOptions {
    Period = 'Period',
    PriorMonth = 'PriorMonth',
    YTDPreviousMonth = 'YTDPreviousMonth',
    MTDAndPrior3Months = 'MTDAndPrior3Months',
}
