import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {getBillingDocumentRequest, getBillingDocumentSuccess} from './actions';
import {BillingActionTypes, BillingDocument} from './types';
import BillingApi from '../../api/billing-api';
import {ApplicationState} from '../index';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof getBillingDocumentRequest>) {
    try {
        const billingDocumentId: string = action.payload;
        const fleetCustomerId = yield getFleetCustomerIdFromState();

        const result: ApiResult<BillingDocument> = yield call(BillingApi.getBillingDocument, fleetCustomerId, billingDocumentId);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(getBillingDocumentSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

const getFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

function* watchFetchRequest() {
    yield takeEvery(BillingActionTypes.GET_BILLING_DOCUMENT_REQUEST, handleFetch);
}

function* billingSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default billingSaga;
