import { ActionType } from 'typesafe-actions';
import {BaseCurrencyType} from '../analytics';
import * as sharedActions from '../shared/actions';
import {RequestStatus, LocalLanguageType, Paged} from '../shared/types';
import * as actions from './actions';
import {UpdatableSoldToMappings} from '../soldto';

export enum FleetCustomerActionTypes {
    CREATE_FLEET_CUSTOMER_REQUEST = '@@fleetCustomers/CREATE_FLEET_CUSTOMER_REQUEST',
    CREATE_FLEET_CUSTOMER_SUCCESS = '@@fleetCustomers/CREATE_FLEET_CUSTOMER_SUCCESS',

    UPDATE_FLEET_CUSTOMER_REQUEST = '@@fleetCustomers/UPDATE_FLEET_CUSTOMER_REQUEST',
    UPDATE_FLEET_CUSTOMER_SUCCESS = '@@fleetCustomers/UPDATE_FLEET_CUSTOMER_SUCCESS',

    GET_FLEET_CUSTOMERS_REQUEST = '@@fleetCustomers/GET_FLEET_CUSTOMERS_REQUEST',
    GET_FLEET_CUSTOMERS_SUCCESS = '@@fleetCustomers/GET_FLEET_CUSTOMERS_SUCCESS',
    GET_FLEET_CUSTOMERS_FAILURE = '@@fleetCustomers/GET_FLEET_CUSTOMERS_FAILURE',

    SEARCH_FLEET_CUSTOMERS_REQUEST = '@@fleetCustomers/SEARCH_FLEET_CUSTOMERS_REQUEST',
    SEARCH_FLEET_CUSTOMERS_SUCCESS = '@@fleetCustomers/SEARCH_FLEET_CUSTOMERS_SUCCESS',
    SEARCH_FLEET_CUSTOMERS_FAILURE = '@@fleetCustomers/SEARCH_FLEET_CUSTOMERS_FAILURE',

    DELETE_FLEET_CUSTOMER_REQUEST = '@@fleetCustomers/DELETE_FLEET_CUSTOMER_REQUEST',
    DELETE_FLEET_CUSTOMER_SUCCESS = '@@fleetCustomers/DELETE_FLEET_CUSTOMER_SUCCESS',
    DELETE_FLEET_CUSTOMER_FAILURE = '@@fleetCustomers/DELETE_FLEET_CUSTOMER_FAILURE',

    CLEAR_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS = '@@fleetCustomers/CLEAR_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS',
    GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_REQUEST = '@@fleetCustomers/GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_REQUEST',
    GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_SUCCESS = '@@fleetCustomers/GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_SUCCESS',
    GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_FAILURE = '@@fleetCustomers/GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_FAILURE',
}

export type FleetCustomerAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface FleetCustomersState {
    readonly fleetCustomers: FleetCustomer[];
    readonly fleetCustomerWithConfigurationAndUsers?: FleetCustomerWithConfigurationAndUsers;
    readonly pagedFleetCustomers?: Paged<FleetCustomer>;

    getFleetCustomersRequestStatus?: RequestStatus;
    deleteFleetCustomerRequestStatus?: RequestStatus;
    getFleetCustomerWithConfigurationAndUsersRequestStatus?: RequestStatus;
    searchFleetCustomersRequestStatus?: RequestStatus;
}

export interface FleetCustomer {
    id: string;
    name: string;
    allowedDomains: string[];
}

export interface CurrencyInfo {
    name: string;
    type: BaseCurrencyType;
}

export interface LanguageInfo {
    name: string;
    type: LocalLanguageType;
}

export interface UserInfo {
    id: string;
    cognitoUsername: string;
    email: string;
    lastLogin?: Date;
}

export interface FleetCustomerWithConfiguration {
    id: string;
    name: string;
    allowedDomains: string[];
    configuration: FleetCustomerConfiguration;
    dataAvailable: boolean;
    earliestContractStartDate?: Date;
    activeCurrency?: CurrencyInfo;
    activeLanguage:LanguageInfo
}

export interface FleetCustomerWithConfigurationAndUsers extends FleetCustomerWithConfiguration {
    users:UserInfo[];
}

export interface FleetCustomerConfiguration {
    baseCurrency1: string;
    baseCurrency2?: string;
    baseCurrency3?: string;
    baseCurrency1Processed: boolean;
    baseCurrency2Processed: boolean;
    baseCurrency3Processed: boolean;
    localLanguage1: string;
    localLanguage2?: string;
    localLanguage3?: string;
    localLanguage1Processed: boolean;
    localLanguage2Processed: boolean;
    localLanguage3Processed: boolean;
    inspectionPeriod: number;
    inspectionPeriodTimeUnit: InspectionPeriodTimeUnit;
    avoidableBreakdownPercentage: number;
    averageCostPerBreakdown: number;
    averageCostPerStopAndGo: number;
    retreadRatioTarget: number;
    averageCostNewTire: number;
    averageCostRetreadTire: number;
    tirePressureTarget: number;
    underInflationThreshold15: number;
    underInflationThreshold25: number;
    averageMileagePerYear: number;
    averageFuelConsumption: number;
    averageFuelCostPerLiter: number;
    expectedMileageNewTire: number;
    fleetType: FleetType;
}

export interface UpdatableConfiguration {
    baseCurrency1?: string;
    baseCurrency2?: string;
    baseCurrency3?: string;
    localLanguage1?: string;
    localLanguage2?: string;
    localLanguage3?: string;
    inspectionPeriod: number;
    inspectionPeriodTimeUnit: InspectionPeriodTimeUnit;
    avoidableBreakdownPercentage: number;
    averageCostPerBreakdown: number;
    averageCostPerStopAndGo: number;
    retreadRatioTarget: number;
    averageCostNewTire: number;
    averageCostRetreadTire: number;
    tirePressureTarget: number;
    underInflationThreshold15: number;
    underInflationThreshold25: number;
    averageMileagePerYear: number;
    averageFuelConsumption: number;
    averageFuelCostPerLiter: number;
    expectedMileageNewTire: number;
    fleetType: FleetType;
}

export interface CreateFleetCustomerRequest {
    fleetCustomerName: string;
    fleetCustomerAllowedDomains: string[];
    configuration: UpdatableConfiguration;
    soldToMappings: UpdatableSoldToMappings;
}

export interface UpdateFleetCustomerRequest {
    fleetCustomerId: string;
    fleetCustomerName: string;
    fleetCustomerAllowedDomains: string[];
    configuration: UpdatableConfiguration;
    soldToMappings: UpdatableSoldToMappings;
}

export enum InspectionPeriodTimeUnit {
    DAYS = 'days',
    MONTHS = 'months',
}

export enum FleetType {
    PPK = 'PPK',
    PayGo = 'PayGo'
}

export interface FleetCustomersSearchRequest {
    page: number;
    itemsPerPage: number;
    search?: string;
}
