import {action} from 'typesafe-actions';
import {
    EnhancedActiveFleetReportRequest,
    ExtendedInspectionReportRequest,
    MountedTireDetailsReportRequest,
    ReportsActionTypes,
    VehiclesDueForInspectionReportRequest,
    VehicleJobListReportRequest,
    RemovedAndFittedTiresReportRequest,
    UnmountedTiresReportRequest,
    TireStockReportRequest,
    TireStockCompany,
    ReportInfo,
    VehiclesCurrentStatusReportRequest,
    InvoiceByVehicleReportRequest,
    BreakdownListReportRequest,
    MasterFleetListReportRequest,
    TireHistoryReportRequest,
    DamagedTireDetailsReportRequest,
    ScheduledReport,
    InvoiceItemByVehicleReportRequest,
    InspectionDetailsReportRequest,
    UnbilledJobsReportRequest,
    InvoiceByJobReportRequest,
    TirePerformanceReportRequest,
    TirePerformanceOptions,
    TCUSensorFitmentsReportRequest,
    JobVerificationReportRequest,
    MileageBillingReportRequest,
    BreakdownAndServicesBillingReportRequest,
    DamagedTiresBillingReportRequest,
    OdometerListReportRequest,
} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchEnhancedActiveFleetReportRequest = (request: EnhancedActiveFleetReportRequest) =>
    action(ReportsActionTypes.FETCH_ENHANCED_ACTIVE_FLEET_REPORT_REQUEST, request);
export const fetchEnhancedActiveFleetReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_ENHANCED_ACTIVE_FLEET_REPORT_SUCCESS, reportInfo);
export const fetchEnhancedActiveFleetReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_ENHANCED_ACTIVE_FLEET_REPORT_FAILURE, message);
export const fetchMasterFleetListReportRequest = (request: MasterFleetListReportRequest) =>
    action(ReportsActionTypes.FETCH_MASTER_FLEET_LIST_REPORT_REQUEST, request);
export const fetchMasterFleetListReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_MASTER_FLEET_LIST_REPORT_SUCCESS, reportInfo);
export const fetchMasterFleetListReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_MASTER_FLEET_LIST_REPORT_FAILURE, message);
export const fetchVehicleJobListReportRequest = (request: VehicleJobListReportRequest) =>
    action(ReportsActionTypes.FETCH_VEHICLE_JOB_LIST_REPORT_REQUEST, request);
export const fetchVehicleJobListReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_VEHICLE_JOB_LIST_REPORT_SUCCESS, reportInfo);
export const fetchVehicleJobListReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_VEHICLE_JOB_LIST_REPORT_FAILURE, message);
export const fetchUnbilledJobsReportRequest = (request: UnbilledJobsReportRequest) =>
    action(ReportsActionTypes.FETCH_UNBILLED_JOBS_REPORT_REQUEST, request);
export const fetchUnbilledJobsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_UNBILLED_JOBS_REPORT_SUCCESS, reportInfo);
export const fetchUnbilledJobsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_UNBILLED_JOBS_REPORT_FAILURE, message);
export const fetchDamagedTireDetailsReportRequest = (request: DamagedTireDetailsReportRequest) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRE_DETAILS_REPORT_REQUEST, request);
export const fetchDamagedTireDetailsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRE_DETAILS_REPORT_SUCCESS, reportInfo);
export const fetchDamagedTireDetailsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRE_DETAILS_REPORT_FAILURE, message);
export const fetchMountedTireDetailsReportRequest = (request: MountedTireDetailsReportRequest) =>
    action(ReportsActionTypes.FETCH_MOUNTED_TIRE_DETAILS_REPORT_REQUEST, request);
export const fetchMountedTireDetailsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_MOUNTED_TIRE_DETAILS_REPORT_SUCCESS, reportInfo);
export const fetchMountedTireDetailsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_MOUNTED_TIRE_DETAILS_REPORT_FAILURE, message);
export const fetchExtendedInspectionReportRequest = (request: ExtendedInspectionReportRequest) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_REQUEST, request);
export const fetchExtendedInspectionReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_SUCCESS, reportInfo);
export const fetchExtendedInspectionReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_FAILURE, message);
export const fetchExtendedServiceReportRequest = (request: ExtendedInspectionReportRequest) =>
    action(ReportsActionTypes.FETCH_EXTENDED_SERVICE_REPORT_REQUEST, request);
export const fetchExtendedServiceReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_EXTENDED_SERVICE_REPORT_SUCCESS, reportInfo);
export const fetchExtendedServiceReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_EXTENDED_SERVICE_REPORT_FAILURE, message);
export const fetchVehiclesDueForInspectionReportRequest = (request: VehiclesDueForInspectionReportRequest) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST, request);
export const fetchVehiclesDueForInspectionReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_SUCCESS, reportInfo);
export const fetchVehiclesDueForInspectionReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_FAILURE, message);
export const fetchRemovedAndFittedTiresReportRequest = (request: RemovedAndFittedTiresReportRequest) =>
    action(ReportsActionTypes.FETCH_REMOVED_AND_FITTED_TIRES_REPORT_REQUEST, request);
export const fetchRemovedAndFittedTiresReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_REMOVED_AND_FITTED_TIRES_REPORT_SUCCESS, reportInfo);
export const fetchRemovedAndFittedTiresReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_REMOVED_AND_FITTED_TIRES_REPORT_FAILURE, message);
export const fetchMileageBillingReportRequest = (request: MileageBillingReportRequest) =>
    action(ReportsActionTypes.FETCH_MILEAGE_BILLING_REPORT_REQUEST, request);
export const fetchMileageBillingReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_MILEAGE_BILLING_REPORT_SUCCESS, reportInfo);
export const fetchMileageBillingReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_MILEAGE_BILLING_REPORT_FAILURE, message);
export const fetchBreakdownAndServicesBillingReportRequest = (request: BreakdownAndServicesBillingReportRequest) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_AND_SERVICES_BILLING_REPORT_REQUEST, request);
export const fetchBreakdownAndServicesBillingReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_AND_SERVICES_BILLING_REPORT_SUCCESS, reportInfo);
export const fetchBreakdownAndServicesBillingReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_AND_SERVICES_BILLING_REPORT_FAILURE, message);
export const fetchDamagedTiresBillingReportRequest = (request: DamagedTiresBillingReportRequest) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRES_BILLING_REPORT_REQUEST, request);
export const fetchDamagedTiresBillingReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRES_BILLING_REPORT_SUCCESS, reportInfo);
export const fetchDamagedTiresBillingReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_DAMAGED_TIRES_BILLING_REPORT_FAILURE, message);
export const fetchUnmountedTiresReportRequest = (request: UnmountedTiresReportRequest) =>
    action(ReportsActionTypes.FETCH_UNMOUNTED_TIRES_REPORT_REQUEST, request);
export const fetchUnmountedTiresReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_UNMOUNTED_TIRES_REPORT_SUCCESS, reportInfo);
export const fetchUnmountedTiresReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_UNMOUNTED_TIRES_REPORT_FAILURE, message);
export const fetchTirePerformanceReportRequest = (request: TirePerformanceReportRequest) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_REPORT_REQUEST, request);
export const fetchTirePerformanceReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_REPORT_SUCCESS, reportInfo);
export const fetchTirePerformanceReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_REPORT_FAILURE, message);
export const fetchTirePerformanceOptionsRequest = (fleetCustomerId: string) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_OPTIONS_REQUEST, fleetCustomerId);
export const fetchTirePerformanceOptionsSuccess = (options:TirePerformanceOptions) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_OPTIONS_SUCCESS, options);
export const fetchTirePerformanceOptionsFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_PERFORMANCE_OPTIONS_FAILURE, message);
export const fetchTireStockCompaniesRequest = (fleetCustomerId: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_REQUEST, fleetCustomerId);
export const fetchTireStockCompaniesSuccess = (companies:TireStockCompany[]) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_SUCCESS, companies);
export const fetchTireStockCompaniesFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_FAILURE, message);
export const fetchTireStockReportRequest = (request: TireStockReportRequest) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_REQUEST, request);
export const fetchTireStockReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_SUCCESS, reportInfo);
export const fetchTireStockReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_FAILURE, message);
export const fetchTireHistoryReportRequest = (request: TireHistoryReportRequest) =>
    action(ReportsActionTypes.FETCH_TIRE_HISTORY_REPORT_REQUEST, request);
export const fetchTireHistoryReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TIRE_HISTORY_REPORT_SUCCESS, reportInfo);
export const fetchTireHistoryReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_HISTORY_REPORT_FAILURE, message);
export const fetchVehiclesCurrentStatusReportRequest = (request: VehiclesCurrentStatusReportRequest) =>
    action(ReportsActionTypes.FETCH_VEHICLES_CURRENT_STATUS_REPORT_REQUEST, request);
export const fetchVehiclesCurrentStatusReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_VEHICLES_CURRENT_STATUS_REPORT_SUCCESS, reportInfo);
export const fetchVehiclesCurrentStatusReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_VEHICLES_CURRENT_STATUS_REPORT_FAILURE, message);
export const fetchInvoiceByVehicleReportRequest = (request: InvoiceByVehicleReportRequest) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_VEHICLE_REPORT_REQUEST, request);
export const fetchInvoiceByVehicleReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_VEHICLE_REPORT_SUCCESS, reportInfo);
export const fetchInvoiceByVehicleReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_VEHICLE_REPORT_FAILURE, message);
export const fetchInvoiceByJobReportRequest = (request: InvoiceByJobReportRequest) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_JOB_REPORT_REQUEST, request);
export const fetchInvoiceByJobReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_JOB_REPORT_SUCCESS, reportInfo);
export const fetchInvoiceByJobReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_INVOICE_BY_JOB_REPORT_FAILURE, message);
export const fetchInvoiceItemByVehicleReportRequest = (request: InvoiceItemByVehicleReportRequest) =>
    action(ReportsActionTypes.FETCH_INVOICE_ITEM_BY_VEHICLE_REPORT_REQUEST, request);
export const fetchInvoiceItemByVehicleReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_INVOICE_ITEM_BY_VEHICLE_REPORT_SUCCESS, reportInfo);
export const fetchInvoiceItemByVehicleReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_INVOICE_ITEM_BY_VEHICLE_REPORT_FAILURE, message);
export const fetchInspectionDetailsReportRequest = (request: InspectionDetailsReportRequest) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_REQUEST, request);
export const fetchInspectionDetailsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_SUCCESS, reportInfo);
export const fetchInspectionDetailsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_FAILURE, message);
export const fetchTCUSensorFitmentsReportRequest = (request: TCUSensorFitmentsReportRequest) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST, request);
export const fetchTCUSensorFitmentsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_SUCCESS, reportInfo);
export const fetchTCUSensorFitmentsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_FAILURE, message);
export const fetchBreakdownListReportRequest = (request: BreakdownListReportRequest) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_LIST_REPORT_REQUEST, request);
export const fetchBreakdownListReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_LIST_REPORT_SUCCESS, reportInfo);
export const fetchBreakdownListReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_BREAKDOWN_LIST_REPORT_FAILURE, message);
export const fetchJobVerificationReportRequest = (request: JobVerificationReportRequest) =>
    action(ReportsActionTypes.FETCH_JOB_VERIFICATION_REPORT_REQUEST, request);
export const fetchJobVerificationReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_JOB_VERIFICATION_REPORT_SUCCESS, reportInfo);
export const fetchJobVerificationReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_JOB_VERIFICATION_REPORT_FAILURE, message);
export const fetchOdometerListReportRequest = (request: OdometerListReportRequest) =>
    action(ReportsActionTypes.FETCH_ODOMETER_LIST_REPORT_REQUEST, request);
export const fetchOdometerListReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_ODOMETER_LIST_REPORT_SUCCESS, reportInfo);
export const fetchOdometerListReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_ODOMETER_LIST_REPORT_FAILURE, message);

export const fetchScheduledReportsRequest = () =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_REQUEST);
export const fetchScheduledReportsSuccess = (scheduledReports: ScheduledReport[]) =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_SUCCESS, scheduledReports);
export const fetchScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_FAILURE, message);
export const createScheduledReportsRequest = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_REQUEST, scheduledReport);
export const createScheduledReportsSuccess = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_SUCCESS, scheduledReport);
export const createScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_FAILURE, message);
export const updateScheduledReportsRequest = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_REQUEST, scheduledReport);
export const updateScheduledReportsSuccess = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_SUCCESS, scheduledReport);
export const updateScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_FAILURE, message);
export const deleteScheduledReportsRequest = (id: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_REQUEST, id);
export const deleteScheduledReportsSuccess = (id: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_SUCCESS, id);
export const deleteScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_FAILURE, message);

export const fetchReportInfoRequest = (id: string) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_REQUEST, id);
export const fetchReportInfoSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_SUCCESS, reportInfo);
export const fetchReportInfoFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_FAILURE, message);

export const fetchReportDownloadRequest = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_REQUEST, reportInfo);
export const fetchReportDownloadSuccess = () =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_SUCCESS);
export const fetchReportDownloadFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_FAILURE, message);
