
import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './inspection-status-widget.module.scss';
import {ApplicationState} from '../../../../store';
import {fetchInspectionStatusRequest, FunctionalLocationSelection} from '../../../../store/analytics';
import {buildRequest} from './request-builder';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetLoading from '../widget-loading';
import {FunctionalLocation, SoldToWithDivisions} from '../../../../store/soldto';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    InspectionStatus,
    InspectionStatusData,
    InspectionStatusGroupingType,
    InspectionStatusRequest,
} from '../../../../store/analytics/types/inspection-status.types';
import {
    addClickEvent,
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {
    buildChartDataWithGroupings,
    buildChartDataWithoutGroupings,
    getGroupings,
    inspectionStatusField,
    typeField,
    vehicleCountField,
} from './chart-data-adapter';
import {
    colorBlue5,
    colorYellow3,
    colorYellow5,
    colorYellow7,
    colorYellow9,
    getBlueColorPalette
} from '../utils/chart-colors';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {formatDateInBrusselsTime} from '../../../../utils/date-helper';
import {RouteUrl} from "../../../../routes";
import {ParamKey} from "../../../../store/dashboard";
import {buildSearchParameters} from "../../../../utils/query-parameter-helpers";

am4core.useTheme(am4themes_animated);

class InspectionStatusWidget extends SingleChartWidgetBase<AllProps, AllState, InspectionStatusData[]> {

    private chartId: string;

    private chartColors: Map<string, Color>;

    private readonly OPTION_NOGROUPING = {id: '1', key: DropdownOptionKey.NoGrouping};

    private readonly OPTION_DEPOT = {id: '2', key: DropdownOptionKey.ByDepot};

    private readonly OPTION_DIVISION = {id: '3', key: DropdownOptionKey.ByDivision};

    private readonly OPTION_SOLD_TO = {id: '4', key: DropdownOptionKey.ByCompany};

    private readonly OPTION_VEHICLE_TYPE = {id: '5', key: DropdownOptionKey.ByVehicleType};

    private locations: Map<string, FunctionalLocation> = new Map<string, FunctionalLocation>();

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.state = {
            options: [this.OPTION_NOGROUPING, this.OPTION_DEPOT, this.OPTION_DIVISION, this.OPTION_SOLD_TO, this.OPTION_VEHICLE_TYPE],
            selectedOption: this.OPTION_NOGROUPING };

        this.chartColors = new Map<string, Color>();
        this.chartColors.set(InspectionStatus.vehiclesInspectedOnTime, am4core.color(colorBlue5));
        this.chartColors.set(InspectionStatus.vehiclesLessThanOneMonthOverdueForInspection, am4core.color(colorYellow3));
        this.chartColors.set(InspectionStatus.vehiclesLessThanTwoMonthsOverdueForInspection, am4core.color(colorYellow5));
        this.chartColors.set(InspectionStatus.vehiclesLessThanThreeMonthsOverdueForInspection, am4core.color(colorYellow7));
        this.chartColors.set(InspectionStatus.vehiclesMoreThanThreeMonthsOverdueForInspection, am4core.color(colorYellow9));

        if (this.props.soldToWithDivisions && this.props.soldToWithDivisions.length > 0) {
            this.updateLocations();
        }
    }

    protected afterComponentDidUpdate(prevProps) {
        if (prevProps.soldToWithDivisions !== this.props.soldToWithDivisions) {
            this.updateLocations();
        }
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Current Inspection Status')} <span className={styles.titleNote}>{formatDateInBrusselsTime(new Date(), ' (DD-MM-YYYY)')}</span>
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    private updateLocations() {
        this.locations.clear();

        if (this.props.soldToWithDivisions) {
            const soldTos = this.props.soldToWithDivisions;
            const divisions = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions);
            const depots = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions.flatMap(div => div.depots));
            soldTos.forEach(st => this.locations.set(st.id, st));
            divisions.forEach(st => this.locations.set(st.id, st));
            depots.forEach(st => this.locations.set(st.id, st));
        }
    }

    private onSelect(option: DropdownOption): void {
        this.setState({selectedOption: option}, () => {
            this.fetchData(this.props.controlsSnapshot);
        });
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchCurrentInspectionStatus(buildRequest(this.props.controlsSnapshot, this.getGroupingEntity(this.state.selectedOption)));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: InspectionStatusData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);
        const currentGrouping = this.getGroupingEntity(this.state.selectedOption);
        const groupings = getGroupings(data, currentGrouping, this.locations);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = inspectionStatusField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Inspections');
        if (currentGrouping === InspectionStatusGroupingType.GLOBAL) {
            this.addFlatSeries(chart);
            chart.data = buildChartDataWithoutGroupings(data, this.props.t);
        } else {
            chart.colors.list = getBlueColorPalette(groupings.length);
            groupings.forEach((grouping) => {
                this.addStackedSeries(chart, grouping);
            });
            chart.legend = new am4charts.Legend();
            chart.legend.position = 'right';
            chart.data = buildChartDataWithGroupings(data, currentGrouping, this.locations, this.props.t);
        }

        return chart;
    }

    private addStackedSeries(chart: any, field: string) {
        const stackedSeries = chart.series.push(new am4charts.ColumnSeries());
        stackedSeries.name = field;
        stackedSeries.stacked = true;
        stackedSeries.dataFields.valueX = field;
        stackedSeries.dataFields.categoryY = inspectionStatusField;
        stackedSeries.columns.template.tooltipText = `{name}: [bold]{valueX} ${this.props.t('vehicles')}[/]`;
        stackedSeries.columns.template.fillOpacity = .8;
        addStandardTooltipStyle(stackedSeries);
        addStrokeToColumnChart(stackedSeries);
    }

    public addFlatSeries(chart: any) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = inspectionStatusField;
        series.dataFields.valueX = vehicleCountField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${this.props.t('vehicles')}[/]`;
        series.columns.template.fillOpacity = .8;
        dynamicallySetColorByType(series, typeField, (type: string) => this.chartColors.get(type)!);
        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);

        const eventHandler = addClickEvent(series, () => {
            const locations: FunctionalLocationSelection[] = this.props.controlsSnapshot.getValueForParam(ParamKey.FunctionalLocationSelections);
            const parameters = {};
            locations.forEach((selection) => {
                parameters[selection.locationType] = selection.includes.join(',');
            });
            this.props.navigateTo({
                pathname: `/${this.props.fleetCustomerId}${RouteUrl.ExtendedInspectionReport}`,
                search: buildSearchParameters(parameters),
            });
        });
        this.disposibles.push(eventHandler);

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '{valueX}';
        valueLabel.label.horizontalCenter = 'left';
        valueLabel.label.dx = 10;
        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;
    }

    private getGroupingEntity(option: DropdownOption): InspectionStatusGroupingType {
        switch (option) {
            case this.OPTION_NOGROUPING:
                return InspectionStatusGroupingType.GLOBAL;
            case this.OPTION_DEPOT:
                return InspectionStatusGroupingType.DEPOT;
            case this.OPTION_DIVISION:
                return InspectionStatusGroupingType.DIVISION;
            case this.OPTION_SOLD_TO:
                return InspectionStatusGroupingType.SOLD_TO;
            case this.OPTION_VEHICLE_TYPE:
                return InspectionStatusGroupingType.VEHICLE_TYPE;
            default:
                throw Error('Can not determine vehicle grouping option');
        }
    }
}

const mapStateToProps = ({analytics, soldTo, authentication}: ApplicationState) => ({
    data: analytics.inspectionStatusData,
    soldToWithDivisions: soldTo.soldToWithDivisions,
    fleetCustomerId: authentication.fleetCustomer?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCurrentInspectionStatus: (request: InspectionStatusRequest) => dispatch(fetchInspectionStatusRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InspectionStatusWidget));

interface PropsFromState {
    data: InspectionStatusData[];
    soldToWithDivisions: SoldToWithDivisions[];
    fleetCustomerId: string;
}

interface PropsFromDispatch {
    fetchCurrentInspectionStatus: typeof fetchInspectionStatusRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
