import {User} from '../../store/authentication';
import {FleetCustomerWithConfiguration, FleetType} from '../../store/fleet-customers';
import {ReportConfigurationContract, UserGroup} from '../../store/report-management';
import {ReportCatalog, ReportCatalogEntry} from './report-catalog';
import {isGoodyearEmail} from '../../utils/user-helper';

export const getAllowedReports = (user: User, fleetCustomer: FleetCustomerWithConfiguration, reportConfigurations: ReportConfigurationContract[]): ReportCatalogEntry[] => {
    const {fleetType} = fleetCustomer.configuration;
    const isInternalUser = isGoodyearEmail(user.email);

    const allowedReports = reportConfigurations
        .filter(reportConfiguration => reportConfiguration.active)
        .filter(reportConfiguration => {
            if (reportConfiguration.availableForUserGroup === UserGroup.AllUsers) {
                return true;
            } else if (reportConfiguration.availableForUserGroup === UserGroup.InternalUsers) {
                return isInternalUser;
            } else {
                return false;
            }
        })
        .filter(reportConfiguration => {
            if (fleetType === FleetType.PayGo) {
                return reportConfiguration.availableForPayGo;
            } else if (fleetType === FleetType.PPK) {
                return reportConfiguration.availableForPPK;
            } else {
                return false;
            }
        });

    return new ReportCatalog().getAllReports()
        .filter((entry) => allowedReports.find(report => report.reportType === entry.type));
}
