import * as am4charts from '@amcharts/amcharts4/charts';
import { faChartBar, faTable } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import WidgetTable, { WidgetTableColumnType } from '../../../../components/widget-table/widget-table';
import { ApplicationState } from '../../../../store';
import {
    DateRangeWithFunctionalLocationsAndLanguageRequest,
     fetchTiresFittedTopPatternsRequest
} from '../../../../store/analytics';
import { TopTirePatternsData } from '../../../../store/analytics/types/top-tire-patterns.types';
import { ViewMode } from '../../../../types/view-mode';
import { generateUUID } from '../../../../utils/uuid-helpers';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import { SingleChartWidgetBase } from '../single-chart-widget-base';
import { addStandardTooltipStyle, addStrokeToColumnChart, createXYChart } from '../utils/chart-utils';
import {
    createDateRangeWithFunctionalLocationsAndLanguageRequest,
    hasInspectionPeriod
} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import { buildChartData, descriptionField, unitsField } from './chart-data-adapter';
import styles from './top-tires-fitted-patterns-widget.module.scss';
import {FleetCustomerWithConfiguration} from '../../../../store/fleet-customers';

class TopTiresFittedPatternsWidget extends SingleChartWidgetBase<AllProps, AllState, TopTirePatternsData[]> {
    private chartId: string;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.state = {
            viewMode: ViewMode.chart,
        };
    }

    public render(): JSX.Element {
        const { t, data } = this.props;
        const { viewMode } = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div className={styles.headerTitle}>{t('Top 15 Tires Fitted by Pattern')}</div>
                            <div className={styles.interactions}>
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable} />
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar} />
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{ width: '100%' }} />
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{
                                        columns: [
                                            {
                                                title: 'Tire Description',
                                                property: descriptionField,
                                                type: WidgetTableColumnType.upperText,
                                                width: '480px',
                                            },
                                            {
                                                title: '# Units',
                                                property: unitsField,
                                                type: WidgetTableColumnType.number,
                                            },
                                        ],
                                    }}
                                    data={data}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        const { dispatchFetchTiresFittedTopPatternsRequest,fleetCustomer } = this.props;
        dispatchFetchTiresFittedTopPatternsRequest(createDateRangeWithFunctionalLocationsAndLanguageRequest(this.props.controlsSnapshot,fleetCustomer));
    }

    private toggleViewMode(): void {
        const { viewMode } = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({ viewMode: updatedViewMode }, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TopTirePatternsData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = descriptionField;
        categoryAxis.title.text = this.props.t('Description');
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Units');

        this.addFlatSeries(chart);
        chart.data = buildChartData(data, this.props.t);

        return chart;
    }

    public addFlatSeries(chart: any): void {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = unitsField;
        series.dataFields.categoryY = descriptionField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${this.props.t('units')}[/]`;
        series.columns.template.fillOpacity = 0.8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
    }
}

const mapStateToProps = ({ analytics,authentication }: ApplicationState) => ({
    data: analytics.topTiresFittedPatterns,
    fleetCustomer:authentication.fleetCustomer
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchFetchTiresFittedTopPatternsRequest: (request: DateRangeWithFunctionalLocationsAndLanguageRequest) =>
        dispatch(fetchTiresFittedTopPatternsRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopTiresFittedPatternsWidget));

interface PropsFromState {
    data: TopTirePatternsData[];
    fleetCustomer: FleetCustomerWithConfiguration;
}

interface PropsFromDispatch {
    dispatchFetchTiresFittedTopPatternsRequest: typeof fetchTiresFittedTopPatternsRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    viewMode: ViewMode;
}

type AllState = OwnState;
