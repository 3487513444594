import {
    EnhancedActiveFleetReportRequest,
    ExtendedInspectionReportRequest,
    MountedTireDetailsReportRequest,
    VehiclesDueForInspectionReportRequest,
    VehicleJobListReportRequest,
    RemovedAndFittedTiresReportRequest,
    UnmountedTiresReportRequest,
    TireStockReportRequest,
    TireStockCompany,
    ReportInfo,
    ReportDownloadUrl,
    VehiclesCurrentStatusReportRequest,
    InvoiceByVehicleReportRequest,
    BreakdownListReportRequest,
    MasterFleetListReportRequest,
    TireHistoryReportRequest,
    DamagedTireDetailsReportRequest,
    ScheduledReport,
    InvoiceItemByVehicleReportRequest,
    InspectionDetailsReportRequest,
    UnbilledJobsReportRequest,
    InvoiceByJobReportRequest,
    TirePerformanceReportRequest,
    TirePerformanceOptions,
    TCUSensorFitmentsReportRequest,
    JobVerificationReportRequest,
    MileageBillingReportRequest,
    BreakdownAndServicesBillingReportRequest,
    DamagedTiresBillingReportRequest, OdometerListReportRequest,
} from '../store/reports';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {Http} from '../http/http';
import {ApiResult} from '../types/api-result';
import {Base64Data, Paged} from '../store/shared/types';
import {AdminScheduledReport, AdminScheduledReportRequest} from '../store/scheduled-report-management';
import {performBase64Download} from '../utils/download-helper';

class ReportsApi {
    public static async getReportInfo(reportId: string, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.get<ReportInfo>(ApiUrl.ReportsReportInfo,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addReportId(reportId).build());
        return {data: result};
    }

    public static async getReportDownloadUrl(reportInfo: ReportInfo, fleetCustomerId: string): Promise<ApiResult<ReportDownloadUrl>> {
        const reportDownloadUrl = await Http.get<ReportDownloadUrl>(ApiUrl.ReportsDownloadUrl,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addReportId(reportInfo.id).build());
        return {data: reportDownloadUrl};
    }

    public static async getEnhancedActiveFleetReport(request: EnhancedActiveFleetReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<EnhancedActiveFleetReportRequest, ReportInfo>(ApiUrl.ReportsEnhancedActiveFleetReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getMasterFleetListReport(request: MasterFleetListReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<EnhancedActiveFleetReportRequest, ReportInfo>(ApiUrl.ReportsMasterFleetListReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getVehicleJobListReport(request: VehicleJobListReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<VehicleJobListReportRequest, ReportInfo>(ApiUrl.ReportsVehicleJobListReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getUnbilledJobsReport(request: UnbilledJobsReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<UnbilledJobsReportRequest, ReportInfo>(ApiUrl.ReportsUnbilledJobsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getMountedTireDetailsReport(request: MountedTireDetailsReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<MountedTireDetailsReportRequest, ReportInfo>(ApiUrl.ReportsMountedTireDetailsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getDamagedTireDetailsReport(request: DamagedTireDetailsReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<DamagedTireDetailsReportRequest, ReportInfo>(ApiUrl.ReportsDamagedTireDetailsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getExtendedInspectionReport(request: ExtendedInspectionReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<ExtendedInspectionReportRequest, ReportInfo>(ApiUrl.ReportsExtendedInspectionReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getExtendedServiceReport(request: ExtendedInspectionReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<ExtendedInspectionReportRequest, ReportInfo>(ApiUrl.ReportsExtendedServiceReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getVehiclesDueForInspectionReport(request: VehiclesDueForInspectionReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<VehiclesDueForInspectionReportRequest, ReportInfo>(ApiUrl.ReportsVehiclesDueForInspectionDetailsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getRemovedAndFittedTiresReport(request: RemovedAndFittedTiresReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<RemovedAndFittedTiresReportRequest, ReportInfo>(ApiUrl.ReportsRemovedAndFittedTiresReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getMileageBillingReport(request: MileageBillingReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<MileageBillingReportRequest, ReportInfo>(ApiUrl.ReportsMileageBillingReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getBreakdownAndServicesBillingReport(request: BreakdownAndServicesBillingReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<BreakdownAndServicesBillingReportRequest, ReportInfo>(ApiUrl.ReportsBreakdownAndServicesBillingReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getDamagedTiresBillingReport(request: DamagedTiresBillingReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<DamagedTiresBillingReportRequest, ReportInfo>(ApiUrl.ReportsDamagedTiresBillingReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getUnmountedTiresReport(request: UnmountedTiresReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<UnmountedTiresReportRequest, ReportInfo>(ApiUrl.ReportsUnmountedTireDetailsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getTirePerformanceReport(request: TirePerformanceReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TirePerformanceReportRequest, ReportInfo>(ApiUrl.ReportsTirePerformanceReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getTirePerformanceOptions(fleetCustomerId: string): Promise<ApiResult<TirePerformanceOptions>> {
        const tirePerformanceOptions = await Http.get<TirePerformanceOptions>(ApiUrl.ReportsTirePerformanceOptions,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: tirePerformanceOptions};
    }

    public static async getTireStockReport(request: TireStockReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TireStockReportRequest, ReportInfo>(ApiUrl.ReportsTireStockReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getTireStockCompanies(fleetCustomerId: string): Promise<ApiResult<TireStockCompany[]>> {
        const tireStockCompanies = await Http.get<TireStockCompany[]>(ApiUrl.ReportsTireStockCompanies,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: tireStockCompanies};
    }

    public static async getTireHistoryReport(request: TireHistoryReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TireHistoryReportRequest, ReportInfo>(ApiUrl.ReportsTireHistoryReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getVehiclesCurrentStatusReport(request: VehiclesCurrentStatusReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<VehiclesCurrentStatusReportRequest, ReportInfo>(ApiUrl.ReportsVehiclesCurrentStatusReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getInvoiceByJobReport(request: InvoiceByJobReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<InvoiceByJobReportRequest, ReportInfo>(ApiUrl.ReportsInvoiceByJobReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getInvoiceByVehicleReport(request: InvoiceByVehicleReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<InvoiceByVehicleReportRequest, ReportInfo>(ApiUrl.ReportsInvoiceByVehicleReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getInvoiceItemByVehicleReport(request: InvoiceItemByVehicleReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<InvoiceItemByVehicleReportRequest, ReportInfo>(ApiUrl.ReportsInvoiceItemByVehicleReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getInspectionDetailsReport(request: InspectionDetailsReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<InspectionDetailsReportRequest, ReportInfo>(ApiUrl.ReportsInspectionDetailsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getTCUSensorFitmentsReport(request: TCUSensorFitmentsReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TCUSensorFitmentsReportRequest, ReportInfo>(ApiUrl.TCUSensorFitmentsReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getBreakdownListReport(request: BreakdownListReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<BreakdownListReportRequest, ReportInfo>(ApiUrl.ReportsBreakdownListReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getJobVerificationReport(request: JobVerificationReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<JobVerificationReportRequest, ReportInfo>(ApiUrl.ReportsJobVerificationReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getOdometerListReport(request: OdometerListReportRequest, fleetCustomerId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<OdometerListReportRequest, ReportInfo>(ApiUrl.ReportsOdometerListReport,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async getScheduledReports(fleetCustomerId: string): Promise<ApiResult<ScheduledReport[]>> {
        const result = await Http.get<ScheduledReport[]>(ApiUrl.ReportsScheduledReports,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async addScheduledReport(request: ScheduledReport, fleetCustomerId: string): Promise<ApiResult<ScheduledReport>> {
        const result = await Http.post<ScheduledReport, ScheduledReport>(ApiUrl.ReportsScheduledReports,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: result};
    }

    public static async updateScheduledReport(request: ScheduledReport, fleetCustomerId: string): Promise<ApiResult<ScheduledReport>> {
        const result = await Http.put<ScheduledReport, ScheduledReport>(ApiUrl.ReportsScheduledReportsUpdate,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addReportId(request.id!).build());
        return {data: result};
    }

    public static async deleteScheduledReport(reportId: string, fleetCustomerId: string): Promise<ApiResult<string>> {
        const result = await Http.delete<string>(ApiUrl.ReportsScheduledReportsUpdate,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addReportId(reportId).build());
        return {data: result};
    }

    public static async getAdminScheduledReports(request: AdminScheduledReportRequest): Promise<ApiResult<Paged<AdminScheduledReport>>> {
        const pagedScheduledReports = await Http.post<any, Paged<AdminScheduledReport>>(ApiUrl.ReportsAdminScheduledReports, request);
        return {data: {pageData: pagedScheduledReports.pageData, totalCount: pagedScheduledReports.totalCount}};
    }

    public static async downloadScheduledReportLog(scheduledReportId: string, fleetCustomerId: string): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.ReportsScheduledReportsLog,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addReportId(scheduledReportId).build());
        performBase64Download(result.data,"vnd.openxmlformats-officedocument.spreadsheetml.sheet", `Scheduled Report Log - ${scheduledReportId}.xlsx`);
    }
}

export default ReportsApi;
