import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {faCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import {Permission, User} from '../../../store/authentication';
import {FleetCustomer} from '../../../store/fleet-customers';
import * as layoutActions from '../../../store/layout/actions';
import {Popup, PopupType, showPopup} from '../../../store/popup';
import {RequestStatus} from '../../../store/shared/types';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../../store/toast';
import {getUserRequest, updateUserRequest} from '../../../store/users';
import {hasPermission, isGoodyearEmail} from '../../../utils/user-helper';
import {generateUUID} from '../../../utils/uuid-helpers';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import FleetCustomerSelectionControl from './components/fleet-customer-selection-control';
import FleetCustomerSelectionPopup from './popups/fleet-customer-selection-popup/fleet-customer-selection-popup';
import styles from './user-details.module.scss';
import ToggleDisplay from '../../../utils/toggle-display';
import {conditionalClassLister} from '../../../utils/class-helpers';

class UserDetailsPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar, dispatchGetUser, match} = this.props;
        const {userId} = match.params;
        dispatchToggleSidebar(false);
        dispatchGetUser(userId);

        this.state = {
            availablePermissions: [Permission.ViewAnalytics, Permission.EditDashboards],
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {updateUserRequestStatus, user} = this.props;
        const {availablePermissions} = this.state;

        if (user !== prevProps.user) {
            const updatedUser: User | undefined = user ? {...user} : undefined;
            if (user && isGoodyearEmail(user.email)) {
                availablePermissions.push(Permission.ViewReports);
                availablePermissions.push(Permission.ManageReports);
                availablePermissions.push(Permission.ViewScheduledReports);
                availablePermissions.push(Permission.ManageScheduledReports);
                availablePermissions.push(Permission.ViewFleets);
                availablePermissions.push(Permission.ManageFleets);
                availablePermissions.push(Permission.ViewUsers);
                availablePermissions.push(Permission.ManageUsers);
            }
            this.setState({updatedUser, availablePermissions});
        }

        if (
            updateUserRequestStatus &&
            prevProps.updateUserRequestStatus !== updateUserRequestStatus &&
            updateUserRequestStatus.isSuccess
        ) {
            this.showUpdateSuccessfulToast();
            this.onNavigateBack();
        }
    }

    public render(): JSX.Element {
        const {t, updateUserRequestStatus, dispatchNavigateTo, loggedInUser,user} = this.props;
        const {updatedUser, availablePermissions} = this.state;

        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageUsers)
        const isOwnUser = loggedInUser?.id === user?.id;

        return updatedUser ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('User Details')}
                    onBack={() => dispatchNavigateTo({pathname: RouteUrl.UserManagement})}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.fields}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{`${t('UMF Username')}:`}</div>
                                <div className={styles.value}>{updatedUser?.cognitoUsername}</div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{`${t('Email')}:`}</div>
                                <div className={styles.value}>{updatedUser?.email}</div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{`${t('Status')}:`}</div>
                                <div className={styles.value}>{t('Active').toUpperCase()}</div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{`${t('Last Login')}:`}</div>
                                <div className={styles.value}>
                                    {`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}
                                </div>
                            </div>
                        </div>
                        <div className={styles.spacer}/>
                        <div className={styles.fields}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Fleet Customer(s)')}</div>
                                <div className={styles.value}>
                                    <FleetCustomerSelectionControl
                                        fleetCustomers={updatedUser.fleetCustomers}
                                        canChange={hasEditPermission}
                                        onChange={() => this.showFleetCustomerSelectionPopup()}
                                    />
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Permissions')}</div>
                                <div>
                                    {availablePermissions.map((permission) => {
                                        const permissionStyles = conditionalClassLister(styles)({
                                            checkBoxItem:true,
                                            disabled:!hasEditPermission || isOwnUser && permission===Permission.ManageUsers
                                        });
                                       return ( <div className={permissionStyles} key={permission}
                                        onClick={() => this.onPermissionChanged(permission)}>
                                        <div className={styles.checkBox}>
                                        <ToggleDisplay if={updatedUser?.permissions.includes(permission)}>
                                        <FontAwesomeIcon icon={faCheckSquare}/>
                                        </ToggleDisplay>
                                        <ToggleDisplay if={!updatedUser?.permissions.includes(permission)}>
                                        <FontAwesomeIcon icon={faSquare}/>
                                        </ToggleDisplay>
                                        </div>
                                        <div className={styles.checkBoxText}>
                                    {permission}
                                        </div>
                                        </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button
                                type="button"
                                className={`${styles.button} ${styles.isNegative}`}
                                onClick={() => {
                                    this.onNavigateBack();
                                }}>
                                {t('Back')}
                            </button>
                            {hasEditPermission ?
                                <button
                                    type="button"
                                    className={styles.button}
                                    onClick={() => {
                                        this.updateUser();
                                    }}>
                                    {updateUserRequestStatus?.isInProgress ? (
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    ) : (
                                        t('Save')
                                    )}
                                </button>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    public onPermissionChanged(permission): void {
        const {updatedUser} = this.state;
        if (updatedUser) {
            if (updatedUser.permissions.includes(permission)) {
                updatedUser.permissions.splice(updatedUser.permissions.indexOf(permission), 1);
            } else {
                updatedUser.permissions.push(permission);
            }
        }
        this.setState({updatedUser});
    }

    public onNavigateBack(): void {
        const {dispatchNavigateTo} = this.props;
        dispatchNavigateTo({pathname: RouteUrl.UserManagement});
    }

    public showFleetCustomerSelectionPopup(): void {
        const {dispatchShowPopup,loggedInUser} = this.props;
        const {updatedUser} = this.state;

        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageUsers)
        dispatchShowPopup({
            type: PopupType.FleetCustomerSelection,
            content: (
                <FleetCustomerSelectionPopup
                    canChange={hasEditPermission}
                    email={updatedUser?.email}
                    originalSelectedFleetCustomers={updatedUser?.fleetCustomers}
                    onFleetCustomersChanged={(fleetCustomers) => this.onFleetCustomersChanged(fleetCustomers)}
                />
            ),
        });
    }

    private updateUser(): void {
        const {dispatchUpdateUser} = this.props;
        const {updatedUser} = this.state;

        if (updatedUser) {
            dispatchUpdateUser(updatedUser);
        }
    }

    private onFleetCustomersChanged(fleetCustomers: FleetCustomer[]): void {
        const {updatedUser} = this.state;

        if (updatedUser) {
            updatedUser.fleetCustomers = fleetCustomers;
            this.setState({updatedUser});
        }
    }

    private showUpdateSuccessfulToast(): void {
        const {dispatchAddToast, t} = this.props;

        dispatchAddToast({
            id: generateUUID(),
            messages: [t('User Successfully Updated')],
            type: ToastType.Success,
            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
        });
    }
}

const mapStateToProps = ({users,authentication}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    user: users.user,
    updateUserRequestStatus: users.updateUserRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchGetUser: (id: string) => dispatch(getUserRequest(id)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchUpdateUser: (user: User) => dispatch(updateUserRequest(user)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserDetailsPage));

interface PropsFromState {
    loggedInUser?: User;
    user?: User;
    updateUserRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchGetUser: typeof getUserRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchUpdateUser: typeof updateUserRequest;
    dispatchAddToast: typeof addToast;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

interface OwnProps {
}

export interface WorkspaceMatchParameters {
    userId: string;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    RouteComponentProps<WorkspaceMatchParameters> &
    WithTranslation &
    OwnProps;

interface OwnState {
    updatedUser?: User;
    availablePermissions: Permission[];
}

type AllState = OwnState;
