import {RuntimeConfig} from '../config/runtime-config';

export class ApiEndpoint {
    constructor(public path: string, public apiType: ApiType) {
    }
}

export enum ApiType {
    Root = 'Root',
    Dashboard = 'Dashboard',
    Reports = 'Reports',
}

export class ApiUrl {
    // ROOT API
    public static MaintenanceStatus: ApiEndpoint = new ApiEndpoint('/maintenance/status', ApiType.Root);


    // DASHBOARD API
    public static DataStatus = new ApiEndpoint('/data/status', ApiType.Dashboard);

    public static CurrentUser = new ApiEndpoint('/users/current', ApiType.Dashboard);

    public static CurrentUserSettings = new ApiEndpoint('/users/current/settings', ApiType.Dashboard);

    public static CurrentUserPrivacyPolicy = new ApiEndpoint('/users/current/privacy-policy', ApiType.Dashboard);

    public static Users = new ApiEndpoint('/users', ApiType.Dashboard);

    public static UsersSearch = new ApiEndpoint('/users/search', ApiType.Dashboard);

    public static UsersGet = new ApiEndpoint('/users/{user-id}', ApiType.Dashboard);

    public static UsersDelete = new ApiEndpoint('/users/{user-id}', ApiType.Dashboard);

    public static UsersWithFleetCustomers = new ApiEndpoint('/users/fleetcustomers', ApiType.Dashboard);

    public static UsersUserStructure = new ApiEndpoint('/users/user-structure', ApiType.Dashboard);

    public static Dashboards = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/dashboards', ApiType.Dashboard);

    public static Depots = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/depots', ApiType.Dashboard);

    public static SoldToMappings = new ApiEndpoint('/sold-to-mappings/{fleet-customer-id}', ApiType.Dashboard);

    public static FleetCustomerSoldToStructure = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/sold-to-structure', ApiType.Dashboard);

    public static FleetCustomersSoldToStructure = new ApiEndpoint('/fleet-customers/sold-to-structure', ApiType.Dashboard);

    public static SoldToWithDepots = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/sold-tos', ApiType.Dashboard);

    public static FleetCustomers = new ApiEndpoint('/fleet-customers', ApiType.Dashboard);

    public static FleetCustomersSearch = new ApiEndpoint('/fleet-customers/search', ApiType.Dashboard);

    public static FleetCustomer = new ApiEndpoint('/fleet-customers/{fleet-customer-id}', ApiType.Dashboard);

    public static ServiceProvider = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/service-providers/{service-provider-id}', ApiType.Dashboard);

    public static Vehicle = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/vehicles/{vehicle-id}', ApiType.Dashboard);

    public static BillingDocument = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/billing-documents/{billing-document-id}', ApiType.Dashboard);

    public static Invoice = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/invoices/{billing-document-reference}', ApiType.Dashboard);

    public static FleetCustomerCreate = new ApiEndpoint('/fleet-customers', ApiType.Dashboard);

    public static Jobs = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/jobs/search', ApiType.Dashboard);

    public static Search = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/search', ApiType.Dashboard);

    public static EjobSheet = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/ejobs/{e-job-id}/job-sheet', ApiType.Dashboard);

    public static JobAttachments = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/jobs/{job-reference}/attachments', ApiType.Dashboard);

    public static TireServiceDetails = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/{job-id}/tire-service-details', ApiType.Dashboard);

    public static ServiceProvidersMonthlyOverview = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/service-providers/monthly-overview', ApiType.Dashboard);

    public static TopServiceProviders = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/service-providers/top-20', ApiType.Dashboard);

    public static TopReusableTiresFittedByServiceProvider = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/service-providers/reusable-tires-top-20', ApiType.Dashboard);

    public static TopRetreadTiresFittedByServiceProvider = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/service-providers/retread-tires-top-20', ApiType.Dashboard);

    public static VehiclesEvolution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/evolution', ApiType.Dashboard);

    public static VehiclesFleetGrowth = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/fleet-growth', ApiType.Dashboard);

    public static VehicleTypesDistribution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/types-distribution', ApiType.Dashboard);

    public static JobsEvolution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/jobs/evolution', ApiType.Dashboard);

    public static JobTypesDistribution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/jobs/types-distribution', ApiType.Dashboard);

    public static JobsAcceptanceTime: ApiEndpoint = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/jobs/acceptance-time', ApiType.Dashboard);

    public static BillingDistribution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/billing/distribution', ApiType.Dashboard);

    public static BillingEvolution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/billing/evolution', ApiType.Dashboard);

    public static InspectionStatus = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/inspection-status', ApiType.Dashboard);

    public static InspectionRatio = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/inspection-ratio', ApiType.Dashboard);

    public static InspectionHistogram = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/inspection-histogram', ApiType.Dashboard);

    public static FleetMovement = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/vehicles/movement', ApiType.Dashboard);

    public static YoyComparison = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/billing/yoy-comparison', ApiType.Dashboard);

    public static TireFitmentsBrandDistribution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/tires/fitments-brand-distribution', ApiType.Dashboard);

    public static TiresFittedBrandDistribution = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/tires/fitted-brand-distribution', ApiType.Dashboard);

    public static TireFitmentsTopPatterns = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/tires/fitments-top-patterns', ApiType.Dashboard);

    public static TiresFittedTopPatterns = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/tires/fitted-top-patterns', ApiType.Dashboard);

    public static TreadDepthAlerts = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/alerts/tread-depth-alerts', ApiType.Dashboard);

    public static TirePressureAlerts = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/alerts/tire-pressure-alerts', ApiType.Dashboard);

    public static BreakdownFrequencyAlerts = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/alerts/breakdown-frequency-alerts', ApiType.Dashboard);

    public static ServiceFrequencyAlerts = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/alerts/service-frequency-alerts', ApiType.Dashboard);

    public static RetreadRatioAlerts = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/alerts/retread-ratio-alerts', ApiType.Dashboard);

    public static AvoidableBreakdownsKPI = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/kpi/avoidable-breakdowns', ApiType.Dashboard);

    public static RetreadRatioKPI = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/kpi/retread-ratio', ApiType.Dashboard);

    public static UnderInflationKPI = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/analytics/kpi/under-inflation', ApiType.Dashboard);


    // REPORTS API

    public static ReportConfigurations = new ApiEndpoint('/report-configurations', ApiType.Reports);

    public static ReportsReportInfo = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/{report-id}', ApiType.Reports);

    public static ReportsDownloadUrl = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/{report-id}/url', ApiType.Reports);

    public static ReportsEnhancedActiveFleetReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/enhanced-active-fleet-report', ApiType.Reports);

    public static ReportsMasterFleetListReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/master-fleet-list-report', ApiType.Reports);

    public static ReportsVehicleJobListReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/vehicle-job-list-report', ApiType.Reports);

    public static ReportsUnbilledJobsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/unbilled-jobs-report', ApiType.Reports);

    public static ReportsMountedTireDetailsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/mounted-tire-details-report', ApiType.Reports);

    public static ReportsDamagedTireDetailsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/damaged-tire-details-report', ApiType.Reports);

    public static ReportsExtendedInspectionReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/extended-inspection-report', ApiType.Reports);

    public static ReportsExtendedServiceReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/extended-service-report', ApiType.Reports);

    public static ReportsVehiclesDueForInspectionDetailsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/vehicles-due-for-inspection-details-report', ApiType.Reports);

    public static ReportsRemovedAndFittedTiresReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/removed-and-fitted-tires-report', ApiType.Reports);

    public static ReportsMileageBillingReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/mileage-billing-report', ApiType.Reports);

    public static ReportsBreakdownAndServicesBillingReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/breakdown-and-services-billing-report', ApiType.Reports);

    public static ReportsDamagedTiresBillingReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/damaged-tires-billing-report', ApiType.Reports);

    public static ReportsUnmountedTireDetailsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/unmounted-tire-details-report', ApiType.Reports);

    public static ReportsTireStockReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tire-stock-report', ApiType.Reports);

    public static ReportsTireStockCompanies = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tire-stock-report/companies', ApiType.Reports);

    public static ReportsTireHistoryReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tire-history-report', ApiType.Reports);

    public static ReportsTirePerformanceReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tire-performance-report', ApiType.Reports);

    public static ReportsTirePerformanceOptions = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tire-performance-report/options', ApiType.Reports);

    public static ReportsVehiclesCurrentStatusReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/vehicles-current-status-report', ApiType.Reports);

    public static ReportsInvoiceByJobReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/invoice-by-job-report', ApiType.Reports);

    public static ReportsInvoiceByVehicleReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/invoice-by-vehicle-report', ApiType.Reports);

    public static ReportsInvoiceItemByVehicleReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/invoice-item-by-vehicle-report', ApiType.Reports);

    public static ReportsBreakdownListReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/breakdown-list-report', ApiType.Reports);

    public static ReportsJobVerificationReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/job-verification-report', ApiType.Reports);

    public static ReportsInspectionDetailsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/inspection-details-report', ApiType.Reports);

    public static TCUSensorFitmentsReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/tcu-sensor-fitments-report', ApiType.Reports);

    public static ReportsOdometerListReport = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/reports/odometer-list-report', ApiType.Reports);

    public static ReportsScheduledReports = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/scheduled-reports', ApiType.Reports);

    public static ReportsScheduledReportsUpdate = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/scheduled-reports/{report-id}', ApiType.Reports);

    public static ReportsScheduledReportsLog = new ApiEndpoint('/fleet-customers/{fleet-customer-id}/scheduled-reports/{report-id}/log', ApiType.Reports);

    public static ReportsAdminScheduledReports = new ApiEndpoint('/scheduled-reports', ApiType.Reports);

}

const apiVersion = 'v1';

export function buildBaseUrl(apiPrefix: ApiType): string {
    const apiUrl = getApiUrl(apiPrefix);
    return `${apiUrl}${apiVersion}`;
}

function getApiUrl(apiType: ApiType): string {
    switch (apiType) {
        case ApiType.Root:
            return RuntimeConfig.getRootApiURL();
        case ApiType.Dashboard:
            return RuntimeConfig.getDashboardApiURL();
        case ApiType.Reports:
            return RuntimeConfig.getReportsApiURL();
        default:
            throw new Error(`${apiType} is not a valid API type.`);
    }
}

export class UrlParamBuilder {
    private params: any = {};

    public addFleetCustomerId(id: string): UrlParamBuilder {
        this.params['fleet-customer-id'] = id;
        return this;
    }

    public addBillingDocumentId(id: string): UrlParamBuilder {
        this.params['billing-document-id'] = id;
        return this;
    }

    public addBillingDocumentReference(billingDocumentReference: string): UrlParamBuilder {
        this.params['billing-document-reference'] = billingDocumentReference;
        return this;
    }

    public addServiceProviderId(id: string): UrlParamBuilder {
        this.params['service-provider-id'] = id;
        return this;
    }

    public addVehicleId(id: string): UrlParamBuilder {
        this.params['vehicle-id'] = id;
        return this;
    }

    public addEJobId(ejobId: string): UrlParamBuilder {
        this.params['e-job-id'] = ejobId;
        return this;
    }

    public addJobReference(jobReference: string): UrlParamBuilder {
        this.params['job-reference'] = jobReference;
        return this;
    }

    public addJobId(jobId: string): UrlParamBuilder {
        this.params['job-id'] = jobId;
        return this;
    }

    public addUserId(id: string): UrlParamBuilder {
        this.params['user-id'] = id;
        return this;
    }

    public addReportId(id: string): UrlParamBuilder {
        this.params['report-id'] = id;
        return this;
    }

    public build(): any {
        return this.params;
    }
}
