import { Reducer } from 'redux';
import { ControlValue } from '../../pages/analytics/dashboard-controls/controls-snapshot';
import { ParamKey } from '../dashboard';
import { SharedActionTypes } from '../shared/types';
import { AnalyticsAction, AnalyticsActionTypes, AnalyticsState } from './types';

export function initialState(): AnalyticsState {
    return {
        keyPerformanceIndicatorData: [],
        alertData: [],
        defaultControlValues: new Map<ParamKey, ControlValue>(),
    };
}

const reducer: Reducer<AnalyticsState, AnalyticsAction> = (state = initialState(), action): AnalyticsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case AnalyticsActionTypes.DAILY_FLEET_SIZE_EVOLUTION_REQUEST: {
            return { ...state, fleetSizeEvolutionData: undefined };
        }
        case AnalyticsActionTypes.DAILY_FLEET_SIZE_EVOLUTION_SUCCESS: {
            return { ...state, fleetSizeEvolutionData: action.payload };
        }
        case AnalyticsActionTypes.VEHICLES_DISTRIBUTION_REQUEST: {
            return { ...state, vehiclesDistributionData: undefined };
        }
        case AnalyticsActionTypes.VEHICLES_DISTRIBUTION_SUCCESS: {
            return { ...state, vehiclesDistributionData: action.payload };
        }
        case AnalyticsActionTypes.DAILY_JOB_EVOLUTION_REQUEST: {
            return { ...state, jobsEvolutionData: undefined };
        }
        case AnalyticsActionTypes.DAILY_JOB_EVOLUTION_SUCCESS: {
            return { ...state, jobsEvolutionData: action.payload };
        }
        case AnalyticsActionTypes.JOB_TYPES_DISTRIBUTION_REQUEST: {
            return { ...state, jobTypesDistributionData: undefined };
        }
        case AnalyticsActionTypes.JOB_TYPES_DISTRIBUTION_SUCCESS: {
            return { ...state, jobTypesDistributionData: action.payload };
        }
        case AnalyticsActionTypes.INSPECTION_STATUS_REQUEST: {
            return { ...state, inspectionStatusData: undefined };
        }
        case AnalyticsActionTypes.INSPECTION_STATUS_SUCCESS: {
            return { ...state, inspectionStatusData: action.payload };
        }
        case AnalyticsActionTypes.BILLING_ITEMS_EVOLUTION_REQUEST: {
            return { ...state, invoiceItemsEvolutionData: undefined };
        }
        case AnalyticsActionTypes.BILLING_ITEMS_EVOLUTION_SUCCESS: {
            return { ...state, invoiceItemsEvolutionData: action.payload };
        }
        case AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST: {
            return { ...state, serviceProvidersMonthlyOverviewData: undefined };
        }
        case AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS: {
            return { ...state, serviceProvidersMonthlyOverviewData: action.payload };
        }
        case AnalyticsActionTypes.TOP_SERVICE_PROVIDERS_REQUEST: {
            return { ...state, topServiceProvidersData: undefined };
        }
        case AnalyticsActionTypes.TOP_SERVICE_PROVIDERS_SUCCESS: {
            return { ...state, topServiceProvidersData: action.payload };
        }
        case AnalyticsActionTypes.TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_REQUEST: {
            return { ...state, topRetreadTiresByServiceProvidersData: undefined };
        }
        case AnalyticsActionTypes.TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_SUCCESS: {
            return { ...state, topRetreadTiresByServiceProvidersData: action.payload };
        }
        case AnalyticsActionTypes.TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_REQUEST: {
            return { ...state, topReusableTiresByServiceProvidersData: undefined };
        }
        case AnalyticsActionTypes.TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_SUCCESS: {
            return { ...state, topReusableTiresByServiceProvidersData: action.payload };
        }
        case AnalyticsActionTypes.BILLING_ITEMS_DISTRIBUTION_REQUEST: {
            return { ...state, invoiceItemsDistributionData: undefined };
        }
        case AnalyticsActionTypes.BILLING_ITEMS_DISTRIBUTION_SUCCESS: {
            return { ...state, invoiceItemsDistributionData: action.payload };
        }
        case AnalyticsActionTypes.FLEET_MOVEMENT_REQUEST: {
            return { ...state, fleetMovementData: undefined };
        }
        case AnalyticsActionTypes.FLEET_MOVEMENT_SUCCESS: {
            return { ...state, fleetMovementData: action.payload };
        }
        case AnalyticsActionTypes.INSPECTION_HISTOGRAM_REQUEST: {
            return { ...state, inspectionHistogramData: undefined };
        }
        case AnalyticsActionTypes.INSPECTION_HISTOGRAM_SUCCESS: {
            return { ...state, inspectionHistogramData: action.payload };
        }
        case AnalyticsActionTypes.TIRE_FITMENTS_BRAND_DISTRIBUTION_REQUEST: {
            return { ...state, tireFitmentsBrandDistributionData: undefined };
        }
        case AnalyticsActionTypes.TIRE_FITMENTS_BRAND_DISTRIBUTION_SUCCESS: {
            return { ...state, tireFitmentsBrandDistributionData: action.payload };
        }
        case AnalyticsActionTypes.TIRES_FITTED_BRAND_DISTRIBUTION_REQUEST: {
            return { ...state, tiresFittedBrandDistributionData: undefined };
        }
        case AnalyticsActionTypes.TIRES_FITTED_BRAND_DISTRIBUTION_SUCCESS: {
            return { ...state, tiresFittedBrandDistributionData: action.payload };
        }
        case AnalyticsActionTypes.TIRE_FITMENTS_TOP_PATTERNS_REQUEST: {
            return { ...state, topTireFitmentsPatterns: undefined };
        }
        case AnalyticsActionTypes.TIRE_FITMENTS_TOP_PATTERNS_SUCCESS: {
            return { ...state, topTireFitmentsPatterns: action.payload };
        }
        case AnalyticsActionTypes.TIRES_FITTED_TOP_PATTERNS_REQUEST: {
            return { ...state, topTiresFittedPatterns: undefined };
        }
        case AnalyticsActionTypes.TIRES_FITTED_TOP_PATTERNS_SUCCESS: {
            return { ...state, topTiresFittedPatterns: action.payload };
        }
        case AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST: {
            const data = state.keyPerformanceIndicatorData.filter((d) => d.type !== action.payload.type);
            return { ...state, keyPerformanceIndicatorData: data };
        }
        case AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_SUCCESS: {
            const data = [...state.keyPerformanceIndicatorData];
            data.push(action.payload);
            return { ...state, keyPerformanceIndicatorData: data };
        }
        case AnalyticsActionTypes.ALERTS_WIDGET_DATA_REQUEST: {
            const data = state.alertData.filter((d) => d.type !== action.payload.type);
            return { ...state, alertData: data };
        }
        case AnalyticsActionTypes.ALERTS_WIDGET_DATA_SUCCESS: {
            const data = [...state.alertData];
            data.push(action.payload);
            return { ...state, alertData: data };
        }
        case AnalyticsActionTypes.UPDATE_DEFAULT_CONTROL_VALUES: {
            const newDefaultValues = action.payload;
            const oldDefaultValues = state.defaultControlValues;
            return { ...state, defaultControlValues: new Map([...oldDefaultValues, ...newDefaultValues]) };
        }
        case AnalyticsActionTypes.UPDATE_SINGLE_DEFAULT_CONTROL_VALUE: {
            const oldDefaultValues = state.defaultControlValues;
            const newDefaultValues = new Map([...oldDefaultValues]);
            newDefaultValues.set(action.payload.defaultValueKey, action.payload.defaultControlValue);
            return { ...state, defaultControlValues: newDefaultValues };
        }
        case AnalyticsActionTypes.AVOIDABLE_BREAKDOWNS_KPI_REQUEST:
            return { ...state, avoidableBreakdownsKPI: undefined };
        case AnalyticsActionTypes.AVOIDABLE_BREAKDOWNS_KPI_SUCCESS:
            return { ...state, avoidableBreakdownsKPI: action.payload };
        case AnalyticsActionTypes.RETREAD_RATIO_KPI_REQUEST:
            return { ...state, retreadRatioKPI: undefined };
        case AnalyticsActionTypes.RETREAD_RATIO_KPI_SUCCESS:
            return { ...state, retreadRatioKPI: action.payload };
        case AnalyticsActionTypes.UNDER_INFLATION_KPI_REQUEST:
            return { ...state, underInflationKPI: undefined };
        case AnalyticsActionTypes.UNDER_INFLATION_KPI_SUCCESS:
            return { ...state, underInflationKPI: action.payload };
        default: {
            return state;
        }
    }
};

export { reducer as analyticsReducer };
export { initialState as initialAnalyticsState };
