import {Reducer} from 'redux';
import {JobsAction, JobsActionTypes, JobsState} from './types';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';

function initialState(): JobsState {
    return {
        jobs: undefined,
        inProgress: false,
        tireService: undefined,
    };
}

const reducer: Reducer<JobsState, JobsAction> = (state = initialState(), action): JobsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case JobsActionTypes.FETCH_JOBS_REQUEST: {
            return {...state, inProgress: true};
        }
        case JobsActionTypes.FETCH_JOBS_SUCCESS: {
            return {...state, jobs: action.payload, inProgress: false};
        }
        case JobsActionTypes.FETCH_TIRE_SERVICE_REQUEST: {
            return {...state, tireService: undefined, tireServiceRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case JobsActionTypes.FETCH_TIRE_SERVICE_SUCCESS: {
            return {...state, tireService: action.payload, tireServiceRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case JobsActionTypes.FETCH_TIRE_SERVICE_FAILURE: {
            return {...state, tireServiceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        default: {
            return state;
        }
    }
};

export {reducer as jobsReducer};
export {initialState as initialJobsState};
