import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import AnalyticsPage from '../../pages/analytics/analytics.page';
import JobsPage from '../../pages/jobs/jobs.page';
import ProfilePage from '../../pages/profile/profile.page';
import ReportsPage from '../../pages/reports/reports.page';
import EnhancedActiveFleetReportPage
    from '../../pages/reports/reports/enhanced-active-fleet-report/enhanced-active-fleet-report.page';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {fetchSelectedFleetCustomerRequest, User} from '../../store/authentication';
import {FleetCustomerWithConfiguration} from '../../store/fleet-customers';
import {fetchSoldTosRequest, SoldToWithDivisions} from '../../store/soldto';
import {FleetCustomerMatchParameters} from '../../types/fleet-customer';
import VehicleJobReportPage from '../../pages/reports/reports/vehicle-job-report/vehicle-job-report.page';
import MountedTireDetailsReportPage
    from '../../pages/reports/reports/mounted-tire-details-report/mounted-tire-details-report.page';
import ExtendedInspectionReportPage
    from '../../pages/reports/reports/extended-inspection-report/extended-inspection-report.page';
import VehiclesDueForInspectionReportPage
    from '../../pages/reports/reports/vehicles-due-for-inpsection-report/vehicles-due-for-inspection-report.page';
import RemovedAndFittedTiresReportPage
    from '../../pages/reports/reports/removed-and-fitted-tires-report/removed-and-fitted-tires-report.page';
import UnmountedTiresReportPage from '../../pages/reports/reports/unmounted-tires-report/unmounted-tires-report.page';
import TireStockReportPage from '../../pages/reports/reports/tire-stock-report/tire-stock-report.page';
import VehiclesCurrentStatusReportPage
    from '../../pages/reports/reports/vehicles-current-status-report/vehicles-current-status-report.page';
import InvoiceByVehicleReportPage
    from '../../pages/reports/reports/invoice-by-vehicle-report/invoice-by-vehicle-report.page';
import BreakdownListReportPage from "../../pages/reports/reports/breakdown-list-report/breakdown-list-report.page";
import MasterFleetListReportPage
    from '../../pages/reports/reports/master-fleet-list-report/master-fleet-list-report.page';
import TireHistoryReportPage from '../../pages/reports/reports/tire-history-report/tire-history-report.page';
import DamagedTireDetailsReportPage
    from '../../pages/reports/reports/damaged-tire-details-report/damaged-tire-details-report.page';
import ReportSchedulerPage from '../../pages/reports/report-scheduler/report-scheduler.page';
import InvoiceItemByVehicleReportPage
    from '../../pages/reports/reports/invoice-item-by-vehicle-report/invoice-item-by-vehicle-report.page';
import InspectionDetailsReportPage
    from '../../pages/reports/reports/inspection-details-report/inspection-details-report.page';
import UnbilledJobsReportPage from '../../pages/reports/reports/unbilled-jobs-report/unbilled-jobs-report.page';
import InvoiceByJobReportPage from '../../pages/reports/reports/invoice-by-job-report/invoice-by-job-report.page';
import TirePerformanceReportPage
    from '../../pages/reports/reports/tire-performance-report/tire-performance-report.page';
import ExtendedServiceReportPage
    from '../../pages/reports/reports/extended-service-report/extended-service-report.page';
import JobVerificationReportPage
    from '../../pages/reports/reports/job-verification-report/job-verification-report.page';
import ReportDownloadPage from '../../pages/reports/report-download/report-download.page';
import MileageBillingReportPage from '../../pages/reports/reports/mileage-billing-report/mileage-billing-report.page';
import TcuSensorFitmentsReportPage
    from '../../pages/reports/reports/tcu-sensor-fitments-report/tcu-sensor-fitments-report.page';
import BreakdownAndServicesBillingReportPage
    from '../../pages/reports/reports/breakdown-and-services-billing-report/breakdown-and-services-billing-report.page';
import DamagedTiresBillingReportPage
    from '../../pages/reports/reports/damaged-tires-billing-report/damaged-tires-billing-report.page';
import OdometerListReportPage from '../../pages/reports/reports/odometer-list-report/odometer-list-report.page';

class FleetCustomerBoundary extends Component<AllProps> {
    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<any>, snapshot?: any): void {
        const {
            fleetCustomer,
            user,
            match,
            dispatchFetchSelectedFleetCustomer,
            dispatchNavigateTo,
            soldTos,
            dispatchFetchSoldTos
        } = this.props;
        const {fleetCustomerId} = match.params;

        // we obviously need a user...
        if (user) {
            if (fleetCustomerId && !fleetCustomer) {
                // set fleet customer (or go to selection screen)
                if (user.fleetCustomers && user.fleetCustomers.find((fc) => fc.id === fleetCustomerId)) {
                    dispatchFetchSelectedFleetCustomer(fleetCustomerId);
                } else {
                    dispatchNavigateTo({pathname: RouteUrl.FleetCustomerSelection});
                }
            } else if (fleetCustomer !== prevProps.fleetCustomer || !soldTos) {
                if (fleetCustomer && !fleetCustomer.dataAvailable) {
                    // Navigate away if there is no data available
                    dispatchNavigateTo({pathname: RouteUrl.FleetCustomerNoDataIssue});
                } else {
                    // Get all fleet customer specific data
                    dispatchFetchSoldTos(fleetCustomerId);
                }
            }
        }
    }

    public render(): JSX.Element {
        const {match} = this.props;

        return (
            <>
                <Route path={`${match.path}${RouteUrl.Analytics}`} component={AnalyticsPage}/>
                <Route path={`${match.path}${RouteUrl.Jobs}`} component={JobsPage}/>
                <Route path={`${match.path}${RouteUrl.Profile}`} component={ProfilePage}/>
                <Route
                    path={`${match.path}${RouteUrl.EnhancedActiveFleetListReport}`}
                    component={EnhancedActiveFleetReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.MasterFleetListReport}`}
                    component={MasterFleetListReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.VehicleJobListReport}`}
                    component={VehicleJobReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.UnbilledJobsReport}`}
                    component={UnbilledJobsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.MountedTiresReport}`}
                    component={MountedTireDetailsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.ExtendedInspectionReport}`}
                    component={ExtendedInspectionReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.ExtendedServiceReport}`}
                    component={ExtendedServiceReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.VehiclesDueForInspectionReport}`}
                    component={VehiclesDueForInspectionReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.RemovedAndFittedTiresReport}`}
                    component={RemovedAndFittedTiresReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.MileageBillingReport}`}
                    component={MileageBillingReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.BreakdownAndServicesBilling}`}
                    component={BreakdownAndServicesBillingReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.DamagedTiresBilling}`}
                    component={DamagedTiresBillingReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.DamagedTiresReport}`}
                    component={DamagedTireDetailsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.UnmountedTiresReport}`}
                    component={UnmountedTiresReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TireStockReport}`}
                    component={TireStockReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TireHistoryReport}`}
                    component={TireHistoryReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TirePerformanceReport}`}
                    component={TirePerformanceReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.VehiclesCurrentStatusReport}`}
                    component={VehiclesCurrentStatusReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.InvoiceByJobReport}`}
                    component={InvoiceByJobReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.InvoiceByVehicleReport}`}
                    component={InvoiceByVehicleReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.InvoiceItemByVehicleReport}`}
                    component={InvoiceItemByVehicleReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.BreakdownListReport}`}
                    component={BreakdownListReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.InspectionDetailsReport}`}
                    component={InspectionDetailsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TCUSensorFitmentsReport}`}
                    component={TcuSensorFitmentsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.JobVerificationReport}`}
                    component={JobVerificationReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.OdometerListReport}`}
                    component={OdometerListReportPage}
                />
                <Route exact path={`${match.path}${RouteUrl.Reports}`} component={ReportsPage}/>
                <Route exact path={`${match.path}${RouteUrl.ReportScheduler}`} component={ReportSchedulerPage}/>
                <Route
                    path={`${match.path}${RouteUrl.ReportDownload}`}
                    component={ReportDownloadPage}
                />
            </>
        );
    }
}

const mapStateToProps = ({authentication, soldTo}: ApplicationState): PropsFromState => ({
    user: authentication.user,
    fleetCustomer: authentication.fleetCustomer,
    soldTos: soldTo.soldToWithDivisions,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchFetchSelectedFleetCustomer: (fleetCustomerId: string) =>
        dispatch(fetchSelectedFleetCustomerRequest(fleetCustomerId)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchSoldTos: (fleetCustomerId: string) => dispatch(fetchSoldTosRequest(fleetCustomerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetCustomerBoundary);

interface PropsFromState {
    user?: User;
    fleetCustomer?: FleetCustomerWithConfiguration;
    soldTos?: SoldToWithDivisions[];
}

interface PropsFromDispatch {
    dispatchFetchSelectedFleetCustomer: typeof fetchSelectedFleetCustomerRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchFetchSoldTos: typeof fetchSoldTosRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<FleetCustomerMatchParameters>;
