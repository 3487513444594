import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {InspectionType, JobType} from '../jobs';

export enum BillingActionTypes {
    GET_BILLING_DOCUMENT_REQUEST = '@@billing/GET_BILLING_DOCUMENT_REQUEST',
    GET_BILLING_DOCUMENT_SUCCESS = '@@billing/GET_BILLING_DOCUMENT_SUCCESS',
}

export type BillingAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface BillingState {
    inProgress: boolean;
    billingDocument?: BillingDocument;
}

export interface BillingDocument {
    id: string;
    date: Date;
    billingRef: string;
    documentType: string;
    originalCurrency: string;
    status: string;
    billingLines: BillingLine[];
}

export interface BillingLine {
    billingLineId: string;
    billingLineNumber: number;
    billedUnits: number;
    originalCurrencyNetPrice: number;
    originalCurrencyVatPrice: number;
    jobId: string;
    jobRef: string;
    jobType: JobType;
    inspectionType: InspectionType;
    startDate: Date;
    endDate: Date;
    vehicleId: string;
    licensePlate: string;
    serviceProviderId: string;
    serviceProviderName: string;
    depotId: string;
    depotName: string;
}

export interface Invoice {
    available: boolean;
    data: string;
}
