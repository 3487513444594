import * as am4core from '@amcharts/amcharts4/core';
import {push} from 'connected-react-router';
import {History, Location, LocationDescriptorObject} from 'history';

import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Component} from 'react';
import {Hub} from 'aws-amplify';
import AuthenticationApi, {CustomAuthData} from './api/authentication-api';
import BillingDetailsPopup from './components/billing-details-popup/billing-details-popup';
import MaintenanceNotification from './components/maintenance-notification/maintenance-notification';
import PopupContainer from './components/popup-container/popup-container';
import ServiceProviderDetailsPopup from './components/service-provider-details-popup/service-provider-details-popup';
import Sidebar from './components/sidebar/sidebar';
import ToastContainer from './components/toast-container/toast-container';
import VehicleDetailsPopup from './components/vehicle-details-popup/vehicle-details-popup';
import PrivacyPolicyPopup from './privacy-policy/privacy-policy-popup';
import Routes, {RouteUrl} from './routes';
import {ApplicationState} from './store';
import {
    fetchCurrentUserRequest,
    fetchSelectedFleetCustomerRequest,
    loginFailure,
    loginSuccess,
    logoutRequest,
    Permission,
    User,
} from './store/authentication';
import {resetFilter} from './store/depot-selection';
import {FleetCustomer} from './store/fleet-customers';
import {closePopup, Popup, PopupType, showPopup} from './store/popup';
import TermsOfUsePopup from './terms-of-use/terms-of-use-popup';
import {confirmPrivacyPolicy, getLastPrivacyPolicy, getPrivacyPolicySettings} from './utils/privacy-policy-helper';
import {
    buildReturnUrlFromQueryParameters,
    buildSearchParameters,
    QueryParameter
} from './utils/query-parameter-helpers';
import {hasAdminPermission} from './utils/user-helper';
import {fetchMaintenanceStatusRequest} from './store/maintenance';
import TireServicePopup from './components/tire-service-details-popup/tire-service-details-popup';
import SupportPopup from './support/support-popup';

class App extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {
            dispatchNavigateTo,
            dispatchFetchCurrentUserRequest,
            dispatchFetchMaintenanceStatusRequest,
            history,
            currentLocation
        } = this.props;

        am4core.addLicense('CH243236532');
        Hub.listen('auth', this.authHubListener.bind(this));

        dispatchNavigateTo(history.location);
        this.handleQueryParameters(currentLocation.search);

        this.state = {
            initialized: false,
        };

        dispatchFetchMaintenanceStatusRequest();

        AuthenticationApi.isAuthenticated()
            .then((authenticated: boolean) => {
                if (authenticated) {
                    dispatchFetchCurrentUserRequest();
                } else {
                    this.setState({initialized: true});
                }
            })
            .catch((err) => {
                dispatchNavigateTo({pathname: RouteUrl.Login});
                // eslint-disable-next-line no-undef
                window.location.reload(); // forced reload is necessary here because of crash in Cognito lib internals
            });
    }

    private authHubListener(data) {
        const {dispatchLoginSuccess, dispatchLoginFailure, dispatchNavigateTo} = this.props;
        switch (data.payload.event) {
            case 'signIn':
                dispatchLoginSuccess();
                break;
            case 'signIn_failure':
                dispatchLoginFailure();
                break;
            case 'customOAuthState':
                // eslint-disable-next-line no-case-declarations
                const customAuthData = JSON.parse(data.payload.data) as CustomAuthData;
                if (customAuthData.searchUrl) {
                    const queryParameters = new URLSearchParams(customAuthData.searchUrl);
                    if (queryParameters.has(QueryParameter.ReturnUrl)) {
                        dispatchNavigateTo(buildReturnUrlFromQueryParameters(queryParameters));
                    }
                }
                break;
            case 'signUp':
            case 'signOut':
            case 'tokenRefresh':
            case 'tokenRefresh_failure':
            case 'configured':
            default:
                break;
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const {currentLocation, user, fleetCustomer, dispatchNavigateTo, userRetrievalFailureMessage} = this.props;
        const {initialized} = this.state;

        if (currentLocation.search !== prevProps.currentLocation.search) {
            this.handleQueryParameters(currentLocation.search);
        }

        // in case we can't retrieve user before initialization has finished, best to forward to login page
        if (userRetrievalFailureMessage && !initialized) {
            this.setState({initialized: true}, () => {
                dispatchNavigateTo({pathname: RouteUrl.Login});
            });
        }

        if (user !== prevProps.user || fleetCustomer !== prevProps.fleetCustomer) {
            if (!initialized && user) {
                this.setState({initialized: true});
                this.handlePrivacyPolicy(user);
            }
            this.navigateAfterLogin(currentLocation, user, fleetCustomer);
        }
    }

    private handlePrivacyPolicy(user: User): void {
        getPrivacyPolicySettings(user, 'EN').then((privacyPolicySettings) => {
            if (privacyPolicySettings.policyDocument && !privacyPolicySettings.hasConfirmed) {
                this.openPrivacyPolicyPopup(true, true, privacyPolicySettings.policyDocument);
            }
        });
    }

    public render(): JSX.Element {
        const {currentLocation, showSidebar, popup, fleetCustomer, underMaintenance} = this.props;
        const {initialized} = this.state;

        const queryParameters = new URLSearchParams(currentLocation.search);
        const inMaintenance = underMaintenance && !queryParameters.has(QueryParameter.SkipMaintenance);
        return (
            <div className="root">
                {inMaintenance ? (
                    <MaintenanceNotification/>
                ) : initialized ? (
                    <div className="appWrapper">
                        <PopupContainer popup={popup} close={() => this.onClosePopup()}/>
                        <ToastContainer/>
                        {showSidebar && (
                            <Sidebar
                                fleetCustomer={fleetCustomer}
                                locationPathName={currentLocation.pathname}
                                onLogout={() => this.onLogout()}
                                onShowPrivacyPolicy={() => this.onUserOpenPrivacyPolicy()}
                                onShowTermsOfUse={() => this.openTermsOfUsePopup()}
                                onShowSupport={() => this.openSupportPopup()}
                                onNavigate={(URL) => this.onNavigate(URL)}
                            />
                        )}
                        <div className="pageMainContent">
                            <Routes/>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private onLogout(): void {
        const {dispatchLogoutRequest} = this.props;
        dispatchLogoutRequest();
    }

    private onNavigate(URL: string): void {
        const {dispatchResetFilter, dispatchNavigateTo} = this.props;
        dispatchResetFilter();
        dispatchNavigateTo({pathname: URL});
    }

    private onClosePopup(): void {
        const {currentLocation, dispatchClosePopup, dispatchNavigateTo} = this.props;
        const queryParameters = buildSearchParameters(
            {
                popup: undefined,
                billingDocumentId: undefined,
                serviceProviderId: undefined,
                vehicleId: undefined,
                jobId: undefined,
                scheduledReportId: undefined
            },
            currentLocation.search,
        );
        dispatchClosePopup();
        dispatchNavigateTo({search: queryParameters});
    }

    private handleQueryParameters(queryParameters: string): void {
        const map = new URLSearchParams(queryParameters);
        const {dispatchShowPopup} = this.props;
        if (
            map.has(QueryParameter.Popup) &&
            map.has(QueryParameter.BillingDocumentId) &&
            map.get(QueryParameter.Popup) === PopupType.BillingDetails
        ) {
            dispatchShowPopup({
                type: PopupType.BillingDetails,
                content: (
                    <BillingDetailsPopup billingDocumentId={map.get(QueryParameter.BillingDocumentId) || undefined}/>
                ),
            });
        }
        if (
            map.has(QueryParameter.Popup) &&
            map.has(QueryParameter.ServiceProviderId) &&
            map.get(QueryParameter.Popup) === PopupType.ServiceProviderDetails
        ) {
            dispatchShowPopup({
                type: PopupType.ServiceProviderDetails,
                content: (
                    <ServiceProviderDetailsPopup
                        serviceProviderId={map.get(QueryParameter.ServiceProviderId) || undefined}
                    />
                ),
            });
        }
        if (
            map.has(QueryParameter.Popup) &&
            map.has(QueryParameter.VehicleId) &&
            map.get(QueryParameter.Popup) === PopupType.VehicleDetails
        ) {
            dispatchShowPopup({
                type: PopupType.VehicleDetails,
                content: <VehicleDetailsPopup vehicleId={map.get(QueryParameter.VehicleId) || undefined}/>,
            });
        }
        if (
            map.has(QueryParameter.Popup) &&
            map.has(QueryParameter.JobId) &&
            map.get(QueryParameter.Popup) === PopupType.TireServiceDetails
        ) {
            dispatchShowPopup({
                type: PopupType.TireServiceDetails,
                content: <TireServicePopup jobId={map.get(QueryParameter.JobId) || undefined}/>,
            });
        }
    }

    private navigateAfterLogin(location: Location, user?: User, fleetCustomer?: FleetCustomer): void {
        const {dispatchNavigateTo, dispatchFetchSelectedFleetCustomerRequest} = this.props;

        if (user && (location.pathname === RouteUrl.Login || location.pathname === '' || location.pathname === '/')) {
            const queryParameters = new URLSearchParams(location.search);
            if (queryParameters.has(QueryParameter.ReturnUrl)) {
                dispatchNavigateTo(buildReturnUrlFromQueryParameters(queryParameters));
            } else if (!user.permissions.includes(Permission.ViewAnalytics)) {
                if (hasAdminPermission(user)) {
                    dispatchNavigateTo({pathname: RouteUrl.Administration});
                } else {
                    dispatchNavigateTo({pathname: RouteUrl.UserConfigurationIssue});
                }
            } else if (fleetCustomer) {
                dispatchNavigateTo({pathname: `/${fleetCustomer.id}${RouteUrl.Analytics}`});
            } else if (user.fleetCustomers.length === 1) {
                dispatchFetchSelectedFleetCustomerRequest(user.fleetCustomers[0].id);
            } else {
                dispatchNavigateTo({pathname: RouteUrl.FleetCustomerSelection});
            }
        }
    }

    private onUserOpenPrivacyPolicy() {
        getLastPrivacyPolicy('EN').then((policyDocument) => {
            if (policyDocument) {
                this.openPrivacyPolicyPopup(false, false, policyDocument);
            }
        });
    }

    private openPrivacyPolicyPopup(shouldConfirm: boolean, showTermsOfUse: boolean, policyDocument?: string): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;
        if (policyDocument) {
            dispatchShowPopup({
                hideCloseButton: true,
                type: PopupType.PrivacyPolicy,
                content: (
                    <PrivacyPolicyPopup
                        shouldConfirm={shouldConfirm}
                        showTermsOfUse={showTermsOfUse}
                        policyDocument={policyDocument}
                        onConfirm={async (): Promise<void> => {
                            await confirmPrivacyPolicy();
                            dispatchClosePopup();
                        }}
                        onClose={(): void => {
                            dispatchClosePopup();
                        }}
                    />
                ),
            });
        }
    }

    private openTermsOfUsePopup(): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.PrivacyPolicy,
            content: (
                <TermsOfUsePopup
                    onClose={async (): Promise<void> => {
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private openSupportPopup(): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.Support,
            content: (
                <SupportPopup
                    onClose={async (): Promise<void> => {
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }
}

const mapStateToProps = ({router, authentication, layout, popup, maintenance}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    underMaintenance: maintenance.underMaintenance,
    showSidebar: layout.showSidebar,
    user: authentication.user,
    fleetCustomer: authentication.fleetCustomer,
    userRetrievalFailureMessage: authentication.userRetrievalFailureMessage,
    popup: popup.popup,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchLoginFailure: (message: string | undefined) => dispatch(loginFailure(message)),
    dispatchLoginSuccess: () => dispatch(loginSuccess()),
    dispatchLogoutRequest: () => dispatch(logoutRequest()),
    dispatchFetchMaintenanceStatusRequest: () => dispatch(fetchMaintenanceStatusRequest()),
    dispatchFetchCurrentUserRequest: () => dispatch(fetchCurrentUserRequest()),
    dispatchFetchSelectedFleetCustomerRequest: (fleetCustomerId: string) =>
        dispatch(fetchSelectedFleetCustomerRequest(fleetCustomerId)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchResetFilter: () => dispatch(resetFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));

interface PropsFromState {
    currentLocation: Location;
    underMaintenance: boolean,
    userRetrievalFailureMessage?: string;
    showSidebar: boolean;
    user?: User;
    popup?: Popup;
    fleetCustomer?: FleetCustomer;
}

interface PropsFromDispatch {
    dispatchLoginFailure: typeof loginFailure;
    dispatchLoginSuccess: typeof loginSuccess;
    dispatchLogoutRequest: typeof logoutRequest;
    dispatchFetchMaintenanceStatusRequest: typeof fetchMaintenanceStatusRequest;
    dispatchFetchCurrentUserRequest: typeof fetchCurrentUserRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchFetchSelectedFleetCustomerRequest: typeof fetchSelectedFleetCustomerRequest;
    dispatchResetFilter: typeof resetFilter;
}

interface OwnProps {
    history: History;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps & WithTranslation;

interface OwnState {
    initialized: boolean;
}

type AllState = OwnState;
