import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
    DamagedTiresBillingCustomReportParameters,
    DamagedTiresBillingScheduledReportParameters,
    ReportPeriodType,
    ScheduledReportPeriod,
} from '../../../../../../store/reports';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import styles from './damaged-tires-billing.module.scss';
import {FleetCustomerWithConfiguration} from '../../../../../../store/fleet-customers';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';

class DamagedTiresBillingComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        if (parameters && parameters.period) {
            period = parameters.period;
        }

        this.state = {
            period,
        };
    }

    public render(): JSX.Element {
        const {periodType, fleetCustomer, t} = this.props;
        const {period} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.formColumn}>
                                <div className={styles.info}>{t('Specify a period')}</div>
                                <DateRangeSelectorComponent
                                    earliestDate={fleetCustomer?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}
                                />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {onScheduledParametersChanged} = this.props;

        onScheduledParametersChanged({
            period,
        });

        this.setState({
            period,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {toDate} = this.state;

            onCustomParametersChanged({
                fromDate,
                toDate,
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {fromDate} = this.state;

            onCustomParametersChanged({
                fromDate,
                toDate,
            });
        });
    }
}

export default withTranslation()(DamagedTiresBillingComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    fleetCustomer?: FleetCustomerWithConfiguration;
    parameters?: DamagedTiresBillingScheduledReportParameters;
    onCustomParametersChanged: (parameters: DamagedTiresBillingCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: DamagedTiresBillingScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    period: ScheduledReportPeriod;
    fromDate?: Date;
    toDate?: Date;
}

type AllState = OwnState;
