import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {RequestStatus} from '../shared/types';

export enum SoldToActionTypes {
    FETCH_SOLD_TO_MAPPINGS_REQUEST = '@@soldTo/FETCH_SOLD_TO_MAPPINGS_REQUEST',
    FETCH_SOLD_TO_MAPPINGS_SUCCESS = '@@soldTo/FETCH_SOLD_TO_MAPPINGS_SUCCESS',
    FETCH_SOLD_TO_MAPPINGS_FAILURE = '@@soldTo/FETCH_SOLD_TO_MAPPINGS_FAILURE',

    FETCH_SOLD_TOS_REQUEST = '@@soldTo/FETCH_SOLD_TOS_REQUEST',
    FETCH_SOLD_TOS_SUCCESS = '@@soldTo/FETCH_SOLD_TOS_SUCCESS',
}

export type SoldToAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface FunctionalLocation {
    id: string;
    name: string;
    code: string;
}

export interface SoldToMapping {
    soldToId: string;
    name: string;
    code: string;
    soldToCustomer:string;
    city?:string;
    country?:string;
    hasMapping: boolean;
}

export interface SoldToMappings {
    mappings: SoldToMapping[];
    tbuIds:number[];
}

export interface UpdatableSoldToMapping {
    soldToId: string;
    name: string;
    code: string;
    hasMapping: boolean;
}

export interface UpdatableSoldToMappings {
    mappings: UpdatableSoldToMapping[];
    tbuIds:number[];
}

export interface SoldToWithDivisions extends FunctionalLocation {
    divisions: DivisionWithDepots[];
}

export interface DivisionWithDepots extends FunctionalLocation {
    depots: Depot[];
}

export interface Depot extends FunctionalLocation {
}

export interface SoldToState {
    readonly soldToWithDivisions?: SoldToWithDivisions[];
    readonly soldToMappings?: SoldToMappings;
    readonly soldToMappingsRequestStatus: RequestStatus;
}
