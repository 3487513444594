import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {ServiceProvider, ServiceProvidersActionTypes} from './types';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import ServiceProvidersApi from '../../api/service-providers-api';
import {fetchServiceProviderRequest, fetchServiceProviderSuccess} from './actions';
import {ApplicationState} from '../index';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleGet(action: ActionType<typeof fetchServiceProviderRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const serviceProviderId: string = action.payload;
        const result: ApiResult<ServiceProvider> = yield call(ServiceProvidersApi.getServiceProvider, serviceProviderId, fleetCustomerId);

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(fetchServiceProviderSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

const fetchFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

function *handleFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
}

function* watchRequests() {
    yield takeEvery(ServiceProvidersActionTypes.FETCH_SERVICE_PROVIDER_REQUEST, handleGet);
}

function* serviceProvidersSaga() {
    yield all([fork(watchRequests)]);
}

export default serviceProvidersSaga;
