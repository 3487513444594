import {TFunction} from 'i18next';
import {
    BillingItemDetailsType,
    BillingItemsDistributionData,
    BillingItemType,
} from '../../../../store/analytics/types/invoice-items-distribution.types';
import {
    getBillingItemDetailsTypeLabel,
    getBillingItemTypeLabel,
} from '../../../../utils/translations/billing-type-translation-helper';

export const invoiceItemTypeField = 'invoiceItemType';
export const netPriceField = 'netPrice';
export const typeField = 'type';

export function buildChartDataWithoutDetails(data: BillingItemsDistributionData[], translate: TFunction): any[]  {
    const materialsRow = {};
    materialsRow[invoiceItemTypeField] = getBillingItemTypeLabel(BillingItemType.Materials, translate);
    materialsRow[typeField] = BillingItemType.Materials;
    materialsRow[netPriceField] = 0;

    const servicesRow = {};
    servicesRow[invoiceItemTypeField] = getBillingItemTypeLabel(BillingItemType.Services, translate);
    servicesRow[typeField] = BillingItemType.Services;
    servicesRow[netPriceField] = 0;

    data.forEach((row) => {
        switch (row.billingItemType) {
            case BillingItemType.Materials:
                materialsRow[netPriceField] += row.netPrice;
                break;
            case BillingItemType.Services:
                servicesRow[netPriceField] += row.netPrice;
                break;
            default:
                throw Error(`Encountered unknown billing item type when building chart data ${row.billingItemType}`);
        }
    });

    return [servicesRow, materialsRow];
}

export function buildChartDataWithDetails(data: BillingItemsDistributionData[], translate: TFunction): any[] {
    const breakdownServicesRow = {};
    breakdownServicesRow[invoiceItemTypeField] = getBillingItemDetailsTypeLabel(BillingItemDetailsType.BreakdownServices, translate);
    breakdownServicesRow[typeField] = BillingItemDetailsType.BreakdownServices;
    breakdownServicesRow[netPriceField] = 0;

    const regularServicesRow = {};
    regularServicesRow[invoiceItemTypeField] = getBillingItemDetailsTypeLabel(BillingItemDetailsType.RegularServices, translate);
    regularServicesRow[typeField] = BillingItemDetailsType.RegularServices;
    regularServicesRow[netPriceField] = 0;

    const newTiresRow = {};
    newTiresRow[invoiceItemTypeField] = getBillingItemDetailsTypeLabel(BillingItemDetailsType.NewTires, translate);
    newTiresRow[typeField] = BillingItemDetailsType.NewTires;
    newTiresRow[netPriceField] = 0;

    const retreadTiresRow = {};
    retreadTiresRow[invoiceItemTypeField] = getBillingItemDetailsTypeLabel(BillingItemDetailsType.RetreadTires, translate);
    retreadTiresRow[typeField] = BillingItemDetailsType.RetreadTires;
    retreadTiresRow[netPriceField] = 0;

    data.forEach((row) => {
        switch (row.billingItemDetailsType) {
            case BillingItemDetailsType.RegularServices:
                regularServicesRow[netPriceField] += row.netPrice;
                break;
            case BillingItemDetailsType.BreakdownServices:
                breakdownServicesRow[netPriceField] += row.netPrice;
                break;
            case BillingItemDetailsType.NewTires:
                newTiresRow[netPriceField] += row.netPrice;
                break;
            case BillingItemDetailsType.RetreadTires:
                retreadTiresRow[netPriceField] += row.netPrice;
                break;
            default:
                throw Error(`Encountered unknown billing item type when building chart data ${row.billingItemDetailsType}`);
        }
    });

    return [breakdownServicesRow, regularServicesRow, newTiresRow, retreadTiresRow];
}
