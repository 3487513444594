import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import {FleetCustomer} from '../../../../store/fleet-customers';
import * as layoutActions from '../../../../store/layout/actions';
import {FleetCustomerMatchParameters} from '../../../../types/fleet-customer';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './vehicles-due-for-inspection-report.module.scss';
import {
    fetchVehiclesDueForInspectionReportRequest, ReportPeriodType, VehiclesCurrentStatusCustomReportParameters,
    VehiclesDueForInspectionReportRequest,
} from '../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import VehiclesDueForInspectionComponent
    from '../../report-scheduler/popup/report-data-components/vehicles-due-for-inspection/vehicles-due-for-inspection.component';

class VehiclesDueForInspectionReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {};
    }

    public render(): JSX.Element {
        const { t, location, reportRequestStatus } = this.props;
        const { functionalLocationSelection } = this.state
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.VehiclesDueForInspectionDetails);

        const isValid = functionalLocationSelection && functionalLocationSelection.value.length > 0;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div />} />
                <div className={styles.pageContent}>
                    <VehiclesDueForInspectionComponent
                        periodType={ReportPeriodType.Custom}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {}}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: VehiclesCurrentStatusCustomReportParameters) {
        this.setState({
            functionalLocationSelection: parameters.functionalLocationSelection,
        });
    }

    private download(fileType:FileType) {
        const {dispatchFetchVehicleDueForInspectionReport} = this.props;
        const {functionalLocationSelection} = this.state;
        if (functionalLocationSelection && functionalLocationSelection.value.length > 0) {
            dispatchFetchVehicleDueForInspectionReport({
                fileType,
                locations: functionalLocationSelection.value,
            });
        }
    }

    private goBack(): void {
        const { dispatchNavigateTo, fleetCustomer } = this.props;
        dispatchNavigateTo({ pathname: `/${fleetCustomer?.id}${RouteUrl.Reports}` });
    }
}

// eslint-disable-next-line
const mapStateToProps = ({ authentication, reports }: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    reportRequestStatus: reports.vehiclesDueForInspectionReportRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchVehicleDueForInspectionReport: (request: VehiclesDueForInspectionReportRequest) => dispatch(fetchVehiclesDueForInspectionReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VehiclesDueForInspectionReportPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomer;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchVehicleDueForInspectionReport: typeof fetchVehiclesDueForInspectionReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
