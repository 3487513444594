import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import FunctionalLocationsSelector
    from '../../analytics/dashboard-controls/functional-locations-selector/functional-locations-selector';
import {generateUUID} from '../../../utils/uuid-helpers';
import {FunctionalLocationsSelectionsControlValue} from '../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import styles from './location-selector.module.scss';

class LocationSelectorComponent extends Component<AllProps, AllState> {

    private functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;

    constructor(props) {
        super(props);

        const {defaultValue} = this.props;

        if (defaultValue) {
            this.functionalLocationSelection = defaultValue;
        } else {
            this.functionalLocationSelection = undefined;
        }
    }

    public render(): JSX.Element {
        return (
            <div className={styles.selectorPanel}>
                <FunctionalLocationsSelector onValueChange={(value => this.valueChange(value))}
                                             defaultValue={this.functionalLocationSelection}
                                             setControlRef={() => this.setControlRef()}
                                             key={generateUUID()}/>
            </div>
        );
    }

    private setControlRef() {

    }

    private valueChange(controlValue: FunctionalLocationsSelectionsControlValue) {
        const {onFunctionalLocationChange} = this.props;
        this.functionalLocationSelection = controlValue;
        onFunctionalLocationChange(controlValue);
    }
}

export default withTranslation()(LocationSelectorComponent);

interface OwnProps {
    defaultValue?: FunctionalLocationsSelectionsControlValue;
    onFunctionalLocationChange: (locations: FunctionalLocationsSelectionsControlValue) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
