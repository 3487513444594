import {Reducer} from 'redux';
import {DashboardAction, DashboardActionTypes, DashboardState} from './types';
import {SharedActionTypes} from '../shared/types';

export function initialState(): DashboardState {
    return {
        dashboards: [],
        inProgress: true,
    };
}

const reducer: Reducer<DashboardState, DashboardAction> = (state = initialState(), action): DashboardState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case DashboardActionTypes.SKIP_DASHBOARDS_REQUEST: {
            return {...state, inProgress: false};
        }
        case DashboardActionTypes.FETCH_DASHBOARDS_REQUEST: {
            return {...state, inProgress: true};
        }
        case DashboardActionTypes.DELETE_DASHBOARD_REQUEST: {
            return {...state, inProgress: true};
        }
        case DashboardActionTypes.CREATE_DASHBOARD_REQUEST: {
            return {...state, inProgress: true};
        }
        case DashboardActionTypes.UPDATE_DASHBOARD_REQUEST: {
            return {...state, inProgress: true};
        }
        case DashboardActionTypes.SET_DASHBOARDS: {
            return {...state, dashboards: action.payload, inProgress: false};
        }
        case DashboardActionTypes.DASHBOARD_ACTION_FAILURE: {
            return {...state, inProgress: false};
        }
        case DashboardActionTypes.RESTORE_DEFAULT_DASHBOARDS: {
            return {...state, inProgress: true};
        }
        default: {
            return state;
        }
    }
};

export {reducer as dashboardReducer};
export {initialState as initialDashboardState};
