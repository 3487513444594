import { faHistory, faStethoscope, faTire, faTruck, faWarehouse, faWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {faEuroSign} from "@fortawesome/pro-light-svg-icons";
import {getReportDescription, getReportName, ReportCatalogEntry, ReportIcon} from '../report-catalog';
import styles from './report-catalog-item.module.scss';

class ReportCatalogItemComponent extends Component<AllProps> {
    public render(): JSX.Element {
        const { catalogItem, t } = this.props;

        return (
            <div className={styles.container} onClick={() => this.onClick()}>
                <div className={styles.iconPanel}>{this.getReportIcon(catalogItem.icon)}</div>
                <div className={styles.reportLabels}>
                    <div className={styles.title}>{getReportName(catalogItem.type,t)}</div>
                    <div className={styles.subTitle}>{getReportDescription(catalogItem.type,t)}</div>
                </div>
            </div>
        );
    }

    private getReportIcon(icon: ReportIcon): JSX.Element {
        switch (icon) {
            case ReportIcon.truck:
                return <FontAwesomeIcon icon={faTruck} />;
            case ReportIcon.inspection:
                return <FontAwesomeIcon icon={faStethoscope} />;
            case ReportIcon.due:
                return <FontAwesomeIcon icon={faHistory} />;
            case ReportIcon.jobs:
                return <FontAwesomeIcon icon={faWrench} />;
            case ReportIcon.stock:
                return <FontAwesomeIcon icon={faWarehouse} />;
            case ReportIcon.billing:
                return <FontAwesomeIcon icon={faEuroSign} />;
            case ReportIcon.tire:
            default:
                return <FontAwesomeIcon icon={faTire} />;
        }
    }

    private onClick(): void {
        const { onSelect } = this.props;
        onSelect();
    }
}

export default withTranslation()(ReportCatalogItemComponent);

interface OwnProps {
    catalogItem: ReportCatalogEntry;
    onSelect: () => void;
}

type AllProps = OwnProps & WithTranslation;
