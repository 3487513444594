import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import MenuButton from '../../components/header-menu/menu-button';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {logoutRequest, User} from '../../store/authentication';
import {FleetCustomer} from '../../store/fleet-customers';
import {toggleSidebar} from '../../store/layout';
import {unselectFleetCustomer} from '../../store/shared/actions';
import {RecentFleetCustomerMap} from '../../types/fleet-customer';
import {compareDesc, format} from '../../utils/date-helper';
import {
    LocalStorageKey,
    persistParsedToLocalStorage,
    retrieveParsedFromLocalStorage,
} from '../../utils/local-storage-helpers';
import styles from './fleet-customer-selection.module.scss';
import {conditionalClassLister} from "../../utils/class-helpers";

class FleetCustomerSelectionPage extends Component<AllProps, AllState> {
    private readonly maximumRecent = 5;

    private selectedElement?: HTMLDivElement;

    constructor(props) {
        super(props);

        const {user, dispatchToggleSidebar, dispatchUnselectFleetCustomer} = this.props;
        const recentFleetCustomers =
            retrieveParsedFromLocalStorage<RecentFleetCustomerMap>(LocalStorageKey.RecentFleetCustomers) || {};
        const sortedRecentFleetCustomers = this.sortRecentFleetCustomers(recentFleetCustomers);
        dispatchUnselectFleetCustomer();

        this.state = {
            search: '',
            selectedIndex: -1,
            fleetCustomers: user ? user.fleetCustomers.sort((a, b) => a.name.localeCompare(b.name)) : [],
            recentFleetCustomers: sortedRecentFleetCustomers,
        };
        dispatchToggleSidebar(false);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const {user} = this.props;
        const {selectedIndex} = this.state;

        if (user !== prevProps.user) {
            const recentFleetCustomers =
                retrieveParsedFromLocalStorage<RecentFleetCustomerMap>(LocalStorageKey.RecentFleetCustomers) || {};
            const sortedRecentFleetCustomers = this.sortRecentFleetCustomers(recentFleetCustomers);

            this.setState({
                fleetCustomers: user ? user.fleetCustomers.sort((a, b) => a.name.localeCompare(b.name)) : [],
                recentFleetCustomers: sortedRecentFleetCustomers,
            });
            this.onFilter('');
        }

        if (selectedIndex > -1 && selectedIndex !== prevState.selectedIndex) {
            this.selectedElement?.scrollIntoView({behavior: 'smooth'});
        }

    }

    public render() {
        const {t, user} = this.props;
        const {search, fleetCustomers, recentFleetCustomers, selectedIndex} = this.state;

        return user ? (
            <div className={styles.container}>
                <div className={styles.header}>
                    <MenuButton/>
                </div>
                <div className={styles.content}>
                    <div className={styles.fleetCustomersContainer}>
                        <div className={styles.availableFleetCustomers}>
                            <div className={styles.availableTitle}>{t('Please select a fleet')}</div>
                            <div className={styles.searchContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                                <input
                                    className={styles.searchInput}
                                    type='text'
                                    onKeyDown={(e) => this.handleKeyDown(e)}
                                    placeholder={t('Fleet name')}
                                    autoFocus
                                    onInput={(event) => this.onFilter(event.currentTarget.value)}
                                />
                            </div>
                            <div className={styles.resultContainer}>
                                {fleetCustomers.map((fc, index: number) => {
                                    const isSelected = index === selectedIndex;
                                    const fleetCustomerClasses = conditionalClassLister(styles)({
                                        isSelected,
                                        resultItem: true,
                                    });
                                    return (
                                        <div
                                            className={fleetCustomerClasses}
                                            ref={(r) => {
                                                if (isSelected) {
                                                    this.selectedElement = r!
                                                }
                                            }
                                            }
                                            key={fc.id}
                                            onClick={() => this.onSelect(fc)}>
                                            {Array.from(fc.name).map((c, i) => (
                                                    <span
                                                        className={
                                                            search.toLowerCase().includes(c.toLowerCase())
                                                                ? styles.matchingCharacter
                                                                : undefined
                                                        }
                                                        key={i}>
                                                        {c !== ' ' ? c : <>&nbsp;</>}
                                                    </span>
                                                ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.recentFleetCustomers}>
                            <div className={styles.recentFleetCustomersTitle}>{t('Recent')}</div>
                            {recentFleetCustomers.hasOwnProperty(user.id) &&
                            Object.keys(recentFleetCustomers[user.id]).length > 0 ? (
                                Object.keys(recentFleetCustomers[user.id]).map((name) => (
                                        <div
                                            className={styles.recentFleetCustomer}
                                            key={name}
                                            onClick={() => this.onSelectRecent(name)}>
                                            <div className={styles.recentName}>{name}</div>
                                            <div className={styles.recentDate}>
                                                {t('Last opened: {{date}}', {
                                                    date: format(
                                                        new Date(recentFleetCustomers[user.id][name]),
                                                        'MMM D, YYYY',
                                                    ),
                                                })}
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className={`${styles.recentFleetCustomer} ${styles.recentFleetCustomerNone}`}>
                                    {t('Nothing yet')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }

    private onFilter(text: string): void {
        const {user} = this.props;

        if (user) {
            const {fleetCustomers} = user;
            this.setState({
                search: text,
                selectedIndex: -1,
                fleetCustomers: fleetCustomers
                    .filter((fc) => fc.name.toLowerCase().includes(text.toLowerCase()))
                    .sort((a, b) => a.name.localeCompare(b.name)),
            });
        }
    }

    private onSelect(fleetCustomer: FleetCustomer): void {
        const {user, dispatchNavigateTo} = this.props;
        const {recentFleetCustomers} = this.state;
        const updatedRecentFleetCustomers = {
            ...recentFleetCustomers,
            [user!.id]: {
                ...recentFleetCustomers[user!.id],
                [fleetCustomer.name]: new Date().toISOString(),
            },
        };
        const sortedUpdatedRecentFleetCustomers = this.sortRecentFleetCustomers(updatedRecentFleetCustomers);

        persistParsedToLocalStorage(LocalStorageKey.RecentFleetCustomers, sortedUpdatedRecentFleetCustomers);

        this.setState({
            recentFleetCustomers: sortedUpdatedRecentFleetCustomers,
        });
        dispatchNavigateTo({pathname: `/${fleetCustomer.id}${RouteUrl.Analytics}`});
    }

    private onSelectRecent(fleetCustomerName: string): void {
        const {fleetCustomers} = this.state;
        const fleetCustomer = fleetCustomers.find((f) => f.name === fleetCustomerName);

        this.onSelect(fleetCustomer!);
    }

    private handleKeyDown(e: any): void {
        const {selectedIndex, fleetCustomers} = this.state;

        if (e.key === 'Enter') {
            if (selectedIndex !== -1) {
                this.onSelect(fleetCustomers[selectedIndex]);
            }
        }
        if (fleetCustomers) {
            let newIndex = -1;
            if (e.key === 'ArrowDown') {
                newIndex = selectedIndex + 1;
                if (newIndex < fleetCustomers.length) {
                    this.setState({selectedIndex: newIndex});
                }
            }
            if (e.key === 'ArrowUp') {
                newIndex = selectedIndex - 1;
                if (newIndex >= -1) {
                    this.setState({selectedIndex: newIndex});
                }
            }
        }
    }

    private sortRecentFleetCustomers(recentFleetCustomers: RecentFleetCustomerMap): RecentFleetCustomerMap {
        return Object.keys(recentFleetCustomers).reduce((map, user) => {
            map[user] = Object.keys(recentFleetCustomers[user])
                .sort((a, b) =>
                    compareDesc(new Date(recentFleetCustomers[user][a]), new Date(recentFleetCustomers[user][b])),
                )
                .slice(0, this.maximumRecent)
                .reduce((map, fleetCustomer) => {
                    map[fleetCustomer] = recentFleetCustomers[user][fleetCustomer];
                    return map;
                }, {});
            return map;
        }, {});
    }
}

const mapStateToProps = ({authentication}: ApplicationState): PropsFromState => ({
    user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogoutRequest: () => dispatch(logoutRequest()),
    dispatchUnselectFleetCustomer: () => dispatch(unselectFleetCustomer()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FleetCustomerSelectionPage));

interface PropsFromState {
    user?: User;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof toggleSidebar;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchLogoutRequest: typeof logoutRequest;
    dispatchUnselectFleetCustomer: typeof unselectFleetCustomer;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    search: string;
    fleetCustomers: FleetCustomer[];
    selectedIndex: number;
    recentFleetCustomers: RecentFleetCustomerMap;
}

type AllState = OwnState;
