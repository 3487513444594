import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {faExclamationCircle, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import {UserSummary} from '../../../store/authentication';
import * as layoutActions from '../../../store/layout/actions';
import {closePopup, Popup, showPopup} from '../../../store/popup';
import {Paged, RequestStatus} from '../../../store/shared/types';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../../store/toast';
import {searchUsersRequest, UsersRequest} from '../../../store/users';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {generateUUID} from '../../../utils/uuid-helpers';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './user-management.module.scss';
import UsersApi from '../../../api/users-api';
import Paging from '../../jobs/jobs-table/paging/paging';

class UserManagementPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(false);
        this.state = {
            page: 1,
            itemsPerPage: 15,
            searchText: undefined,
        };
    }

    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {deleteUserRequestStatus, createUserRequestStatus} = this.props;
        const {page, itemsPerPage} = this.state;

        if (
            deleteUserRequestStatus &&
            prevProps.deleteUserRequestStatus !== deleteUserRequestStatus &&
            deleteUserRequestStatus.isSuccess
        ) {
            this.showSuccessToast('User Successfully Deleted');
        }

        if (
            createUserRequestStatus &&
            prevProps.createUserRequestStatus !== createUserRequestStatus &&
            createUserRequestStatus.isSuccess
        ) {
            this.showSuccessToast('User Successfully Created');
        }

        if (
            prevState.page !== page ||
            prevState.itemsPerPage !== itemsPerPage
        ) {
            this.fetchData();
        }
    }

    public render(): JSX.Element {
        const {
            t,
            users,
            createUserRequestStatus,
            dispatchNavigateTo,
            deleteUserRequestStatus,
            getUsersRequestStatus,
        } = this.props;
        const {page, itemsPerPage} = this.state;

        return users && users.pageData && !createUserRequestStatus?.isInProgress && !deleteUserRequestStatus?.isInProgress ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('User Management')}
                    onBack={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.topBar}>
                            <div className={styles.searchContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                                <input
                                    className={styles.searchInput}
                                    type='text'
                                    placeholder={t('Filter')}
                                    autoFocus
                                    onChange={(event) => this.onSearch(event)}
                                    onKeyUp={(event) => this.onKeyUp(event)}
                                />
                            </div>
                        </div>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.alertCellSpacer}/>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('UMF Username')}
                                </div>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('Email')}
                                </div>
                                <div className={styles.cell}>{t('Fleet Customers')}</div>
                                <div className={styles.cell}>{t('Last Login')}</div>
                            </div>
                            <div className={styles.rows}>
                                {getUsersRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    </div>
                                ) : users.pageData.length > 0 ? (
                                    users.pageData.map((u) => {
                                        const needsAttention =
                                            u.numberOfFleetCustomers === 0 || u.numberOfPermissions === 0;
                                        const rowClasses = conditionalClassLister(styles)({
                                            row: true,
                                            highlight: needsAttention,
                                        });

                                        return (
                                            <div
                                                className={rowClasses}
                                                key={u.email}
                                                onClick={() => this.onSelectUser(u)}>
                                                <div className={styles.alertCellSpacer}>
                                                    {needsAttention ? (
                                                        <FontAwesomeIcon icon={faExclamationCircle}/>
                                                    ) : null}
                                                </div>
                                                <div className={styles.cell} style={{flexGrow: 2}}>
                                                    {u.cognitoUsername}
                                                </div>
                                                <div className={styles.cell} style={{flexGrow: 2}}>
                                                    {u.email}
                                                </div>
                                                <div className={styles.cell}>
                                                    {u.numberOfFleetCustomers}
                                                </div>
                                                <div className={styles.cell}>
                                                    {u.lastLogin
                                                        ? `${new Date(u.lastLogin).toLocaleDateString()} ${new Date(
                                                            u.lastLogin,
                                                        ).toLocaleTimeString()}`
                                                        : ''}
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className={styles.noData}>
                                        {t('No data found.')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {users ? (
                        <div className={styles.paging}>
                            <Paging
                                currentPage={page}
                                initialItemsPerPage={itemsPerPage}
                                onPreviousPage={() => this.onPreviousPage()}
                                onNextPage={() => this.onNextPage()}
                                onJumpToPage={(index: number) => this.onJumpToPage(index)}
                                total={users.totalCount}
                                onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                                itemsPerPageBackground="white"
                            />
                        </div>
                    ) : null}
                    <div className={styles.buttons}>
                        <button
                            type='button'
                            className={styles.button}
                            onClick={() => this.onDownloadUserStructure()}>
                            {t('Download users details')}
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    private onSearch(event: any) {
        const searchValue = event.target.value;
        this.setState({
            searchText: searchValue,
        });
    }

    private onKeyUp(event: any) {
        if (event.key === 'Enter') {
            this.fetchData();
        }
    }

    public onSelectUser(user: UserSummary): void {
        const {dispatchNavigateTo} = this.props;
        dispatchNavigateTo({pathname: RouteUrl.UserDetails.replace(':userId', user.id)});
    }

    private fetchData(): void {
        const {dispatchSearchUsers} = this.props;
        const {page, itemsPerPage, searchText} = this.state;
        const request: UsersRequest = {
            page,
            itemsPerPage,
            search: searchText
        };
        dispatchSearchUsers(request);
    }

    private onItemsPerPageChanged(items: number): void {
        this.setState({
            itemsPerPage: items,
        });
    }

    private onPreviousPage(): void {
        const {page} = this.state;
        this.setState({
            page: page - 1,
        });
    }

    private onNextPage(): void {
        const {page} = this.state;
        this.setState({
            page: page + 1,
        });
    }

    private onJumpToPage(pageIndex: number): void {
        if (!Number.isNaN(pageIndex)) {
            this.setState({
                page: pageIndex,
            })
        }
    }

    private onDownloadUserStructure(): void {
        // eslint-disable-next-line react/destructuring-assignment
        UsersApi.downloadUserStructure().then(() => {
        });
    }

    private showSuccessToast(message): void {
        const {dispatchAddToast, t} = this.props;

        dispatchAddToast({
            id: generateUUID(),
            messages: [t(message)],
            type: ToastType.Success,
            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
        });
    }
}

const mapStateToProps = ({users}: ApplicationState): PropsFromState => ({
    users: users.users,
    getUsersRequestStatus: users.searchUsersRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchSearchUsers: (request: UsersRequest) => dispatch(searchUsersRequest(request)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserManagementPage));

interface PropsFromState {
    users?: Paged<UserSummary>;
    deleteUserRequestStatus?: RequestStatus;
    createUserRequestStatus?: RequestStatus;
    getUsersRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchSearchUsers: typeof searchUsersRequest;
    dispatchAddToast: typeof addToast;
}

interface OwnProps {
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    page: number;
    itemsPerPage: number;
    searchText: string | undefined;
}

type AllState = OwnState;
