import {TFunction} from 'i18next';
import {DateRange, KpiTypes} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {ParamKey} from '../../../../store/dashboard';
import {
    toBetweenMonthRangeDisplay,
    toEndDateDisplay, yoyComparisonDisplay,
} from '../../dashboard-controls/date-range-selector/helpers/date-range-helper';

export interface KpiMetaData {
    numberFormatter: NumberFormatter;
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
    subtitleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
}

export class NumberFormatter {
    constructor(
        public thousandsSeparator: string,
        public decimalSeparator: string,
        public fixedDecimalScale: boolean,
        public decimalScale: number) {
    }
}

function betweenMonthRangeDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return toBetweenMonthRangeDisplay(dateRange, t);
    }
    return '';
}

function yoyRangeDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return yoyComparisonDisplay(dateRange.start, dateRange.end, t);
    }
    return '';
}

function vehiclesDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return `${t('Vehicles {{displayDate}}', {displayDate: toEndDateDisplay(dateRange, t)})}`;
    }
    return '';
}

export const kpiMetaStore = new Map<KpiTypes, KpiMetaData>();
kpiMetaStore.set(KpiTypes.TotalFleetSize, {
    numberFormatter: new NumberFormatter('.', ',', true, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Fleet Parc'),
    subtitleFunction: vehiclesDisplay});
kpiMetaStore.set(KpiTypes.TotalInvoiced, {
    numberFormatter: new NumberFormatter('.', ',', true, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Total Invoiced'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.YoYFinancialComparison, {
    numberFormatter: new NumberFormatter('.', ',', true, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('YoY Comparison'),
    subtitleFunction: yoyRangeDisplay});
kpiMetaStore.set(KpiTypes.InspectionRatio, {
    numberFormatter: new NumberFormatter('.', ',', true, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Inspection Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalBreakdowns, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Breakdowns Handled'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalInspections, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Inspections Conducted'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.ScheduledInspections, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Scheduled Inspections'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.UnscheduledInspections, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Unscheduled Inspections'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalJobCount, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Jobs Executed'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.FleetGrowth, {
    numberFormatter: new NumberFormatter('.', ',', true, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Fleet Growth'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.JobsAvgAcceptanceTime, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Average Acceptance Time'),
    subtitleFunction: betweenMonthRangeDisplay});
