import {TFunction} from 'i18next';
import {generateUUID} from '../../utils/uuid-helpers';
import {ControlType, Dashboard, ParamKey, WidgetType} from '../../store/dashboard';

export function getDashboardConfiguration(t:TFunction): Dashboard {
    return {
        id: generateUUID(),
        index: 0,
        name: 'JOB EXPLORER',
        controls: [
            {
                type: ControlType.DateRange,
                summaryTitle: t('Date Range'),
                key: ParamKey.InspectionPeriod,
                configuration: {},
            },
            {
                type: ControlType.FunctionalLocations,
                key: ParamKey.FunctionalLocationSelections,
                summaryTitle: t('Depots'),
                configuration: {},
            },
        ],
        widgets: [
            {
                type: WidgetType.JobsTable,
                configuration: {},
                id: generateUUID(),
                layout: {i: generateUUID(), x: 0, y: 0, w: 12, h: 12, static: true},
            },
        ],
        isConfigurable: false
    }
}
