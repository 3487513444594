import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import AnalyticsApi from '../../api/analytics-api';
import {ApiResult} from '../../types/api-result';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApplicationState} from '../index';
import {
    fetchAvoidableBreakdownsKPIRequest,
    fetchAvoidableBreakdownsKPISuccess,
    fetchBillingItemsDistributionRequest,
    fetchBillingItemsDistributionSuccess,
    fetchBillingItemsEvolutionRequest,
    fetchBillingItemsEvolutionSuccess,
    fetchFleetMovementRequest,
    fetchFleetMovementSuccess,
    fetchFleetSizeEvolutionRequest,
    fetchFleetSizeEvolutionSuccess,
    fetchInspectionHistogramRequest,
    fetchInspectionHistogramSuccess,
    fetchInspectionStatusRequest,
    fetchInspectionStatusSuccess,
    fetchJobEvolutionRequest,
    fetchJobEvolutionSuccess,
    fetchJobTypesDistributionRequest,
    fetchJobTypesDistributionSuccess,
    fetchRetreadRatioKPIRequest,
    fetchRetreadRatioKPISuccess,
    fetchServiceProvidersMonthlyOverviewRequest,
    fetchServiceProvidersMonthlyOverviewSuccess,
    fetchTireFitmentsBrandDistributionSuccess,
    fetchTopServiceProvidersRequest,
    fetchTopServiceProvidersSuccess,
    fetchTireFitmentsTopPatternsSuccess,
    fetchUnderInflationKPIRequest,
    fetchUnderInflationKPISuccess,
    fetchVehiclesDistributionSuccess,
    fetchVehicleTypesDistributionRequest,
    getAlertsWidgetDataRequest,
    getAlertsWidgetDataSuccess,
    getKPIDataRequest,
    getKPIDataSuccess,
    fetchTiresFittedBrandDistributionSuccess,
    fetchTiresFittedTopPatternsSuccess,
    fetchTopReusableTiresFittedByServiceProviderRequest,
    fetchTopRetreadTiresFittedByServiceProviderRequest,
    fetchTopRetreadTiresFittedByServiceProviderSuccess, fetchTopReusableTiresFittedByServiceProviderSuccess,
} from './actions';
import {AlertTypes, AnalyticsActionTypes, KpiTypes} from './types';

const fetchFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* processAnalyticsCall<TRequest>(requestParameters: TRequest, apiCall: any, dispatchData: any) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<any> = yield call(apiCall, requestParameters, fleetCustomerId);
        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(dispatchData(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleVehicleTypesDistribution(action: ActionType<typeof fetchVehicleTypesDistributionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getVehicleTypesDistribution,
        fetchVehiclesDistributionSuccess,
    );
}

export function* handleFetchFleetSizeEvolution(action: ActionType<typeof fetchFleetSizeEvolutionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getFleetSizeEvolution,
        fetchFleetSizeEvolutionSuccess,
    );
}

export function* handleFetchJobEvolution(action: ActionType<typeof fetchJobEvolutionRequest>) {
    yield call(processAnalyticsCall, action.payload, AnalyticsApi.getJobEvolution, fetchJobEvolutionSuccess);
}

export function* handleServiceProvidersMonthlyOverview(
    action: ActionType<typeof fetchServiceProvidersMonthlyOverviewRequest>,
) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getServiceProvidersMonthlyOverview,
        fetchServiceProvidersMonthlyOverviewSuccess,
    );
}

export function* handleTopServiceProvider(action: ActionType<typeof fetchTopServiceProvidersRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTopServiceProvidersRequest,
        fetchTopServiceProvidersSuccess,
    );
}

export function* handleTopReusableTiresFittedByServiceProvider(action: ActionType<typeof fetchTopReusableTiresFittedByServiceProviderRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTopReusableTiresFittedByServiceProvidersRequest,
        fetchTopReusableTiresFittedByServiceProviderSuccess,
    );
}

export function* handleTopRetreadTiresFittedByServiceProvider(action: ActionType<typeof fetchTopRetreadTiresFittedByServiceProviderRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTopRetreadTiresFittedByServiceProvidersRequest,
        fetchTopRetreadTiresFittedByServiceProviderSuccess,
    );
}

export function* handleInspectionStatus(action: ActionType<typeof fetchInspectionStatusRequest>) {
    yield call(processAnalyticsCall, action.payload, AnalyticsApi.getInspectionStatus, fetchInspectionStatusSuccess);
}

export function* handleBillingItemsDistribution(action: ActionType<typeof fetchBillingItemsDistributionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getBillingItemsDistribution,
        fetchBillingItemsDistributionSuccess,
    );
}

export function* handleBillingItemsEvolution(action: ActionType<typeof fetchBillingItemsEvolutionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getBillingItemsEvolution,
        fetchBillingItemsEvolutionSuccess,
    );
}

export function* handleFetchJobTypeDistribution(action: ActionType<typeof fetchJobTypesDistributionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getJobTypesDistribution,
        fetchJobTypesDistributionSuccess,
    );
}

export function* handleFetchFleetMovement(action: ActionType<typeof fetchFleetMovementRequest>) {
    yield call(processAnalyticsCall, action.payload, AnalyticsApi.getFleetMovement, fetchFleetMovementSuccess);
}

export function* handleFetchInspectionHistogram(action: ActionType<typeof fetchInspectionHistogramRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getInspectionHistogram,
        fetchInspectionHistogramSuccess,
    );
}

export function* handleTireFitmentsBrandDistribution(action: ActionType<typeof fetchInspectionHistogramRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTireFitmentsBrandDistribution,
        fetchTireFitmentsBrandDistributionSuccess,
    );
}

export function* handleTiresFittedBrandDistribution(action: ActionType<typeof fetchInspectionHistogramRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTiresFittedBrandDistribution,
        fetchTiresFittedBrandDistributionSuccess,
    );
}

export function* handleTireFitmentsTopPatterns(action: ActionType<typeof fetchInspectionHistogramRequest>) {
    yield call(processAnalyticsCall, action.payload, AnalyticsApi.getTireFitmentsTopPatterns, fetchTireFitmentsTopPatternsSuccess);
}

export function* handleTiresFittedTopPatterns(action: ActionType<typeof fetchInspectionHistogramRequest>) {
    yield call(processAnalyticsCall, action.payload, AnalyticsApi.getTiresFittedTopPatterns, fetchTiresFittedTopPatternsSuccess);
}

export function* handleAvoidableBreakdownsKPI(action: ActionType<typeof fetchAvoidableBreakdownsKPIRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getAvoidableBreakdownsKPIData,
        fetchAvoidableBreakdownsKPISuccess,
    );
}

export function* handleRetreadRatioKPI(action: ActionType<typeof fetchRetreadRatioKPIRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getRetreadRatioKPIData,
        fetchRetreadRatioKPISuccess,
    );
}

export function* handleUnderInflationKPI(action: ActionType<typeof fetchUnderInflationKPIRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getUnderInflationKPIData,
        fetchUnderInflationKPISuccess,
    );
}

export function* handleGetKPIWidgetData(action: ActionType<typeof getKPIDataRequest>) {
    const request = action.payload;
    switch (request.type) {
        case KpiTypes.TotalBreakdowns:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalBreakdownsKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalJobCount:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalJobCountKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalFleetSize:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalFleetSizeKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalInvoiced:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalInvoicedKPI, getKPIDataSuccess);
            break;
        case KpiTypes.YoYFinancialComparison:
            yield call(processAnalyticsCall, request, AnalyticsApi.getYoYFinancialComparisonKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalInspections:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalInspectionsKPI, getKPIDataSuccess);
            break;
        case KpiTypes.ScheduledInspections:
            yield call(processAnalyticsCall, request, AnalyticsApi.getScheduledInspectionsKPI, getKPIDataSuccess);
            break;
        case KpiTypes.UnscheduledInspections:
            yield call(processAnalyticsCall, request, AnalyticsApi.getUnscheduledInspectionsKPI, getKPIDataSuccess);
            break;
        case KpiTypes.InspectionRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getInspectionRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.FleetGrowth:
            yield call(processAnalyticsCall, request, AnalyticsApi.getFleetGrowthKPI, getKPIDataSuccess);
            break;
        case KpiTypes.JobsAvgAcceptanceTime:
            yield call(processAnalyticsCall, request, AnalyticsApi.getJobsAverageAcceptanceTime, getKPIDataSuccess);
            break;
        default:
            yield call(handleUnexpectedErrorWithToast, `No Mapping found in sagas handler for ${request.type}`);
    }
}

export function* handleGetAlertsWidgetData(action: ActionType<typeof getAlertsWidgetDataRequest>) {
    const request = action.payload;
    switch (request.type) {
        case AlertTypes.TirePressure:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTirePressureAlertData, getAlertsWidgetDataSuccess);
            break;
        case AlertTypes.TreadDepth:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTreadDepthAlertData, getAlertsWidgetDataSuccess);
            break;
        case AlertTypes.RetreadRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getRetreadRatioAlertData, getAlertsWidgetDataSuccess);
            break;
        case AlertTypes.ServiceFrequency:
            yield call(processAnalyticsCall, request, AnalyticsApi.getServiceFrequencyAlertData, getAlertsWidgetDataSuccess);
            break;
        case AlertTypes.BreakdownRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getBreakdownFrequencyAlertData, getAlertsWidgetDataSuccess);
            break;
        default:
            yield call(handleUnexpectedErrorWithToast, `No Mapping found in sagas handler for ${request.type}`);
    }
}

function* watchFetchRequest() {
    yield takeEvery(AnalyticsActionTypes.VEHICLES_DISTRIBUTION_REQUEST, handleVehicleTypesDistribution);
    yield takeEvery(AnalyticsActionTypes.DAILY_FLEET_SIZE_EVOLUTION_REQUEST, handleFetchFleetSizeEvolution);
    yield takeEvery(AnalyticsActionTypes.JOB_TYPES_DISTRIBUTION_REQUEST, handleFetchJobTypeDistribution);
    yield takeEvery(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST, handleGetKPIWidgetData);
    yield takeEvery(AnalyticsActionTypes.ALERTS_WIDGET_DATA_REQUEST, handleGetAlertsWidgetData);
    yield takeEvery(AnalyticsActionTypes.DAILY_JOB_EVOLUTION_REQUEST, handleFetchJobEvolution);
    yield takeEvery(AnalyticsActionTypes.BILLING_ITEMS_DISTRIBUTION_REQUEST, handleBillingItemsDistribution);
    yield takeEvery(AnalyticsActionTypes.BILLING_ITEMS_EVOLUTION_REQUEST, handleBillingItemsEvolution);
    yield takeEvery(AnalyticsActionTypes.INSPECTION_STATUS_REQUEST, handleInspectionStatus);
    yield takeEvery(
        AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST,
        handleServiceProvidersMonthlyOverview,
    );
    yield takeEvery(AnalyticsActionTypes.TOP_SERVICE_PROVIDERS_REQUEST, handleTopServiceProvider);
    yield takeEvery(AnalyticsActionTypes.TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_REQUEST, handleTopRetreadTiresFittedByServiceProvider);
    yield takeEvery(AnalyticsActionTypes.TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_REQUEST, handleTopReusableTiresFittedByServiceProvider);
    yield takeEvery(AnalyticsActionTypes.FLEET_MOVEMENT_REQUEST, handleFetchFleetMovement);
    yield takeEvery(AnalyticsActionTypes.INSPECTION_HISTOGRAM_REQUEST, handleFetchInspectionHistogram);
    yield takeEvery(AnalyticsActionTypes.TIRE_FITMENTS_BRAND_DISTRIBUTION_REQUEST, handleTireFitmentsBrandDistribution);
    yield takeEvery(AnalyticsActionTypes.TIRES_FITTED_BRAND_DISTRIBUTION_REQUEST, handleTiresFittedBrandDistribution);
    yield takeEvery(AnalyticsActionTypes.TIRE_FITMENTS_TOP_PATTERNS_REQUEST, handleTireFitmentsTopPatterns);
    yield takeEvery(AnalyticsActionTypes.TIRES_FITTED_TOP_PATTERNS_REQUEST, handleTiresFittedTopPatterns);
    yield takeEvery(AnalyticsActionTypes.AVOIDABLE_BREAKDOWNS_KPI_REQUEST, handleAvoidableBreakdownsKPI);
    yield takeEvery(AnalyticsActionTypes.RETREAD_RATIO_KPI_REQUEST, handleRetreadRatioKPI);
    yield takeEvery(AnalyticsActionTypes.UNDER_INFLATION_KPI_REQUEST, handleUnderInflationKPI);
}

function* analyticsSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default analyticsSaga;
