import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './vehicle-types-distribution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import {
    fetchVehicleTypesDistributionRequest,
    FunctionalLocationSelection,
    FunctionalLocationType,
    updateSingleDefaultControlValue,
} from '../../../../store/analytics';
import {buildRequest} from './request-builder';
import WidgetHeaderDropdown, {
    DropdownOption
} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetLoading from '../widget-loading';
import {FunctionalLocation, SoldToWithDivisions} from '../../../../store/soldto';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    addClickEvent,
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    customizeGrip,
} from '../utils/chart-utils';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    buildChartData,
    functionalLocationField,
    functionalLocationIdField,
    getVehiclesTypes,
} from './chart-data-adapter';
import {getBoldColorPalette} from '../utils/chart-colors';
import {RouteUrl} from '../../../../routes';
import {ParamKey} from '../../../../store/dashboard';
import {
    FunctionalLocationsSelectionsControlValue
} from '../../dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {getDropDownOptionLabel} from '../../../../utils/translations/dropdown-option-translation-helper';
import {
    VehiclesDistributionData,
    VehicleTypesDistributionRequest,
} from '../../../../store/analytics/types/vehicle-types-distribution.types';
import {ViewMode} from '../../../../types/view-mode';
import WidgetTable, {WidgetColumnConfig, WidgetTableColumnType} from '../../../../components/widget-table/widget-table';
import {FleetCustomerWithConfiguration} from '../../../../store/fleet-customers';

am4core.useTheme(am4themes_animated);

class VehicleTypesDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, VehiclesDistributionData[]> {

    private chartId: string;

    private readonly OPTION_DEPOT = {id: '1', key: DropdownOptionKey.ByDepot};

    private readonly OPTION_DIVISION = {id: '2', key: DropdownOptionKey.ByDivision};

    private readonly OPTION_SOLD_TO = {id: '3', key: DropdownOptionKey.ByCompany};

    private locations: Map<string, FunctionalLocation> = new Map<string, FunctionalLocation>();

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.state = {
            options: [this.OPTION_DEPOT, this.OPTION_DIVISION, this.OPTION_SOLD_TO],
            selectedOption: this.OPTION_DEPOT,
            viewMode: ViewMode.chart,
        };

        if (this.props.soldToWithDivisions && this.props.soldToWithDivisions.length > 0) {
            this.updateLocations();
        }
    }

    protected afterComponentDidUpdate(prevProps) {
        if (prevProps.soldToWithDivisions !== this.props.soldToWithDivisions) {
            this.updateLocations();
        }
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options, viewMode} = this.state;

        const locationEntity = this.getLocationEntity(this.state.selectedOption);
        const vehicleTypes = getVehiclesTypes(data);
        let listData: any[] = [];
        if (data) {
            listData = buildChartData(data, locationEntity, this.locations);
        }

        const columnConfigs: WidgetColumnConfig[] = [
            {title: 'Name', property: 'location', type: WidgetTableColumnType.upperText, width: '50%'},
        ];

        vehicleTypes.forEach((type) => {
            columnConfigs.push({title: type, property: type, type: WidgetTableColumnType.text, width: '100px'});
        });

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {`${t('Vehicle Type Distribution')} `} {getDropDownOptionLabel(selectedOption.key, t)}
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                            <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                {viewMode === ViewMode.chart ? <FontAwesomeIcon icon={faTable}/> :
                                    <FontAwesomeIcon icon={faChartBar}/>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.chartContent}
                         style={{display: viewMode === ViewMode.chart ? 'flex' : 'none'}}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                    {viewMode === ViewMode.table ?
                        <div className={styles.tableContent}>
                            <WidgetTable config={{columns: columnConfigs}} data={listData}/>
                        </div> : ''
                    }
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    private updateLocations() {
        this.locations.clear();

        if (this.props.soldToWithDivisions) {
            const soldTos = this.props.soldToWithDivisions;
            const divisions = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions);
            const depots = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions.flatMap(div => div.depots));
            soldTos.forEach(st => this.locations.set(st.id, st));
            divisions.forEach(st => this.locations.set(st.id, st));
            depots.forEach(st => this.locations.set(st.id, st));
        }
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        const {fleetCustomer} = this.props;
        this.props.fetchVehiclesDistribution(buildRequest(this.props.controlsSnapshot, this.getLocationEntity(this.state.selectedOption), fleetCustomer));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    private onSelect(option: DropdownOption): void {
        this.setState({selectedOption: option}, () => {
            this.fetchData(this.props.controlsSnapshot);
        });
    }

    private toggleViewMode() {
        const {viewMode} = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({viewMode: updatedViewMode}, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            }
        });
    }

    protected createChart(data: VehiclesDistributionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);
        const vehicleTypes = getVehiclesTypes(data);
        const locationEntity = this.getLocationEntity(this.state.selectedOption);
        chart.data = buildChartData(data, locationEntity, this.locations);
        chart.colors.list = getBoldColorPalette(vehicleTypes.length);

        // Create axes
        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = functionalLocationField;
        categoryAxis.renderer.grid.template.opacity = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.minGridDistance = 10;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.opacity = 0;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 40;

        vehicleTypes.forEach((vehicleType: string) => {
            this.createSeries(chart, vehicleType, vehicleType);
        });

        if (chart.data.length > 5) {
            chart.scrollbarY = new am4charts.XYChartScrollbar();
            chart.scrollbarY.minWidth = 30;
            chart.zoomOutButton.disabled = true;
            chart.events.on('ready', () => {
                const location1 = chart.data[0].location;
                const location5 = chart.data[4].location;
                categoryAxis.zoomToCategories(location1, location5);
            });
            customizeGrip(chart.scrollbarY.startGrip);
            customizeGrip(chart.scrollbarY.endGrip);
        }

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';

        return chart;
    }

    private createSeries(chart: any, field: string, name: string) {
        const stackedSeries = chart.series.push(new am4charts.ColumnSeries());
        stackedSeries.name = name;
        stackedSeries.stacked = true;
        stackedSeries.dataFields.valueX = field;
        stackedSeries.dataFields.categoryY = functionalLocationField;
        stackedSeries.columns.template.tooltipText = `{name}: [bold]{valueX} ${this.props.t('vehicles')}[/]`;
        stackedSeries.columns.template.fillOpacity = .8;
        stackedSeries.columns.template.maxHeight = 100;

        const eventHandler = addClickEvent(stackedSeries, ((datapoint) => {
            const locationId = datapoint[functionalLocationIdField];
            const selection: FunctionalLocationSelection = {
                locationType: FunctionalLocationType.DEPOT,
                includes: [locationId]
            };
            const controlValue = new FunctionalLocationsSelectionsControlValue('', [selection]);
            this.props.updateSingleDefaultControlValue(ParamKey.FunctionalLocationSelections, controlValue);
            this.props.navigateTo({pathname: `/${this.props.fleetCustomer.id}${RouteUrl.Jobs}`});
        }));

        addStandardTooltipStyle(stackedSeries);
        addStrokeToColumnChart(stackedSeries);
        this.disposibles.push(eventHandler);
    }

    private getLocationEntity(option: DropdownOption): FunctionalLocationType {
        switch (option) {
            case this.OPTION_DEPOT:
                return FunctionalLocationType.DEPOT;
            case this.OPTION_DIVISION:
                return FunctionalLocationType.DIVISION;
            case this.OPTION_SOLD_TO:
                return FunctionalLocationType.SOLD_TO;
            default:
                throw Error('Can not determine vehicle grouping option');
        }
    }
}

const mapStateToProps = ({analytics, authentication, soldTo}: ApplicationState) => ({
    data: analytics.vehiclesDistributionData,
    soldToWithDivisions: soldTo.soldToWithDivisions,
    fleetCustomer: authentication.fleetCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchVehiclesDistribution: (request: VehicleTypesDistributionRequest) => dispatch(fetchVehicleTypesDistributionRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    updateSingleDefaultControlValue: (key, value) => dispatch(updateSingleDefaultControlValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VehicleTypesDistributionWidget));

interface PropsFromState {
    data: VehiclesDistributionData[];
    soldToWithDivisions: SoldToWithDivisions[];
    fleetCustomer: FleetCustomerWithConfiguration;
}

interface PropsFromDispatch {
    updateSingleDefaultControlValue: typeof updateSingleDefaultControlValue;
    fetchVehiclesDistribution: typeof fetchVehicleTypesDistributionRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
    viewMode: ViewMode;
}

type AllState = OwnState;
