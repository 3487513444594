import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import styles from './functional-locations-selector.module.scss';
import {ApplicationState} from '../../../../store';
import {Depot, DivisionWithDepots, SoldToWithDivisions} from '../../../../store/soldto';
import SelectionPanel, {Item} from './selection-panel';
import {
    buildSelectionHierarchyFromSelection,
    buildSelectionHierarchySelectAll,
    getLocationsFromSelection,
    getSelectionsFromHierarchy,
    SelectionHierarchy,
} from './helpers/depots-selector-helper';
import {ControlValue} from '../controls-snapshot';
import {ControlBase, ControlProps} from '../control-base';
import {FunctionalLocationSelection} from '../../../../store/analytics';
import {FunctionalLocationsSelectionsControlValue} from './types/functional-locations-selections-control-value';
import {filterDepots, filterDivisions, filterSoldTos} from '../../../../store/depot-selection';

class FunctionalLocationsSelector extends ControlBase<AllProps, AllState, FunctionalLocationsSelectionsControlValue> {

    constructor(props) {
        super(props);
        let selectionHierarchy = new SelectionHierarchy();
        let depots: Depot[] = [];
        let divisions: DivisionWithDepots[] = [];
        let soldTos: SoldToWithDivisions[] = [];

        // select all depots by default
        if (this.props.soldToWithDivisions && this.props.soldToWithDivisions.length > 0) {
            depots = this.props.soldToWithDivisions.flatMap(soldTo => soldTo.divisions.flatMap(div => div.depots));
            divisions = this.props.soldToWithDivisions.flatMap(soldTo => soldTo.divisions);
            soldTos = this.props.soldToWithDivisions;

            if (this.props.defaultValue) {
                const selections = this.props.defaultValue.value as FunctionalLocationSelection[];
                const selectedDepots = getLocationsFromSelection(soldTos, divisions, depots, selections);
                selectionHierarchy = buildSelectionHierarchyFromSelection(soldTos, selectedDepots);
            } else {
                selectionHierarchy = buildSelectionHierarchySelectAll(soldTos, divisions, depots);
            }
        }

        this.state = {depots, divisions, soldTos, selectionHierarchy};
        // if depots are available you can trigger and end the initialization flow
        if (selectionHierarchy.fullDepotsSelection.size > 0) {
            this.props.onValueChange(this.getValue());
        }
    }

    public componentDidUpdate(prevProps) {
        // if depots are set you can end initialization flow
        if (this.props.soldToWithDivisions && this.props.soldToWithDivisions !== prevProps.soldToWithDivisions) {
            let selectionHierarchy = new SelectionHierarchy();
            const depots = this.props.soldToWithDivisions.flatMap(soldTo => soldTo.divisions.flatMap(div => div.depots));
            const divisions = this.props.soldToWithDivisions.flatMap(soldTo => soldTo.divisions);
            const soldTos = this.props.soldToWithDivisions;

            if (this.props.defaultValue) {
                const selections = this.props.defaultValue.value as FunctionalLocationSelection[];
                const selectedDepots = getLocationsFromSelection(soldTos, divisions, depots, selections);
                selectionHierarchy = buildSelectionHierarchyFromSelection(soldTos, selectedDepots);
            } else {
                selectionHierarchy = buildSelectionHierarchySelectAll(soldTos, divisions, depots);
            }

            this.setState({depots, divisions, soldTos, selectionHierarchy}, () => {
                this.props.onValueChange(this.getValue());
            });
        }
    }

    public forceRefresh(value: FunctionalLocationsSelectionsControlValue): void {
    }

    public render(): JSX.Element {
        const {depots, divisions, soldTos, selectionHierarchy} = this.state;
        const {depotsSearchText, divisionsSearchText, soldTosSearchText, t} = this.props;

        return (
            <div className={styles.selectorContainer}>
                {soldTos.length > 0 ?
                    <div className={styles.selectorColumn}>
                        <SelectionPanel
                            items={soldTos}
                            initialSearchText={soldTosSearchText}
                            selectedItems={selectionHierarchy.fullSoldToSelection}
                            partialItems={selectionHierarchy.partialSoldToSelection}
                            searchPlaceholderType={t('fleets')}
                            onToggleSelection={(item: Item) => this.onToggleSoldToSelection(item as SoldToWithDivisions)}
                            onToggleSelectAll={(selectAll: boolean, items: Item[]) => this.onToggleSelectAllSoldTos(selectAll, items)}
                            onSearchChanged={(searchText: string) => this.onSoldTosSearchChanged(searchText)}
                        />
                    </div> : null}
                {divisions.length > 0 ?
                    <div className={styles.selectorColumn}>
                        <SelectionPanel
                            items={divisions}
                            initialSearchText={divisionsSearchText}
                            selectedItems={selectionHierarchy.fullDivisionSelection}
                            partialItems={selectionHierarchy.partialDivisionSelection}
                            searchPlaceholderType={t('divisions')}
                            onToggleSelection={(item: Item) => this.onToggleDivisionSelection(item as DivisionWithDepots)}
                            onToggleSelectAll={(selectAll: boolean, items: Item[]) => this.onToggleSelectAllDivisions(selectAll, items)}
                            onSearchChanged={(searchText: string) => this.onDivisionsSearchChanged(searchText)}
                        />
                    </div> : null}
                {depots.length > 0 ?
                    <div className={styles.selectorColumn}>
                        <SelectionPanel
                            items={depots}
                            initialSearchText={depotsSearchText}
                            selectedItems={selectionHierarchy.fullDepotsSelection}
                            searchPlaceholderType={t('depots')}
                            onToggleSelection={(item: Item) => this.onToggleDepotSelection(item)}
                            onToggleSelectAll={(selectAll: boolean, items: Item[]) => this.onToggleSelectAllDepots(selectAll, items)}
                            onSearchChanged={(searchText: string) => this.onDepotsSearchChanged(searchText)}
                        />
                    </div> : null}
            </div>
        );
    }

    private onSoldTosSearchChanged(searchText: string) {
        this.props.dispatchFilterSoldTosRequest(searchText);
    }

    private onDivisionsSearchChanged(searchText: string) {
        this.props.dispatchFilterDivisionsRequest(searchText);
    }

    private onDepotsSearchChanged(searchText: string) {
        this.props.dispatchFilterDepotsRequest(searchText);
    }

    private onToggleSelectAllSoldTos(selectAll: boolean, items: Item[]): void {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        if (selectAll) {
            this.state.soldTos.filter(soldTo => items.includes(soldTo))
                .forEach(soldTo => soldTo.divisions.forEach(division => division.depots.forEach(depot => depotsSelection.set(depot.id, depot))));
        } else {
            this.state.soldTos.filter(soldTo => items.includes(soldTo))
                .forEach(soldTo => soldTo.divisions.forEach(division => division.depots.forEach(depot => depotsSelection.delete(depot.id))));
        }

        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleSelectAllDivisions(selectAll: boolean, items: Item[]): void {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        if (selectAll) {
            this.state.divisions.filter(division => items.includes(division)).forEach(division => division.depots.forEach(depot => depotsSelection.set(depot.id, depot)));
        } else {
            this.state.divisions.filter(division => items.includes(division)).forEach(division => division.depots.forEach(depot => depotsSelection.delete(depot.id)));
        }

        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleSelectAllDepots(selectAll: boolean, items: Item[]): void {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        if (selectAll) {
            this.state.depots.filter(depot => items.includes(depot)).forEach((depot => depotsSelection.set(depot.id, depot)));
        } else {
            items.forEach(item => depotsSelection.delete(item.id));
        }

        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleDepotSelection(depot: Item): void {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        if (depotsSelection.get(depot.id)) {
            depotsSelection.delete(depot.id)
        } else {
            depotsSelection.set(depot.id, depot);
        }
        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleDivisionSelection(division: DivisionWithDepots) {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        const isSelected = this.state.selectionHierarchy.isFullDivisionSelected(division.id);
        const isPartial = this.state.selectionHierarchy.isPartialDivisionSelected(division.id);

        if (isSelected || isPartial) {
            division.depots.forEach(depot => depotsSelection.delete(depot.id));
        } else {
            division.depots.forEach(depot => depotsSelection.set(depot.id, depot));
        }

        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleSoldToSelection(soldTo: SoldToWithDivisions) {
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        const isSelected = this.state.selectionHierarchy.isFullSoldToSelected(soldTo.id);
        const isPartial = this.state.selectionHierarchy.isPartialSoldToSelected(soldTo.id);

        if (isSelected || isPartial) {
            soldTo.divisions.forEach(division => division.depots.forEach(depot => depotsSelection.delete(depot.id)));
        } else {
            soldTo.divisions.forEach(division => division.depots.forEach(depot => depotsSelection.set(depot.id, depot)));
        }

        this.alignHierarchyWithDepots(depotsSelection, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private alignHierarchyWithDepots(depotsSelection, callback: any): void {
        const selectionHierarchy: SelectionHierarchy = buildSelectionHierarchyFromSelection(this.state.soldTos, depotsSelection);
        this.setState({
            selectionHierarchy,
        }, callback);
    }

    private getValue(): ControlValue {
        const selections = getSelectionsFromHierarchy(this.state.selectionHierarchy);
        const display = this.getDisplay();
        return new FunctionalLocationsSelectionsControlValue(display, selections);
    }

    private getDisplay(): string {
        let displayValue = '';
        const depotsSelection = this.state.selectionHierarchy.fullDepotsSelection;
        if (depotsSelection.size < 1) {
            displayValue = this.props.t('None');
        } else if (depotsSelection.size === 1) {
            displayValue = Array.from(depotsSelection.values())[0].name;
        } else if (depotsSelection.size < this.state.depots!.length) {
            displayValue = this.props.t('{{count}} depots', {count: depotsSelection.size});
        } else if (depotsSelection.size === this.state.depots!.length) {
            displayValue = this.props.t('All depots');
        }

        return displayValue;
    }
}

const mapStateToProps = ({soldTo, depotSelection}: ApplicationState) => ({
    soldToWithDivisions: soldTo.soldToWithDivisions,
    soldTosSearchText: depotSelection.soldTosFilter,
    divisionsSearchText: depotSelection.divisionsFilter,
    depotsSearchText: depotSelection.depotsFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchFilterSoldTosRequest: (filter: string) => dispatch(filterSoldTos(filter)),
    dispatchFilterDivisionsRequest: (filter: string) => dispatch(filterDivisions(filter)),
    dispatchFilterDepotsRequest: (filter: string) => dispatch(filterDepots(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FunctionalLocationsSelector));

interface OwnProps extends ControlProps<FunctionalLocationsSelectionsControlValue> {
}

interface PropsFromState {
    soldToWithDivisions?: SoldToWithDivisions[];
    soldTosSearchText?: string;
    divisionsSearchText?: string;
    depotsSearchText?: string;
}

interface PropsFromDispatch {
    dispatchFilterSoldTosRequest: typeof filterSoldTos;
    dispatchFilterDivisionsRequest: typeof filterDivisions;
    dispatchFilterDepotsRequest: typeof filterDepots;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectionHierarchy: SelectionHierarchy;
    depots: Item[];
    divisions: DivisionWithDepots[];
    soldTos: SoldToWithDivisions[];
}

type AllState = OwnState;
