import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum DepotSelectionActionTypes {
    FILTER_SOLD_TOS = '@@depot-selection/FILTER_SOLD_TOS',
    FILTER_DIVISIONS = '@@depot-selection/FILTER_DIVISIONS',
    FILTER_DEPOTS = '@@depot-selection/FILTER_DEPOTS',
    FILTER_RESET = '@@depot-selection/FILTER_RESET',
}

export type DepotSelectionAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface DepotSelectionState {
    soldTosFilter?: string;
    divisionsFilter?: string;
    depotsFilter?: string;
}
