import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './damaged-tire-details.module.scss';
import {
    DamagedTireDetailsCustomReportParameters,
    DamagedTireDetailsScheduledReportParameters,
    ReportPeriodType,
    ScheduledReportPeriod,
} from '../../../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import LocationSelectorComponent from '../../../../components/location-selector.component';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import {FleetCustomerWithConfiguration} from '../../../../../../store/fleet-customers';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';

class DamagedTireDetailsComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        let locations: FunctionalLocationSelection[] = [];
        let fleetNumber = '';
        let licensePlate = '';
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.period) {
            period = parameters.period;
        }
        if (parameters && parameters.fleetNumber) {
            fleetNumber = parameters.fleetNumber;
        }
        if (parameters && parameters.licensePlate) {
            licensePlate = parameters.licensePlate;
        }

        this.state = {
            period,
            fleetNumber,
            licensePlate,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public render(): JSX.Element {
        const {periodType, fleetCustomer, t} = this.props;
        const {period, functionalLocationSelection, fleetNumber, licensePlate} = this.state;


        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a customer')}</div>
                            <LocationSelectorComponent
                                defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                                onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)} />
                        </div>
                    </div>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.formColumn}>
                                <div className={styles.info}>{t('Specify a period')}</div>
                                <DateRangeSelectorComponent
                                    earliestDate={fleetCustomer?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}
                                />
                            </div>
                        </div>
                        : null
                    }
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Optional Filter')}</div>
                            <div className={styles.textFilter}>
                                <label htmlFor='licencePlate'>
                                    License Plate:
                                    <input type='text' id='licencePlate'
                                           value={licensePlate}
                                           className={styles.fieldInput}
                                           onChange={(event: any) => this.onLicencePlateChanged(event)} />
                                </label>
                            </div>
                        </div>
                        <div className={styles.formColumn}>
                            <div className={styles.info} />
                            <div className={styles.textFilter}>
                                <label htmlFor='fleetNumber'>
                                    Fleet Number:
                                    <input type='text' id='fleetNumber'
                                           value={fleetNumber}
                                           className={styles.fieldInput}
                                           onChange={(event: any) => this.onFleetNumberChanged(event)} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, licensePlate, fleetNumber, fromDate, toDate} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    licensePlate,
                    fleetNumber,
                    functionalLocationSelection: controlValue,
                    fromDate,
                    toDate,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    period,
                    licensePlate,
                    fleetNumber,
                    locations: controlValue.value,
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, licensePlate, fleetNumber} = this.state;

        onScheduledParametersChanged({
            period,
            licensePlate,
            fleetNumber,
            locations: functionalLocationSelection!.value,
        });

        this.setState({
            period,
        });
    }

    private onLicencePlateChanged(event: any) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fleetNumber, fromDate, toDate} = this.state;
        const text = event.target.value;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                licensePlate: text,
                fleetNumber,
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                period,
                licensePlate: text,
                fleetNumber,
                locations: functionalLocationSelection!.value,
            });
        }

        this.setState({
            licensePlate: text,
        });
    }

    private onFleetNumberChanged(event: any) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, licensePlate, fromDate, toDate} = this.state;
        const text = event.target.value;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                licensePlate,
                fleetNumber: text,
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                period,
                licensePlate,
                fleetNumber: text,
                locations: functionalLocationSelection!.value,
            });
        }

        this.setState({
            fleetNumber: text,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, licensePlate, fleetNumber, toDate} = this.state;

            onCustomParametersChanged({
                licensePlate,
                fleetNumber,
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, licensePlate, fleetNumber, fromDate} = this.state;

            onCustomParametersChanged({
                licensePlate,
                fleetNumber,
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }
}

export default withTranslation()(DamagedTireDetailsComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    fleetCustomer?: FleetCustomerWithConfiguration;
    parameters?: DamagedTireDetailsScheduledReportParameters;
    onCustomParametersChanged: (parameters: DamagedTireDetailsCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: DamagedTireDetailsScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    licensePlate?: string;
    fleetNumber?: string;
    period: ScheduledReportPeriod;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
    fromDate?: Date;
    toDate?: Date;
}

type AllState = OwnState;
