import {TFunction} from 'i18next';
import {ControlType, Dashboard, ParamKey} from '../../../store/dashboard';
import {generateUUID} from '../../../utils/uuid-helpers';

export function createBlankDashboard(name: string, index: number, t: TFunction): Dashboard {
    return {
        name,
        id: generateUUID(),
        index: 0,
        controls: [
            {
                type: ControlType.DateRange,
                key: ParamKey.InspectionPeriod,
                summaryTitle: t('Date Range'),
                configuration: {},
            },
            {type: ControlType.FunctionalLocations, key: ParamKey.FunctionalLocationSelections, summaryTitle: t('Depots'), configuration: {}}],
        widgets: [],
        isConfigurable: true
    };
}
