import {Http} from '../http/http';
import {
    DateRangeWithFunctionalLocationsAndCurrencyRequest, DateRangeWithFunctionalLocationsAndLanguageRequest,
    DateRangeWithFunctionalLocationsRequest, DateWithFunctionalLocationsAndLanguageRequest,
    DateWithFunctionalLocationsRequest,
} from '../store/analytics';
import {
    AlertData,
    AlertsWidgetDataRequest,
} from '../store/analytics/types/alert.types';
import {FleetMovementData, FleetMovementRequest} from '../store/analytics/types/fleet-movement.types';
import {
    FleetGrowthRequest,
    FleetSizeEvolutionData,
    FleetSizeEvolutionRequest,
} from '../store/analytics/types/fleet-size-evolution.types';
import {
    InspectionHistogramData,
    InspectionHistogramRequest,
} from '../store/analytics/types/inspection-histogram.types';
import {InspectionRatioData} from '../store/analytics/types/inspection-ratio.types';
import {InspectionStatusData, InspectionStatusRequest} from '../store/analytics/types/inspection-status.types';
import {
    BillingDistributionRequest,
    BillingItemsDistributionData,
} from '../store/analytics/types/invoice-items-distribution.types';
import {
    BillingEvolutionRequest,
    BillingItemsEvolutionData,
} from '../store/analytics/types/invoice-items-evolution.types';
import {JobTypesDistributionData} from '../store/analytics/types/job-types-distribution.types';
import {JobsEvolutionData, JobsEvolutionRequest} from '../store/analytics/types/jobs-evolution.types';
import {
    AvoidableBreakdownsKPIData,
    AvoidableBreakdownsKPIRequest,
    KeyPerformanceIndicatorData,
    KeyPerformanceIndicatorRequest,
    RetreadRatioKPIData,
    RetreadRatioKPIRequest, UnderInflationKPIData, UnderInflationKPIRequest,
} from '../store/analytics/types/key-performance-indicator.types';
import {
    ServiceProvidersMonthlyOverviewData,
    ServiceProvidersMonthlyOverviewRequest,
} from '../store/analytics/types/service-providers-monthly-overview.types';
import {TireBrandDistributionData} from '../store/analytics/types/tire-brand-distribution.types';
import {
    TopServiceProvidersData,
    TopServiceProvidersRequest,
} from '../store/analytics/types/top-service-providers-types';
import {TopTirePatternsData} from '../store/analytics/types/top-tire-patterns.types';
import {VehicleTypesDistributionRequest} from '../store/analytics/types/vehicle-types-distribution.types';
import {YoyComparisonData} from '../store/analytics/types/yoy-comparison.types';
import {InspectionType, JobType} from '../store/jobs';
import {ApiResult} from '../types/api-result';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {AcceptanceTimeData} from '../store/analytics/types/jobs-average-acceptance-time.types';
import {LocalLanguageType} from '../store/shared/types';
import {
    TopRetreadTiresFittedByServiceProvidersData,
    TopRetreadTiresFittedByServiceProvidersRequest,
    TopReusableTiresFittedByServiceProvidersData,
    TopReusableTiresFittedByServiceProvidersRequest
} from "../store/analytics/types/top-tires-fitted-by-service-providers-types";

class AnalyticsApi {
    public static async getVehicleTypesDistribution(
        request: VehicleTypesDistributionRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<FleetSizeEvolutionData[]>> {
        const data = await Http.post<VehicleTypesDistributionRequest, FleetSizeEvolutionData[]>(
            ApiUrl.VehicleTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getFleetSizeEvolution(
        request: FleetSizeEvolutionRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<FleetSizeEvolutionData[]>> {
        const data = await Http.post<FleetSizeEvolutionRequest, FleetSizeEvolutionData[]>(
            ApiUrl.VehiclesEvolution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getJobEvolution(
        request: JobsEvolutionRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<JobsEvolutionData[]>> {
        const data = await Http.post<JobsEvolutionRequest, JobsEvolutionData[]>(
            ApiUrl.JobsEvolution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getJobTypesDistribution(
        request: DateRangeWithFunctionalLocationsRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<JobTypesDistributionData[]>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getInspectionStatus(
        request: InspectionStatusRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<InspectionStatusData[]>> {
        const data = await Http.post<InspectionStatusRequest, InspectionStatusData[]>(
            ApiUrl.InspectionStatus,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTotalJobCountKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let jobCount = 0;
        data.forEach((d) => (jobCount += d.jobCount));
        return {data: {type: request.type, value: jobCount, unit: '#'}};
    }

    public static async getTotalBreakdownsKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let jobCount = 0;
        data.filter((d) => d.jobType === JobType.Breakdown).forEach((d) => (jobCount += d.jobCount));
        return {data: {type: request.type, value: jobCount, unit: '#'}};
    }

    public static async getTotalInvoicedKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const requestWithCurrency: DateRangeWithFunctionalLocationsAndCurrencyRequest = {
            ...request,
            currencyType: request.currency.type,
        };
        const data = await Http.post<DateRangeWithFunctionalLocationsAndCurrencyRequest,
            BillingItemsDistributionData[]>(
            ApiUrl.BillingDistribution,
            requestWithCurrency,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let total = 0;
        data.forEach((d) => (total += d.netPrice));
        return {data: {type: request.type, value: total, unit: request.currency.name}};
    }

    public static async getYoYFinancialComparisonKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const requestWithCurrency: DateRangeWithFunctionalLocationsAndCurrencyRequest = {
            ...request,
            currencyType: request.currency.type,
        };
        const data = await Http.post<DateRangeWithFunctionalLocationsAndCurrencyRequest, YoyComparisonData>(
            ApiUrl.YoyComparison,
            requestWithCurrency,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        if (data.sumPreviousYear === 0) {
            return {data: {type: request.type, value: undefined, unit: 'NA'}};
        } else {
            const result = ((data.sumCurrentYear - data.sumPreviousYear) / data.sumPreviousYear) * 100.0;
            return {data: {type: request.type, value: result, unit: '%'}};
        }
    }

    public static async getTotalFleetSizeKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const distributionRequest = {
            date: request.end,
            locations: request.locations,
            grouping: [],
            language: LocalLanguageType.DEFAULT_LANGUAGE
        };
        const data = await Http.post<VehicleTypesDistributionRequest, FleetSizeEvolutionData[]>(
            ApiUrl.VehicleTypesDistribution,
            distributionRequest,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let vehicleCount = 0;
        data.forEach((d) => (vehicleCount += d.vehicleCount));
        return {data: {type: request.type, value: vehicleCount, unit: '#'}};
    }

    public static async getFleetGrowthKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const evolutionRequest = {
            start: request.start,
            end: request.end,
            locations: request.locations,
        };
        const data = await Http.post<FleetGrowthRequest, FleetSizeEvolutionData[]>(
            ApiUrl.VehiclesFleetGrowth,
            evolutionRequest,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let growthPercentage = 0;
        if (data.length > 0) {
            data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            const firstVehicleCount = data[0].vehicleCount;
            const lastVehicleCount = data[data.length - 1].vehicleCount;
            if (firstVehicleCount && firstVehicleCount > 0) {
                growthPercentage = ((lastVehicleCount - firstVehicleCount) / firstVehicleCount) * 100;
            }
        }
        return {data: {type: request.type, value: growthPercentage, unit: '%'}};
    }

    public static async getTotalInspectionsKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let jobCount = 0;
        data.filter((d) => d.inspectionType).forEach((d) => (jobCount += d.jobCount));
        return {data: {type: request.type, value: jobCount, unit: '#'}};
    }

    public static async getScheduledInspectionsKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let jobCount = 0;
        data.filter((d) => d.inspectionType && d.inspectionType === InspectionType.Scheduled).forEach(
            (d) => (jobCount += d.jobCount),
        );
        return {data: {type: request.type, value: jobCount, unit: '#'}};
    }

    public static async getUnscheduledInspectionsKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, JobTypesDistributionData[]>(
            ApiUrl.JobTypesDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        let jobCount = 0;
        data.filter((d) => d.inspectionType && d.inspectionType === InspectionType.Unscheduled).forEach(
            (d) => (jobCount += d.jobCount),
        );
        return {data: {type: request.type, value: jobCount, unit: '#'}};
    }

    public static async getJobsAverageAcceptanceTime(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AcceptanceTimeData>(
            ApiUrl.JobsAcceptanceTime,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data: {type: request.type, stringValue: data.avgAcceptanceTime, unit: ''}};
    }

    public static async getTirePressureAlertData(
        request: AlertsWidgetDataRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AlertData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AlertData>(
            ApiUrl.TirePressureAlerts,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTreadDepthAlertData(
        request: AlertsWidgetDataRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AlertData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AlertData>(
            ApiUrl.TreadDepthAlerts,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getBreakdownFrequencyAlertData(
        request: AlertsWidgetDataRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AlertData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AlertData>(
            ApiUrl.BreakdownFrequencyAlerts,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getServiceFrequencyAlertData(
        request: AlertsWidgetDataRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AlertData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AlertData>(
            ApiUrl.ServiceFrequencyAlerts,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getRetreadRatioAlertData(
        request: AlertsWidgetDataRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AlertData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, AlertData>(
            ApiUrl.RetreadRatioAlerts,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getAvoidableBreakdownsKPIData(
        request: AvoidableBreakdownsKPIRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<AvoidableBreakdownsKPIData>> {
        const data = await Http.post<AvoidableBreakdownsKPIRequest, AvoidableBreakdownsKPIData>(
            ApiUrl.AvoidableBreakdownsKPI,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getRetreadRatioKPIData(
        request: RetreadRatioKPIRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<RetreadRatioKPIData>> {
        const data = await Http.post<RetreadRatioKPIRequest, RetreadRatioKPIData>(
            ApiUrl.RetreadRatioKPI,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getUnderInflationKPIData(
        request: UnderInflationKPIRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<UnderInflationKPIData>> {
        const data = await Http.post<UnderInflationKPIRequest, UnderInflationKPIData>(
            ApiUrl.UnderInflationKPI,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        // const data: UnderInflationKPIData = {
        //     tirePressureAverage: 8.38,
        //     tirePressureTarget: 8.71,
        //     yearlyAverageCostRelatedToMileageLoss: 150,
        //     yearlyEstimatedCostRelatedToIncreasedFuelConsumption: 600,
        //     yearlyTotalCostCausedByUnderInflation: 1040,
        //     savingsPotential: 121.94,
        // };
        return {data};
    }

    public static async getInspectionRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, InspectionRatioData>(
            ApiUrl.InspectionRatio,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data: {type: request.type, value: data.ratio, unit: '%'}};
    }

    public static async getBillingItemsDistribution(
        request: BillingDistributionRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<BillingItemsDistributionData[]>> {
        const data = await Http.post<BillingDistributionRequest, BillingItemsDistributionData[]>(
            ApiUrl.BillingDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getServiceProvidersMonthlyOverview(
        request: ServiceProvidersMonthlyOverviewRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<ServiceProvidersMonthlyOverviewData[]>> {
        const data = await Http.post<ServiceProvidersMonthlyOverviewRequest, ServiceProvidersMonthlyOverviewData[]>(
            ApiUrl.ServiceProvidersMonthlyOverview,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTopServiceProvidersRequest(
        request: TopServiceProvidersRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TopServiceProvidersData[]>> {
        const data = await Http.post<TopServiceProvidersRequest, TopServiceProvidersData[]>(
            ApiUrl.TopServiceProviders,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTopReusableTiresFittedByServiceProvidersRequest(
        request: TopReusableTiresFittedByServiceProvidersRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TopReusableTiresFittedByServiceProvidersData[]>> {
        const data = await Http.post<TopReusableTiresFittedByServiceProvidersRequest, TopReusableTiresFittedByServiceProvidersData[]>(
            ApiUrl.TopReusableTiresFittedByServiceProvider,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTopRetreadTiresFittedByServiceProvidersRequest(
        request: TopRetreadTiresFittedByServiceProvidersRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TopRetreadTiresFittedByServiceProvidersData[]>> {
        const data = await Http.post<TopRetreadTiresFittedByServiceProvidersRequest, TopRetreadTiresFittedByServiceProvidersData[]>(
            ApiUrl.TopRetreadTiresFittedByServiceProvider,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getBillingItemsEvolution(
        request: BillingEvolutionRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<BillingItemsEvolutionData[]>> {
        const data = await Http.post<BillingEvolutionRequest, BillingItemsEvolutionData[]>(
            ApiUrl.BillingEvolution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getFleetMovement(
        request: FleetMovementRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<FleetMovementData[]>> {
        const data = await Http.post<FleetMovementRequest, FleetMovementData[]>(
            ApiUrl.FleetMovement,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getInspectionHistogram(
        request: InspectionHistogramRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<InspectionHistogramData>> {
        const data = await Http.post<InspectionHistogramRequest, InspectionHistogramData>(
            ApiUrl.InspectionHistogram,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTireFitmentsBrandDistribution(
        request: DateWithFunctionalLocationsRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TireBrandDistributionData[]>> {
        const data = await Http.post<DateWithFunctionalLocationsRequest, TireBrandDistributionData[]>(
            ApiUrl.TireFitmentsBrandDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};

    }

    public static async getTiresFittedBrandDistribution(
        request: DateRangeWithFunctionalLocationsRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TireBrandDistributionData[]>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsRequest, TireBrandDistributionData[]>(
            ApiUrl.TiresFittedBrandDistribution,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTireFitmentsTopPatterns(
        request: DateWithFunctionalLocationsAndLanguageRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TopTirePatternsData[]>> {
        const data = await Http.post<DateWithFunctionalLocationsAndLanguageRequest, TopTirePatternsData[]>(
            ApiUrl.TireFitmentsTopPatterns,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }

    public static async getTiresFittedTopPatterns(
        request: DateRangeWithFunctionalLocationsAndLanguageRequest,
        fleetCustomerId: string,
    ): Promise<ApiResult<TopTirePatternsData[]>> {
        const data = await Http.post<DateRangeWithFunctionalLocationsAndLanguageRequest, TopTirePatternsData[]>(
            ApiUrl.TiresFittedTopPatterns,
            request,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build(),
        );
        return {data};
    }
}

export default AnalyticsApi;
