import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare} from "@fortawesome/pro-regular-svg-icons/faCheckSquare";
import {faSquare} from "@fortawesome/pro-regular-svg-icons/faSquare";
import ReactTooltip from "react-tooltip";
import {ControlBase, ControlProps} from '../control-base';
import {BillingControlValue} from './types/billing-control-value';
import styles from './billing-selector.module.scss';
import ToggleDisplay from '../../../../utils/toggle-display';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {ControlValue} from '../controls-snapshot';

class BillingSelector extends ControlBase<AllProps, AllState, BillingControlValue> {
    constructor(props) {
        super(props);

        let isSelected = false;

        if (this.props.defaultValue) {
            isSelected = this.props.defaultValue.value as boolean;
        }

        this.state = {isSelected};

        this.props.onValueChange(this.getValue());
    }

    public forceRefresh(value: BillingControlValue): void {
        this.setState({isSelected: value.value}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    public render(): JSX.Element {
        const {isSelected} = this.state;
        const {t} = this.props;

        const itemClasses = conditionalClassLister(styles)({
            isSelected,
            itemText: true,
        });

        return (
            <div className={styles.selectorContainer}
                 onClick={() => this.onToggleSelection()}>
                <ReactTooltip className={styles.infoTooltip} backgroundColor='#9FB3C8' id="info"/>
                <div className={styles.selectorLine}>
                    <div className={styles.selectBox}>
                        <ToggleDisplay if={isSelected}>
                            <FontAwesomeIcon icon={faCheckSquare}/>
                        </ToggleDisplay>
                        <ToggleDisplay if={!isSelected}>
                            <FontAwesomeIcon icon={faSquare}/>
                        </ToggleDisplay>
                    </div>
                    <div className={itemClasses}>
                        <span className={styles.itemLine}>{t('Include invoice transactions that are not linked to a vehicle.')}</span>
                        <span className={styles.itemLine}>{t('These transactions will be added at fleet level.')}</span>
                    </div>
                </div>
            </div>
        )

    }

    public getInfo(): string {
        const {t} = this.props;
        return t('Here you can include/exclude invoice transaction that are not linked to a vehicle. These transactions will be added at fleet level.');
    }

    public onToggleSelection() {
        const {isSelected} = this.state;
        this.setState({isSelected: !isSelected}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private getValue(): ControlValue {
        const {isSelected} = this.state;
        const display = this.getDisplay();
        return new BillingControlValue(display, isSelected);
    }

    private getDisplay(): string {
        const {isSelected} = this.state;
        const {t} = this.props;
        const displayValue = isSelected ?
            t('Include unlinked billings') :
            t('Exclude unlinked billings');

        return displayValue;
    }
}

export default withTranslation()(BillingSelector);

interface OwnProps extends ControlProps<BillingControlValue> {

}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isSelected: boolean;
}

type AllState = OwnState;
