import {Frequency} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {JobsEvolutionRequest} from '../../../../store/analytics/types/jobs-evolution.types';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';

export function buildRequest(snapshot: ControlsSnapshot): JobsEvolutionRequest {
    const request = Object.assign(
        createDateRangeWithFunctionalLocationsRequest(snapshot), {
            frequency: Frequency.MONTHLY,
        });

    return request;
}
