import { action } from 'typesafe-actions';
import {
    CreateFleetCustomerRequest,
    FleetCustomer,
    FleetCustomerActionTypes, FleetCustomersSearchRequest,
    FleetCustomerWithConfigurationAndUsers,
    UpdateFleetCustomerRequest,
} from './types';
import {Paged} from "../shared/types";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const createFleetCustomerRequest = (createRequest: CreateFleetCustomerRequest) =>
    action(FleetCustomerActionTypes.CREATE_FLEET_CUSTOMER_REQUEST, createRequest);
export const addFleetCustomerSuccess = (fleetCustomer: FleetCustomer) =>
    action(FleetCustomerActionTypes.CREATE_FLEET_CUSTOMER_SUCCESS, fleetCustomer);

export const deleteFleetCustomerRequest = (fleetCustomer: FleetCustomer) =>
    action(FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_REQUEST, fleetCustomer);
export const deleteFleetCustomerSuccess = (fleetCustomer: FleetCustomer) =>
    action(FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_SUCCESS, fleetCustomer);
export const deleteFleetCustomerFailure = (message: string) =>
    action(FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_FAILURE, message);

export const getFleetCustomersRequest = () => action(FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_REQUEST);
export const getFleetCustomersSuccess = (fleetCustomers: FleetCustomer[]) =>
    action(FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_SUCCESS, fleetCustomers);
export const getFleetCustomersFailure = (message: string) =>
    action(FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_FAILURE, message);

export const searchFleetCustomersRequest = (request: FleetCustomersSearchRequest) =>
    action(FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_REQUEST, request);
export const searchFleetCustomersSuccess = (fleetCustomers: Paged<FleetCustomer>) =>
    action(FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_SUCCESS, fleetCustomers);
export const searchFleetCustomersFailure = (message: string) =>
    action(FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_FAILURE, message);


export const clearFleetCustomerWithConfigurationAndUsers = () =>
    action(FleetCustomerActionTypes.CLEAR_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS);
export const getFleetCustomerWithConfigurationAndUsersRequest = (fleetCustomerId: string) =>
    action(FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_REQUEST, fleetCustomerId);
export const getFleetCustomerWithConfigurationSuccess = (fleetCustomerWithConfigurationAndUsers: FleetCustomerWithConfigurationAndUsers) =>
    action(FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_SUCCESS, fleetCustomerWithConfigurationAndUsers);
export const getFleetCustomerWithConfigurationFailure = (message: string) =>
    action(FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_FAILURE, message);

export const updateFleetCustomerRequest = (updateRequest: UpdateFleetCustomerRequest) =>
    action(FleetCustomerActionTypes.UPDATE_FLEET_CUSTOMER_REQUEST, updateRequest);
export const updateFleetCustomerSuccess = (updateRequest: UpdateFleetCustomerRequest) =>
    action(FleetCustomerActionTypes.UPDATE_FLEET_CUSTOMER_SUCCESS, updateRequest);
