import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './components/authenticated-route/authenticated-route';
import FleetCustomerBoundary from './components/fleet-customer-boundary/fleet-customer-boundary';
import AdminMenuPage from './pages/admin/admin-menu.page';
import FleetCustomerDetailsPage from './pages/admin/fleet-customers/fleet-customer-details.page';
import FleetCustomersManagementPage from './pages/admin/fleet-customers/fleet-customers-management.page';
import UserDetailsPage from './pages/admin/users/user-details.page';
import UserManagementPage from './pages/admin/users/user-management.page';
import FleetCustomerNoDataPage from './pages/fleet-customer-no-data/fleet-customer-no-data.page';
import FleetCustomerSelectionPage from './pages/fleet-customer-selection/fleet-customer-selection.page';
import ForgotPasswordPage from './pages/reset-password/reset-password.page';
import LoginPage from './pages/login/login.page';
import UserConfigurationIssuePage from './pages/user-configuration-issue/user-configuration-issue.page';
import DataStatusPage from './pages/admin/data-status/data-status.page';
import ReportManagementPage from './pages/admin/report-management/report-management.page';
import ScheduledReportManagementPage from './pages/admin/scheduled-report-management/scheduled-report-management.page';

export enum RouteUrl {
    Root = '/',
    Login = '/login',
    ResetPassword = '/ResetPassword',
    Analytics = '/analytics',
    Jobs = '/jobs',
    Reports = '/reports',
    ReportScheduler = '/reports/scheduler',
    Profile = '/profile',
    FleetCustomerSelection = '/fleet-customer',
    UserConfigurationIssue = '/user-config-issue',
    FleetCustomerNoDataIssue = '/fleet-customer-no-data-issue',
    Administration = '/admin',
    FleetCustomersManagement = '/admin/fleet-customers',
    FleetCustomerDetails = '/admin/fleet-customers/:fleetCustomerId',
    UserManagement = '/admin/users',
    UserDetails = '/admin/users/:userId',
    DataStatus = '/admin/data-status',
    ReportManagement = '/admin/report-management',
    ScheduledReportManagement = '/admin/scheduled-report-management',
    EnhancedActiveFleetListReport = '/reports/enhancedActiveFleet',
    MasterFleetListReport = '/reports/masterFleetList',
    TireHistoryReport = '/reports/tireHistory',
    TirePerformanceReport = '/reports/tirePerformance',
    VehicleJobListReport = '/reports/vehicleJobList',
    UnbilledJobsReport = '/reports/unbilledJobs',
    ExtendedInspectionReport = '/reports/extendedInspection',
    ExtendedServiceReport = '/reports/extendedService',
    VehiclesDueForInspectionReport = '/reports/vehiclesDueForInspection',
    RemovedAndFittedTiresReport = '/reports/removedAndFittedTires',
    MountedTiresReport = '/reports/mountedTires',
    DamagedTiresReport = '/reports/damagedTires',
    UnmountedTiresReport = '/reports/unmountedTires',
    TireStockReport = '/reports/tireStock',
    VehiclesCurrentStatusReport = '/reports/vehiclesCurrentStatus',
    InvoiceByJobReport = '/reports/invoiceByJob',
    InvoiceByVehicleReport = '/reports/invoiceByVehicle',
    InvoiceItemByVehicleReport = '/reports/invoiceItemByVehicle',
    BreakdownListReport = '/reports/breakdownList',
    InspectionDetailsReport = '/reports/inspectionDetails',
    TCUSensorFitmentsReport = '/reports/tcuSensorFitments',
    JobVerificationReport = '/reports/jobVerificationByInvoice',
    OdometerListReport = '/reports/odometerList',
    MileageBillingReport = '/reports/mileageBilling',
    BreakdownAndServicesBilling = '/reports/breakdownAndServicesBilling',
    DamagedTiresBilling = '/reports/damagedTiresBilling',
    ReportDownload = '/reports/:reportId/download',
}

const routes: React.FunctionComponent = () => (
    <Switch>
        <Route exact path={RouteUrl.Root} component={LoginPage} />
        <Route exact path={RouteUrl.Login} component={LoginPage} />
        <Route exact path={RouteUrl.ResetPassword} component={ForgotPasswordPage} />
        <Route exact path={RouteUrl.UserConfigurationIssue} component={UserConfigurationIssuePage} />
        <Route exact path={RouteUrl.FleetCustomerNoDataIssue} component={FleetCustomerNoDataPage} />
        <AuthenticatedRoute exact path={RouteUrl.FleetCustomerSelection} component={FleetCustomerSelectionPage} />
        <AuthenticatedRoute exact path={RouteUrl.UserDetails} component={UserDetailsPage} />
        <AuthenticatedRoute exact path={RouteUrl.UserManagement} component={UserManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.FleetCustomerDetails} component={FleetCustomerDetailsPage} />
        <AuthenticatedRoute exact path={RouteUrl.FleetCustomersManagement} component={FleetCustomersManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.DataStatus} component={DataStatusPage} />
        <AuthenticatedRoute exact path={RouteUrl.ReportManagement} component={ReportManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.ScheduledReportManagement} component={ScheduledReportManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.Administration} component={AdminMenuPage} />
        <AuthenticatedRoute path="/:fleetCustomerId" component={FleetCustomerBoundary} />
        <Route component={() => <div>Not Found</div>} />
    </Switch>
);

export default routes;
