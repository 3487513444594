import { AlertTypes, DateRangeWithFunctionalLocationsRequest } from '../types';

export interface AlertData {
    type: AlertTypes;
    goodPercentage: number;
    warningPercentage: number;
    riskPercentage: number;
    goodLabel?: string;
    warningLabel?: string;
    riskLabel?: string;
    alertStatus: AlertStatus;
}

export interface AlertsWidgetDataRequest extends DateRangeWithFunctionalLocationsRequest {
    type: AlertTypes;
    goodValue: number;
    riskValue: number;
}

export enum AlertStatus {
    Good = 'good',
    Warning = 'warning',
    Risk = 'risk',
}
