import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';

import {WithTranslation, withTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../utils/class-helpers';
import styles from './dropdown.module.scss';

export class Dropdown extends Component<AllProps, AllState> {
    private disableCloseOnBlur = false;

    constructor(props:AllProps) {
        super(props);

        this.state = {
            isOptionsOpen: false,
        };
    }

    public render(): JSX.Element {
        const {options, noItemSelectedLabel, selection, width, disabled, background} = this.props;
        const {isOptionsOpen} = this.state;

        const selectionPanelClasses = conditionalClassLister(styles)({
            selectionPanel: true,
            isOpen: isOptionsOpen,
            isDisabled: disabled,
        });

        const optionsPanelClasses = conditionalClassLister(styles)({
            optionsPanel: true,
            isOpen: isOptionsOpen,
        });

        let additionalPanelStyles = {};
        if (width && background) {
            additionalPanelStyles = {
                minWidth: width,
                backgroundColor: background,
            }
        } else if (width && !background) {
            additionalPanelStyles = {
                minWidth: width,
            }
        } else if (!width && background) {
            additionalPanelStyles = {
                backgroundColor: background,
            }
        }

        return (
            <div className={styles.dropdownPanel}
                 style={width ? {width} : {}}>
                <div
                    className={selectionPanelClasses}
                    style={additionalPanelStyles}
                    tabIndex={0}
                    onClick={() => this.toggleOptionsOpen(!isOptionsOpen)}
                    onBlur={() => this.toggleOptionsOpen(false)}>
                    <div className={styles.selectionText}>{selection ? selection.label : noItemSelectedLabel}</div>
                    <div className={styles.selectionIcon}>
                        {isOptionsOpen ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>}
                    </div>
                </div>
                <div className={optionsPanelClasses}>
                    {options.map((option) => (
                        <div
                            className={styles.optionItem}
                            key={option.key ? option.key : option.id}
                            onMouseDown={() => this.onDisableCloseOnBlur(true)}
                            onMouseUp={() => this.onDisableCloseOnBlur(false)}
                            onClick={() => this.select(option)}>
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    private select(option: DropdownOption) {
        this.setState({isOptionsOpen: false});
        const {onSelectionChanged} = this.props;
        onSelectionChanged(option);
    }

    private toggleOptionsOpen(open: boolean) {
        const {disabled} = this.props;
        if (!disabled) {
            if (open || (!open && !this.disableCloseOnBlur)) {
                this.setState({isOptionsOpen: open});
            }
        }
    }

    private onDisableCloseOnBlur(disableOnBlur: boolean) {
        this.disableCloseOnBlur = disableOnBlur;
    }
}

export default withTranslation()(Dropdown);

interface OwnProps {
    options: DropdownOption[];
    onSelectionChanged: (selection: DropdownOption) => void;
    noItemSelectedLabel?: string;
    selection?: DropdownOption;
    width?: string;
    disabled?: boolean;
    background?: string;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOptionsOpen: boolean;
}

type AllState = OwnState;

export interface DropdownOption {
    id?: string;
    label: string;
    key?: string;
}
