import { Http } from '../http/http';
import { SearchResult } from '../store/search';
import { ApiResult } from '../types/api-result';
import { ApiUrl, UrlParamBuilder } from './api-urls';

class SearchApi {
    public static async search(fleetCustomerId: string, query: string): Promise<ApiResult<SearchResult[]>> {
        const data = await Http.post<SearchParameters, SearchResult[]>(
            ApiUrl.Search,
            { searchText: query },
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId.toString()).build(),
        );
        return { data };
    }
}

interface SearchParameters {
    searchText: string;
}

export default SearchApi;
