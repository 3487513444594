import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';
import {ReportType} from '../../pages/reports/report-catalog';

export enum ReportConfigurationActionTypes {
    FETCH_REPORT_CONFIGURATIONS_REQUEST = '@@report-configuration/FETCH_REPORT_CONFIGURATIONS_REQUEST',
    FETCH_REPORT_CONFIGURATIONS_SUCCESS = '@@report-configuration/FETCH_REPORT_CONFIGURATIONS_SUCCESS',
    FETCH_REPORT_CONFIGURATIONS_FAILURE = '@@report-configuration/FETCH_REPORT_CONFIGURATIONS_FAILURE',

    UPDATE_REPORT_CONFIGURATIONS_REQUEST = '@@report-configuration/UPDATE_REPORT_CONFIGURATIONS_REQUEST',
    UPDATE_REPORT_CONFIGURATIONS_SUCCESS = '@@report-configuration/UPDATE_REPORT_CONFIGURATIONS_SUCCESS',
    UPDATE_REPORT_CONFIGURATIONS_FAILURE = '@@report-configuration/UPDATE_REPORT_CONFIGURATIONS_FAILURE',
}

export type ReportConfigurationAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ReportConfigurationState {
    readonly reportConfigurations: ReportConfigurationContract[];

    readonly getReportConfigurationsRequestStatus?: RequestStatus;
    readonly updateReportConfigurationsRequestStatus?: RequestStatus;
}

export interface ReportConfigurationContract {
    reportType: ReportType;
    availableForPayGo: boolean;
    availableForPPK: boolean;
    availableForUserGroup: UserGroup;
    active: boolean;
}

export enum UserGroup {
    AllUsers = 'all',
    InternalUsers = 'internal'
}

export interface UpdateReportConfigurationsRequest {
    reportConfigurations: ReportConfigurationContract[];
}
