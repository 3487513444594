import {
    TopReusableTiresFittedByServiceProvidersData
} from "../../../../store/analytics/types/top-tires-fitted-by-service-providers-types";
import {onlyUnique} from "../../../../utils/collection-helper";

export const serviceProviderIdField = 'serviceProviderIdField';
export const serviceProviderField = 'serviceProviderField';
export const reusableNewTiresField = 'reusableNewTiresField';
export const reusableTiresField = 'reusableTiresField';

export function buildReusableChartData(data: TopReusableTiresFittedByServiceProvidersData[]): any[] {
    const datapoints: any[] = [];
    const duplicateNames = data.filter(d => data.filter(dd => dd.name.toUpperCase() === d.name.toUpperCase()).length > 1)
        .map(d => d.name.toUpperCase())
        .filter(onlyUnique);

    data.forEach((row) => {
        const datapoint = {};
        datapoint[serviceProviderIdField] = row.id;
        datapoint[serviceProviderField] = duplicateNames.includes(row.name.toUpperCase())
            ? `${row.name.toUpperCase()}, ${row.city.toUpperCase()}`
            : row.name.toUpperCase();
        datapoint[reusableNewTiresField] = row.newCount;
        datapoint[reusableTiresField] = row.reusableCount;
        datapoints.push(datapoint);
    });
    return datapoints.reverse();
}
