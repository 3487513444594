import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import {FleetCustomer} from '../../../../store/fleet-customers';
import * as layoutActions from '../../../../store/layout/actions';
import {FleetCustomerMatchParameters} from '../../../../types/fleet-customer';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './extended-inspection-report.module.scss';
import {
    InspectionStatus,
    ExtendedInspectionReportRequest,
    fetchExtendedInspectionReportRequest,
    ReportPeriodType,
    ExtendedInspectionCustomReportParameters,
} from '../../../../store/reports';
import {FunctionalLocationSelection, FunctionalLocationType} from '../../../../store/analytics';
import {FunctionalLocationsSelectionsControlValue} from '../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import ExtendedInspectionComponent
    from '../../report-scheduler/popup/report-data-components/extended-inspection/extended-inspection.component';

class ExtendedInspectionReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        const functionalLocationSelection = this.getLocationsFromUrl();
        const controlValue = functionalLocationSelection && functionalLocationSelection.length > 0 ?
            new FunctionalLocationsSelectionsControlValue('', functionalLocationSelection) : undefined;

        this.state = {
            selectedInspectionStatus: InspectionStatus.All,
            functionalLocationSelection: controlValue,
        };
    }

    public render(): JSX.Element {
        const { t, location, reportRequestStatus } = this.props;
        const { functionalLocationSelection } = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.ExtendedInspection);

        const isValid = functionalLocationSelection &&  functionalLocationSelection.value.length > 0;
        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div />} />
                <div className={styles.pageContent}>
                    <ExtendedInspectionComponent
                        periodType={ReportPeriodType.Custom}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {}}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: ExtendedInspectionCustomReportParameters) {
        this.setState({
            functionalLocationSelection: parameters.functionalLocationSelection,
            selectedInspectionStatus: parameters.selectedInspectionStatus,
        });
    }

    private getLocationsFromUrl(): FunctionalLocationSelection[] {
        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        const soldTos = queryParameters.get(FunctionalLocationType.SOLD_TO);
        const divisions = queryParameters.get(FunctionalLocationType.DIVISION);
        const depots = queryParameters.get(FunctionalLocationType.DEPOT);
        const locations: FunctionalLocationSelection[] = [];
        if (soldTos) {
            locations.push({
                locationType: FunctionalLocationType.SOLD_TO,
                includes: soldTos.split(','),
            })
        }
        if (divisions) {
            locations.push({
                locationType: FunctionalLocationType.DIVISION,
                includes: divisions.split(','),
            })
        }
        if (depots) {
            locations.push({
                locationType: FunctionalLocationType.DEPOT,
                includes: depots.split(','),
            })
        }
        return locations;
    }

    private download(fileType:FileType) {
        const {selectedInspectionStatus, functionalLocationSelection} = this.state;
        const {dispatchFetchExtendedInspectionReport} = this.props;
        if (functionalLocationSelection && functionalLocationSelection.value.length > 0 && selectedInspectionStatus) {
            dispatchFetchExtendedInspectionReport({
                fileType,
                locations: functionalLocationSelection.value,
                inspectionStatus: selectedInspectionStatus,
            });
        }
    }

    private goBack(): void {
        const { dispatchNavigateTo, fleetCustomer } = this.props;
        dispatchNavigateTo({ pathname: `/${fleetCustomer?.id}${RouteUrl.Reports}` });
    }
}

// eslint-disable-next-line
const mapStateToProps = ({ authentication, reports, router }: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    reportRequestStatus: reports.extendedInspectionReportRequestStatus,
    currentLocation: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchExtendedInspectionReport: (request: ExtendedInspectionReportRequest) => dispatch(fetchExtendedInspectionReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExtendedInspectionReportPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomer;
    reportRequestStatus: RequestStatus;
    currentLocation: Location;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchExtendedInspectionReport: typeof fetchExtendedInspectionReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    selectedInspectionStatus?: InspectionStatus;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
