import {TFunction} from 'i18next';
import {
    JobTypesDistributionData,
} from '../../../../store/analytics/types/job-types-distribution.types';
import {InspectionType, JobType} from '../../../../store/jobs';
import {getJobTypeLabel} from '../../../../utils/translations/job-type-translation-helpert';

export const jobTypeField = 'jobType';
export const jobCountField = 'jobCount';
export const noInspectionCountField = 'noInspectionCount';
export const scheduledInspectionCountField = 'scheduledCount';
export const unscheduledInspectionCountField = 'unscheduledCount';
export const typeField = 'type';

export function buildChartDataWithoutInspections(data: JobTypesDistributionData[], translate: TFunction): any[]  {
    const breakdownServiceRow = {};
    breakdownServiceRow[jobTypeField] = getJobTypeLabel(JobType.Breakdown, translate);
    breakdownServiceRow[typeField] = JobType.Breakdown;
    breakdownServiceRow[jobCountField] = 0;

    const regularServiceRow = {};
    regularServiceRow[jobTypeField] = getJobTypeLabel(JobType.Regular, translate);
    regularServiceRow[typeField] = JobType.Regular;
    regularServiceRow[jobCountField] = 0;

    data.forEach((row) => {
        switch (row.jobType) {
            case JobType.Regular:
                regularServiceRow[jobCountField] = row.jobCount + regularServiceRow[jobCountField];
                break;
            case JobType.Breakdown:
                breakdownServiceRow[jobCountField] = row.jobCount + breakdownServiceRow[jobCountField];
                break;
            default:
                throw Error(`Encountered unknown job type when building chart data ${row.jobType}`);
        }
    });

    return [breakdownServiceRow, regularServiceRow];
}

export function buildChartDataWithInspections(data: JobTypesDistributionData[], translate: TFunction): any[] {
    const breakdownServiceRow = {};
    breakdownServiceRow[jobTypeField] = getJobTypeLabel(JobType.Breakdown, translate);
    breakdownServiceRow[typeField] = JobType.Breakdown;
    breakdownServiceRow[noInspectionCountField] = 0;
    breakdownServiceRow[scheduledInspectionCountField] = 0;
    breakdownServiceRow[unscheduledInspectionCountField] = 0;

    const regularServiceRow = {};
    regularServiceRow[jobTypeField] = getJobTypeLabel(JobType.Regular, translate);
    regularServiceRow[typeField] = JobType.Regular;
    regularServiceRow[noInspectionCountField] = 0;
    regularServiceRow[scheduledInspectionCountField] = 0;
    regularServiceRow[unscheduledInspectionCountField] = 0;

    data.forEach((row) => {
        let dataRow;
        switch (row.jobType) {
            case JobType.Regular:
                dataRow = regularServiceRow;
                break;
            case JobType.Breakdown:
                dataRow = breakdownServiceRow;
                break;
            default:
                throw Error(`Encountered unknown job type when building chart data ${row.jobType}`);
        }
        if (row.inspectionType) {
            switch (row.inspectionType) {
                case InspectionType.Scheduled:
                    dataRow[scheduledInspectionCountField] = row.jobCount;
                    break;
                case InspectionType.Unscheduled:
                    dataRow[unscheduledInspectionCountField] = row.jobCount;
                    break;
                default:
                    throw Error(`Encountered unknown inspection type when building chart data ${row.inspectionType}`);
            }
        } else {
            dataRow[noInspectionCountField] = row.jobCount;
        }
    });

    return [breakdownServiceRow, regularServiceRow];
}
