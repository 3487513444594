import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum ServiceProvidersActionTypes {
    FETCH_SERVICE_PROVIDER_REQUEST = '@@serviceProviders/FETCH_SERVICE_PROVIDER_REQUEST',
    FETCH_SERVICE_PROVIDER_SUCCESS = '@@serviceProviders/FETCH_SERVICE_PROVIDER_SUCCESS',
}

export type ServiceProvidersAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ServiceProvidersState {
    readonly serviceProvider?: ServiceProvider;
    readonly inProgress: boolean;
}

export interface ServiceProvider {
    id: string;
    name: string;
    countryKey: string;
    city: string;
    district: string;
    postalCode: string;
    region: string;
    streetAndNumber: string;
}
