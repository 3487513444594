import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './vehicle-job-report.module.scss';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import * as layoutActions from '../../../../store/layout/actions';
import {FleetCustomerWithConfiguration} from '../../../../store/fleet-customers';
import {FleetCustomerMatchParameters} from '../../../../types/fleet-customer';
import {
    DamagedTireDetailsCustomReportParameters,
    fetchVehicleJobListReportRequest, ReportPeriodType,
    VehicleJobListReportRequest,
} from '../../../../store/reports';
import {endOfDay, forceDateToBrusselsTime, startOfDay} from '../../../../utils/date-helper';
import {FunctionalLocationsSelectionsControlValue} from '../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import VehicleJobListComponent
    from '../../report-scheduler/popup/report-data-components/vehicle-job-list/vehicle-job-list.component';

class VehicleJobReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t, location, reportRequestStatus, fleetCustomer} = this.props;
        const {fromDate, toDate, functionalLocationSelection} = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.VehicleJobList);

        const isValid = functionalLocationSelection && functionalLocationSelection.value.length > 0 && fromDate && toDate;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div/>}/>
                <div className={styles.pageContent}>
                    <VehicleJobListComponent
                        periodType={ReportPeriodType.Custom}
                        fleetCustomer={fleetCustomer}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {}}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: DamagedTireDetailsCustomReportParameters) {
        this.setState({
            functionalLocationSelection: parameters.functionalLocationSelection,
            fromDate: parameters.fromDate,
            toDate: parameters.toDate,
            licensePlate: parameters.licensePlate,
            fleetNumber: parameters.fleetNumber
        });
    }

    private download(fileType:FileType) {
        const {fromDate, toDate, licensePlate, fleetNumber, functionalLocationSelection} = this.state;
        const {dispatchFetchVehicleJobListReport} = this.props
        if (functionalLocationSelection && functionalLocationSelection.value.length > 0 && fromDate && toDate) {
            const request: VehicleJobListReportRequest = {
                fileType,
                locations: functionalLocationSelection.value,
                fromDate: forceDateToBrusselsTime(startOfDay(fromDate)).toISOString(),
                toDate: forceDateToBrusselsTime(endOfDay(toDate)).toISOString(),
                licensePlate,
                fleetNumber,
            }

            dispatchFetchVehicleJobListReport(request);
        }
    }

    private goBack(): void {
        const {dispatchNavigateTo, fleetCustomer} = this.props;
        dispatchNavigateTo({pathname: `/${fleetCustomer?.id}${RouteUrl.Reports}`});
    }
}

// eslint-disable-next-line
const mapStateToProps = ({authentication, reports}: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    reportRequestStatus: reports.vehicleJobListReportRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchVehicleJobListReport: (request: VehicleJobListReportRequest) => dispatch(fetchVehicleJobListReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VehicleJobReportPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomerWithConfiguration;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchVehicleJobListReport: typeof fetchVehicleJobListReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    licensePlate?: string;
    fleetNumber?: string;
    fromDate?: Date;
    toDate?: Date;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
