import {Reducer} from 'redux';
import {SharedActionTypes} from '../shared/types';
import {ServiceProvidersAction, ServiceProvidersActionTypes, ServiceProvidersState} from './types';

function initialState(): ServiceProvidersState {

    return {
        inProgress: false,
        serviceProvider: undefined,
    };
}

const reducer: Reducer<ServiceProvidersState, ServiceProvidersAction> = (state = initialState(), action): ServiceProvidersState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case ServiceProvidersActionTypes.FETCH_SERVICE_PROVIDER_REQUEST: {
            return {...state, serviceProvider: undefined, inProgress: true};
        }
        case ServiceProvidersActionTypes.FETCH_SERVICE_PROVIDER_SUCCESS: {
            return {...state, serviceProvider: action.payload, inProgress: false};
        }
        default: {
            return state;
        }
    }
};

export {reducer as serviceProvidersReducer};
export {initialState as initialServiceProvidersState};
