import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {ParamKey} from '../../../../store/dashboard';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {AvoidableBreakdownsKPIRequest} from '../../../../store/analytics/types/key-performance-indicator.types';

export function buildRequest(snapshot: ControlsSnapshot): AvoidableBreakdownsKPIRequest {
    return Object.assign(createDateRangeWithFunctionalLocationsRequest(snapshot));
}

export function isValidSnapshot(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
