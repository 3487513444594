import {ControlValue} from '../../controls-snapshot';
import {FunctionalLocationSelection} from '../../../../../store/analytics';

export class FunctionalLocationsSelectionsControlValue implements ControlValue {
    constructor(public displayValue: string,
                public value: FunctionalLocationSelection[]) {}

    public equals(other: ControlValue) :boolean{
        if (!other ||
            !other.value ||
            !(other instanceof FunctionalLocationsSelectionsControlValue) ||
            this.value.length !== other.value.length) {
            return false;
        }

        const otherValue: FunctionalLocationSelection[] = other.value;
        let result = true;

        this.value.forEach((selection: FunctionalLocationSelection) => {
            if (result) {
                const {locationType} = selection;
                const otherSelection = otherValue.find(i => i.locationType === locationType);

                if (!otherSelection) {
                    result = false;
                } else {
                    result = selection.includes.length === otherSelection.includes.length;
                    if (result) {
                        selection.includes.forEach((include: string) => {
                            if (!otherSelection.includes.includes(include)) {
                                result = false;
                            }
                        });
                    }
                }
            }
        });

        return result;
    }
}
