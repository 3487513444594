
import {WidgetType} from '../../../store/dashboard';
import JobsTable from '../../jobs/jobs-table/jobs-table';
import {ControlsSnapshot} from '../dashboard-controls/controls-snapshot';
import AlertBox from './alert-box/alert-box';
import BillingDistributionWidget from './billing-distribution-widget/billing-distribution-widget';
import BillingEvolutionWidget from './billing-evolution-widget/billing-evolution-widget';
import FleetMovementWidget from './fleet-movement-widget/fleet-movement-widget';
import FleetSizeEvolutionWidget from './fleet-size-evolution-widget/fleet-size-evolution-widget';
import InspectionHistogramWidget from './inspection-histogram-widget/inspection-histogram-widget';
import InspectionStatusWidget from './inspection-status-widget/inspection-status-widget';
import JobTypesDistributionWidget from './job-types-distribution-widget/job-types-distribution-widget';
import JobsEvolutionWidget from './jobs-evolution-widget/jobs-evolution-widget';
import KpiBox from './kpi-box/kpi-box';
import TireFitmentsBrandDistributionWidget from './tire-fitments-brand-distribution-widget/tire-fitments-brand-distribution-widget';
import TopServiceProvidersWidget from './top-service-providers-widget/top-service-providers-widget';
import TopTireFitmentsPatternsWidget from './top-tire-fitments-patterns-widget/top-tire-fitments-patterns-widget';
import TrafficLightsWidget from './traffic-lights-widget/traffic-lights-widget';
import VehicleTypesDistribution from './vehicle-types-distribution-widget/vehicle-types-distribution-widget';
import AvoidableBreakdownsWidget from './avoidable-breakdowns-widgets/avoidable-breakdowns-widget';
import CostSavingByBreakdownWidget from './avoidable-breakdowns-widgets/cost-saving-by-breakdown-widget';
import MonthlySavingPotentialWidget from './avoidable-breakdowns-widgets/monthly-saving-potential-widget';
import RetreadRatioWidget from './retread-ratio-widget/retread-ratio-widget';
import UnderInflationWidget from "./under-inflation-widget/under-inflation-widget";
import TopTiresFittedPatternsWidget from "./top-tires-fitted-patterns-widget/top-tires-fitted-patterns-widget";
import TiresFittedBrandDistributionWidget
    from "./tires-fitted-brand-distribution-widget/tires-fitted-brand-distribution-widget";
import TopReusableTiresFittedByServiceProvidersWidget
    from "./top-reusable-tires-fitted-by-service-providers-widget/top-reusable-tires-fitted-by-service-providers-widget";
import TopRetreadTiresFittedByServiceProvidersWidget
    from "./top-retread-tires-fitted-by-service-providers-widget/top-retread-tires-fitted-by-service-providers-widget";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function renderWidget(type: WidgetType, configuration: any, controlsSnapshot?: ControlsSnapshot): JSX.Element {
    if (controlsSnapshot) {
        switch (type) {
            case WidgetType.KpiBox:
                return <KpiBox controlsSnapshot={controlsSnapshot} configuration={configuration} />;
            case WidgetType.AlertBox:
                return <AlertBox controlsSnapshot={controlsSnapshot} configuration={configuration} />;
            case WidgetType.JobsEvolution:
                return <JobsEvolutionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.JobsTable:
                return <JobsTable controlsSnapshot={controlsSnapshot} />;
            case WidgetType.VehiclesEvolution:
                return <FleetSizeEvolutionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.JobTypesDistribution:
                return <JobTypesDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.VehicleTypesDistribution:
                return <VehicleTypesDistribution controlsSnapshot={controlsSnapshot} />;
            case WidgetType.BillingItemsDistribution:
                return <BillingDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.BillingItemsEvolution:
                return <BillingEvolutionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.InspectionStatus:
                return <InspectionStatusWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.FleetMovement:
                return <FleetMovementWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopServiceProviders:
                return <TopServiceProvidersWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.InspectionHistogram:
                return <InspectionHistogramWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TireFitmentsBrandDistribution:
                return <TireFitmentsBrandDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopTireFitmentsPatterns:
                return <TopTireFitmentsPatternsWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TiresFittedBrandDistribution:
                return <TiresFittedBrandDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopTiresFittedPatterns:
                return <TopTiresFittedPatternsWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopReusableTiresFittedByServiceProvider:
                return <TopReusableTiresFittedByServiceProvidersWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopRetreadTiresFittedByServiceProvider:
                return <TopRetreadTiresFittedByServiceProvidersWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TrafficLights:
                return <TrafficLightsWidget />;
            case WidgetType.AvoidableBreakdowns:
                return <AvoidableBreakdownsWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.CostSavingByBreakdown:
                return <CostSavingByBreakdownWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.MonthlySavingPotential:
                return <MonthlySavingPotentialWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.RetreadRatio:
                return <RetreadRatioWidget controlsSnapshot={controlsSnapshot} />
            case WidgetType.UnderInflationEffect:
                return <UnderInflationWidget controlsSnapshot={controlsSnapshot} />
            default:
                return <div />;
        }
    }
    return <div />;
}
