import {ApiResult} from '../types/api-result';
import {SoldToMappings, SoldToWithDivisions} from '../store/soldto';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {performBase64Download} from '../utils/download-helper';
import {Base64Data} from '../store/shared/types';

class SoldToApi {

    public static async getAllMappingsForFleetCustomer(fleetCustomerId: string): Promise<ApiResult<SoldToMappings>> {
        const mappings = await Http.get<SoldToMappings>(ApiUrl.SoldToMappings,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId.toString()).build());
        return {data: mappings};
    }

    public static async getAllWithDepots(fleetCustomerId: string): Promise<ApiResult<SoldToWithDivisions[]>> {
        const mappings = await Http.get<SoldToWithDivisions[]>(ApiUrl.SoldToWithDepots,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        return {data: mappings};
    }

    public static async downloadFleetCustomerStructure(fleetCustomerId: string, fleetCustomerName: string): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.FleetCustomerSoldToStructure,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).build());
        performBase64Download(result.data,'vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Sold to structure - ${fleetCustomerName}.xlsx`);
    }

    public static async downloadFleetCustomersStructure(): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.FleetCustomersSoldToStructure);
        performBase64Download(result.data,'vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Sold to structure - All.xlsx`);
    }
}

export default SoldToApi;
