import {ApiResult} from '../types/api-result';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {Vehicle, VehicleRequest} from '../store/vehicles';
import {LanguageRequest} from '../store/analytics';

class VehiclesApi {

    public static async getVehicle(request: VehicleRequest): Promise<ApiResult<Vehicle>> {
        const result = await Http.post<LanguageRequest,Vehicle>(ApiUrl.Vehicle,{language:request.language},
            new UrlParamBuilder().addFleetCustomerId(request.fleetCustomerId).addVehicleId(request.vehicleId).build());
        return {data: result};
    }
}

export default VehiclesApi;
