import { AlertTypes } from '../../../../store/analytics';
import { AlertsWidgetDataRequest } from '../../../../store/analytics/types/alert.types';
import { ParamKey } from '../../../../store/dashboard';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import { createDateRangeWithFunctionalLocationsRequest } from '../utils/widget-helper';

export function buildRequest(snapshot: ControlsSnapshot, type: AlertTypes): AlertsWidgetDataRequest {
    let goodValue;
    let riskValue;
    switch (type) {
        case AlertTypes.TirePressure:
            goodValue = 8;
            riskValue = 6;
            break;
        case AlertTypes.TreadDepth:
            goodValue = 7;
            riskValue = 5;
            break;
        case AlertTypes.BreakdownRatio:
            goodValue = 12;
            riskValue = 6;
            break;
        case AlertTypes.ServiceFrequency:
            goodValue = 90;
            riskValue = 180;
            break;
        case AlertTypes.RetreadRatio:
            break;
        default:
            throw Error(`No Mapping found in sagas handler for ${type}`);
    }
    return Object.assign(createDateRangeWithFunctionalLocationsRequest(snapshot), {
        type,
        goodValue,
        riskValue,
    });
}

export function isValidSnapshot(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
