import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FleetCustomer} from '../../../../store/fleet-customers';
import styles from './fleet-customer-selection-control.module.scss';

class FleetCustomerSelectionControl extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);
        const {fleetCustomers} = this.props;

        this.state = {
            caption: this.getCaption(fleetCustomers),
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {fleetCustomers} = this.props;

        if (fleetCustomers !== prevProps.fleetCustomers) {
            this.setState({caption: this.getCaption(fleetCustomers)});
        }
    }

    public render(): JSX.Element {
        const {onChange, canChange, t} = this.props;
        const {caption} = this.state;
        return (
            <>
                <div className={styles.fleetCustomerSelectionControl} onClick={() => onChange()}>
                    {caption}
                </div>
                <div className={styles.fleetCustomerSelectionLink} onClick={() => onChange()}>
                    {canChange ? t('change') : t('view')}
                </div>
            </>
        );
    }

    private getCaption(fleetCustomers?: FleetCustomer[]): string {
        const {t} = this.props;

        if (!fleetCustomers) {
            return t('None');
        } else if (fleetCustomers.length === 1) {
            return fleetCustomers[0].name;
        } else {
            return t('{{itemCount}} items selected', {itemCount: fleetCustomers.length});
        }
    }
}

export default withTranslation()(FleetCustomerSelectionControl);

interface OwnProps {
    fleetCustomers?: FleetCustomer[];
    canChange: boolean;
    onChange: () => void;
}

interface OwnState {
    caption: string;
}

type AllProps = OwnProps & WithTranslation;
