import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './vehicles-current-status.module.scss';
import {
    ReportPeriodType,
    VehiclesCurrentStatusCustomReportParameters,
    VehiclesCurrentStatusScheduledReportParameters,
} from '../../../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import LocationSelectorComponent from '../../../../components/location-selector.component';

class VehiclesCurrentStatusComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let locations: FunctionalLocationSelection[] = [];
        let treadDepth;
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.treadDepth) {
            treadDepth = parameters.treadDepth;
        }

        this.state = {
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
            treadDepth,
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {functionalLocationSelection, treadDepth} = this.state;


        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a customer')}</div>
                            <LocationSelectorComponent
                                defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                                onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Optional Filter')}</div>
                            <div className={styles.textFilter}>
                                <label htmlFor="treadDepth">
                                    {t('Tread Depth Threshold')} (mm):
                                    <input type="number" id="treadDepth"
                                           value={treadDepth || ''}
                                           className={styles.fieldInput}
                                           onChange={(event: any) => this.onTreadDepthChanged(event)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, treadDepth} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    treadDepth,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    locations: controlValue.value,
                    treadDepth,
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onTreadDepthChanged(event: any) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;
        const treadDepth = event.target.value;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                functionalLocationSelection,
                treadDepth,
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                locations: functionalLocationSelection!.value,
                treadDepth,
            });
        }

        this.setState({
            treadDepth,
        });
    }
}

export default withTranslation()(VehiclesCurrentStatusComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: VehiclesCurrentStatusScheduledReportParameters;
    onCustomParametersChanged: (parameters: VehiclesCurrentStatusCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: VehiclesCurrentStatusScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
    treadDepth?: number;
}

type AllState = OwnState;
