import {Depot, DivisionWithDepots, SoldToWithDivisions} from '../../../../../store/soldto';
import {Item} from '../selection-panel';
import {FunctionalLocationSelection, FunctionalLocationType} from '../../../../../store/analytics';

export function getLocationsFromSelection(allSoldTos: SoldToWithDivisions[], allDivisions: DivisionWithDepots[],
                                          allDepots: Depot[], selections: FunctionalLocationSelection[]): Map<string, Item> {
    const selectedDepots = new Map<string, Item>();
    selections.forEach((selection: FunctionalLocationSelection) => {
        switch (selection.locationType) {
            case FunctionalLocationType.DEPOT:
                allDepots.forEach((depot) => {
                    if (selection.includes.includes(depot.id)) {
                        selectedDepots.set(depot.id, depot);
                    }
                });
                break;
            case FunctionalLocationType.DIVISION:
                allDivisions.forEach((division) => {
                    if (selection.includes.includes(division.id)) {
                        division.depots.forEach(depot => selectedDepots.set(depot.id, depot));
                    }
                });
                break;
            case FunctionalLocationType.SOLD_TO:
                allSoldTos.forEach((soldTo) => {
                    if (selection.includes.includes(soldTo.id)) {
                        soldTo.divisions.forEach((division) => {
                            division.depots.forEach(depot => selectedDepots.set(depot.id, depot));
                        });
                    }
                });
                break;
            default:
        }
    });
    return selectedDepots;
}

export function buildSelectionHierarchySelectAll(allSoldTos: SoldToWithDivisions[], allDivisions: DivisionWithDepots[], allDepots: Depot[]): SelectionHierarchy {
    const selectionHierarchy = new SelectionHierarchy();
    allDepots.forEach(depot => selectionHierarchy.fullDepotsSelection.set(depot.id, depot));
    allDivisions.forEach(div => selectionHierarchy.fullDivisionSelection.set(div.id, div));
    allSoldTos.forEach(soldTo => selectionHierarchy.fullSoldToSelection.set(soldTo.id, soldTo));
    return selectionHierarchy;
}

export function buildSelectionHierarchyFromSelection(allSoldToWithDivisions: SoldToWithDivisions[], fullDepotsSelection: Map<string, Item>): SelectionHierarchy {
    const selectionHierarchy = new SelectionHierarchy();
    selectionHierarchy.fullDepotsSelection = fullDepotsSelection;
    allSoldToWithDivisions.forEach((soldTo) => {
        let divisionCounter = 0;
        let hasPartialDivision = false;
        soldTo.divisions.forEach((division) => {
            let depotsCounter = 0;
            division.depots.forEach((depot) => {
                depotsCounter += fullDepotsSelection.get(depot.id) ? 1 : 0;
            });
            if (depotsCounter === division.depots.length) {
                selectionHierarchy.fullDivisionSelection.set(division.id, division);
                divisionCounter += 1;
            } else if (depotsCounter > 0) {
                selectionHierarchy.partialDivisionSelection.set(division.id, division);
                hasPartialDivision = true;
            }
        });
        if (divisionCounter === soldTo.divisions.length) {
            selectionHierarchy.fullSoldToSelection.set(soldTo.id, soldTo);
        } else if (divisionCounter > 0 || hasPartialDivision) {
            selectionHierarchy.partialSoldToSelection.set(soldTo.id, soldTo);
        }
    });

    return selectionHierarchy;
}

export function getSelectionsFromHierarchy(selectionHierachy: SelectionHierarchy): FunctionalLocationSelection[] {
    const selections: FunctionalLocationSelection[] = [];
    const soldToIds: string[] = Array.from(selectionHierachy.fullSoldToSelection.values()).map(s => s.id);
    const divisionIds: string[] = [];
    const depotIds: string[] = [];

    Array.from(selectionHierachy.partialSoldToSelection.values()).forEach((soldTo) => {
        (soldTo as SoldToWithDivisions).divisions.forEach((division) => {
            if (selectionHierachy.fullDivisionSelection.get(division.id)) {
                divisionIds.push(division.id);
            } else if (selectionHierachy.partialDivisionSelection.get(division.id)) {
                (division as DivisionWithDepots).depots.forEach((depot) => {
                    if (selectionHierachy.fullDepotsSelection.get(depot.id)) {
                        depotIds.push(depot.id);
                    }
                });
            }
        });
    });

    if (soldToIds.length > 0) {
        selections.push({locationType: FunctionalLocationType.SOLD_TO, includes: soldToIds.map(id => id.toString())});
    }
    if (divisionIds.length > 0) {
        selections.push({locationType: FunctionalLocationType.DIVISION, includes: divisionIds.map(id => id.toString())});
    }
    if (depotIds.length > 0) {
        selections.push({locationType: FunctionalLocationType.DEPOT, includes: depotIds.map(id => id.toString())});
    }

    return selections;
}

export class SelectionHierarchy {

    public fullDepotsSelection: Map<string, Item>;

    public fullDivisionSelection: Map<string, DivisionWithDepots>;

    public fullSoldToSelection: Map<string, SoldToWithDivisions>;

    public partialDivisionSelection: Map<string, DivisionWithDepots>;

    public partialSoldToSelection: Map<string, SoldToWithDivisions>;

    constructor() {
        this.fullDepotsSelection = new Map<string, Item>();
        this.fullDivisionSelection = new Map<string, DivisionWithDepots>();
        this.fullSoldToSelection = new Map<string, SoldToWithDivisions>();
        this.partialDivisionSelection = new Map<string, DivisionWithDepots>();
        this.partialSoldToSelection = new Map<string, SoldToWithDivisions>();
    }

    public isPartialDivisionSelected(id: string): boolean {
        return this.partialDivisionSelection.has(id);
    }

    public isFullDivisionSelected(id: string): boolean {
        return this.fullDivisionSelection.has(id);
    }

    public isPartialSoldToSelected(id: string): boolean {
        return this.partialSoldToSelection.has(id);
    }

    public isFullSoldToSelected(id: string): boolean {
        return this.fullSoldToSelection.has(id);
    }
}
