import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { TFunction } from 'i18next';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ConfirmationPopup from '../../../components/confirmation-popup/confirmation-popup';
import { RouteUrl } from '../../../routes';
import { ApplicationState } from '../../../store';
import {
    deleteFleetCustomerRequest,
    FleetCustomer,
    FleetCustomersSearchRequest,
    searchFleetCustomersRequest
} from '../../../store/fleet-customers';
import * as layoutActions from '../../../store/layout/actions';
import { closePopup, Popup, PopupType, showPopup } from '../../../store/popup';
import {Paged, RequestStatus} from '../../../store/shared/types';
import { addToast, defaultRemoveAfterMilliseconds, ToastType } from '../../../store/toast';
import { generateUUID } from '../../../utils/uuid-helpers';
import ActionsMenu, { ActionItem, ActionType } from '../components/actions-menu/actions-menu/actions-menu';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './fleet-customers-management.module.scss';
import SoldToApi from '../../../api/sold-to-api';
import {Permission, User} from '../../../store/authentication';
import {hasPermission} from '../../../utils/user-helper';
import Paging from '../../jobs/jobs-table/paging/paging';

class FleetCustomersManagementPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const { dispatchToggleSidebar } = this.props;
        dispatchToggleSidebar(false);

        this.state = {
            page: 1,
            itemsPerPage: 15,
            searchText: undefined,
        };
    }

    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const { deleteFleetCustomerRequestStatus } = this.props;
        const {page, itemsPerPage} = this.state;

        if (
            deleteFleetCustomerRequestStatus &&
            prevProps.deleteFleetCustomerRequestStatus !== deleteFleetCustomerRequestStatus &&
            deleteFleetCustomerRequestStatus.isSuccess
        ) {
            this.showSuccessToast('Fleet Customer Successfully Deleted');
        }

        if (
            prevState.page !== page ||
            prevState.itemsPerPage !== itemsPerPage
        ) {
            this.fetchData();
        }
    }

    public render(): JSX.Element {
        const { t, searchFleetCustomersRequestStatus, dispatchNavigateTo, loggedInUser, fleetCustomers } = this.props;
        const {page, itemsPerPage} = this.state;
        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageFleets)

        return fleetCustomers && fleetCustomers.pageData ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('Fleet Management')}
                    onBack={() => dispatchNavigateTo({ pathname: RouteUrl.Administration })}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.topBar}>
                            <div className={styles.searchContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
                                <input
                                    className={styles.searchInput}
                                    type='text'
                                    placeholder={t('Filter')}
                                    autoFocus
                                    onChange={(event) => this.onSearch(event)}
                                    onKeyUp={(event) => this.onKeyUp(event)}
                                />
                            </div>
                            {hasEditPermission
                                ?<button
                                type='button'
                                className={styles.button}
                                onClick={() => this.onCreateFleetCustomer()}>
                                {t('Create Fleet Customer')}
                            </button>
                                :null}
                        </div>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell}>{t('Name')}</div>
                                <div className={styles.cell}>{t('Allowed Domains')}</div>
                                <div className={styles.optionsCellSpacer} />
                            </div>
                            <div className={styles.rows}>
                                {searchFleetCustomersRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </div>
                                ) : fleetCustomers.pageData.length > 0 ? (
                                    fleetCustomers.pageData.map((fc) => (
                                            <div
                                                className={styles.row}
                                                key={fc.id}
                                                onClick={() => this.onSelectFleetCustomer(fc)}>
                                                <div className={styles.cell}>{fc.name}</div>
                                                <div className={styles.cell}>{fc.allowedDomains.join(', ')}</div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu actions={this.getActionsForUser(hasEditPermission,fc, t)} />
                                                </div>
                                            </div>
                                        ))
                                ) : (
                                    <div className={styles.noData}>
                                        {t('No data found.')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {fleetCustomers ? (
                        <div className={styles.paging}>
                            <Paging
                                currentPage={page}
                                initialItemsPerPage={itemsPerPage}
                                onPreviousPage={() => this.onPreviousPage()}
                                onNextPage={() => this.onNextPage()}
                                onJumpToPage={(index: number) => this.onJumpToPage(index)}
                                total={fleetCustomers.totalCount}
                                onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                                itemsPerPageBackground="white"
                            />
                        </div>
                    ) : null}
                    <div className={styles.buttons}>
                        <button
                            type='button'
                            className={styles.button}
                            onClick={() => this.onDownloadSoldToStructure()}>
                            {t('Download fleet customers details')}
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>
        );
    }

    private fetchData(): void {
        const {dispatchSearchFleetCustomers} = this.props;
        const {page, itemsPerPage, searchText} = this.state;
        const request: FleetCustomersSearchRequest = {
            page,
            itemsPerPage,
            search: searchText
        };
        dispatchSearchFleetCustomers(request);
    }

    private onSearch(event: any) {
        const searchValue = event.target.value;
        this.setState({
            searchText: searchValue,
        });
    }

    private onKeyUp(event: any) {
        if (event.key === 'Enter') {
            this.fetchData();
        }
    }

    private getActionsForUser(hasEditPermission:boolean,fleetCustomer: FleetCustomer, t: TFunction): ActionItem[] {
        const actions: ActionItem[] = [];
        if (hasEditPermission) {
            actions.push({
                label: t('Delete Fleet Customer'),
                onClick: (): void => this.onDeleteFleetCustomer(fleetCustomer),
                type: ActionType.negative,
            });
        }
        return actions;
    }

    public onSelectFleetCustomer(fleetCustomer: FleetCustomer): void {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: RouteUrl.FleetCustomerDetails.replace(':fleetCustomerId', fleetCustomer.id) });
    }

    public onCreateFleetCustomer(): void {
        const { dispatchNavigateTo } = this.props;
        dispatchNavigateTo({ pathname: RouteUrl.FleetCustomerDetails.replace(':fleetCustomerId', 'new') });
    }

    private onDeleteFleetCustomer(fleetCustomer: FleetCustomer): void {
        const { dispatchShowPopup, dispatchClosePopup, t, dispatchDeleteFleetCustomer } = this.props;

        dispatchShowPopup({
            type: PopupType.UserCreation,
            content: (
                <ConfirmationPopup
                    message={t('Are you sure you want to delete this fleet customer?')}
                    onCancel={() => dispatchClosePopup()}
                    onConfirm={() => {
                        dispatchDeleteFleetCustomer(fleetCustomer);
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private onItemsPerPageChanged(items: number): void {
        this.setState({
            itemsPerPage: items,
        });
    }

    private onPreviousPage(): void {
        const {page} = this.state;
        this.setState({
            page: page - 1,
        });
    }

    private onNextPage(): void {
        const {page} = this.state;
        this.setState({
            page: page + 1,
        });
    }

    private onJumpToPage(pageIndex: number): void {
        if (!Number.isNaN(pageIndex)) {
            this.setState({
                page: pageIndex,
            })
        }
    }

    private onDownloadSoldToStructure(): void {
        // eslint-disable-next-line react/destructuring-assignment
        SoldToApi.downloadFleetCustomersStructure().then(() => {
        });
    }

    private showSuccessToast(message): void {
        const { dispatchAddToast, t } = this.props;

        dispatchAddToast({
            id: generateUUID(),
            messages: [t(message)],
            type: ToastType.Success,
            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
        });
    }
}

const mapStateToProps = ({ fleetCustomers ,authentication}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    fleetCustomers: fleetCustomers.pagedFleetCustomers,
    deleteFleetCustomerRequestStatus: fleetCustomers.deleteFleetCustomerRequestStatus,
    searchFleetCustomersRequestStatus: fleetCustomers.getFleetCustomersRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchSearchFleetCustomers: (request: FleetCustomersSearchRequest) => dispatch(searchFleetCustomersRequest(request)),
    dispatchDeleteFleetCustomer: (fleetCustomer: FleetCustomer) => dispatch(deleteFleetCustomerRequest(fleetCustomer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FleetCustomersManagementPage));

interface PropsFromState {
    loggedInUser?: User;
    fleetCustomers?: Paged<FleetCustomer>;
    deleteFleetCustomerRequestStatus?: RequestStatus;
    searchFleetCustomersRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchAddToast: typeof addToast;
    dispatchSearchFleetCustomers: typeof searchFleetCustomersRequest;
    dispatchDeleteFleetCustomer: typeof deleteFleetCustomerRequest;
}

interface OwnProps {}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    page: number;
    itemsPerPage: number;
    searchText: string | undefined;
}

type AllState = OwnState;
