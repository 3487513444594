import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {Job, JobsActionTypes, JobsExplorerRequest, TireServiceDetails} from './types';
import {
    fetchJobsRequest,
    fetchJobsSuccess,
    fetchTireServiceFailure,
    fetchTireServiceRequest,
    fetchTireServiceSuccess
} from './actions';
import JobsApi from '../../api/jobs-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApplicationState} from '../index';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof fetchJobsRequest>) {
    try {
        const request: JobsExplorerRequest = action.payload;
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const result: ApiResult<Paged<Job>> = yield call(JobsApi.getJobs, request, fleetCustomerId);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(fetchJobsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetTiresService(action: ActionType<typeof fetchTireServiceRequest>) {
    try {
        const request = action.payload;
        const result: ApiResult<TireServiceDetails> = yield call(JobsApi.getTireService, request);

        if (result.error) {
            yield call(fetchTireServiceFailure, result.error);
        } else {
            yield put(fetchTireServiceSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

const fetchFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

function* watchFetchRequest() {
    yield takeEvery(JobsActionTypes.FETCH_JOBS_REQUEST, handleFetch);
    yield takeEvery(JobsActionTypes.FETCH_TIRE_SERVICE_REQUEST, handleGetTiresService);
}

function* jobsSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default jobsSaga;
