import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import { Color } from '@amcharts/amcharts4/core';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './billing-distribution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import WidgetLoading from '../widget-loading';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {fetchBillingItemsDistributionRequest} from '../../../../store/analytics';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import {
    buildChartDataWithDetails,
    buildChartDataWithoutDetails,
    invoiceItemTypeField,
    netPriceField,
} from './chart-data-adapter';
import {
    BillingDistributionRequest,
    BillingItemDetailsType,
    BillingItemsDistributionData,
    BillingItemType,
} from '../../../../store/analytics/types/invoice-items-distribution.types';
import {hasInspectionPeriod} from '../utils/widget-helper';
// tslint:disable-next-line:no-duplicate-imports
import {colorBlue5, colorBlue4, colorBlue8, colorYellow5, colorYellow4, colorYellow8} from '../utils/chart-colors';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {typeField} from '../job-types-distribution-widget/chart-data-adapter';
import {buildRequest} from '../billing-evolution-widget/request-builder';
import {CurrencyInfo} from '../../../../store/fleet-customers';

am4core.useTheme(am4themes_animated);

class BillingDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, BillingItemsDistributionData[]> {

    private chartId: string;

    private chartColors: Map<string, Color>;

    private readonly OPTION_NO_DETAILS = {id: '1', key: DropdownOptionKey.ServicesVsTires};

    private readonly OPTION_WITH_DETAILS = {id: '2', key: DropdownOptionKey.IncludeDetails};

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.chartColors = new Map<string, Color>();
        this.chartColors.set(BillingItemType.Materials, am4core.color(colorYellow5));
        this.chartColors.set(BillingItemType.Services, am4core.color(colorBlue5));
        this.chartColors.set(BillingItemDetailsType.RegularServices, am4core.color(colorBlue8));
        this.chartColors.set(BillingItemDetailsType.BreakdownServices, am4core.color(colorBlue4));
        this.chartColors.set(BillingItemDetailsType.NewTires, am4core.color(colorYellow8));
        this.chartColors.set(BillingItemDetailsType.RetreadTires, am4core.color(colorYellow4));

        this.state = {
            options: [this.OPTION_NO_DETAILS, this.OPTION_WITH_DETAILS],
            selectedOption: this.OPTION_NO_DETAILS };
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Billing Distribution')}
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading />}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        const {activeCurrency} = this.props;
        this.props.fetchInvoiceItemsDistributionRequest(buildRequest(snapshot,activeCurrency));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    private onSelect(option: DropdownOption): void {
        this.setState({selectedOption: option}, () => {
            this.redraw(this.props.data);
        });
    }

    protected createChart(data: BillingItemsDistributionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = invoiceItemTypeField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.activeCurrency.name;

        this.addFlatSeries(chart);

        if (this.state.selectedOption === this.OPTION_NO_DETAILS) {
            chart.data = buildChartDataWithoutDetails(data, this.props.t);
        } else {
            chart.data = buildChartDataWithDetails(data, this.props.t);
        }

        return chart;
    }

    public addFlatSeries(chart: any) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = netPriceField;
        series.dataFields.categoryX = invoiceItemTypeField;
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${this.props.activeCurrency.name}[/]`;
        series.columns.template.fillOpacity = .8;

        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);
        dynamicallySetColorByType(series, typeField, (categoryX: string) => {
            if (!this.chartColors.has(categoryX)) {
                throw Error(`No color found for category ${categoryX}`);
            }
            return this.chartColors.get(categoryX)!;
        });
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.invoiceItemsDistributionData,
    activeCurrency: authentication.fleetCustomer!.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchInvoiceItemsDistributionRequest:(request: BillingDistributionRequest) => dispatch(fetchBillingItemsDistributionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingDistributionWidget));

interface PropsFromState {
    data: BillingItemsDistributionData[];
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    fetchInvoiceItemsDistributionRequest: typeof fetchBillingItemsDistributionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
