import {Component} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ApplicationState} from '../../../store';
import {ScheduledReport} from '../../../store/reports';
import {RequestStatus} from '../../../store/shared/types';
import styles from './report-scheduler-table.module.scss';
import {generateUUID} from '../../../utils/uuid-helpers';
import WidgetLoading from '../../analytics/dashboard-widgets/widget-loading';
import {FleetCustomer} from '../../../store/fleet-customers';
import {getReportName} from '../report-catalog';
import {getRecipientsDisplay, getReportScheduleDisplay} from '../utils/scheduled-reports-helper';

class ReportSchedulerTableComponent extends Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {t, scheduledReports, scheduledReportsRequestStatus} = this.props;
        return (
            <div className={styles.table}>
                <div className={styles.tableHead}>
                    <div className={`${styles.tableRow} ${styles.tableRowHeader}`}>
                        <span className={styles.tableColumn}>{t('Name').toUpperCase()}</span>
                        <span className={styles.tableColumn}>{t('Report type').toUpperCase()}</span>
                        <span className={styles.tableColumn}>{t('Schedule').toUpperCase()}</span>
                        <span className={styles.tableColumn}>{t('Recipients').toUpperCase()}</span>
                        <span className={styles.tableColumnSmall}/>
                    </div>
                </div>
                <div className={styles.tableBody}>
                    {scheduledReports && scheduledReports.length > 0 && scheduledReportsRequestStatus?.isSuccess ? (
                        <div className={styles.tableRows}>
                            {scheduledReports.map((report: ScheduledReport) => {
                                const rowId = generateUUID();
                                return (
                                    <div className={styles.tableRow} key={rowId}>
                                        <span className={styles.tableColumn}>{report.name}</span>
                                        <span
                                            className={styles.tableColumn}>{getReportName(report.reportType, t)}</span>
                                        <span
                                            className={styles.tableColumn}>{getReportScheduleDisplay(report, t).toUpperCase()}</span>
                                        <span
                                            className={styles.tableColumn}>{getRecipientsDisplay(report.recipients, t).toUpperCase()}</span>
                                        <span className={styles.tableColumnSmall}>
                                            <span className={styles.tableColumnLink}
                                                  onClick={() => this.onUpdateScheduleReportClick(report)}>{t('Update')}</span>
                                            <span className={styles.tableColumnLink}
                                                  onClick={() => this.onDeleteScheduleReportClick(report.id!)}>{t('Remove')}</span>
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    ) : scheduledReportsRequestStatus?.isInProgress ? (
                        <div className={`${styles.tableRow} ${styles.tableRowLoading}`}>
                            <WidgetLoading/>
                        </div>
                    ) : (
                        <div className={`${styles.tableRow} ${styles.tableRowLoading}`}>{t('No data found.')}</div>
                    )}
                </div>
            </div>
        )
    }

    private onUpdateScheduleReportClick(scheduledReport: ScheduledReport): void {
        const {onUpdateScheduledReportClick} = this.props;
        onUpdateScheduledReportClick(scheduledReport);
    }

    private onDeleteScheduleReportClick(id: string): void {
        const {onDeleteScheduledReportClick} = this.props;
        onDeleteScheduledReportClick(id);
    }
}

const mapStateToProps = ({reports, authentication}: ApplicationState): PropsFromState => ({
    scheduledReportsRequestStatus: reports.getScheduledReportsRequestStatus,
    fleetCustomer: authentication.fleetCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportSchedulerTableComponent));

interface PropsFromState {
    scheduledReportsRequestStatus?: RequestStatus;
    fleetCustomer?: FleetCustomer;
}

interface PropsFromDispatch {
}

interface OwnProps {
    scheduledReports: ScheduledReport[];
    onUpdateScheduledReportClick: (scheduledReport: ScheduledReport) => void;
    onDeleteScheduledReportClick: (id: string) => void;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
