import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './vehicles-due-for-inspection.module.scss';
import {
    ReportPeriodType,
    VehiclesDueForInspectionCustomReportParameters,
    VehiclesDueForInspectionScheduledReportParameters,
} from '../../../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import LocationSelectorComponent from '../../../../components/location-selector.component';

class VehiclesDueForInspectionComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let locations: FunctionalLocationSelection[] = [];
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }

        this.state = {
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {functionalLocationSelection} = this.state;


        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a customer')}</div>
                            <LocationSelectorComponent
                                defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                                onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    locations: controlValue.value,
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }
}

export default withTranslation()(VehiclesDueForInspectionComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: VehiclesDueForInspectionScheduledReportParameters;
    onCustomParametersChanged: (parameters: VehiclesDueForInspectionCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: VehiclesDueForInspectionScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
