import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../utils/class-helpers';
import styles from './confirmation-popup.module.scss';

class ConfirmationPopup extends Component<AllProps> {
    public render(): JSX.Element {
        const { t ,message} = this.props;
        const negativeButtonClasses = conditionalClassLister(styles)({
            button: true,
            isNegative: true,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader} />
                <div className={styles.popupBody}>
                    <div className={styles.caption}>{t(message)}</div>
                    <div className={styles.buttons}>
                        <button type='button' className={negativeButtonClasses} onClick={() => this.onCancelClick()}>
                            {t('Cancel')}
                        </button>
                        <button type='button' className={styles.button} onClick={() => this.onConfirmClick()}>
                            {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onConfirmClick() {
        const {onConfirm} = this.props;
        onConfirm();
    }

    private onCancelClick() {
        const {onCancel} = this.props;
        onCancel();
    }
}

export default withTranslation()(ConfirmationPopup);

interface OwnProps {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

type AllProps = OwnProps & WithTranslation;
