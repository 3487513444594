import { connectRouter, RouterState } from 'connected-react-router';
import {CombinedState, combineReducers, Reducer} from 'redux';
import { all, fork } from 'redux-saga/effects';
import {
    History,
} from 'history';
import { analyticsReducer, AnalyticsState } from './analytics';
import analyticsSaga from './analytics/sagas';
import { authenticationReducer, AuthenticationState } from './authentication';
import authenticationSaga from './authentication/sagas';
import { billingReducer, BillingState } from './billing';
import billingSaga from './billing/sagas';
import { dashboardReducer, DashboardState } from './dashboard';
import dashboardSaga from './dashboard/sagas';
import { fleetCustomersReducer, FleetCustomersState } from './fleet-customers';
import fleetCustomersSaga from './fleet-customers/sagas';
import { jobsReducer, JobsState } from './jobs';
import jobsSaga from './jobs/sagas';
import { layoutReducer, LayoutState } from './layout';
import { popupReducer, PopupState } from './popup';
import { reportsReducer, ReportsState } from './reports';
import reportsSaga from './reports/sagas';
import { searchReducer, SearchState } from './search';
import searchSaga from './search/sagas';
import { serviceProvidersReducer, ServiceProvidersState } from './service-providers';
import serviceProvidersSaga from './service-providers/sagas';
import { soldToReducer, SoldToState } from './soldto';
import soldToSaga from './soldto/sagas';
import { toastReducer, ToastState } from './toast';
import { usersReducer, UsersState } from './users';
import usersSaga from './users/sagas';
import { vehiclesReducer, VehiclesState } from './vehicles';
import vehiclesSaga from './vehicles/sagas';
import {depotSelectionReducer, DepotSelectionState} from './depot-selection';
import { maintenanceReducer, MaintenanceState } from './maintenance';
import maintenanceSaga from './maintenance/sagas';
import {dataStatusReducer, DataStatusState} from './data-status';
import dataStatusSaga from './data-status/sagas';
import {reportConfigurationReducer, ReportConfigurationState} from './report-management';
import reportConfigurationSaga from './report-management/sagas';
import {scheduledReportsReducer, ScheduledReportsState} from './scheduled-report-management';
import scheduledReportsSaga from './scheduled-report-management/sagas';

export interface ApplicationState {
    router: RouterState;
    layout: LayoutState;
    authentication: AuthenticationState;
    dashboard: DashboardState;
    toast: ToastState;
    popup: PopupState;
    search: SearchState;
    jobs: JobsState;
    soldTo: SoldToState;
    fleetCustomers: FleetCustomersState;
    analytics: AnalyticsState;
    users: UsersState;
    maintenance: MaintenanceState;
    billing: BillingState;
    serviceProviders: ServiceProvidersState;
    vehicles: VehiclesState;
    reports: ReportsState;
    depotSelection: DepotSelectionState;
    dataStatus: DataStatusState;
    reportConfigurations: ReportConfigurationState;
    scheduledReports: ScheduledReportsState;
}

export const rootReducer = (history:History): Reducer<CombinedState<ApplicationState>> =>
    combineReducers<ApplicationState>({
        router: connectRouter(history),
        layout: layoutReducer,
        authentication: authenticationReducer,
        dashboard: dashboardReducer,
        toast: toastReducer,
        search: searchReducer,
        fleetCustomers: fleetCustomersReducer,
        popup: popupReducer,
        jobs: jobsReducer,
        soldTo: soldToReducer,
        analytics: analyticsReducer,
        billing: billingReducer,
        users: usersReducer,
        maintenance: maintenanceReducer,
        serviceProviders: serviceProvidersReducer,
        vehicles: vehiclesReducer,
        reports: reportsReducer,
        depotSelection: depotSelectionReducer,
        dataStatus: dataStatusReducer,
        reportConfigurations: reportConfigurationReducer,
        scheduledReports:scheduledReportsReducer,
    });

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(dashboardSaga),
        fork(usersSaga),
        fork(maintenanceSaga),
        fork(searchSaga),
        fork(jobsSaga),
        fork(billingSaga),
        fork(soldToSaga),
        fork(analyticsSaga),
        fork(fleetCustomersSaga),
        fork(serviceProvidersSaga),
        fork(vehiclesSaga),
        fork(reportsSaga),
        fork(dataStatusSaga),
        fork(reportConfigurationSaga),
        fork(scheduledReportsSaga),
    ]);
}
