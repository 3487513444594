import {TFunction} from 'i18next';
import {InspectionStatus} from '../../store/analytics/types/inspection-status.types';

export function getInspectionStatusLabel(status: InspectionStatus, t: TFunction): string {
    switch (status) {
        case InspectionStatus.vehiclesInspectedOnTime: {
            return t('inspectionStatus_vehiclesInspectedOnTime');
        }
        case InspectionStatus.vehiclesLessThanOneMonthOverdueForInspection: {
            return t('inspectionStatus_vehiclesLessThanOneMonthOverdueForInspection');
        }
        case InspectionStatus.vehiclesLessThanTwoMonthsOverdueForInspection: {
            return t('inspectionStatus_vehiclesLessThanTwoMonthsOverdueForInspection');
        }
        case InspectionStatus.vehiclesLessThanThreeMonthsOverdueForInspection: {
            return t('inspectionStatus_vehiclesLessThanThreeMonthsOverdueForInspection');
        }
        case InspectionStatus.vehiclesMoreThanThreeMonthsOverdueForInspection: {
            return t('inspectionStatus_vehiclesMoreThanThreeMonthsOverdueForInspection');
        }
        default:
            throw new Error(`Inspection status ${status} is not supported.`);
    }
}
