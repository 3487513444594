import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {
    fetchTirePerformanceOptionsRequest,
    ReportPeriodType,
    ScheduledReportPeriod,
    TirePerformanceCustomReportParameters, TirePerformanceOptions,
    TirePerformanceScheduledReportParameters,
} from '../../../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import styles from './tire-performance.module.scss';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import LocationSelectorComponent from '../../../../components/location-selector.component';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import {FleetCustomer, FleetCustomerWithConfiguration} from '../../../../../../store/fleet-customers';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';
import {ApplicationState} from '../../../../../../store';
import PerformanceOptionSelectorComponent, {OPTIONS_ALL} from '../../../../components/performance-option-selector.component';

class TirePerformanceComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters, fleetCustomer, options, dispatchFetchTirePerformanceOptions} = this.props;

        if (fleetCustomer && !options) {
            dispatchFetchTirePerformanceOptions(fleetCustomer.id);
        }

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        let locations: FunctionalLocationSelection[] = [];
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.period) {
            period = parameters.period;
        }

        this.state = {
            period,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>) {
        const {dispatchFetchTirePerformanceOptions, options, fleetCustomer} = this.props;

        if (!options && fleetCustomer && prevProps.fleetCustomer !== fleetCustomer) {
            dispatchFetchTirePerformanceOptions(fleetCustomer.id);
        }
    }

    public render(): JSX.Element {
        const {periodType, fleetCustomer, t, options} = this.props;
        const {period, functionalLocationSelection, brand, size, design, vehicleType} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                        <div className={styles.info}>{t('Select a customer')}</div>
                        <LocationSelectorComponent
                            defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                            onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                    </div>
                    </div>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.formColumn}>
                                <div className={styles.info}>{t('Specify a period')}</div>
                                <DateRangeSelectorComponent
                                    earliestDate={fleetCustomer?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}
                                />
                            </div>
                        </div>
                        : null
                    }
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a size')}</div>
                            <PerformanceOptionSelectorComponent
                                defaultOption={options?.sizeOptions?.find(c => c.id === size)}
                                options={options?.sizeOptions}
                                optionChanged={(opt => this.onSizeChanged(opt))}/>
                        </div>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a brand')}</div>
                            <PerformanceOptionSelectorComponent
                                defaultOption={options?.brandOptions?.find(c => c.id === brand)}
                                options={options?.brandOptions}
                                optionChanged={(opt => this.onBrandChanged(opt))}/>
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a design')}</div>
                            <PerformanceOptionSelectorComponent
                                defaultOption={options?.designOptions?.find(c => c.id === design)}
                                options={options?.designOptions}
                                optionChanged={(opt => this.onDesignChanged(opt))}/>
                        </div>
                        <div className={styles.formColumn}>
                            <div className={styles.info}>{t('Select a vehicle type')}</div>
                            <PerformanceOptionSelectorComponent
                                defaultOption={options?.vehicleTypeOptions?.find(c => c.id === vehicleType)}
                                options={options?.vehicleTypeOptions}
                                optionChanged={(opt => this.onVehicleTypeChanged(opt))}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onBrandChanged(brandId: string): void {
        const {periodType, options, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate, size, design, vehicleType} = this.state;

        if (options) {
            const selectedBrand = brandId === OPTIONS_ALL ? undefined : options.brandOptions.find(c => c.id === brandId);
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    brand: selectedBrand?.id,
                    functionalLocationSelection,
                    fromDate,
                    toDate,
                    size,
                    design,
                    vehicleType
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    brand: selectedBrand?.id,
                    period,
                    locations: functionalLocationSelection!.value,
                    size,
                    design,
                    vehicleType
                });
            }

            this.setState({
                brand: brandId,
            });
        }
    }

    private onSizeChanged(sizeId: string): void {
        const {periodType, options, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate, brand, design, vehicleType} = this.state;

        if (options) {
            const selectedSize = sizeId === OPTIONS_ALL ? undefined : options.sizeOptions.find(c => c.id === sizeId);
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    size: selectedSize?.id,
                    functionalLocationSelection,
                    fromDate,
                    toDate,
                    brand,
                    design,
                    vehicleType
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    size: selectedSize?.id,
                    period,
                    locations: functionalLocationSelection!.value,
                    brand,
                    design,
                    vehicleType
                });
            }

            this.setState({
                size: sizeId,
            });
        }
    }

    private onDesignChanged(designId: string): void {
        const {periodType, options, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate, size, brand, vehicleType} = this.state;

        if (options) {
            const selectedDesign = designId === OPTIONS_ALL ? undefined : options.designOptions.find(c => c.id === designId);
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    design: selectedDesign?.id,
                    functionalLocationSelection,
                    fromDate,
                    toDate,
                    size,
                    brand,
                    vehicleType
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    design: selectedDesign?.id,
                    period,
                    locations: functionalLocationSelection!.value,
                    size,
                    brand,
                    vehicleType
                });
            }

            this.setState({
                design: designId,
            });
        }
    }

    private onVehicleTypeChanged(vehicleTypeId: string): void {
        const {periodType, options, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate, size, design, brand} = this.state;

        if (options) {
            const selectedVehicleType = vehicleTypeId === OPTIONS_ALL ? undefined : options.vehicleTypeOptions.find(c => c.id === vehicleTypeId);
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    vehicleType: selectedVehicleType?.id,
                    functionalLocationSelection,
                    fromDate,
                    toDate,
                    size,
                    design,
                    brand
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    vehicleType: selectedVehicleType?.id,
                    period,
                    locations: functionalLocationSelection!.value,
                    size,
                    design,
                    brand
                });
            }

            this.setState({
                vehicleType: vehicleTypeId,
            });
        }
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate, brand, size, design, vehicleType} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    fromDate,
                    toDate,
                    brand,
                    size,
                    design,
                    vehicleType
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    period,
                    locations: controlValue.value,
                    brand,
                    size,
                    design,
                    vehicleType
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {
            onScheduledParametersChanged
        } = this.props;
        const {
            functionalLocationSelection,
            brand,
            size,
            design,
            vehicleType
        } = this.state;

        onScheduledParametersChanged({
            period,
            locations: functionalLocationSelection!.value,
            brand,
            size,
            design,
            vehicleType
        });

        this.setState({
            period,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {
                functionalLocationSelection, toDate, brand,
                size,
                design,
                vehicleType
            } = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
                brand,
                size,
                design,
                vehicleType
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {
                functionalLocationSelection, fromDate, brand,
                size,
                design,
                vehicleType
            } = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
                brand,
                size,
                design,
                vehicleType
            });
        });
    }
}

const mapStateToProps = ({authentication, reports}: ApplicationState) => ({
    options: reports.tirePerformanceOptions,
    fleetCustomer: authentication.fleetCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchTirePerformanceOptions: (fleetCustomerId: string) => dispatch(fetchTirePerformanceOptionsRequest(fleetCustomerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TirePerformanceComponent));

interface PropsFromState {
    options?: TirePerformanceOptions;
    fleetCustomer?: FleetCustomer;
}

interface PropsFromDispatch {
    dispatchFetchTirePerformanceOptions: typeof fetchTirePerformanceOptionsRequest;
}

interface OwnProps {
    periodType: ReportPeriodType;
    fleetCustomer?: FleetCustomerWithConfiguration;
    parameters?: TirePerformanceScheduledReportParameters;
    onCustomParametersChanged: (parameters: TirePerformanceCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: TirePerformanceScheduledReportParameters) => void;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps;

interface OwnState {
    period: ScheduledReportPeriod;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
    fromDate?: Date;
    toDate?: Date;
    brand?: string;
    size?: string;
    design?: string;
    vehicleType?: string;
}

type AllState = OwnState;
