import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WithTranslation, withTranslation } from 'react-i18next';
import {Component} from 'react';
import MenuButton from '../../../../components/header-menu/menu-button';
import styles from './admin-page-header.module.scss';

class AdminPageHeader extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const { title, onBack,t } = this.props;
        return (
            <div className={styles.header}>
                <div className={styles.titlePanel}>
                    <div className={styles.title}>{title}</div>
                    {onBack ? (
                        <div className={styles.backLink} onClick={() => onBack()}>
                            <FontAwesomeIcon className={styles.backIcon} icon={faChevronLeft} /> {t('Back')}
                        </div>
                    ) : null}
                </div>
                <MenuButton />
            </div>
        );
    }
}

export default withTranslation()(AdminPageHeader);

interface OwnProps {
    title: string;
    onBack?: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {}

type AllState = OwnState;
