import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './job-types-distribution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import WidgetLoading from '../widget-loading';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {JobTypesDistributionData} from '../../../../store/analytics/types/job-types-distribution.types';
import {DateRangeWithFunctionalLocationsRequest, fetchJobTypesDistributionRequest} from '../../../../store/analytics';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import {
    buildChartDataWithInspections,
    buildChartDataWithoutInspections,
    jobCountField,
    jobTypeField,
    noInspectionCountField,
    scheduledInspectionCountField,
    typeField,
    unscheduledInspectionCountField,
} from './chart-data-adapter';
import {createDateRangeWithFunctionalLocationsRequest, hasInspectionPeriod} from '../utils/widget-helper';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart, createXYChart,
    dynamicallySetColorBySeriesAndType,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {
    colorBlue2,
    colorBlue4,
    colorBlue5,
    colorBlue8,
} from '../utils/chart-colors';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {InspectionType, JobType} from '../../../../store/jobs/types';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {getInspectionTypeLabel} from '../../../../utils/translations/job-type-translation-helpert';

am4core.useTheme(am4themes_animated);

class JobTypesDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, JobTypesDistributionData[]> {

    private chartId: string;

    private chartColors: Map<string, Color>;

    private readonly OPTION_EXCL_INSPECTION = {id: '1', key: DropdownOptionKey.BreakdownVsRegular};

    private readonly OPTION_INCL_INSPECTION = {id: '2', key: DropdownOptionKey.WithInspectionStatus};

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.chartColors = new Map<string, Color>();
        this.chartColors.set(JobType.Breakdown, am4core.color(colorBlue4));
        this.chartColors.set(JobType.Regular, am4core.color(colorBlue8));
        this.chartColors.set(noInspectionCountField + JobType.Breakdown, am4core.color(colorBlue2));
        this.chartColors.set(scheduledInspectionCountField + JobType.Breakdown, am4core.color(colorBlue5));
        this.chartColors.set(unscheduledInspectionCountField + JobType.Breakdown, am4core.color(colorBlue8));
        this.chartColors.set(noInspectionCountField + JobType.Regular, am4core.color(colorBlue2));
        this.chartColors.set(scheduledInspectionCountField + JobType.Regular, am4core.color(colorBlue5));
        this.chartColors.set(unscheduledInspectionCountField + JobType.Regular, am4core.color(colorBlue8));

        this.state = {
            options: [this.OPTION_EXCL_INSPECTION, this.OPTION_INCL_INSPECTION],
            selectedOption: this.OPTION_EXCL_INSPECTION,
        };
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Job Types Distribution')}
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading />}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchJobTypesDistributionRequest(createDateRangeWithFunctionalLocationsRequest(this.props.controlsSnapshot));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    private onSelect(option: DropdownOption) {
        this.setState({selectedOption: option}, () => {
            this.redraw(this.props.data);
        });
    }

    protected createChart(data: JobTypesDistributionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = jobTypeField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Jobs');

        if (this.state.selectedOption === this.OPTION_EXCL_INSPECTION) {
            this.addFlatSeries(chart, jobCountField);
            chart.data = buildChartDataWithoutInspections(data, this.props.t);
        } else {
            this.addStackedSeries(chart, unscheduledInspectionCountField, InspectionType.Unscheduled);
            this.addStackedSeries(chart, scheduledInspectionCountField, InspectionType.Scheduled);
            this.addStackedSeries(chart, noInspectionCountField);
            chart.data = buildChartDataWithInspections(data, this.props.t);
        }

        return chart;
    }

    public addFlatSeries(chart: any, seriesField: string) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = seriesField;
        series.dataFields.categoryX = jobTypeField;
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${this.props.t('jobs')}[/]`;
        series.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
        dynamicallySetColorByType(series, typeField, (type: string) => this.chartColors.get(type)!);
    }

    public addStackedSeries(chart: any, seriesField: string, inspectionType?: InspectionType) {
        const stackedSeries = chart.series.push(new am4charts.ColumnSeries());
        stackedSeries.name = seriesField;
        stackedSeries.label = getInspectionTypeLabel(this.props.t, inspectionType);
        stackedSeries.stacked = true;
        stackedSeries.dataFields.valueY = seriesField;
        stackedSeries.dataFields.categoryX = jobTypeField;
        stackedSeries.columns.template.tooltipText = `{label}: [bold]{valueY} ${this.props.t('jobs')}[/]`;
        stackedSeries.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(stackedSeries);
        addStrokeToColumnChart(stackedSeries);
        dynamicallySetColorBySeriesAndType(stackedSeries, typeField, (seriesName: string, type: string) => this.chartColors.get(seriesName + type)!);
    }
}

const mapStateToProps = ({analytics}: ApplicationState) => ({
    data: analytics.jobTypesDistributionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchJobTypesDistributionRequest:(request: DateRangeWithFunctionalLocationsRequest) => dispatch(fetchJobTypesDistributionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobTypesDistributionWidget));

interface PropsFromState {
    data: JobTypesDistributionData[];
    fleetCustomerId: string;
}

interface PropsFromDispatch {
    fetchJobTypesDistributionRequest: typeof fetchJobTypesDistributionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
