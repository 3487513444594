import {TFunction} from 'i18next';
import {InspectionType, JobType} from '../../store/jobs';

export function getJobTypeLabel(type: JobType, t: TFunction): string {
    switch (type) {
        case JobType.Breakdown: {
            return t('jobType_Breakdown');
        }
        case JobType.Regular: {
            return t('jobType_Regular');
        }
        default:
            throw new Error(`Job type ${type} is not supported.`);
    }
}

export function getInspectionTypeLabel(t: TFunction, type?: InspectionType): string {
    if (!type) {
        return t('inspectionType_NoInspection');
    }

    switch (type) {
        case InspectionType.Scheduled: {
            return t('inspectionType_Scheduled');
        }
        case InspectionType.Unscheduled: {
            return t('inspectionType_Unscheduled');
        }
        default:
            throw new Error(`Inspection type ${type} is not supported.`);
    }
}
