import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './master-fleet-list.module.scss';
import {
    ActiveStatus,
    MasterFleetListCustomReportParameters,
    MasterFleetListScheduledReportParameters,
    ReportPeriodType,
    SortColumn,
} from '../../../../../../store/reports';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import LocationSelectorComponent from '../../../../components/location-selector.component';

class MasterFleetListComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let sortOrder = SortColumn.byLicensePlate;
        let locations: FunctionalLocationSelection[] = [];
        let activeStatus = ActiveStatus.All;
        if (parameters && parameters.sortColumn) {
            sortOrder = parameters.sortColumn;
        }
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.activeStatus) {
            activeStatus = parameters.activeStatus;
        }

        this.state = {
            selectedSortOrder: sortOrder,
            selectedActiveStatus: activeStatus,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {selectedSortOrder, functionalLocationSelection, selectedActiveStatus} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.info}>{t('Select a customer')}</div>
                    <LocationSelectorComponent
                        defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                        onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                    <div className={styles.formRow}>
                        <div className={styles.formRowSection}>
                            <div className={styles.info}>{t('Select Vehicle Status')}</div>
                            <div className={styles.select}>
                                <label htmlFor="allVehiclesActivation">
                                    <input type="radio" id="allVehiclesActivation"
                                           checked={selectedActiveStatus === ActiveStatus.All}
                                           onChange={() => this.onActiveStatusChanged(ActiveStatus.All)}
                                    />{t('Show all vehicles')}
                                </label>
                                <label htmlFor="vehiclesActivatedOnly">
                                    <input type="radio" id="vehiclesActivatedOnly"
                                           checked={selectedActiveStatus === ActiveStatus.Activated}
                                           onChange={() => this.onActiveStatusChanged(ActiveStatus.Activated)}
                                    />{t('Show only activated vehicles')}
                                </label>
                                <label htmlFor="vehiclesDeactivatedOnly">
                                    <input type="radio" id="vehiclesDeactivatedOnly"
                                           checked={selectedActiveStatus === ActiveStatus.Deactivated}
                                           onChange={() => this.onActiveStatusChanged(ActiveStatus.Deactivated)}
                                    />{t('Show only deactivated vehicles')}
                                </label>
                            </div>
                        </div>
                        <div className={styles.formRowSection}>
                            <div className={styles.info}>{t('Select Sort Order')}</div>
                            <div className={styles.select}>
                                <label htmlFor="byLicensePlate">
                                    <input type="radio" id="byLicensePlate"
                                           checked={selectedSortOrder === SortColumn.byLicensePlate}
                                           onChange={() => this.onSortOrderChanged(SortColumn.byLicensePlate)}
                                    />{t('By License Plate')}
                                </label>
                                <label htmlFor="byChassisNumber">
                                    <input type="radio" id="byChassisNumber"
                                           checked={selectedSortOrder === SortColumn.byChassisNumber}
                                           onChange={() => this.onSortOrderChanged(SortColumn.byChassisNumber)}
                                    />{t('By Chassis Number')}
                                </label>
                                <label htmlFor="byFleetNumber">
                                    <input type="radio" id="byFleetNumber"
                                           checked={selectedSortOrder === SortColumn.byFleetNumber}
                                           onChange={() => this.onSortOrderChanged(SortColumn.byFleetNumber)}
                                    />{t('By Fleet Number')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, selectedSortOrder, selectedActiveStatus} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    selectedSortOrder,
                    selectedActiveStatus,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    locations: controlValue.value,
                    sortColumn: selectedSortOrder,
                    activeStatus: selectedActiveStatus
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onActiveStatusChanged(status: ActiveStatus): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, selectedSortOrder} = this.state;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                functionalLocationSelection,
                selectedSortOrder,
                selectedActiveStatus: status,
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                locations: functionalLocationSelection!.value,
                sortColumn: selectedSortOrder,
                activeStatus: status
            });
        }

        this.setState({
            selectedActiveStatus: status,
        })
    }

    private onSortOrderChanged(sortOrder: SortColumn): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, selectedActiveStatus} = this.state;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                functionalLocationSelection,
                selectedSortOrder: sortOrder,
                selectedActiveStatus,
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                locations: functionalLocationSelection!.value,
                sortColumn: sortOrder,
                activeStatus: selectedActiveStatus
            });
        }

        this.setState({
            selectedSortOrder: sortOrder,
        });
    }
}

export default withTranslation()(MasterFleetListComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: MasterFleetListScheduledReportParameters;
    onCustomParametersChanged: (parameters: MasterFleetListCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: MasterFleetListScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    selectedSortOrder: SortColumn;
    selectedActiveStatus: ActiveStatus;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
}

type AllState = OwnState;
