import {ApiResult} from '../types/api-result';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {BillingDocument, Invoice} from '../store/billing';
import {performBase64Download} from '../utils/download-helper';

class BillingApi {

    public static async getBillingDocument(fleetCustomerId: string, billingDocumentId: string): Promise<ApiResult<BillingDocument>> {
        const data = await Http.get<BillingDocument>(ApiUrl.BillingDocument,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addBillingDocumentId(billingDocumentId).build());
        return {data};
    }

    public static async downloadInvoice(fleetCustomerId: string, billingDocumentReference: string): Promise<boolean> {
        const result = await Http.get<Invoice>(ApiUrl.Invoice,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addBillingDocumentReference(billingDocumentReference).build());
        if (result.available) {
            performBase64Download(result.data, 'pdf', `Invoice - ${billingDocumentReference}.pdf`);
            return true
        } else {
            return false;
        }
    }
}

export default BillingApi;
