import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import am4lang_de_DE from '@amcharts/amcharts4/lang/de_DE';

export function getChartLanguage(language: string): any {
    switch (language) {
        case 'en':
            return am4lang_en_US;
        case 'fr':
            return am4lang_fr_FR;
        case 'de':
            return am4lang_de_DE;
        default:
            return am4lang_en_US;
    }
}
