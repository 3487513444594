import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {
    DateRangeWithFunctionalLocationsAndLanguageRequest,
} from '../analytics';
import {LocalLanguageType, Paged, RequestStatus, SortDirection} from '../shared/types';

export enum JobsActionTypes {
    FETCH_JOBS_REQUEST = '@@jobs/FETCH_JOBS_REQUEST',
    FETCH_JOBS_SUCCESS = '@@jobs/FETCH_JOBS_SUCCESS',

    FETCH_TIRE_SERVICE_REQUEST = '@@jobs/FETCH_TIRE_SERVICE_REQUEST',
    FETCH_TIRE_SERVICE_SUCCESS = '@@jobs/FETCH_TIRE_SERVICE_SUCCESS',
    FETCH_TIRE_SERVICE_FAILURE = '@@jobs/FETCH_TIRE_SERVICE_FAILURE',
}

export type JobsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface JobContract {
    id: string;
    startDate: string;
    endDate: string;
    isEjob: boolean;
    ejobId:string;
    vehicleId: string;
    jobType: JobType;
    inspectionType: InspectionType;
    depotId: string;
    depotName: string;
    serviceProviderId: string;
    serviceProviderName: string;
    billingDocumentId: string;
    licensePlate: string;
    billingRef: string;
    jobReference: string;
    fleetReference: string;
}

export interface Job {
    id: string;
    startDate: Date;
    endDate: Date;
    isEjob: boolean;
    ejobId:string;
    vehicleId: string;
    jobType: JobType;
    inspectionType: InspectionType;
    depotId: string;
    depotName: string;
    serviceProviderId: string;
    serviceProviderName: string;
    billingDocumentId: string;
    licensePlate: string;
    billingRef: string;
    jobReference: string;
    fleetReference: string;
}

export interface JobsExplorerRequest extends DateRangeWithFunctionalLocationsAndLanguageRequest {
    page: number;
    itemsPerPage: number;
    sort: JobsSort;
    serviceProviderNameContains?: string;
    licensePlateContains?: string;
    jobReferenceContains?: string;
    depotNameContains?: string;
    fleetReferenceContains?: string;
    billingDocumentIdContains?: string;
    jobTypeExcludedOptions?:JobType[];
    isEjobExcludedOptions?:boolean[];
}

export interface JobsState {
    readonly jobs?: Paged<Job>;
    readonly inProgress: boolean;

    readonly tireService?: TireServiceDetails;
    readonly tireServiceRequestStatus?: RequestStatus;
}

export enum JobType {
    Breakdown = 'Breakdown Service',
    Regular = 'Regular Service',
}

export enum InspectionType {
    Scheduled = 'scheduled',
    Unscheduled = 'unscheduled',
}

export interface JobsSort {
    column: JobsSortColumn;
    direction: SortDirection;
}

export enum JobsSortColumn {
    None = 'none',
    Date = 'date',
    JobReference = 'jobReference',
    IsEjob = 'isEjob',
    JobType = 'jobType',
    LicensePlate = 'licensePlate',
    DepotName = 'depotName',
    ServiceProviderName = 'serviceProviderName',
    FleetRef = 'fleetRef',
    BillingRef = 'billingRef',
}

export interface TireServiceDetailsRequest {
    jobId: string;
    fleetCustomerId:string;
    language:LocalLanguageType
}

export interface TireServiceDetails {
    reference: string;
    vehicleId: string;
    licensePlate: string;
    fleetNumber: string;
    odometerReading: string;
    serviceProvider: string;
    jobType: JobType;
    jobDate: string;
    isEjob: boolean;
    ejobId:string;
    hasAttachments:number;
    taskList: TaskList[];
    damageDetails: DamageDetail[];
}

export interface TaskList {
    axle: string;
    wheel: string;
    description: string;
    serialNumber: string;
    taskReason: string;
    treadDepth: number;
    pressureInitial: number;
    pressureAdjust: number;
}

export interface DamageDetail {
    serialNumber: string;
    damageArea: string;
    damageType: string;
    treadDepth: number;
}

export interface EjobSheet {
    available: boolean;
    data: string;
}

export interface JobAttachments {
    available: boolean;
    data: JobAttachmentData;
}

export interface JobAttachmentData{
    documents: JobAttachmentDocument[];
}

export interface JobAttachmentDocument {
    name: string;
    base64Content: string;
}

