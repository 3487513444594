import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import styles from './tire-stock.module.scss';
import CompanySelectorComponent, {COMPANIES_ALL} from '../../../../components/company-selector.component';
import {
    fetchTireStockCompaniesRequest,
    fetchTireStockReportRequest,
    ReportPeriodType,
    TireStockCompany, TireStockCustomReportParameters,
    TireStockScheduledReportParameters,
} from '../../../../../../store/reports';
import {FleetCustomer} from '../../../../../../store/fleet-customers';
import {ApplicationState} from '../../../../../../store';

class TireStockComponent extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchFetchTireStockCompanies, companies, fleetCustomer, parameters} = this.props;

        if (fleetCustomer && !companies) {
            dispatchFetchTireStockCompanies(fleetCustomer.id);
        }

        let selectedCompanyNr = COMPANIES_ALL;
        if (parameters && parameters.companies && parameters.companies.length > 0) {
            selectedCompanyNr = parameters.companies[0].companyNr
        }
        this.state = {
            selectedCompanyNr,
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>) {
        const {dispatchFetchTireStockCompanies, companies, fleetCustomer} = this.props;

        if (!companies && fleetCustomer && prevProps.fleetCustomer !== fleetCustomer) {
            dispatchFetchTireStockCompanies(fleetCustomer.id);
        }
    }

    public render(): JSX.Element {
        const {periodType, t, companies} = this.props;
        const {selectedCompanyNr} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.info}>{t('Select a location')}</div>
                    <CompanySelectorComponent
                        defaultCompany={companies?.find(c => c.companyNr === selectedCompanyNr)}
                        companies={companies}
                        companyChanged={(comp => this.onCompanyChanged(comp))}/>
                </div>
            </div>
        );
    }

    private onCompanyChanged(companyNr: string): void {
        const {periodType, companies, onCustomParametersChanged, onScheduledParametersChanged} = this.props;

        if (companies && companyNr) {
            const selectedCompany = companyNr === COMPANIES_ALL ? undefined : companies.find(c => c.companyNr === companyNr);
            const selectedCompanies = selectedCompany ? [selectedCompany] : [];
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    companies: selectedCompanies
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    companies: selectedCompanies
                });
            }

            this.setState({
                selectedCompanyNr: companyNr,
            });
        }
    }
}

const mapStateToProps = ({authentication, reports}: ApplicationState) => ({
    companies: reports.tireStockCompanies,
    fleetCustomer: authentication.fleetCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchTireStockCompanies: (fleetCustomerId: string) => dispatch(fetchTireStockCompaniesRequest(fleetCustomerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TireStockComponent));

interface PropsFromState {
    companies?: TireStockCompany[];
    fleetCustomer?: FleetCustomer;
}

interface PropsFromDispatch {
    dispatchFetchTireStockCompanies: typeof fetchTireStockCompaniesRequest;
    dispatchFetchTireStockReport: typeof fetchTireStockReportRequest;
}

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: TireStockScheduledReportParameters;
    onCustomParametersChanged: (parameters: TireStockCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: TireStockScheduledReportParameters) => void;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps;

interface OwnState {
    selectedCompanyNr?: string;
}

type AllState = OwnState;
