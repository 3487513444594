import { faCars, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {faDatabase, faFileAlt,faCalendarAlt} from "@fortawesome/pro-regular-svg-icons";
import { RouteUrl } from '../../routes';
import { ApplicationState } from '../../store';
import { Permission, User } from '../../store/authentication';
import * as layoutActions from '../../store/layout/actions';
import { conditionalClassLister } from '../../utils/class-helpers';
import { hasPermission } from '../../utils/user-helper';
import styles from './admin-menu.module.scss';
import AdminPageHeader from './components/admin-page-header/admin-page-header';

class AdminMenuPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const { dispatchToggleSidebar } = this.props;
        dispatchToggleSidebar(false);
    }

    public render(): JSX.Element {
        const { dispatchNavigateTo, user, t } = this.props;

        const userManagementTileClasses = conditionalClassLister(styles)({
            adminTile: true,
            isDisabled: !hasPermission(user, Permission.ManageUsers)&&!hasPermission(user, Permission.ViewUsers),
        });

        const fleetManagementTileClasses = conditionalClassLister(styles)({
            adminTile: true,
            isDisabled: !hasPermission(user, Permission.ManageFleets)&&!hasPermission(user, Permission.ViewFleets),
        });

        const reportManagementTileClasses = conditionalClassLister(styles)({
            adminTile: true,
            isDisabled: !hasPermission(user, Permission.ManageReports)&& !hasPermission(user, Permission.ViewReports),
        });

        const scheduledReportManagementTileClasses = conditionalClassLister(styles)({
            adminTile: true,
            isDisabled: !hasPermission(user, Permission.ManageScheduledReports)&&!hasPermission(user, Permission.ViewScheduledReports),
        });

        return (
            <div className={styles.container}>
                <AdminPageHeader title={t('Administration Hub')} />
                <div className={styles.adminTiles}>
                    <div
                        className={userManagementTileClasses}
                        onClick={() => this.navigateToUserManagement()}>
                        <FontAwesomeIcon className={styles.adminIcon} icon={faUsers} />
                        <div>{t('User Management')}</div>
                    </div>
                    <div
                        className={fleetManagementTileClasses}
                        onClick={() => this.navigateToFleetManagement()}>
                        <div>
                            <FontAwesomeIcon className={styles.adminIcon} icon={faCars} />
                        </div>
                        <div>{t('Fleet Management')}</div>
                    </div>
                    <div
                        className={reportManagementTileClasses}
                        onClick={() => this.navigateToReportManagement()}>
                        <div>
                            <FontAwesomeIcon className={styles.adminIcon} icon={faFileAlt} />
                        </div>
                        <div>{t('Report Management')}</div>
                    </div>
                    <div
                        className={scheduledReportManagementTileClasses}
                        onClick={() => this.navigateToScheduledReportManagement()}>
                        <div>
                            <FontAwesomeIcon className={styles.adminIcon} icon={faCalendarAlt} />
                        </div>
                        <div>{t('Scheduled Report Management')}</div>
                    </div>
                    <div
                        className={styles.adminTile}
                        onClick={() => dispatchNavigateTo({ pathname: RouteUrl.DataStatus })}>
                        <div>
                            <FontAwesomeIcon className={styles.adminIcon} icon={faDatabase} />
                        </div>
                        <div>{t('Data Status')}</div>
                    </div>
                </div>
            </div>
        );
    }

    private navigateToUserManagement() {
        const {user, dispatchNavigateTo} = this.props;

        if (hasPermission(user, Permission.ManageUsers)||hasPermission(user, Permission.ViewUsers)) {
            dispatchNavigateTo({ pathname: RouteUrl.UserManagement });
        }
    }

    private navigateToFleetManagement() {
        const {user, dispatchNavigateTo} = this.props;

        if (hasPermission(user, Permission.ManageFleets)||hasPermission(user, Permission.ViewFleets)) {
            dispatchNavigateTo({ pathname: RouteUrl.FleetCustomersManagement });
        }
    }

    private navigateToReportManagement() {
        const {user, dispatchNavigateTo} = this.props;

        if (hasPermission(user, Permission.ManageReports)||hasPermission(user, Permission.ViewReports)) {
            dispatchNavigateTo({ pathname: RouteUrl.ReportManagement });
        }
    }

    private navigateToScheduledReportManagement() {
        const {user, dispatchNavigateTo} = this.props;

        if (hasPermission(user, Permission.ManageScheduledReports)||hasPermission(user, Permission.ViewScheduledReports)) {
            dispatchNavigateTo({ pathname: RouteUrl.ScheduledReportManagement });
        }
    }
}

const mapStateToProps = ({ authentication }: ApplicationState) => ({
    user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminMenuPage));

interface PropsFromState {
    user: User;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
}

interface OwnProps {}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {}

type AllState = OwnState;
