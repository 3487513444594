import {BaseCurrencyType, DateRangeWithFunctionalLocationsRequest} from '../types';

export interface BillingDistributionRequest extends DateRangeWithFunctionalLocationsRequest {
    currencyType:BaseCurrencyType;
}

export interface BillingItemsDistributionData {
    billingItemType: BillingItemType;
    billingItemDetailsType: BillingItemDetailsType;
    netPrice: number;
}

export enum BillingItemType {
    Services = 'services',
    Materials = 'materials',
}

export enum BillingItemDetailsType {
    NewTires = 'newTires',
    RetreadTires = 'retreadTires',
    RegularServices = 'regularServices',
    BreakdownServices = 'breakdownServices',
}
