import {Reducer} from 'redux';
import {SoldToAction, SoldToActionTypes, SoldToState} from './types';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';

function initialState(): SoldToState {
    return {
        soldToMappingsRequestStatus: new RequestStatus(RequestStatusType.New),
    };
}

const reducer: Reducer<SoldToState, SoldToAction> = (state = initialState(), action): SoldToState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_REQUEST: {
            return {...state, soldToMappingsRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_SUCCESS: {
            return {
                ...state,
                soldToMappingsRequestStatus: new RequestStatus(RequestStatusType.Success),
                soldToMappings: {...action.payload}
            };
        }
        case SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_FAILURE: {
            return {...state, soldToMappingsRequestStatus: new RequestStatus(RequestStatusType.Failed)}
        }
        case SoldToActionTypes.FETCH_SOLD_TOS_REQUEST: {
            return {...state};
        }
        case SoldToActionTypes.FETCH_SOLD_TOS_SUCCESS: {
            return {...state, soldToWithDivisions: action.payload};
        }

        default: {
            return state;
        }
    }
};

export {reducer as soldToReducer};
export {initialState as initialSoldToState};
