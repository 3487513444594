import { TFunction } from 'i18next';
import { TireBrandDistributionData } from '../../../../store/analytics/types/tire-brand-distribution.types';
import {BrandType} from "../../../../store/analytics/types/brand-distribution.types";

export const brandField = 'brand';
export const unitsField = 'tireCount';
export const ownBrandField = 'ownBrand';

export function buildChartData(data: TireBrandDistributionData[], translate: TFunction): any[] {
    const result: any[] = [];
    data.forEach((row) => {
        const r = {};
        r[brandField] = row.brand;
        r[unitsField] = row.tireCount;
        if (row.brand === 'UNKNOWN') {
            r[ownBrandField] = BrandType.Unknown;
        } else {
            r[ownBrandField] = row.ownBrand ? BrandType.Goodyear : BrandType.NonGoodyear;
        }
        result.push(r);
    });

    return result.reverse();
}
