import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createMaxDateTodayWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {InspectionHistogramRequest} from '../../../../store/analytics/types/inspection-histogram.types';

export function buildRequest(snapshot: ControlsSnapshot, bucketSize: number, bucketCount: number): InspectionHistogramRequest {
    return Object.assign(
        createMaxDateTodayWithFunctionalLocationsRequest(snapshot), {
            bucketSize,
            bucketCount,
        });
}
