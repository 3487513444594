import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {Location, LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './tire-history-report.module.scss';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import * as layoutActions from '../../../../store/layout/actions';
import {FleetCustomer} from '../../../../store/fleet-customers';
import {FleetCustomerMatchParameters} from '../../../../types/fleet-customer';
import {
    fetchTireHistoryReportRequest,
    ReportPeriodType,
    TireHistoryCustomReportParameters,
    TireHistoryReportRequest,
} from '../../../../store/reports';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import TireHistoryComponent
    from '../../report-scheduler/popup/report-data-components/tire-history/tire-history.component';
import {buildReturnUrlFromQueryParameters, QueryParameter} from '../../../../utils/query-parameter-helpers';

class TireHistoryReportPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {
            serialNumber: undefined,
            initialSerialNumber:undefined,
        }
    }

    public componentDidMount(): void {
        const {currentLocation} = this.props;
        this.handleQueryParameters(currentLocation.search);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {currentLocation} = this.props;
        this.handleQueryParameters(currentLocation.search);
    }

    public render(): JSX.Element {
        const {t, location, reportRequestStatus} = this.props;
        const {serialNumber, initialSerialNumber} = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.TireHistory);

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div/>}/>
                <div className={styles.pageContent}>
                    <TireHistoryComponent
                        initialSerialNumber={initialSerialNumber}
                        periodType={ReportPeriodType.Custom}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {
                        }}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!serialNumber || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!serialNumber || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private handleQueryParameters(queryParameters: string): void {
        const {initialSerialNumber} = this.state;
        const map = new URLSearchParams(queryParameters);
        if (initialSerialNumber === undefined && map.has(QueryParameter.SerialNumber)) {
            const serialNumberFromUrl = map.get(QueryParameter.SerialNumber)!;
            this.setState({initialSerialNumber: serialNumberFromUrl})
        }
    }

    private onParametersChanged(parameters: TireHistoryCustomReportParameters) {
        this.setState({
            serialNumber: parameters.serialNumber,
        });
    }


    private download(fileType:FileType) {
        const {serialNumber} = this.state;
        const {dispatchFetchTireHistoryReport} = this.props
        if (serialNumber) {
            const request: TireHistoryReportRequest = {
                fileType,
                serialNumber
            }

            dispatchFetchTireHistoryReport(request);
        }
    }

    private goBack(): void {
        const {dispatchNavigateTo, fleetCustomer,location} = this.props;
        const queryParameters = new URLSearchParams(location.search);
        if (queryParameters.has(QueryParameter.ReturnUrl)) {
            dispatchNavigateTo(buildReturnUrlFromQueryParameters(queryParameters));
        } else {
            dispatchNavigateTo({pathname: `/${fleetCustomer?.id}${RouteUrl.Reports}`});
        }
    }
}

// eslint-disable-next-line
const mapStateToProps = ({authentication, reports, router}: ApplicationState) => ({
    currentLocation: router.location,
    fleetCustomer: authentication.fleetCustomer,
    reportRequestStatus: reports.tireHistoryReportRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchTireHistoryReport: (request: TireHistoryReportRequest) => dispatch(fetchTireHistoryReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TireHistoryReportPage));

interface PropsFromState {
    currentLocation: Location;
    fleetCustomer?: FleetCustomer;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchTireHistoryReport: typeof fetchTireHistoryReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    initialSerialNumber?: string;
    serialNumber?: string;
}

type AllState = OwnState;
