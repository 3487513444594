export enum DropdownOptionKey {
    ByCompany = 'byCompany',
    ByDivision = 'byDivision',
    ByDepot = 'byDepot',
    NoGrouping = 'noGrouping',
    WithInspectionStatus = 'includeInspections',
    BreakdownVsRegular = 'breakdownVsRegular',
    ServicesVsTires = 'servicesVsTires',
    IncludeDetails = 'includeDetails',
    ByVehicleType = 'byVehicleType',
    ByNetPrice = 'byNetPrice',
    ByNewTires = 'byNewTires',
    ByReusableTires = 'byReusableTires',
    ByRetreadTires = 'byRetreadTires',
    ByJobCount = 'byJobCount',
}
