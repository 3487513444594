import {JobsEvolutionData} from '../../../../store/analytics/types/jobs-evolution.types';
import {formatStringInBrusselsTime} from '../../../../utils/date-helper';

export const dateField = 'date';
export const jobCountField = 'jobCount';
export const dateFormatMoment = 'YYYY-MM-DD';
export const dateFormatAmcharts = 'yyyy-MM-dd';

export function buildChartData(data: JobsEvolutionData[]): any[] {
    const result: any[] = [];

    data.forEach((row) => {
        const datapoint = {};
        datapoint[jobCountField] = row.jobCount;
        datapoint[dateField] = formatStringInBrusselsTime(row.date, dateFormatMoment);
        result.push(datapoint);
    });
    return result;
}
