import {AlertTypes, KpiTypes} from '../../../store/analytics';
import {WidgetType} from '../../../store/dashboard';

export interface WidgetCatalogItem {
    name: string;
    type: WidgetType;

    configuration: any;
    defaultWidth: number;
    defaultHeight: number;
}

export interface WidgetCatalogGroup {
    id: string;
    name: string;
    widgets: WidgetCatalogItem[];
}

export const allCatalogGroups: WidgetCatalogGroup[] = [
    {
        id: 'graphs',
        name: 'Graphs',
        widgets: [
            {
                name: 'Fleet Size Evolution',
                type: WidgetType.VehiclesEvolution,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Vehicle Distribution',
                type: WidgetType.VehicleTypesDistribution,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Job Types Distribution',
                type: WidgetType.JobTypesDistribution,
                configuration: {},
                defaultWidth: 7,
                defaultHeight: 5,
            },
            {
                name: 'Jobs Types Evolution',
                type: WidgetType.JobsEvolution,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Invoice Items Distribution',
                type: WidgetType.BillingItemsDistribution,
                configuration: {},
                defaultWidth: 7,
                defaultHeight: 5,
            },
            {
                name: 'Invoice Items Evolution',
                type: WidgetType.BillingItemsEvolution,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Current Inspection Status',
                type: WidgetType.InspectionStatus,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 6,
            },
            {
                name: 'Fleet Movement',
                type: WidgetType.FleetMovement,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Service Providers Top 20',
                type: WidgetType.TopServiceProviders,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 12,
            },
            {
                name: 'Inspection Histogram',
                type: WidgetType.InspectionHistogram,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 12,
            },
            {
                name: 'Tire Fitments Brand Distribution',
                type: WidgetType.TireFitmentsBrandDistribution,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
            {
                name: 'Top 15 Tire Fitments by Tire Pattern',
                type: WidgetType.TopTireFitmentsPatterns,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
            {
                name: 'Tires Fitted Brand Distribution',
                type: WidgetType.TiresFittedBrandDistribution,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
            {
                name: 'Top 15 Tires Fitted by Tire Pattern',
                type: WidgetType.TopTiresFittedPatterns,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
            {
                name: 'Top 20 Retread Tires Fitted By Service Provider',
                type: WidgetType.TopRetreadTiresFittedByServiceProvider,
                configuration: {},
                defaultWidth:6,
                defaultHeight: 15,
            },
            {
                name: 'Top 20 Reusable Tires Fitted By Service Provider',
                type: WidgetType.TopReusableTiresFittedByServiceProvider,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            }
        ],
    },
    {
        id: 'kpis',
        name: 'KPIs',
        widgets: [
            {
                name: 'Fleet Size',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalFleetSize},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Fleet Growth',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.FleetGrowth},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Invoiced',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalInvoiced},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Invoice Trend',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.YoYFinancialComparison},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Inspection',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalInspections},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Breakdown Services',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalBreakdowns},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Job Count',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalJobCount},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Average Acceptance Time',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.JobsAvgAcceptanceTime},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Inspection Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.InspectionRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Avoidable Breakdowns - Totals',
                type: WidgetType.AvoidableBreakdowns,
                configuration: {},
                defaultWidth: 5,
                defaultHeight: 3,
            },
            {
                name: 'Avoidable Breakdowns - Cost Saving by Breakdown',
                type: WidgetType.CostSavingByBreakdown,
                configuration: {},
                defaultWidth: 5,
                defaultHeight: 3,
            },
            {
                name: 'Avoidable Breakdowns - Monthly Saving Potential',
                type: WidgetType.MonthlySavingPotential,
                configuration: {},
                defaultWidth: 5,
                defaultHeight: 3,
            },
            {
                name: 'Retread Ratio',
                type: WidgetType.RetreadRatio,
                configuration: {},
                defaultWidth: 3,
                defaultHeight: 6,
            },
            {
                name: 'Underinflation Effect',
                type: WidgetType.UnderInflationEffect,
                configuration: {},
                defaultWidth: 5,
                defaultHeight: 6,
            },
        ],
    },
    {
        id: 'alerts',
        name: 'Alerts',
        widgets: [
            {
                name: 'Breakdown Frequency',
                type: WidgetType.AlertBox,
                configuration: {type: AlertTypes.BreakdownRatio},
                defaultWidth: 4,
                defaultHeight: 4,
            },
            {
                name: 'Service Frequency',
                type: WidgetType.AlertBox,
                configuration: {type: AlertTypes.ServiceFrequency},
                defaultWidth: 4,
                defaultHeight: 4,
            },
            {
                name: 'Retread Ratio',
                type: WidgetType.AlertBox,
                configuration: {type: AlertTypes.RetreadRatio},
                defaultWidth: 4,
                defaultHeight: 4,
            },
            {
                name: 'Tread Depth Distribution',
                type: WidgetType.AlertBox,
                configuration: {type: AlertTypes.TreadDepth},
                defaultWidth: 4,
                defaultHeight: 4,
            },
            {
                name: 'Tire Pressure Distribution',
                type: WidgetType.AlertBox,
                configuration: {type: AlertTypes.TirePressure},
                defaultWidth: 4,
                defaultHeight: 4,
            }
        ],
    },
];
