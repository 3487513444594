import {DateRangeWithFunctionalLocationsRequest} from '../types';

export interface FleetMovementRequest extends DateRangeWithFunctionalLocationsRequest {
    grouping: FleetMovementGroupingEntity[];
}

export interface FleetMovementData {
    commissioned: number;
    decommissioned: number;
}

export enum FleetMovementGroupingEntity {
    SOLD_TO = 'soldTo',
    DIVISION = 'division',
    DEPOT = 'depot',
    VEHICLE_TYPE = 'vehicleType',
}
