import {action} from 'typesafe-actions';
import {VehicleRequest, VehiclesActionTypes, VehicleWithFitmentsAndJobs} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchVehicleRequest = (request:VehicleRequest) =>
    action(VehiclesActionTypes.FETCH_VEHICLE_REQUEST, request);
export const fetchVehicleSuccess = (vehicle: VehicleWithFitmentsAndJobs) =>
    action(VehiclesActionTypes.FETCH_VEHICLE_SUCCESS, vehicle);
export const fetchVehicleFailure = (message: string) =>
    action(VehiclesActionTypes.FETCH_VEHICLE_FAILURE, message)
