import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { conditionalClassLister } from '../../../../../utils/class-helpers';
import ToggleDisplay from '../../../../../utils/toggle-display';
import styles from './sold-to-selection-item.module.scss';
import {SoldToMapping} from '../../../../../store/soldto';

class SoldToSelectionItem extends Component<AllProps> {
    public render(): JSX.Element {
        const { soldTo, selected,canSelect } = this.props;

        const itemClasses = conditionalClassLister(styles)({
            soldToItem: true,
            isDisabled: !canSelect,
        });

        const indicatorClass = conditionalClassLister(styles)({
            indicator: true,
            item: true,
        });

        const codeClass = conditionalClassLister(styles)({
            item: true,
            small:true
        });

        const nameClass = conditionalClassLister(styles)({
            item: true,
            large: true,
        });

        const cityClass = conditionalClassLister(styles)({
            item: true,
            medium:true
        });

        const countryClass = conditionalClassLister(styles)({
            item: true,
            small:true
        });

        return (
            <div key={soldTo.soldToId} className={itemClasses} onClick={() => this.onSelect()}>
                <div className={indicatorClass}>
                    <ToggleDisplay if={selected}>
                        <FontAwesomeIcon icon={faCheck} />
                    </ToggleDisplay>
                </div>
                <div className={codeClass}>{soldTo.code}</div>
                <div className={nameClass}>{soldTo.name}</div>
                <div className={codeClass}>{soldTo.soldToCustomer}</div>
                <div className={cityClass}>{soldTo.city}</div>
                <div className={countryClass}>{soldTo.country}</div>
            </div>
        );
    }

    private onSelect() {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect();
        }
    }
}

export default withTranslation()(SoldToSelectionItem);

interface OwnProps {
    canSelect: boolean;
    soldTo: SoldToMapping;
    onSelect?: () => void;
    selected: boolean;
}

type AllProps = OwnProps & WithTranslation;
