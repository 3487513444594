import {TFunction} from 'i18next';
import {DropdownOptionKey} from '../../components/widget-header-dropdown/dropdown-option-key';

export function getDropDownOptionLabel(option: DropdownOptionKey, t: TFunction): string {
    switch (option) {
        case DropdownOptionKey.ByDepot: {
            return t('dropdownOption_ByDepot');
        }
        case DropdownOptionKey.ByDivision: {
            return t('dropdownOption_ByDivision');
        }
        case DropdownOptionKey.ByCompany: {
            return t('dropdownOption_ByCompany');
        }
        case DropdownOptionKey.ServicesVsTires: {
            return t('dropdownOption_ServicesVsTires');
        }
        case DropdownOptionKey.IncludeDetails: {
            return t('dropdownOption_IncludeDetails');
        }
        case DropdownOptionKey.BreakdownVsRegular: {
            return t('dropdownOption_BreakdownVsRegular');
        }
        case DropdownOptionKey.WithInspectionStatus: {
            return t('dropdownOption_WithInspectionStatus');
        }
        case DropdownOptionKey.NoGrouping: {
            return t('dropdownOption_NoGrouping');
        }
        case DropdownOptionKey.ByVehicleType: {
            return t('dropdownOption_ByVehicleType');
        }
        case DropdownOptionKey.ByNetPrice: {
            return t('dropdownOption_ByNetPrice');
        }
        case DropdownOptionKey.ByJobCount: {
            return t('dropdownOption_ByJobCount');
        }
        case DropdownOptionKey.ByNewTires: {
            return t('dropdownOption_ByNewTires');
        }
        case DropdownOptionKey.ByReusableTires: {
            return t('dropdownOption_ByReusableTires');
        }
        case DropdownOptionKey.ByRetreadTires: {
            return t('dropdownOption_ByRetreadTires');
        }
        default:
            throw new Error(`Drop down option ${option} is not supported.`);
    }
}
