import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import WidgetHeaderDropdown, {DropdownOption,} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetTable, {WidgetTableColumnType} from '../../../../components/widget-table/widget-table';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import {fetchTopServiceProvidersRequest} from '../../../../store/analytics';
import {
    OrderType,
    TopServiceProvidersData,
    TopServiceProvidersRequest,
} from '../../../../store/analytics/types/top-service-providers-types';
import {CurrencyInfo, FleetCustomerWithConfiguration, FleetType} from '../../../../store/fleet-customers';
import {ViewMode} from '../../../../types/view-mode';
import {buildSearchParameters} from '../../../../utils/query-parameter-helpers';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {addClickEvent, addStandardTooltipStyle, addStrokeToColumnChart, createXYChart} from '../utils/chart-utils';
import {hasInspectionPeriod} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import {buildChartData, orderTypeField, serviceProviderField} from './chart-data-adapter';
import {buildRequest} from './request-builder';
import styles from './top-service-providers-widget.module.scss';

am4core.useTheme(am4themes_animated);

class TopServiceProvidersWidget extends SingleChartWidgetBase<AllProps, AllState, TopServiceProvidersData[]> {
    private chartId: string;

    private readonly OPTION_NET_PRICE = { id: '1', key: DropdownOptionKey.ByNetPrice };

    private readonly OPTION_JOB_COUNT = { id: '2', key: DropdownOptionKey.ByJobCount };

    constructor(props) {
        super(props);
        const {fleetCustomer} = this.props;
        this.chartId = generateUUID();

        this.state = {
            options: [this.OPTION_NET_PRICE, this.OPTION_JOB_COUNT],
            selectedOption: fleetCustomer.configuration.fleetType === FleetType.PayGo ? this.OPTION_NET_PRICE : this.OPTION_JOB_COUNT,
            viewMode: ViewMode.chart,
        };
    }

    public render(): JSX.Element {
        const { t, data, activeCurrency, fleetCustomer } = this.props;
        const { selectedOption, options, viewMode } = this.state;

        const columns = [
            {
                title: 'Name',
                property: 'name',
                type: WidgetTableColumnType.upperText,
                width: '480px',
            },
            {
                title: '# Jobs',
                property: 'jobCount',
                type: WidgetTableColumnType.number,
            },
        ]
        if (fleetCustomer.configuration.fleetType === FleetType.PayGo) {
            columns.push({
                title: `Net Price (${activeCurrency.name})`,
                property: 'netPrice',
                type: WidgetTableColumnType.currency,
            });
        }

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div className={styles.headerTitle}>{t('Top 20 Service Providers')}</div>
                            <div className={styles.interactions}>
                                {fleetCustomer.configuration.fleetType === FleetType.PayGo ? <WidgetHeaderDropdown
                                    options={options}
                                    selection={selectedOption}
                                    onSelectionChanged={(selection) => this.onSelect(selection)}
                                /> : ''}
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable} />
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar} />
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{ width: '100%' }} />
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{columns}}
                                    data={data}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        );
    }

    private onSelect(option: DropdownOption): void {
        this.setState({ selectedOption: option, viewMode: ViewMode.chart }, () => {
            this.fetchData(this.props.controlsSnapshot);
        });
    }

    private toggleViewMode(): void {
        const { viewMode } = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({ viewMode: updatedViewMode }, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        const { activeCurrency } = this.props;
        this.props.fetchTopServiceProviders(
            buildRequest(this.props.controlsSnapshot, this.getOrderType(this.state.selectedOption), activeCurrency),
        );
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TopServiceProvidersData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = serviceProviderField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text =
            this.state.selectedOption === this.OPTION_NET_PRICE
                ? this.props.activeCurrency.name
                : this.props.t('# Jobs');

        chart.data = buildChartData(data, this.getOrderType(this.state.selectedOption));
        this.addSeries(chart);

        return chart;
    }

    private addSeries(chart: any): void {
        const series = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.categoryY = serviceProviderField;
        series.dataFields.valueX = orderTypeField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${
            this.state.selectedOption === this.OPTION_NET_PRICE ? this.props.activeCurrency.name : this.props.t('Jobs')
        }[/]`;
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.maxHeight = 100;

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '{valueX}';
        valueLabel.label.horizontalCenter = 'left';
        valueLabel.label.dx = 10;
        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;

        const eventHandler = addClickEvent(series, (datapoint) => {
            const serviceProviderName = datapoint[serviceProviderField];
            const parameters = {
                serviceProviderNameContains: serviceProviderName,
            };
            this.props.navigateTo({
                pathname: `/${this.props.fleetCustomerId}${RouteUrl.Jobs}`,
                search: buildSearchParameters(parameters),
            });
        });

        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);
        this.disposibles.push(eventHandler);
    }

    private getOrderType(option: DropdownOption): OrderType {
        switch (option) {
            case this.OPTION_NET_PRICE:
                return OrderType.NET_PRICE;
            case this.OPTION_JOB_COUNT:
                return OrderType.JOB_COUNT;
            default:
                throw Error('Can not determine service provider order type');
        }
    }
}

const mapStateToProps = ({ analytics, authentication }: ApplicationState) => ({
    data: analytics.topServiceProvidersData,
    fleetCustomer: authentication.fleetCustomer,
    activeCurrency: authentication.fleetCustomer!.activeCurrency,
    fleetCustomerId: authentication.fleetCustomer?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchTopServiceProviders: (request: TopServiceProvidersRequest) =>
        dispatch(fetchTopServiceProvidersRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopServiceProvidersWidget));

interface PropsFromState {
    data: TopServiceProvidersData[];
    fleetCustomer: FleetCustomerWithConfiguration;
    fleetCustomerId: string;
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    fetchTopServiceProviders: typeof fetchTopServiceProvidersRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
    viewMode: ViewMode;
}

type AllState = OwnState;
