import * as am4core from '@amcharts/amcharts4/core';

export const colorBlue1 = '#DCEEFB';
export const colorBlue2 = '#B6E0FE';
export const colorBlue3 = '#84C5F4';
export const colorBlue4 = '#62B0E8';
export const colorBlue5 = '#4098D7';
export const colorBlue6 = '#2680C2';
export const colorBlue7 = '#186FAF';
export const colorBlue8 = '#0F609B';
export const colorBlue9 = '#0A558C';
export const colorBlue10 = '#003E6B';

export const colorGreen1 = '#EFFCF6';
export const colorGreen2 = '#C6F7E2';
export const colorGreen3 = '#8EEDC7';
export const colorGreen4 = '#65D6AD';
export const colorGreen5 = '#3EBD93';
export const colorGreen6 = '#27AB83';
export const colorGreen7 = '#199473';
export const colorGreen8 = '#147D64';
export const colorGreen9 = '#0C6B58';
export const colorGreen10 = '#014D40';

export const colorRed1 = '#FFE3E3';
export const colorRed2 = '#FFBDBD';
export const colorRed3 = '#FF9B9B';
export const colorRed4 = '#F86A6A';
export const colorRed5 = '#EF4E4E';
export const colorRed6 = '#E12D39';
export const colorRed7 = '#CF1124';
export const colorRed8 = '#AB091E';
export const colorRed9 = '#8A041A';
export const colorRed10 = '#610316';

export const colorYellow1 = '#FFFBEA';
export const colorYellow2 = '#FFF3C4';
export const colorYellow3 = '#FCE588';
export const colorYellow4 = '#FADB5F';
export const colorYellow5 = '#F7C948';
export const colorYellow6 = '#F0B429';
export const colorYellow7 = '#DE911D';
export const colorYellow8 = '#CB6E17';
export const colorYellow9 = '#B44D12';
export const colorYellow10 = '#8D2B0B';

export const colorCyan1 = '#E0FCFF';
export const colorCyan2 = '#BEF8FD';
export const colorCyan3 = '#87EAF2';
export const colorCyan4 = '#54D1DB';
export const colorCyan5 = '#38BEC9';
export const colorCyan6 = '#2CB1BC';
export const colorCyan7 = '#14919B';
export const colorCyan8 = '#0E7C86';
export const colorCyan9 = '#0A6C74';
export const colorCyan10 = '#044E54';

export const colorLime1 = '#F2FDE0';
export const colorLime2 = '#E2F7C2';
export const colorLime3 = '#C7EA8F';
export const colorLime4 = '#ABDB5E';
export const colorLime5 = '#94C843';
export const colorLime6 = '#7BB026';
export const colorLime7 = '#63921A';
export const colorLime8 = '#507712';
export const colorLime9 = '#42600C';
export const colorLime10 = '#2B4005';

export const colorGrey1 = '#BBBBBB';

export function getBlueColorPalette(size: number) :am4core.Color[]{
    switch (size) {
        case 1:
            return [am4core.color(colorBlue4)];
        case 2:
            return [am4core.color(colorBlue4), am4core.color(colorBlue6)];
        case 3:
            return [am4core.color(colorBlue3), am4core.color(colorBlue5), am4core.color(colorBlue7)];
        case 4:
            return [
                am4core.color(colorBlue2),
                am4core.color(colorBlue4),
                am4core.color(colorBlue6),
                am4core.color(colorBlue8),
            ];
        case 5:
            return [
                am4core.color(colorBlue2),
                am4core.color(colorBlue4),
                am4core.color(colorBlue6),
                am4core.color(colorBlue8),
                am4core.color(colorBlue10),
            ];
        case 6:
            return [
                am4core.color(colorBlue2),
                am4core.color(colorBlue4),
                am4core.color(colorBlue5),
                am4core.color(colorBlue6),
                am4core.color(colorBlue8),
                am4core.color(colorBlue10),
            ];
        default:
            return [
                am4core.color(colorBlue1),
                am4core.color(colorBlue2),
                am4core.color(colorBlue3),
                am4core.color(colorBlue4),
                am4core.color(colorBlue5),
                am4core.color(colorBlue6),
                am4core.color(colorBlue7),
                am4core.color(colorBlue8),
                am4core.color(colorBlue9),
            ];
    }
}

export function getLimeColorPalette(size: number):am4core.Color[] {
    switch (size) {
        case 1:
            return [am4core.color(colorLime4)];
        case 2:
            return [am4core.color(colorLime4), am4core.color(colorLime6)];
        case 3:
            return [am4core.color(colorLime3), am4core.color(colorLime5), am4core.color(colorLime7)];
        case 4:
            return [
                am4core.color(colorLime2),
                am4core.color(colorLime4),
                am4core.color(colorLime6),
                am4core.color(colorLime8),
            ];
        case 5:
            return [
                am4core.color(colorLime2),
                am4core.color(colorLime4),
                am4core.color(colorLime6),
                am4core.color(colorLime8),
                am4core.color(colorLime10),
            ];
        case 6:
            return [
                am4core.color(colorLime2),
                am4core.color(colorLime4),
                am4core.color(colorLime5),
                am4core.color(colorLime6),
                am4core.color(colorLime8),
                am4core.color(colorLime10),
            ];
        default:
            return [
                am4core.color(colorLime1),
                am4core.color(colorLime2),
                am4core.color(colorLime3),
                am4core.color(colorLime4),
                am4core.color(colorLime5),
                am4core.color(colorLime6),
                am4core.color(colorLime7),
                am4core.color(colorLime8),
                am4core.color(colorLime9),
                am4core.color(colorLime10),
            ];
    }
}

export function getCyanColorPalette(size: number):am4core.Color[] {
    switch (size) {
        case 1:
            return [am4core.color(colorCyan4)];
        case 2:
            return [am4core.color(colorCyan4), am4core.color(colorCyan6)];
        case 3:
            return [am4core.color(colorCyan3), am4core.color(colorCyan5), am4core.color(colorCyan7)];
        case 4:
            return [
                am4core.color(colorCyan2),
                am4core.color(colorCyan4),
                am4core.color(colorCyan6),
                am4core.color(colorCyan8),
            ];
        case 5:
            return [
                am4core.color(colorCyan2),
                am4core.color(colorCyan4),
                am4core.color(colorCyan6),
                am4core.color(colorCyan8),
                am4core.color(colorCyan10),
            ];
        case 6:
            return [
                am4core.color(colorCyan2),
                am4core.color(colorCyan4),
                am4core.color(colorCyan5),
                am4core.color(colorCyan6),
                am4core.color(colorCyan8),
                am4core.color(colorCyan10),
            ];
        default:
            return [
                am4core.color(colorCyan1),
                am4core.color(colorCyan2),
                am4core.color(colorCyan3),
                am4core.color(colorCyan4),
                am4core.color(colorCyan5),
                am4core.color(colorCyan6),
                am4core.color(colorCyan7),
                am4core.color(colorCyan8),
                am4core.color(colorCyan9),
                am4core.color(colorCyan10),
            ];
    }
}

export function getBoldColorPalette(size: number):am4core.Color[] {
    switch (size) {
        case 1:
            return [am4core.color(colorBlue4)];
        case 2:
            return [am4core.color(colorBlue5), am4core.color(colorCyan5)];
        case 3:
            return [am4core.color(colorBlue4), am4core.color(colorCyan5), am4core.color(colorBlue8)];
        case 4:
            return [
                am4core.color(colorBlue4),
                am4core.color(colorCyan4),
                am4core.color(colorBlue8),
                am4core.color(colorCyan8),
            ];
        case 5:
            return [
                am4core.color(colorBlue4),
                am4core.color(colorCyan4),
                am4core.color(colorLime4),
                am4core.color(colorBlue8),
                am4core.color(colorCyan8),
            ];
        case 6:
            return [
                am4core.color(colorBlue4),
                am4core.color(colorCyan4),
                am4core.color(colorLime4),
                am4core.color(colorBlue8),
                am4core.color(colorCyan8),
                am4core.color(colorLime8),
            ];
        default:
            return [
                am4core.color(colorBlue2),
                am4core.color(colorCyan2),
                am4core.color(colorLime2),
                am4core.color(colorBlue4),
                am4core.color(colorCyan4),
                am4core.color(colorLime4),
                am4core.color(colorBlue6),
                am4core.color(colorCyan6),
                am4core.color(colorLime6),
                am4core.color(colorBlue8),
                am4core.color(colorCyan8),
                am4core.color(colorLime8),
            ];
    }
}
