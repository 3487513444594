import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import { UsersAction, UsersActionTypes, UsersState } from './types';

function initialState(): UsersState {
    return {};
}

const reducer: Reducer<UsersState, UsersAction> = (state = initialState(), action): UsersState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case UsersActionTypes.GET_USER_REQUEST: {
            return { ...state, getUserRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case UsersActionTypes.GET_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                getUserRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case UsersActionTypes.GET_USER_FAILURE: {
            return { ...state, getUserRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        case UsersActionTypes.CLEAR_USERS: {
            return { ...state , users: undefined, searchUsersRequestStatus: new RequestStatus(RequestStatusType.New) };
        }
        case UsersActionTypes.SEARCH_USERS_REQUEST: {
            return { ...state, searchUsersRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case UsersActionTypes.SEARCH_USERS_SUCCESS: {
            return {
                ...state,
                users: action.payload,
                searchUsersRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case UsersActionTypes.SEARCH_USERS_FAILURE: {
            return { ...state, searchUsersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        case UsersActionTypes.UPDATE_USER_REQUEST: {
            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case UsersActionTypes.UPDATE_USER_SUCCESS: {
            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case UsersActionTypes.UPDATE_USER_FAILURE: {
            return {
                ...state,
                updateUserRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as usersReducer };
export { initialState as initialUsersState };
