import { ActionType } from 'typesafe-actions';
import { ControlValue } from '../../pages/analytics/dashboard-controls/controls-snapshot';
import { ParamKey } from '../dashboard';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';
import {AlertData} from './types/alert.types';
import { FleetMovementData } from './types/fleet-movement.types';
import { FleetSizeEvolutionData } from './types/fleet-size-evolution.types';
import { InspectionHistogramData } from './types/inspection-histogram.types';
import { InspectionStatusData } from './types/inspection-status.types';
import { BillingItemsDistributionData } from './types/invoice-items-distribution.types';
import { BillingItemsEvolutionData } from './types/invoice-items-evolution.types';
import { JobTypesDistributionData } from './types/job-types-distribution.types';
import { JobsEvolutionData } from './types/jobs-evolution.types';
import {
    AvoidableBreakdownsKPIData,
    KeyPerformanceIndicatorData,
    RetreadRatioKPIData, UnderInflationKPIData
} from './types/key-performance-indicator.types';
import { ServiceProvidersMonthlyOverviewData } from './types/service-providers-monthly-overview.types';
import { TireBrandDistributionData } from './types/tire-brand-distribution.types';
import { TopServiceProvidersData } from './types/top-service-providers-types';
import { TopTirePatternsData } from './types/top-tire-patterns.types';
import { VehiclesDistributionData } from './types/vehicle-types-distribution.types';
import {LocalLanguageType} from '../shared/types';
import {
    TopRetreadTiresFittedByServiceProvidersData,
    TopReusableTiresFittedByServiceProvidersData
} from "./types/top-tires-fitted-by-service-providers-types";

export enum AnalyticsActionTypes {
    KEY_PERFORMANCE_INDICATOR_REQUEST = '@@analytics/KEY_PERFORMANCE_INDICATOR_REQUEST',
    KEY_PERFORMANCE_INDICATOR_SUCCESS = '@@analytics/KEY_PERFORMANCE_INDICATOR_SUCCESS',

    ALERTS_WIDGET_DATA_REQUEST = '@@analytics/ALERTS_WIDGET_DATA_REQUEST',
    ALERTS_WIDGET_DATA_SUCCESS = '@@analytics/ALERTS_WIDGET_SUCCESS',

    VEHICLES_DISTRIBUTION_REQUEST = '@@analytics/VEHICLES_DISTRIBUTION_REQUEST',
    VEHICLES_DISTRIBUTION_SUCCESS = '@@analytics/VEHICLES_DISTRIBUTION_SUCCESS',
    DAILY_FLEET_SIZE_EVOLUTION_REQUEST = '@@analytics/DAILY_FLEET_SIZE_EVOLUTION_REQUEST',
    DAILY_FLEET_SIZE_EVOLUTION_SUCCESS = '@@analytics/DAILY_FLEET_SIZE_EVOLUTION_SUCCESS',

    JOB_TYPES_DISTRIBUTION_REQUEST = '@@analytics/JOB_TYPES_DISTRIBUTION_REQUEST',
    JOB_TYPES_DISTRIBUTION_SUCCESS = '@@analytics/JOB_TYPES_DISTRIBUTION_SUCCESS',
    DAILY_JOB_EVOLUTION_REQUEST = '@@analytics/DAILY_JOB_EVOLUTION_REQUEST',
    DAILY_JOB_EVOLUTION_SUCCESS = '@@analytics/DAILY_JOB_EVOLUTION_SUCCESS',

    BILLING_ITEMS_DISTRIBUTION_REQUEST = '@@analytics/BILLING_ITEMS_DISTRIBUTION_REQUEST',
    BILLING_ITEMS_DISTRIBUTION_SUCCESS = '@@analytics/BILLING_ITEMS_DISTRIBUTION_SUCCESS',
    BILLING_ITEMS_EVOLUTION_REQUEST = '@@analytics/BILLING_ITEMS_EVOLUTION_REQUEST',
    BILLING_ITEMS_EVOLUTION_SUCCESS = '@@analytics/BILLING_ITEMS_EVOLUTION_SUCCESS',

    SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST = '@@analytics/SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST',
    SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS = '@@analytics/SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS',

    TOP_SERVICE_PROVIDERS_REQUEST = '@@analytics/TOP_SERVICE_PROVIDERS_REQUEST',
    TOP_SERVICE_PROVIDERS_SUCCESS = '@@analytics/TOP_SERVICE_PROVIDERS_SUCCESS',

    TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_REQUEST = '@@analytics/TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_REQUEST',
    TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_SUCCESS = '@@analytics/TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_SUCCESS',

    TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_REQUEST = '@@analytics/TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_REQUEST',
    TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_SUCCESS = '@@analytics/TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_SUCCESS',

    INSPECTION_STATUS_REQUEST = '@@analytics/INSPECTION_STATUS_REQUEST',
    INSPECTION_STATUS_SUCCESS = '@@analytics/INSPECTION_STATUS_SUCCESS',

    UPDATE_DEFAULT_CONTROL_VALUES = '@@analytics/UPDATE_DEFAULT_CONTROL_VALUES',
    UPDATE_SINGLE_DEFAULT_CONTROL_VALUE = '@@analytics/UPDATE_SINGLE_DEFAULT_CONTROL_VALUE',

    FLEET_MOVEMENT_REQUEST = '@@analytics/FLEET_MOVEMENT_REQUEST',
    FLEET_MOVEMENT_SUCCESS = '@@analytics/FLEET_MOVEMENT_SUCCESS',

    INSPECTION_HISTOGRAM_REQUEST = '@@analytics/INSPECTION_HISTOGRAM_REQUEST',
    INSPECTION_HISTOGRAM_SUCCESS = '@@analytics/INSPECTION_HISTOGRAM_SUCCESS',

    TIRE_FITMENTS_BRAND_DISTRIBUTION_REQUEST = '@@analytics/TIRE_FITMENTS_BRAND_DISTRIBUTION_REQUEST',
    TIRE_FITMENTS_BRAND_DISTRIBUTION_SUCCESS = '@@analytics/TIRE_FITMENTS_BRAND_DISTRIBUTION_SUCCESS',

    TIRES_FITTED_BRAND_DISTRIBUTION_REQUEST = '@@analytics/TIRES_FITTED_BRAND_DISTRIBUTION_REQUEST',
    TIRES_FITTED_BRAND_DISTRIBUTION_SUCCESS = '@@analytics/TIRES_FITTED_BRAND_DISTRIBUTION_SUCCESS',

    TIRE_FITMENTS_TOP_PATTERNS_REQUEST = '@@analytics/TIRE_FITMENTS_TOP_PATTERNS_REQUEST',
    TIRE_FITMENTS_TOP_PATTERNS_SUCCESS = '@@analytics/TIRE_FITMENTS_TOP_PATTERNS_SUCCESS',

    TIRES_FITTED_TOP_PATTERNS_REQUEST = '@@analytics/TIRES_FITTED_TOP_PATTERNS_REQUEST',
    TIRES_FITTED_TOP_PATTERNS_SUCCESS = '@@analytics/TIRES_FITTED_TOP_PATTERNS_SUCCESS',

    AVOIDABLE_BREAKDOWNS_KPI_REQUEST = '@@analytics/AVOIDABLE_BREAKDOWNS_KPI_REQUEST',
    AVOIDABLE_BREAKDOWNS_KPI_SUCCESS = '@@analytics/AVOIDABLE_BREAKDOWNS_KPI_SUCCESS',

    RETREAD_RATIO_KPI_REQUEST = '@@analytics/RETREAD_RATIO_KPI_REQUEST',
    RETREAD_RATIO_KPI_SUCCESS = '@@analytics/RETREAD_RATIO_KPI_SUCCESS',

    UNDER_INFLATION_KPI_REQUEST = '@@analytics/UNDER_INFLATION_KPI_REQUEST',
    UNDER_INFLATION_KPI_SUCCESS = '@@analytics/UNDER_INFLATION_KPI_SUCCESS',
}

export type AnalyticsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface AnalyticsState {
    readonly defaultControlValues: Map<ParamKey, ControlValue>;

    readonly keyPerformanceIndicatorData: KeyPerformanceIndicatorData[];
    readonly alertData: AlertData[];
    readonly jobTypesDistributionData?: JobTypesDistributionData[];
    readonly jobsEvolutionData?: JobsEvolutionData[];
    readonly fleetSizeEvolutionData?: FleetSizeEvolutionData[];
    readonly vehiclesDistributionData?: VehiclesDistributionData[];
    readonly invoiceItemsDistributionData?: BillingItemsDistributionData[];
    readonly invoiceItemsEvolutionData?: BillingItemsEvolutionData[];
    readonly inspectionStatusData?: InspectionStatusData[];
    readonly serviceProvidersMonthlyOverviewData?: ServiceProvidersMonthlyOverviewData[];
    readonly topServiceProvidersData?: TopServiceProvidersData[];
    readonly topRetreadTiresByServiceProvidersData?: TopRetreadTiresFittedByServiceProvidersData[];
    readonly topReusableTiresByServiceProvidersData?: TopReusableTiresFittedByServiceProvidersData[];
    readonly fleetMovementData?: FleetMovementData[];
    readonly inspectionHistogramData?: InspectionHistogramData;
    readonly tireFitmentsBrandDistributionData?: TireBrandDistributionData[];
    readonly tiresFittedBrandDistributionData?: TireBrandDistributionData[];
    readonly topTireFitmentsPatterns?: TopTirePatternsData[];
    readonly topTiresFittedPatterns?: TopTirePatternsData[];
    readonly avoidableBreakdownsKPI?: AvoidableBreakdownsKPIData;
    readonly retreadRatioKPI ?: RetreadRatioKPIData;
    readonly underInflationKPI ?: UnderInflationKPIData;
}

export enum KpiTypes {
    TotalJobCount = 'TotalJobCount',
    TotalBreakdowns = 'TotalBreakdowns',
    TotalInspections = 'TotalInspections',
    ScheduledInspections = 'ScheduledInspections',
    UnscheduledInspections = 'UnscheduledInspections',
    InspectionRatio = 'InspectionRatio',
    TotalFleetSize = 'TotalFleetSize',
    FleetGrowth = 'FleetGrowth',
    JobsAvgAcceptanceTime = 'JobsAvgAcceptanceTime',
    TotalInvoiced = 'InvoiceTotal',
    YoYFinancialComparison = 'YoYFinancialComparison',
}

export enum AlertTypes {
    TirePressure = 'TirePressure',
    TreadDepth = 'TreadDepth',
    RetreadRatio = 'RetreadRatio',
    BreakdownRatio = 'BreakdownRatio',
    ServiceFrequency = 'InspectionFrequency',
}

export interface LanguageRequest {
    language: LocalLanguageType;
}

export interface DateRangeRequest {
    start: string;
    end: string;
}

export interface DateRangeWithFunctionalLocationsRequest {
    start: string;
    end: string;
    locations: FunctionalLocationSelection[];
    includeUnlinkedBillingLines?: boolean;
}

export interface DateRangeWithFunctionalLocationsAndLanguageRequest {
    start: string;
    end: string;
    locations: FunctionalLocationSelection[];
    language: LocalLanguageType;
    includeUnlinkedBillingLines?: boolean;
}

export interface DateRangeWithFunctionalLocationsAndCurrencyRequest {
    start: string;
    end: string;
    locations: FunctionalLocationSelection[];
    currencyType: BaseCurrencyType;
}

export interface DateWithFunctionalLocationsRequest {
    date: string;
    locations: FunctionalLocationSelection[];
}

export interface DateWithFunctionalLocationsAndLanguageRequest {
    date: string;
    locations: FunctionalLocationSelection[];
    language: LocalLanguageType;
}

export interface DateRange {
    start: Date;
    end: Date;
}

export interface FunctionalLocationSelection {
    locationType: FunctionalLocationType;
    includes: string[];
}

export enum FunctionalLocationType {
    SOLD_TO = 'soldTo',
    DIVISION = 'division',
    DEPOT = 'depot',
}

export enum Frequency {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export enum BaseCurrencyType {
    BASE_CURRENCY_1 = 'baseCurrency1',
    BASE_CURRENCY_2 = 'baseCurrency2',
    BASE_CURRENCY_3 = 'baseCurrency3',
}
