import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import Dropdown, {DropdownOption} from '../../../components/dropdown/dropdown';
import styles from './company-selector.module.scss';
import {TirePerformanceOption} from '../../../store/reports';

export const OPTIONS_ALL = 'options_all';

class PerformanceOptionSelectorComponent extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {t, options, defaultOption} = this.props;
        this.state = this.getInitialState(t, options, defaultOption);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {t, options, defaultOption} = this.props;
        if (options && options !== prevProps.options) {
            this.setState(this.getInitialState(t, options, defaultOption));
        }
    }

    public render() {
        const {selectedOptionId, availableOptions} = this.state;

        const width = '340px';

        return (
            <div>
                <div className={styles.select}>
                    <Dropdown options={availableOptions} width={width}
                              selection={selectedOptionId !== undefined ? availableOptions.find(opt => opt.id === selectedOptionId) : undefined}
                              onSelectionChanged={opt => this.onCompanyChanged(opt)}/>
                </div>
            </div>
        )
    }

    private onCompanyChanged(option: DropdownOption) {
        const {optionChanged} = this.props;
        this.setState({
            selectedOptionId: option.id,
        });
        optionChanged(option.id!);
    }

    private getInitialState(t, options?: TirePerformanceOption[], defaultOption?: TirePerformanceOption) {
        const opts = [{id: OPTIONS_ALL, label: t('All').toUpperCase()}];

        if (options) {
            opts.push(...options.map(c => ({id: c.id, label: c.name})));
        }

        return {
            selectedOptionId: defaultOption ? defaultOption.id : OPTIONS_ALL,
            availableOptions: opts,
        };
    }
}

export default withTranslation()(PerformanceOptionSelectorComponent);

interface OwnProps {
    defaultOption?: TirePerformanceOption;
    options?: TirePerformanceOption[];
    optionChanged: (optionId: string) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    selectedOptionId?: string;
    availableOptions: DropdownOption[];
}

type AllState = OwnState;

