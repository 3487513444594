import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortAmountDown} from '@fortawesome/pro-regular-svg-icons/faSortAmountDown';
import {faSortAmountUp} from '@fortawesome/pro-regular-svg-icons/faSortAmountUp';
import {WithTranslation, withTranslation} from 'react-i18next';
import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {faFilter as faSolidFilter} from '@fortawesome/pro-solid-svg-icons';
import {Component, MouseEvent as ReactMouseEvent} from 'react';
import styles from './table.module.scss';
import TableHeaderSearch from './table-header-search-panel';
import {conditionalClassLister} from '../../../utils/class-helpers';
import ToggleDisplay from '../../../utils/toggle-display';
import {SortDirection} from '../../../store/shared/types';
import {SelectionOption} from './types/selection-option';
import TableHeaderSelect from './table-header-select-panel';

export class TableHeader extends Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            filterPanelOpen: false,
        };
    }

    public render(): JSX.Element {
        const {
            column,
            sortColumn,
            filterText,
            sortDirection,
            title,
            onApplySearchFilter,
            onApplySelectFilter,
            small,
            tiny,
            filterOptions,
            filterExcludedOptions,
            filterType
        } = this.props;
        const {filterPanelOpen} = this.state;

        const showActive = (!!filterText) || filterExcludedOptions && filterExcludedOptions.length > 0;

        const filterIconClasses = conditionalClassLister(styles)({
            filterIcon: true,
            isOpen: filterPanelOpen,
            alwaysVisible: showActive,
        });

        const columnClasses = conditionalClassLister(styles)({
            tableColumn: true,
            tableColumnHeader: true,
            tableColumnSmall: small,
            tableColumnTiny: tiny,
        });

        return (
            <div className={columnClasses}>
                <div onClick={() => this.onToggleSort(column)}>
                    {title}
                    <ToggleDisplay if={sortColumn === column && sortDirection === SortDirection.Descending}>
                        <FontAwesomeIcon className={styles.tableColumnIcon} icon={faSortAmountDown}/>
                    </ToggleDisplay>
                    <ToggleDisplay if={sortColumn === column && sortDirection === SortDirection.Ascending}>
                        <FontAwesomeIcon className={styles.tableColumnIcon} icon={faSortAmountUp}/>
                    </ToggleDisplay>
                    {onApplySearchFilter || onApplySelectFilter ?
                        <span>
                            <FontAwesomeIcon
                                onClick={event => this.onFilterClick(event)}
                                tabIndex={0}
                                className={filterIconClasses} icon={showActive ? faSolidFilter : faFilter}/>
                        </span>
                        : null}
                </div>
                {filterType === TableHeaderFilterType.SEARCH ?
                    <TableHeaderSearch
                        onApply={text => this.onApplySearchFilter(text)}
                        isOpen={filterPanelOpen}
                        filterText={filterText || ''}/>
                    : null
                }
                {filterType === TableHeaderFilterType.SELECT ?
                    <TableHeaderSelect
                        onApply={selectedOptions => this.onApplySelectFilter(selectedOptions)}
                        isOpen={filterPanelOpen}
                        options={filterOptions!}
                        excludedOptions={filterExcludedOptions!}/>
                    : null
                }
            </div>
        );
    }

    public onToggleSort(column: string): void {
        const {onToggleSort: onToggleSort1} = this.props;
        onToggleSort1(column);
    }

    public onFilterClick(event: ReactMouseEvent<SVGSVGElement, MouseEvent>): void {
        event.preventDefault();
        event.stopPropagation();
        const {filterPanelOpen} = this.state;
        this.toggleFilterPanelOpen(!filterPanelOpen);
    }

    public onApplySearchFilter(text: string): void {
        const {onApplySearchFilter: onApplyFilter1} = this.props;
        if (onApplyFilter1) {
            onApplyFilter1(text);
        }
        this.toggleFilterPanelOpen(false);
    }

    public onApplySelectFilter(selectedOptions: SelectionOption[]): void {
        const {onApplySelectFilter: onApplyFilter1} = this.props;
        if (onApplyFilter1) {
            onApplyFilter1(selectedOptions);
        }
        this.toggleFilterPanelOpen(false);
    }

    private toggleFilterPanelOpen(open: boolean) {
        this.setState({filterPanelOpen: open});
    }
}

export default withTranslation()(TableHeader);

interface OwnState {
    filterPanelOpen: boolean;
}

interface OwnProps {
    column: string;
    sortColumn: string;
    sortDirection: SortDirection;
    title: string;
    small?: boolean;
    tiny?: boolean;
    onToggleSort: (sortColumn) => void;
    filterType: TableHeaderFilterType;
    onApplySearchFilter?: (string) => void;
    filterText?: string;
    onApplySelectFilter?: (selectedOptions) => void;
    filterOptions?: SelectionOption[];
    filterExcludedOptions?: SelectionOption[];
}

type AllProps = OwnProps & WithTranslation;

export enum TableHeaderFilterType {
    NONE = 'none',
    SEARCH = 'search',
    SELECT = 'select',
}
