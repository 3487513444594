import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {
    RetreadOrderType,
    TopRetreadTiresFittedByServiceProvidersRequest,
} from "../../../../store/analytics/types/top-tires-fitted-by-service-providers-types";
export function buildRetreadRequest(snapshot: ControlsSnapshot, orderType: RetreadOrderType): TopRetreadTiresFittedByServiceProvidersRequest {
    const request = Object.assign(createDateRangeWithFunctionalLocationsRequest(snapshot), {
        orderType,
    });
    return request;
}
