import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import i18next from 'i18next';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './fleet-size-evolution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import {fetchFleetSizeEvolutionRequest} from '../../../../store/analytics';
import {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetLoading from '../widget-loading';
import {FleetSizeEvolutionRequest, FleetSizeEvolutionData} from '../../../../store/analytics/types/fleet-size-evolution.types';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {buildRequest} from './request-builder';
import {colorCyan6} from '../utils/chart-colors';
import {buildChartData, dateField, dateFormatAmcharts, vehicleCountField} from './chart-data-adapter';
import {addStandardTooltipStyle, createXYChart} from '../utils/chart-utils';
import {getChartLanguage} from '../../../../utils/translations/chart-translation-helper';

am4core.useTheme(am4themes_animated);

class FleetSizeEvolutionWidget extends SingleChartWidgetBase<AllProps, AllState, FleetSizeEvolutionData[]> {

    private chartId: string;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
    }

    public render(): JSX.Element {
        const {t, data} = this.props;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Fleet Parc Evolution')}
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading />}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchFleetSizeEvolution(buildRequest(snapshot));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: FleetSizeEvolutionData[]) {
        const chart = createXYChart(this.chartId);
        chart.data = buildChartData(data);

        chart.dateFormatter.inputDateFormat = dateFormatAmcharts;
        chart.language.locale = getChartLanguage(i18next.language);

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.tooltip!.disabled = true;

        const fleetSeries = chart.series.push(new am4charts.LineSeries());
        fleetSeries.dataFields.valueY = vehicleCountField;
        fleetSeries.dataFields.dateX = dateField;
        fleetSeries.name = 'Fleet';
        fleetSeries.fill = am4core.color(colorCyan6);
        fleetSeries.fillOpacity = 0.8;
        fleetSeries.strokeWidth = 0;
        fleetSeries.tooltipText = `{valueY.value} ${this.props.t('vehicles')}`;

        addStandardTooltipStyle(fleetSeries);

        /* Create a cursor */
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.fullWidthLineX = true;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = am4core.color('#000');
        chart.cursor.lineX.fillOpacity = 0.1;
        chart.cursor.behavior = 'selectX';
        chart.cursor.lineY.disabled = true;

        return chart;
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.fleetSizeEvolutionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFleetSizeEvolution:(requestBody: FleetSizeEvolutionRequest) => dispatch(fetchFleetSizeEvolutionRequest(requestBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FleetSizeEvolutionWidget));

interface PropsFromState {
    data: FleetSizeEvolutionData[];
}

interface PropsFromDispatch {
    fetchFleetSizeEvolution: typeof fetchFleetSizeEvolutionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
