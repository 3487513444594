import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import styles from './billing-details-popup.module.scss';
import {buildSearchParameters} from '../../utils/query-parameter-helpers';
import {PopupType} from '../../store/popup';
import {BillingDocument, BillingLine, getBillingDocumentRequest} from '../../store/billing';
import {ApplicationState} from '../../store';
import {formatDateInBrusselsTime} from '../../utils/date-helper';
import {conditionalClassLister} from '../../utils/class-helpers';
import {FleetCustomer} from '../../store/fleet-customers';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../store/toast';
import BillingApi from '../../api/billing-api';
import {generateUUID} from '../../utils/uuid-helpers';

class BillingDetailsPopup extends Component<AllProps, OwnState> {

    constructor(props) {
        super(props);

        const {getBillingDocument, billingDocumentId} = this.props;
        if (billingDocumentId) {
            getBillingDocument(billingDocumentId);
        }

        this.state = {};
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const {billingDocument} = this.props;
        if (billingDocument !== prevProps.billingDocument) {
            if (billingDocument && billingDocument.billingLines.length > 0) {
                this.setState({selectedLine: billingDocument.billingLines[0]});
            }
        }
    }

    public render(): JSX.Element {
        const {t, billingDocument} = this.props;
        const {selectedLine} = this.state;

        let totalNetPrice = 0;
        let totalVAT = 0;

        if (billingDocument) {
            billingDocument.billingLines.forEach((line) => {
                totalVAT += line.originalCurrencyVatPrice;
                totalNetPrice += line.originalCurrencyNetPrice;
            });
        }

        return (
            <div className={styles.popupContainer}>
                {billingDocument ?
                    <div className={styles.popupContent}>
                        <div className={styles.contentTitle}>
                            <div className={styles.type}>{t('Billing Document')}</div>
                            <div className={styles.id}>{billingDocument.billingRef}</div>
                        </div>
                        <div className={styles.contentBody}>
                            <div className={styles.infoContainers}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Date')}</div>
                                        <div
                                            className={styles.value}>{formatDateInBrusselsTime(billingDocument.date, 'YYYY-MM-DD')}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Status')}</div>
                                        <div className={styles.value}>{billingDocument.status}</div>
                                    </div>
                                    {billingDocument.billingRef ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Invoice')}</div>
                                            <div className={styles.action}
                                                 onClick={() => this.onDownloadInvoice(billingDocument?.billingRef)}>{t('Download')}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className={styles.infoContainer}>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Net Price')}</div>
                                        <div className={styles.value}>
                                            <NumberFormat value={totalNetPrice} displayType="text" decimalSeparator=","
                                                          thousandSeparator="." fixedDecimalScale
                                                          decimalScale={2}/> {billingDocument.originalCurrency}
                                        </div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('VAT charge')}</div>
                                        <div className={styles.value}>
                                            <NumberFormat value={totalVAT} displayType="text" decimalSeparator=","
                                                          thousandSeparator="." fixedDecimalScale
                                                          decimalScale={2}/> {billingDocument.originalCurrency}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.linesContainer}>
                                <div className={styles.lines}>
                                    {billingDocument.billingLines.map((line) => {
                                            const lineClasses = conditionalClassLister(styles)({
                                                line: true,
                                                selected: selectedLine && selectedLine.billingLineId === line.billingLineId,
                                            });
                                            return <div key={line.billingLineId} className={lineClasses}
                                                        onClick={() => this.selectLine(line)}>
                                                <div
                                                    className={styles.dateField}>{formatDateInBrusselsTime(line.startDate, 'YYYY-MM-DD HH:mm')}</div>
                                                <div className={styles.jobTypeField}>{line.jobType}</div>
                                                <div className={styles.netPriceField}><NumberFormat
                                                    value={line.originalCurrencyNetPrice} displayType="text"
                                                    decimalSeparator=","
                                                    thousandSeparator="." fixedDecimalScale
                                                    decimalScale={2}/> {billingDocument.originalCurrency}</div>
                                            </div>;
                                        },
                                    )}
                                </div>
                                <div className={styles.details}>
                                    {selectedLine ?
                                        <div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Type')}</div>
                                                <div className={styles.value}>{selectedLine.jobType}</div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Reference')}</div>
                                                <div className={styles.value}>{selectedLine.jobRef}</div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Date')}</div>
                                                <div
                                                    className={styles.value}>{formatDateInBrusselsTime(selectedLine.startDate, 'YYYY-MM-DD HH:mm')} -- {formatDateInBrusselsTime(selectedLine.endDate, 'YYYY-MM-DD HH:mm')}</div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Units')}</div>
                                                <div className={styles.value}>{selectedLine.billedUnits}</div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Net Price')}</div>
                                                <div className={styles.value}>
                                                    <NumberFormat value={selectedLine.originalCurrencyNetPrice}
                                                                  displayType="text" decimalSeparator=","
                                                                  thousandSeparator="." fixedDecimalScale
                                                                  decimalScale={2}/> {billingDocument.originalCurrency}
                                                </div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('VAT Price')}</div>
                                                <div className={styles.value}>
                                                    <NumberFormat value={selectedLine.originalCurrencyVatPrice}
                                                                  displayType="text" decimalSeparator=","
                                                                  thousandSeparator="." fixedDecimalScale
                                                                  decimalScale={2}/> {billingDocument.originalCurrency}
                                                </div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Depot')}</div>
                                                <div className={styles.value}>{selectedLine.depotName}</div>
                                            </div>
                                            <div className={styles.field}>
                                                <div className={styles.label}>{t('Service Provider')}</div>
                                                <div className={styles.value}>{selectedLine.serviceProviderName}</div>
                                            </div>
                                        </div> : null}
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className={styles.loadingContainer}>
                        <FontAwesomeIcon icon={faSpinner} spin/>
                        <div className={styles.caption}>{t('loading')}</div>
                    </div>
                }
            </div>
        );
    }

   private onDownloadInvoice(billingDocumentReference: string): void {
        const {fleetCustomer, dispatchAddToast, t} = this.props;

        if (billingDocumentReference) {
            BillingApi.downloadInvoice(fleetCustomer!.id, billingDocumentReference)
                .then((success) => {
                    if (!success) {
                        dispatchAddToast({
                            id: generateUUID(),
                            messages: [t('This document is not yet available.')],
                            type: ToastType.Error,
                            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                        })
                    }
                });
        }
    }

    public selectLine(line: BillingLine): void {
        this.setState({selectedLine: line});
    }
}

const mapStateToProps = ({router, billing, authentication}: ApplicationState): PropsFromState => ({
    fleetCustomer: authentication.fleetCustomer,
    billingDocument: billing.billingDocument,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBillingDocument: (billingDocumentId: string) => dispatch(getBillingDocumentRequest(billingDocumentId)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingDetailsPopup));

interface OwnProps {
    billingDocumentId?: string;
}

interface PropsFromState {
    billingDocument?: BillingDocument;
    fleetCustomer?: FleetCustomer;
}

interface PropsFromDispatch {
    getBillingDocument: typeof getBillingDocumentRequest;
    dispatchAddToast: typeof addToast;
}

type AllProps =
    PropsFromState
    & PropsFromDispatch
    & WithTranslation
    & OwnProps;

export function updateBillingDetailsQueryParameters(billingDocumentId: string, search: string): string {
    return buildSearchParameters(
        {
            billingDocumentId,
            popup: PopupType.BillingDetails,
            serviceProviderId: undefined,
            vehicleId: undefined,
            jobId: undefined,
        },
        search,
    );
}

interface OwnState {
    selectedLine?: BillingLine;
}
