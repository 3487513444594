import {faQuestionCircle} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../../store';
import styles from './traffic-lights-widget.module.scss';

class TrafficLightsWidget extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {
            data: [
                {
                    name: 'Tire Pressure',
                    good: 0.3,
                    warning: 0.3,
                    risk: 0.4,
                    threshold: 0.35,
                    warningBoundaryLabel: '6bar',
                    riskBoundaryLabel: '4bar',
                },
                {
                    name: 'Tread Depth',
                    good: 0.2,
                    warning: 0.5,
                    risk: 0.3,
                    threshold: 0.21,
                    warningBoundaryLabel: '8mm',
                    riskBoundaryLabel: '6mm',
                },
                {
                    name: 'Inspection Frequency',
                    good: 0.5,
                    warning: 0.25,
                    risk: 0.25,
                    threshold: 0.47,
                    warningBoundaryLabel: '90d',
                    riskBoundaryLabel: '120d',
                },
                {
                    name: 'Breakdown Ratio',
                    good: 0.7,
                    warning: 0.2,
                    risk: 0.1,
                    threshold: 0.51,
                    warningBoundaryLabel: '12m',
                    riskBoundaryLabel: '6m',
                },
                {
                    name: 'Retread Ratio',
                    good: 0.6,
                    warning: 0,
                    risk: 0.4,
                    threshold: 0.61,
                    warningBoundaryLabel: 'retread vs new',
                },
            ],
        };
    }

    public render(): JSX.Element {
        const {t} = this.props;
        const {data} = this.state;

        return (
            <div className={styles.widgetContainer}>
                <div className={styles.widgetHeader}>
                    <div className={styles.headerTitle}>{t('Alerts')}</div>
                </div>
                <div className={styles.widgetContent}>
                    {data.map((d) => {
                        let trafficColor = styles.green;
                        if (d.threshold > d.good + d.warning) {
                            trafficColor = styles.red;
                        } else if (d.threshold > d.good) {
                            trafficColor = styles.orange;
                        }

                        return (
                            <div className={styles.alertContainer}>
                                <div className={`${styles.trafficLight} ${trafficColor}`}/>
                                <div className={styles.label}>{t(d.name)}</div>
                                <div className={styles.graphContainer}>
                                    <div className={styles.barGraph}>
                                        <div className={styles.greenSection} style={{width: `${d.good * 100}%`}}>
                                            {d.good > 0.1 ? `${d.good * 100}%` : ``}
                                        </div>
                                        <div className={styles.boundary} style={{left: `${d.good * 100}%`}}>
                                            {d.warningBoundaryLabel}
                                        </div>
                                        <div className={styles.orangeSection} style={{width: `${d.warning * 100}%`}}>
                                            {d.warning > 0.1 ? `${d.warning * 100}%` : ``}
                                        </div>
                                        <div
                                            className={styles.boundary}
                                            style={{left: `${d.good * 100 + d.warning * 100}%`}}>
                                            {d.riskBoundaryLabel}
                                        </div>
                                        <div className={styles.redSection} style={{width: `${d.risk * 100}%`}}>
                                            {d.risk > 0.1 ? `${d.risk * 100}%` : ``}
                                        </div>
                                        <div className={styles.indicator} style={{left: `${d.threshold * 100}%`}}>
                                            <FontAwesomeIcon className={styles.icon} size='3x' icon={faCaretDown}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.questionIcon}>
                                    <FontAwesomeIcon size='lg' icon={faQuestionCircle}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TrafficLightsWidget));

interface PropsFromState {
}

interface PropsFromDispatch {
}

export interface OwnProps {
}

export interface OwnState {
    data: AlertData[];
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;

interface AlertData {
    name: string;
    threshold: number;
    good: number;
    warning: number;
    risk: number;
    warningBoundaryLabel?: string;
    riskBoundaryLabel?: string;
}
