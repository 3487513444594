import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import * as am4charts from '@amcharts/amcharts4/charts';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    InspectionHistogramData,
    InspectionHistogramRequest,
} from '../../../../store/analytics/types/inspection-histogram.types';
import {ApplicationState} from '../../../../store';
import {DateRange, fetchInspectionHistogramRequest} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './inspection-histogram-widget.module.scss';

import WidgetLoading from '../widget-loading';

import {buildRequest} from './request-builder';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {bucketField, buildChartData, inspectionCountField} from './chart-data-adapter';
import {addStandardTooltipStyle, addStrokeToColumnChart, createXYChart, rotateAxisLabels} from '../utils/chart-utils';
import {colorBlue5} from '../utils/chart-colors';
import {ParamKey} from '../../../../store/dashboard';
import {formatDateInBrusselsTime} from '../../../../utils/date-helper';

class InspectionHistogramWidget extends SingleChartWidgetBase<AllProps, AllState, InspectionHistogramData[]> {
    private chartId: string;

    private bucketCount: number;

    private bucketSize: number;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.bucketCount = 11;
        this.bucketSize = 15;
    }

    public render(): JSX.Element {
        const {t, data} = this.props;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Last inspected Histogram')} <span className={styles.titleNote}>{this.getFormattedDate()}</span>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading />}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchInspectionHistogramRequest(buildRequest(this.props.controlsSnapshot, this.bucketSize, this.bucketCount));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: InspectionHistogramData[]) {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = bucketField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.title.text = this.props.t('# Days ago');
        if (data.length > 60) {
            rotateAxisLabels(categoryAxis);
        }
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Inspections');

        this.addSeries(chart);
        chart.data = buildChartData(data, this.bucketCount, this.bucketSize);

        return chart;
    }

    private addSeries(chart: any) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = inspectionCountField;
        series.dataFields.categoryX = bucketField;
        series.dataFields.name = bucketField;
        series.columns.template.fill = colorBlue5;
        series.columns.template.maxWidth = 100;
        series.columns.template.tooltipText = `{name}: [bold]{valueY} ${this.props.t('vehicles')}[/]`;
        series.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
    }

    private getFormattedDate(): string {
        const inspectionPeriod = this.props.controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        const now = new Date();
        const date = inspectionPeriod.end > now ? now : inspectionPeriod.end;
        return formatDateInBrusselsTime(date, ' (DD-MM-YYYY)');
    }
}

const mapStateToProps = ({analytics}: ApplicationState) => ({
    data: analytics.inspectionHistogramData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchInspectionHistogramRequest:(request: InspectionHistogramRequest) => dispatch(fetchInspectionHistogramRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InspectionHistogramWidget));

interface PropsFromState {
    data: InspectionHistogramData[];
}

interface PropsFromDispatch {
    fetchInspectionHistogramRequest: typeof fetchInspectionHistogramRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
