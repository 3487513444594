import {DateRangeRequest} from '../types';

export interface TopRetreadTiresFittedByServiceProvidersRequest extends DateRangeRequest {
    orderType: RetreadOrderType;
}

export interface TopReusableTiresFittedByServiceProvidersRequest extends DateRangeRequest {
    orderType: ReusableOrderType;
}

export interface TopRetreadTiresFittedByServiceProvidersData {
    readonly id: string;
    readonly name: string;
    readonly city: string;
    readonly newCount: number;
    readonly retreadCount: number;
}

export interface TopReusableTiresFittedByServiceProvidersData {
    readonly id: string;
    readonly name: string;
    readonly city: string;
    readonly newCount: number;
    readonly reusableCount: number;
}

export enum RetreadOrderType {
    NEW_TIRES = 'newTires',
    RETREAD_TIRES = 'retreadTires',
}

export enum ReusableOrderType {
    NEW_TIRES = 'newTires',
    REUSABLE_TIRES = 'reusableTires',
}
