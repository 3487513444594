import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import PageHeader from '../../components/page-header/page-header';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {User} from '../../store/authentication';
import {FleetCustomerWithConfiguration} from '../../store/fleet-customers';
import * as layoutActions from '../../store/layout/actions';
import {FleetCustomerMatchParameters} from '../../types/fleet-customer';
import ReportCatalogItemComponent from './components/report-catalog-item.component';
import styles from './reports.module.scss';
import ReportsMenuComponent, {ReportTab} from './components/reports-menu.component';
import {ReportCatalogEntry} from './report-catalog';
import {getReportConfigurationsRequest, ReportConfigurationContract} from '../../store/report-management';
import {getAllowedReports} from './report-access-helper';
import WidgetLoading from '../analytics/dashboard-widgets/widget-loading';

class ReportsPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {
            dispatchToggleSidebar,
            dispatchGetReportConfigurations,
            user,
            fleetCustomer,
            reportConfigurations
        } = this.props;
        dispatchToggleSidebar(true);

        this.state = {
            catalogEntries: (user && fleetCustomer && reportConfigurations) ? getAllowedReports(user, fleetCustomer, reportConfigurations) : [],
            reportTabs: [ReportTab.DownloadableReports, ReportTab.ReportScheduler],
            activeTab: ReportTab.DownloadableReports,
        };

        if (!reportConfigurations || reportConfigurations.length === 0) {
            dispatchGetReportConfigurations();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {user, fleetCustomer, reportConfigurations} = this.props;

        if (user && fleetCustomer && reportConfigurations &&
            (user !== prevProps.user || fleetCustomer !== prevProps.fleetCustomer || reportConfigurations !== prevProps.reportConfigurations)) {
            this.setState({catalogEntries: getAllowedReports(user, fleetCustomer, reportConfigurations)});
        }
    }

    public render(): JSX.Element {
        const {t, location, reportConfigurations} = this.props;
        const {catalogEntries, reportTabs, activeTab} = this.state;
        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={t('Reports')}
                            location={location}
                            bottomComponent={
                                <ReportsMenuComponent
                                    reportTabs={reportTabs}
                                    activeReportTab={activeTab}
                                    onSelectReportTab={(tab) => this.onSelectTab(tab)}
                                />
                            }
                />
                <div className={styles.pageContent}>
                    {catalogEntries && catalogEntries.length > 0 ? (
                        <div className={styles.catalogItems}>
                            {catalogEntries.map((item) => (
                                <ReportCatalogItemComponent
                                    key={item.type}
                                    catalogItem={item}
                                    onSelect={() => this.navigateTo(item.route)}
                                />
                            ))}
                        </div>
                    ) : (!reportConfigurations || reportConfigurations.length === 0)
                        ? <WidgetLoading/>
                        : (
                            <div className={styles.noReports}>
                                <div className={styles.iconPanel}>
                                    <FontAwesomeIcon icon={faInfoCircle} size="3x"/>
                                </div>
                                <div className={styles.textPanel}>
                                    <div>{t('You do not have access to any reports for this fleet.')}</div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }

    private onSelectTab(tab: ReportTab): void {
        if (tab === ReportTab.DownloadableReports) {
            this.navigateTo(RouteUrl.Reports);
        } else if (tab === ReportTab.ReportScheduler) {
            this.navigateTo(RouteUrl.ReportScheduler);
        }
    }

    private navigateTo(route: RouteUrl): void {
        const {dispatchNavigateTo, fleetCustomer} = this.props;
        dispatchNavigateTo({pathname: `/${fleetCustomer?.id}${route}`});
    }
}

const mapStateToProps = ({authentication, router, reportConfigurations}: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    user: authentication.user,
    currentLocation: router.location,
    reportConfigurations: reportConfigurations.reportConfigurations,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchGetReportConfigurations: () => dispatch(getReportConfigurationsRequest()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportsPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomerWithConfiguration;
    user: User;
    reportConfigurations: ReportConfigurationContract[];
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchNavigateTo: typeof push;
    dispatchGetReportConfigurations: typeof getReportConfigurationsRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    catalogEntries: ReportCatalogEntry[];
    reportTabs: ReportTab[];
    activeTab: ReportTab;
}

type AllState = OwnState;
