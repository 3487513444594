import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {push} from 'connected-react-router';
import {TFunction} from 'i18next';
import ReactTooltip from 'react-tooltip';
import {ApplicationState} from '../../store';
import {FleetCustomerWithConfiguration} from '../../store/fleet-customers';
import {closePopup, PopupType} from '../../store/popup';
import {RequestStatus, RequestStatusType} from '../../store/shared/types';
import {fetchVehicleRequest, TireFitment, VehicleJobs, VehicleWithFitmentsAndJobs} from '../../store/vehicles';
import {groupBy} from '../../utils/collection-helper';
import {format, formatDateInBrusselsTime} from '../../utils/date-helper';
import {buildSearchParameters} from '../../utils/query-parameter-helpers';
import WidgetTable, {WidgetColumnConfig, WidgetTableColumnType} from '../widget-table/widget-table';
import {ReactComponent as TPMSIcon} from '../../../assets/icons/icons_24_px_tpms_indicator.svg';
import {ReactComponent as DORIcon} from '../../../assets/icons/ic_dor_32_px.svg';
import {
    GroupPosition,
    Axle,
    TireGroup,
    TireInfo,
    TireValues,
    VehicleConfiguration,
} from './types/vehicle-configuration';
import styles from './vehicle-details-popup.module.scss';
import {RouteUrl} from '../../routes';
import VehicleConfigurationView from './components/vehicle-configuration-viewer/vehicle-configuration-view';
import {
    getLastSeenValue,
    getTireValueType,
} from './components/vehicle-configuration-viewer/tire-value-helper';
import {updateTireServiceQueryParameters} from '../tire-service-details-popup/tire-service-details-popup';
import {getInspectionTypeLabel, getJobTypeLabel} from '../../utils/translations/job-type-translation-helpert';

class VehicleDetailsPopup extends Component<AllProps> {
    constructor(props) {
        super(props);

        const {vehicleId, fleetCustomer, dispatchGetVehicleDetails} = this.props;
        if (vehicleId && fleetCustomer) {
            dispatchGetVehicleDetails({
                vehicleId,
                fleetCustomerId: fleetCustomer.id,
                language: fleetCustomer.activeLanguage.type
            });
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<any>, snapshot?: any): void {
        const {vehicleId, fleetCustomer, dispatchGetVehicleDetails} = this.props;
        if (vehicleId && fleetCustomer && fleetCustomer !== prevProps.fleetCustomer) {
            dispatchGetVehicleDetails({
                vehicleId,
                fleetCustomerId: fleetCustomer.id,
                language: fleetCustomer.activeLanguage.type
            });
        }
    }

    public render() {
        const {vehicleInfo, t, requestStatus} = this.props;
        const configuration = vehicleInfo
            ? this.buildVehicleConfiguration(vehicleInfo.tireFitments)
            : undefined;
        const details = configuration ? this.buildDetailData(configuration, t) : [];
        const jobs = vehicleInfo ? this.buildJobDetailData(vehicleInfo.vehicleJobs,t) : [];

        const columnConfigs: WidgetColumnConfig[] = [
            {title: t('Position'), property: 'position', type: WidgetTableColumnType.text, width: '30%'},
            {
                title: t('Serial Number'),
                property: 'serialNumber',
                type: WidgetTableColumnType.text,
                width: '35%',
                clickAction: this.onSerialNumberClick.bind(this),
                skipClick: this.serialNumberSkipClick.bind(this)
            },
            {title: t('Last Seen'), property: 'lastSeen', type: WidgetTableColumnType.text, width: '35%'},
        ];

        const jobConfigs: WidgetColumnConfig[] = [
            {
                title: t('Job'), property: 'job', type: WidgetTableColumnType.text, width: '15%',
                clickAction: this.onJobNumberClick.bind(this),
                skipClick: this.jobNumberSkipClick.bind(this)
            },
            {title: t('Type'), property: 'type', type: WidgetTableColumnType.text, width: '20%'},
            {
                title: t('Inspection Type Indicator'),
                property: 'inspectionTypeIndicator',
                type: WidgetTableColumnType.text,
                width: '20%'
            },
            {title: t('Requested Date'), property: 'requestedDate', type: WidgetTableColumnType.text, width: '15%'},
            {title: t('Start Date'), property: 'startDate', type: WidgetTableColumnType.text, width: '15%'},
            {title: t('Odometer Reading'), property: 'odometerReading', type: WidgetTableColumnType.text, width: '15%'},
        ]

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupContent}>
                    <div className={styles.contentTitle}>
                        <div className={styles.type}>{t('Vehicle')}</div>
                        <div className={styles.info}>
                            <div className={styles.id}>{vehicleInfo ? vehicleInfo.vehicle.fleetReference : ''}</div>
                            <div className={styles.dorIndicator}>{vehicleInfo?.vehicle.hasDOR ?
                                <>
                                    <DORIcon className={styles.dorIcon} data-tip
                                             data-for={`${vehicleInfo.vehicle.id}dor`}/>
                                    <ReactTooltip id={`${vehicleInfo.vehicle.id}dor`}
                                                  className={styles.tooltip}
                                                  place="right"
                                                  effect="solid"
                                                  type="dark">{t('This vehicle\'s tire information can be captured by a DOR (Drive-Over-Reader) checkpoint')}</ReactTooltip>
                                </>
                                : null}</div>
                            <div className={styles.tpmsIndicator}>{vehicleInfo?.vehicle.hasTPMS ?
                                <>
                                    <TPMSIcon className={styles.tpmsIcon} data-tip
                                              data-for={`${vehicleInfo.vehicle.id}tpms`}/>
                                    <ReactTooltip id={`${vehicleInfo.vehicle.id}tpms`}
                                                  className={styles.tooltip}
                                                  place="right"
                                                  effect="solid"
                                                  type="dark">{t('This vehicle is equipped with TPMS (Tyre Pressure Monitoring System)')}</ReactTooltip>
                                </>
                                : null}</div>
                        </div>

                    </div>
                    <div className={styles.contentBody}>
                        {vehicleInfo ? (
                            <div className={styles.infoContainers}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('License Plate')}</div>
                                        <div className={styles.value}>{vehicleInfo.vehicle.licensePlate}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Chassis Number')}</div>
                                        <div className={styles.value}>{vehicleInfo.vehicle.chassisNr}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Type')}</div>
                                        <div className={styles.value}>{vehicleInfo.vehicle.type}</div>
                                    </div>
                                </div>
                                <div className={styles.infoContainer}>
                                    {vehicleInfo.vehicle.depotName ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Depot')}</div>
                                            <div className={styles.value}>{vehicleInfo.vehicle.depotName}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {vehicleInfo.vehicle.activeFrom ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Active From')}</div>
                                            <div className={styles.value}>
                                                {format(new Date(vehicleInfo.vehicle.activeFrom), 'MMM D, YYYY')}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {vehicleInfo.vehicle.activeUntil ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Active Until')}</div>
                                            <div className={styles.value}>
                                                {format(new Date(vehicleInfo.vehicle.activeUntil), 'MMM D, YYYY')}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {vehicleInfo && vehicleInfo.tireFitments.length > 0 ? (
                            <div className={styles.configurationContainer}>
                                <div className={styles.configurationView}>
                                    <VehicleConfigurationView configuration={configuration}/>
                                </div>
                                <div className={styles.configurationDetails}>
                                    <WidgetTable config={{columns: columnConfigs, hideLastRecordBorder: true}}
                                                 data={details}/>
                                </div>
                            </div>
                        ) : requestStatus && requestStatus.type === RequestStatusType.InProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}} spin/>{' '}
                                {t('loading')}
                            </div>
                        ) : (
                            <div>{t('No tires fitted')}</div>
                        )}
                        <div className={styles.jobsTitle}>{t('Job list')}</div>
                        {vehicleInfo && vehicleInfo.vehicleJobs.length > 0 ? (
                            <div className={styles.configurationContainer}>
                                <div className={styles.configurationDetails}>
                                    <WidgetTable config={{columns: jobConfigs, hideLastRecordBorder: true}}
                                                 data={jobs}/>
                                </div>
                            </div>
                        ) : requestStatus && requestStatus.type === RequestStatusType.InProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}} spin/>{' '}
                                {t('loading')}
                            </div>
                        ) : (
                            <div>{t('No jobs found')}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private serialNumberSkipClick(value: string) {
        return value === undefined || value === null;
    }

    private jobNumberSkipClick(value: string) {
        return false;
    }

    private onJobNumberClick(value: string, row: JobRecord) {
        const {dispatchClosePopup, dispatchNavigateTo, currentLocation} = this.props;
        const queryParameters = updateTireServiceQueryParameters(row.jobId, currentLocation.search);
        dispatchClosePopup();
        dispatchNavigateTo({search: queryParameters});
    }

    private onSerialNumberClick(value: string, row: TireDetailRecord) {
        const {dispatchClosePopup, dispatchNavigateTo, fleetCustomer, currentLocation} = this.props;
        const returnUrl = currentLocation.search ? `${currentLocation.pathname}${currentLocation.search}` : currentLocation.pathname;
        const returnUrlEncoded = encodeURIComponent(returnUrl);
        const queryParameters = buildSearchParameters(
            {
                serialNumber: value,
                returnUrl: returnUrlEncoded
            },
        );
        dispatchClosePopup();
        dispatchNavigateTo({pathname: `/${fleetCustomer?.id}${RouteUrl.TireHistoryReport}`, search: queryParameters});
    }

    private buildDetailData(vehicleConfiguration: VehicleConfiguration, t: TFunction): TireDetailRecord[] {
        const tireInfos: TireInfo[] = [];
        vehicleConfiguration.axles.forEach(axle => {
            axle.leftTires.tires.forEach(t => {
                tireInfos.push(t);
            });
            axle.rightTires.tires.forEach(t => {
                tireInfos.push(t);
            });
        })
        vehicleConfiguration.spareTires.forEach(t => {
            tireInfos.push(t);
        });
        tireInfos.sort((a, b) => a.number! - b.number!);
        return tireInfos.map((tireInfo) => {
            const tireValueType = getTireValueType(tireInfo);
            const lastSeen = getLastSeenValue(tireInfo, tireValueType, t);
            return {
                position: tireInfo.position,
                serialNumber: tireInfo.serialNumber,
                lastSeen
            }
        });
    }

    private buildJobDetailData(jobDetails: VehicleJobs[], t: TFunction): JobRecord[] {
        return jobDetails.map((job) => ({
            job: job.job,
            type: getJobTypeLabel(job.type, t),
            inspectionTypeIndicator: getInspectionTypeLabel(t, job.inspectionTypeIndicator),
            requestedDate: job.requestedDate === undefined ? '' : format(new Date(job.requestedDate), 'MMM D, YYYY'),
            startDate: job.startDate === undefined ? '' : format(new Date(job.startDate), 'MMM D, YYYY'),
            odometerReading: job.odometerReading,
            jobId: job.jobId
        }));
    }

    private buildVehicleConfiguration(fitments: TireFitment[]): VehicleConfiguration {
        const fittedTires = fitments
            .filter((f) => f.positionType === 'W-L' || f.positionType === 'W-R')
            .sort((a, b) => a.axle - b.axle);
        const spares = fitments.filter((f) => f.positionType === 'W-SP');
        const groupedByAxle: Map<number, TireFitment[]> = groupBy(fittedTires, 'axle');

        let counter = 1;
        const axles = Array.from(groupedByAxle)
            .map(([axleNumber, axleTires]) => {
                const axleLeftTires = axleTires.filter((f) => f.positionType === 'W-L');
                axleLeftTires.sort((a, b) => a.positionFromEdge - b.positionFromEdge);
                const leftTires = this.createTireGroup(axleNumber, GroupPosition.Left, counter, axleLeftTires);
                counter += leftTires.tires.length;
                const axleRightTires = axleTires.filter((f) => f.positionType === 'W-R');
                axleRightTires.sort((a, b) => b.positionFromEdge - a.positionFromEdge);
                const rightTires = this.createTireGroup(axleNumber, GroupPosition.Right, counter, axleRightTires);
                counter += rightTires.tires.length;
                return new Axle(axleNumber, leftTires, rightTires);
            })
            .sort((a, b) => a.position - b.position);

        const spareTires = spares.map((t) => this.createTireInfo(0, counter++, t));

        return new VehicleConfiguration(axles, spareTires);
    }

    private createTireGroup(axleNumber: number, position: GroupPosition, counter: number, fitments: TireFitment[]): TireGroup {
        const tireInfos = fitments.map((f, i) => this.createTireInfo(axleNumber, counter + i, f));
        return new TireGroup(position, tireInfos);
    }

    private createTireInfo(axleNumber: number, counter: number, fitment: TireFitment): TireInfo {
        // eslint-disable-next-line no-param-reassign
        fitment.displayNumber = counter;
        const position = this.determinePosition(axleNumber, fitment.positionFromEdge, fitment.positionType);
        return new TireInfo(counter, position, fitment.tire.description, fitment.tire.brand, fitment.tire.sizeTypeId, fitment.tire.serialNumber, fitment.tire.materialType === 'Retread', this.getValues(fitment), this.getDORValues(fitment));
    }

    private determinePosition(axleNumber: number, positionFromEdge: number, positionType: string): string {
        if (positionFromEdge && positionFromEdge > 0 && (positionType === 'W-L' || positionType === 'W-R')) {
            return `${axleNumber}${positionFromEdge === 1 ? 'O' : 'I'}${positionType === 'W-L' ? 'L' : 'R'}`;
        } else {
            return 'SP';
        }
    }

    private getValues(fitment: TireFitment): TireValues | undefined {
        if (fitment.lastSeen) {
            const lastSeenDate = new Date(fitment.lastSeen);
            return new TireValues(formatDateInBrusselsTime(lastSeenDate, 'YYYY-MM-DD HH:mm'), fitment.pressureLastSeen, fitment.treadDepthLastSeen);
        } else {
            return undefined;
        }
    }

    private getDORValues(fitment: TireFitment): TireValues | undefined {
        if (fitment.lastSeenDOR) {
            const lastSeenDORDate = new Date(fitment.lastSeenDOR);
            return new TireValues(formatDateInBrusselsTime(lastSeenDORDate, 'YYYY-MM-DD HH:mm'), fitment.pressureLastSeenDOR, fitment.treadDepthLastSeenDOR);
        } else {
            return undefined;
        }
    }
}

const mapStateToProps = ({router, vehicles, authentication}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    vehicleInfo: vehicles.vehicle,
    requestStatus: vehicles.vehicleRequestStatus,
    fleetCustomer: authentication.fleetCustomer ? authentication.fleetCustomer : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchGetVehicleDetails: (request) =>
        dispatch(fetchVehicleRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VehicleDetailsPopup));

interface OwnProps {
    vehicleId?: string;
}

interface PropsFromState {
    currentLocation: Location;
    requestStatus?: RequestStatus;
    vehicleInfo?: VehicleWithFitmentsAndJobs;
    fleetCustomer?: FleetCustomerWithConfiguration;
}

interface PropsFromDispatch {
    dispatchGetVehicleDetails: typeof fetchVehicleRequest;
    dispatchNavigateTo: (LocationDescriptorObject) => void;
    dispatchClosePopup: typeof closePopup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

export function updateVehicleDetailsQueryParameters(vehicleId: string, search: string): string {
    return buildSearchParameters(
        {
            vehicleId,
            popup: PopupType.VehicleDetails,
            billingDocumentId: undefined,
            serviceProviderId: undefined,
            jobId: undefined,
        },
        search,
    );
}

export interface TireDetailRecord {
    position: string;
    serialNumber: string;
    lastSeen: string;
}

export interface JobRecord {
    jobId: string;
    job: string;
    type: string;
    inspectionTypeIndicator: string;
    requestedDate: string;
    startDate: string;
    odometerReading: string;
}
