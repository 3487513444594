import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import SearchApi from '../../api/search-api';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { ApplicationState } from '../index';
import { searchFailure, searchSuccess } from './actions';
import { SearchActionTypes, SearchResult } from './types';

const getFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleSearch(action) {
    try {
        const fleetCustomerId = yield getFleetCustomerIdFromState();
        const result: ApiResult<SearchResult[]> = yield call(SearchApi.search, fleetCustomerId, action.payload);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
            yield put(searchFailure());
        } else {
            yield put(searchSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(searchFailure());
    }
}

function* watchSearchRequest() {
    yield takeEvery(SearchActionTypes.SEARCH_REQUEST, handleSearch);
}

function* searchSaga() {
    yield all([fork(watchSearchRequest)]);
}

export default searchSaga;
