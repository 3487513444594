import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';

import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ApplicationState} from '../../store';
import {logoutRequest, User} from '../../store/authentication';
import {clearSearch, searchRequest, SearchResult, SearchResultType} from '../../store/search';
import {updateBillingDetailsQueryParameters} from '../billing-details-popup/billing-details-popup';
import MenuButton from '../header-menu/menu-button';
import HeaderSearch from '../header-search/header-search';
import {updateServiceProviderDetailsQueryParameters} from '../service-provider-details-popup/service-provider-details-popup';
import {updateVehicleDetailsQueryParameters} from '../vehicle-details-popup/vehicle-details-popup';
import styles from './page-header.module.scss';
import {updateTireServiceQueryParameters} from '../tire-service-details-popup/tire-service-details-popup';
import JobsApi from '../../api/jobs-api';
import {FleetCustomer} from '../../store/fleet-customers';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../store/toast';
import {generateUUID} from '../../utils/uuid-helpers';
import BillingApi from '../../api/billing-api';

class PageHeader extends Component<AllProps, AllState> {
    public render() {
        const {headerTitle, bottomComponent, isSearching, searchQuery, searchResults} = this.props;

        return (
            <div className={styles.headerContainer}>
                <div className={styles.topContainer}>
                    <div className={styles.pageTitle}>{headerTitle}</div>
                    <div className={styles.rightPanel}>
                        <HeaderSearch
                            onSearch={(query): void => this.onSearch(query)}
                            onClearSearch={(): void => this.onClearSearch()}
                            onResultSelected={(result): void => this.onResultSelected(result)}
                            isSearching={isSearching}
                            lastSearchQuery={searchQuery}
                            searchResults={searchResults}
                        />
                        <MenuButton/>
                    </div>
                </div>
                <div className={styles.bottomContainer}>{bottomComponent}</div>
            </div>
        );
    }

    private onClearSearch(): void {
        const {dispatchClearSearch} = this.props;
        dispatchClearSearch();
    }

    private onSearch(searchQuery: string): void {
        const {dispatchSearch} = this.props;
        dispatchSearch(searchQuery);
    }

    private onResultSelected(result: SearchResult): void {
        const {dispatchNavigateTo, location, fleetCustomer,dispatchAddToast, t} = this.props;
        switch (result.type) {
            case SearchResultType.Vehicle: {
                const queryParameters = updateVehicleDetailsQueryParameters(result.id, location.search);
                dispatchNavigateTo({search: queryParameters});
                break;
            }
            case SearchResultType.BillingDocument: {
                const queryParameters = updateBillingDetailsQueryParameters(result.id, location.search);
                dispatchNavigateTo({search: queryParameters});
                break;
            }
            case SearchResultType.Invoice: {
                BillingApi.downloadInvoice(fleetCustomer.id, result.id)
                    .then((success) => {
                        if (!success) {
                            dispatchAddToast({
                                id: generateUUID(),
                                messages: [t('This document is not yet available.')],
                                type: ToastType.Error,
                                removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                            })
                        }
                    });
                break;
            }
            case SearchResultType.ServiceProvider: {
                const queryParameters = updateServiceProviderDetailsQueryParameters(result.id, location.search);
                dispatchNavigateTo({search: queryParameters});
                break;
            }
            case SearchResultType.Job: {
                const queryParameters = updateTireServiceQueryParameters(result.id, location.search);
                dispatchNavigateTo({search: queryParameters});
                break;
            }
            case SearchResultType.Ejob: {
                JobsApi.downloadEjobSheet(fleetCustomer.id, result.id, result.name)
                    .then((success) => {
                        if (!success) {
                           dispatchAddToast({
                               id: generateUUID(),
                               messages: [t('This document is not yet available.')],
                               type: ToastType.Error,
                               removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                           })
                        }
                    });
                break;
            }
            default:
                break;
        }
    }
}

const mapStateToProps = ({authentication, search}: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    user: authentication.user,
    isSearching: search.isSearching,
    searchResults: search.searchResults,
    searchQuery: search.searchQuery,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogoutRequest: () => dispatch(logoutRequest()),
    dispatchSearch: (searchQuery) => dispatch(searchRequest(searchQuery)),
    dispatchClearSearch: () => dispatch(clearSearch()),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PageHeader));

interface OwnProps {
    location: Location;
    headerTitle: string;
    bottomComponent: Element;
}

interface PropsFromState {
    fleetCustomer: FleetCustomer;
    user: User;
    isSearching: boolean;
    searchQuery: string;
    searchResults: SearchResult[];
}

interface PropsFromDispatch {
    dispatchLogoutRequest: typeof logoutRequest;
    dispatchNavigateTo: (LocationDescriptorObject) => void;
    dispatchSearch: typeof searchRequest;
    dispatchClearSearch: typeof clearSearch;
    dispatchAddToast: typeof addToast;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation &OwnProps;

interface OwnState {
}

type AllState = OwnState;
