import { action } from 'typesafe-actions';
import { ControlValue } from '../../pages/analytics/dashboard-controls/controls-snapshot';
import { ParamKey } from '../dashboard';
import {
    AnalyticsActionTypes, DateRangeWithFunctionalLocationsAndLanguageRequest,
    DateRangeWithFunctionalLocationsRequest, DateWithFunctionalLocationsAndLanguageRequest,
    DateWithFunctionalLocationsRequest,
} from './types';
import {
    AlertData,
    AlertsWidgetDataRequest,
} from './types/alert.types';
import { FleetMovementData, FleetMovementRequest } from './types/fleet-movement.types';
import { FleetSizeEvolutionData, FleetSizeEvolutionRequest } from './types/fleet-size-evolution.types';
import { InspectionHistogramData, InspectionHistogramRequest } from './types/inspection-histogram.types';
import { InspectionStatusData, InspectionStatusRequest } from './types/inspection-status.types';
import { BillingDistributionRequest, BillingItemsDistributionData } from './types/invoice-items-distribution.types';
import { BillingEvolutionRequest, BillingItemsEvolutionData } from './types/invoice-items-evolution.types';
import { JobTypesDistributionData } from './types/job-types-distribution.types';
import { JobsEvolutionData, JobsEvolutionRequest } from './types/jobs-evolution.types';
import {
    AvoidableBreakdownsKPIData,
    AvoidableBreakdownsKPIRequest,
    KeyPerformanceIndicatorData,
    KeyPerformanceIndicatorRequest,
    RetreadRatioKPIData,
    RetreadRatioKPIRequest,
    UnderInflationKPIData,
    UnderInflationKPIRequest
} from './types/key-performance-indicator.types';
import {
    ServiceProvidersMonthlyOverviewData,
    ServiceProvidersMonthlyOverviewRequest,
} from './types/service-providers-monthly-overview.types';
import { TireBrandDistributionData } from './types/tire-brand-distribution.types';
import { TopServiceProvidersData, TopServiceProvidersRequest } from './types/top-service-providers-types';
import { TopTirePatternsData } from './types/top-tire-patterns.types';
import { VehiclesDistributionData, VehicleTypesDistributionRequest } from './types/vehicle-types-distribution.types';
import {
    TopRetreadTiresFittedByServiceProvidersData,
    TopRetreadTiresFittedByServiceProvidersRequest,
    TopReusableTiresFittedByServiceProvidersData,
    TopReusableTiresFittedByServiceProvidersRequest
} from "./types/top-tires-fitted-by-service-providers-types";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchFleetSizeEvolutionRequest = (request: FleetSizeEvolutionRequest) =>
    action(AnalyticsActionTypes.DAILY_FLEET_SIZE_EVOLUTION_REQUEST, request);
export const fetchFleetSizeEvolutionSuccess = (data: FleetSizeEvolutionData[]) =>
    action(AnalyticsActionTypes.DAILY_FLEET_SIZE_EVOLUTION_SUCCESS, data);

export const fetchVehicleTypesDistributionRequest = (request: VehicleTypesDistributionRequest) =>
    action(AnalyticsActionTypes.VEHICLES_DISTRIBUTION_REQUEST, request);
export const fetchVehiclesDistributionSuccess = (data: VehiclesDistributionData[]) =>
    action(AnalyticsActionTypes.VEHICLES_DISTRIBUTION_SUCCESS, data);

export const fetchJobTypesDistributionRequest = (request: DateRangeWithFunctionalLocationsRequest) =>
    action(AnalyticsActionTypes.JOB_TYPES_DISTRIBUTION_REQUEST, request);
export const fetchJobTypesDistributionSuccess = (data: JobTypesDistributionData[]) =>
    action(AnalyticsActionTypes.JOB_TYPES_DISTRIBUTION_SUCCESS, data);

export const fetchJobEvolutionRequest = (request: JobsEvolutionRequest) =>
    action(AnalyticsActionTypes.DAILY_JOB_EVOLUTION_REQUEST, request);
export const fetchJobEvolutionSuccess = (data: JobsEvolutionData[]) =>
    action(AnalyticsActionTypes.DAILY_JOB_EVOLUTION_SUCCESS, data);

export const fetchBillingItemsDistributionRequest = (request: BillingDistributionRequest) =>
    action(AnalyticsActionTypes.BILLING_ITEMS_DISTRIBUTION_REQUEST, request);
export const fetchBillingItemsDistributionSuccess = (data: BillingItemsDistributionData[]) =>
    action(AnalyticsActionTypes.BILLING_ITEMS_DISTRIBUTION_SUCCESS, data);

export const fetchBillingItemsEvolutionRequest = (request: BillingEvolutionRequest) =>
    action(AnalyticsActionTypes.BILLING_ITEMS_EVOLUTION_REQUEST, request);
export const fetchBillingItemsEvolutionSuccess = (data: BillingItemsEvolutionData[]) =>
    action(AnalyticsActionTypes.BILLING_ITEMS_EVOLUTION_SUCCESS, data);

export const getAlertsWidgetDataRequest = (request: AlertsWidgetDataRequest) =>
    action(AnalyticsActionTypes.ALERTS_WIDGET_DATA_REQUEST, request);
export const getAlertsWidgetDataSuccess = (data: AlertData) =>
    action(AnalyticsActionTypes.ALERTS_WIDGET_DATA_SUCCESS, data);

export const getKPIDataRequest = (request: KeyPerformanceIndicatorRequest) =>
    action(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST, request);
export const getKPIDataSuccess = (data: KeyPerformanceIndicatorData) =>
    action(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_SUCCESS, data);

export const fetchInspectionStatusRequest = (request: InspectionStatusRequest) =>
    action(AnalyticsActionTypes.INSPECTION_STATUS_REQUEST, request);
export const fetchInspectionStatusSuccess = (data: InspectionStatusData[]) =>
    action(AnalyticsActionTypes.INSPECTION_STATUS_SUCCESS, data);

export const fetchServiceProvidersMonthlyOverviewRequest = (request: ServiceProvidersMonthlyOverviewRequest) =>
    action(AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST, request);
export const fetchServiceProvidersMonthlyOverviewSuccess = (data: ServiceProvidersMonthlyOverviewData[]) =>
    action(AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS, data);

export const fetchTopServiceProvidersRequest = (request: TopServiceProvidersRequest) =>
    action(AnalyticsActionTypes.TOP_SERVICE_PROVIDERS_REQUEST, request);
export const fetchTopServiceProvidersSuccess = (data: TopServiceProvidersData[]) =>
    action(AnalyticsActionTypes.TOP_SERVICE_PROVIDERS_SUCCESS, data);

export const fetchTopRetreadTiresFittedByServiceProviderRequest = (request: TopRetreadTiresFittedByServiceProvidersRequest) =>
    action(AnalyticsActionTypes.TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_REQUEST, request);
export const fetchTopRetreadTiresFittedByServiceProviderSuccess = (data: TopRetreadTiresFittedByServiceProvidersData[]) =>
    action(AnalyticsActionTypes.TOP_RETREAD_TIRES_BY_SERVICE_PROVIDER_SUCCESS, data);

export const fetchTopReusableTiresFittedByServiceProviderRequest = (request: TopReusableTiresFittedByServiceProvidersRequest) =>
    action(AnalyticsActionTypes.TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_REQUEST, request);
export const fetchTopReusableTiresFittedByServiceProviderSuccess = (data: TopReusableTiresFittedByServiceProvidersData[]) =>
    action(AnalyticsActionTypes.TOP_REUSABLE_TIRES_BY_SERVICE_PROVIDER_SUCCESS, data);

export const updateDefaultControlValues = (defaultValues: Map<ParamKey, ControlValue>) =>
    action(AnalyticsActionTypes.UPDATE_DEFAULT_CONTROL_VALUES, defaultValues);
export const updateSingleDefaultControlValue = (defaultValueKey: ParamKey, defaultControlValue: ControlValue) =>
    action(AnalyticsActionTypes.UPDATE_SINGLE_DEFAULT_CONTROL_VALUE, { defaultValueKey, defaultControlValue });

export const fetchFleetMovementRequest = (request: FleetMovementRequest) =>
    action(AnalyticsActionTypes.FLEET_MOVEMENT_REQUEST, request);
export const fetchFleetMovementSuccess = (data: FleetMovementData[]) =>
    action(AnalyticsActionTypes.FLEET_MOVEMENT_SUCCESS, data);

export const fetchInspectionHistogramRequest = (request: InspectionHistogramRequest) =>
    action(AnalyticsActionTypes.INSPECTION_HISTOGRAM_REQUEST, request);
export const fetchInspectionHistogramSuccess = (data: InspectionHistogramData[]) =>
    action(AnalyticsActionTypes.INSPECTION_HISTOGRAM_SUCCESS, data);

export const fetchTireFitmentsBrandDistributionRequest = (request: DateWithFunctionalLocationsRequest) =>
    action(AnalyticsActionTypes.TIRE_FITMENTS_BRAND_DISTRIBUTION_REQUEST, request);
export const fetchTireFitmentsBrandDistributionSuccess = (data: TireBrandDistributionData[]) =>
    action(AnalyticsActionTypes.TIRE_FITMENTS_BRAND_DISTRIBUTION_SUCCESS, data);

export const fetchTiresFittedBrandDistributionRequest = (request: DateRangeWithFunctionalLocationsRequest) =>
    action(AnalyticsActionTypes.TIRES_FITTED_BRAND_DISTRIBUTION_REQUEST, request);
export const fetchTiresFittedBrandDistributionSuccess = (data: TireBrandDistributionData[]) =>
    action(AnalyticsActionTypes.TIRES_FITTED_BRAND_DISTRIBUTION_SUCCESS, data);

export const fetchTireFitmentsTopPatternsRequest = (request: DateWithFunctionalLocationsAndLanguageRequest) =>
    action(AnalyticsActionTypes.TIRE_FITMENTS_TOP_PATTERNS_REQUEST, request);
export const fetchTireFitmentsTopPatternsSuccess = (data: TopTirePatternsData[]) =>
    action(AnalyticsActionTypes.TIRE_FITMENTS_TOP_PATTERNS_SUCCESS, data);

export const fetchTiresFittedTopPatternsRequest = (request: DateRangeWithFunctionalLocationsAndLanguageRequest) =>
    action(AnalyticsActionTypes.TIRES_FITTED_TOP_PATTERNS_REQUEST, request);
export const fetchTiresFittedTopPatternsSuccess = (data: TopTirePatternsData[]) =>
    action(AnalyticsActionTypes.TIRES_FITTED_TOP_PATTERNS_SUCCESS, data);

export const fetchAvoidableBreakdownsKPIRequest = (request: AvoidableBreakdownsKPIRequest) =>
    action(AnalyticsActionTypes.AVOIDABLE_BREAKDOWNS_KPI_REQUEST, request);
export const fetchAvoidableBreakdownsKPISuccess = (data: AvoidableBreakdownsKPIData) =>
    action(AnalyticsActionTypes.AVOIDABLE_BREAKDOWNS_KPI_SUCCESS, data);

export const fetchRetreadRatioKPIRequest = (request: RetreadRatioKPIRequest) =>
    action(AnalyticsActionTypes.RETREAD_RATIO_KPI_REQUEST, request);
export const fetchRetreadRatioKPISuccess = (data: RetreadRatioKPIData) =>
    action(AnalyticsActionTypes.RETREAD_RATIO_KPI_SUCCESS, data);

export const fetchUnderInflationKPIRequest = (request: UnderInflationKPIRequest) =>
    action(AnalyticsActionTypes.UNDER_INFLATION_KPI_REQUEST, request);
export const fetchUnderInflationKPISuccess = (data: UnderInflationKPIData) =>
    action(AnalyticsActionTypes.UNDER_INFLATION_KPI_SUCCESS, data);
