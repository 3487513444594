import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {
    VehiclesAction,
    VehiclesActionTypes,
    VehiclesState
} from './types';

function initialState(): VehiclesState {
    return {
        vehicle: undefined,
    };
}

const reducer: Reducer<VehiclesState, VehiclesAction> = (state = initialState(), action): VehiclesState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case VehiclesActionTypes.FETCH_VEHICLE_REQUEST: {
            return {...state, vehicle: undefined, vehicleRequestStatus:new RequestStatus(RequestStatusType.InProgress)};
        }
        case VehiclesActionTypes.FETCH_VEHICLE_SUCCESS: {
            return {...state, vehicle: action.payload, vehicleRequestStatus:new RequestStatus(RequestStatusType.Success)};
        }
        case VehiclesActionTypes.FETCH_VEHICLE_FAILURE: {
            return {...state, vehicleRequestStatus:new RequestStatus(RequestStatusType.Failed,action.payload)};
        }
        default: {
            return state;
        }
    }
};

export {reducer as vehiclesReducer};
export {initialState as initialVehiclesState};
