import {Reducer} from 'redux';
import {DepotSelectionAction, DepotSelectionActionTypes, DepotSelectionState} from './types';
import {SharedActionTypes} from '../shared/types';

function initialState(): DepotSelectionState {
    return {};
}

const reducer: Reducer<DepotSelectionState, DepotSelectionAction> = (state = initialState(), action): DepotSelectionState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case DepotSelectionActionTypes.FILTER_SOLD_TOS: {
            return {...state, soldTosFilter: action.payload};
        }
        case DepotSelectionActionTypes.FILTER_DIVISIONS: {
            return {...state, divisionsFilter: action.payload};
        }
        case DepotSelectionActionTypes.FILTER_DEPOTS: {
            return {...state, depotsFilter: action.payload};
        }
        case DepotSelectionActionTypes.FILTER_RESET: {
            return {...state, soldTosFilter: undefined, divisionsFilter: undefined, depotsFilter: undefined};
        }
        default: {
            return state;
        }
    }
};

export {reducer as depotSelectionReducer};
export {initialState as initialDepotSelectionState};
