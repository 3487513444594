import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import {FileType, Paged, RequestStatus, SortDirection} from '../shared/types';
import * as actions from './actions';
import {ReportType} from '../../pages/reports/report-catalog';
import {ScheduledReportDeliveryMethod, ScheduledReportGranularity} from '../reports';

export enum ScheduledReportsActionTypes {
    FETCH_ADMIN_SCHEDULED_REPORTS_REQUEST = '@@scheduled-reports/FETCH_ADMIN_SCHEDULED_REPORTS_REQUEST',
    FETCH_ADMIN_SCHEDULED_REPORTS_SUCCESS = '@@scheduled-reports/FETCH_ADMIN_SCHEDULED_REPORTS_SUCCESS',
    FETCH_ADMIN_SCHEDULED_REPORTS_FAILURE = '@@scheduled-reports/FETCH_ADMIN_SCHEDULED_REPORTS_FAILURE',
}

export type ScheduledReportsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ScheduledReportsState {
    readonly adminScheduledReports?:  Paged<AdminScheduledReport>;
    readonly getAdminScheduledReportsRequestStatus?: RequestStatus;
}

export interface AdminScheduledReportRequest {
    page: number;
    itemsPerPage: number;
    sort: ScheduledReportsSort;
    fleetCustomerNameContains?: string;
    nameContains?: string;
    reportTypeContains?: string;
    recipientsContains?: string;
    lastRunStatusExcludedOptions?:ScheduledReportRunStatus[];
}

export interface ScheduledReportsSort {
    column: ScheduledReportsSortColumn;
    direction: SortDirection;
}

export enum ScheduledReportsSortColumn {
    None = 'none',
    fleetCustomerName = 'fleetCustomerName',
    name = 'name',
    reportType = 'reportType',
    granularity = 'granularity',
    recipients = 'recipients',
    lastRunStatus = 'lastRunStatus'
}

export enum ScheduledReportRunStatus {
    NEW = 'NEW',
    RUNNING = 'RUNNING',
    SUCCEEDED = 'SUCCEEDED',
    SKIPPED = 'SKIPPED',
    FAILED = 'FAILED',
}

export interface AdminScheduledReport {
    id: string;
    fleetCustomerId: string;
    fleetCustomerName: string;
    name: string;
    reportType: ReportType;
    parametersVersion: number;
    parameters: any;
    deliveryMethod: ScheduledReportDeliveryMethod;
    fileType:FileType;
    granularity: ScheduledReportGranularity;
    scheduleTime: string;
    recipients: string[];
    lastRunStatus:ScheduledReportRunStatus;
}
