import {action} from 'typesafe-actions';
import {Job, JobsActionTypes, JobsExplorerRequest, TireServiceDetails, TireServiceDetailsRequest} from './types';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchJobsRequest = (request: JobsExplorerRequest) =>
  action(JobsActionTypes.FETCH_JOBS_REQUEST, request);
export const fetchJobsSuccess = (jobs: Paged<Job>) =>
  action(JobsActionTypes.FETCH_JOBS_SUCCESS, jobs);

export const fetchTireServiceRequest = (request:TireServiceDetailsRequest) =>
    action(JobsActionTypes.FETCH_TIRE_SERVICE_REQUEST, request);
export const fetchTireServiceSuccess = (tireService: TireServiceDetails) =>
    action(JobsActionTypes.FETCH_TIRE_SERVICE_SUCCESS, tireService);
export const fetchTireServiceFailure = (message: string) =>
    action(JobsActionTypes.FETCH_TIRE_SERVICE_FAILURE, message)
