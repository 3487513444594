import {TFunction} from 'i18next';
import {
    commissionedField,
    decommissionedField,
} from '../../pages/analytics/dashboard-widgets/fleet-movement-widget/chart-data-adapter';

export function getVehicleCommissionFieldLabel(field: string, t: TFunction): string {
    if (field === commissionedField) {
        return t('commissionType_commissioned');
    } else if (field === decommissionedField) {
        return t('commissionType_decommissioned');
    } else {
        return field;
    }
}
