import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {OrderType, TopServiceProvidersRequest} from '../../../../store/analytics/types/top-service-providers-types';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {CurrencyInfo} from '../../../../store/fleet-customers';

export function buildRequest(snapshot: ControlsSnapshot, orderType: OrderType, currency:CurrencyInfo): TopServiceProvidersRequest {
    const request = Object.assign(createDateRangeWithFunctionalLocationsRequest(snapshot), {
        orderType,
        currencyType:currency.type
    });
    return request;
}
