import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {SoldToActionTypes, SoldToWithDivisions, SoldToMappings} from './types';
import {
    fetchSoldToMappingsFailure,
    fetchSoldToMappingsRequest,
    fetchSoldToMappingsSuccess,
    fetchSoldTosRequest,
    fetchSoldTosSuccess,
} from './actions';
import SoldToApi from '../../api/sold-to-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';

function *handleMappingsFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
    yield put(fetchSoldToMappingsFailure(err));
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetchMappingsForFleetCustomer(action: ActionType<typeof fetchSoldToMappingsRequest>) {
    try {
        const fleetCustomerId = action.payload;
        const result: ApiResult<SoldToMappings> = yield call(SoldToApi.getAllMappingsForFleetCustomer, fleetCustomerId);

        if (result.error) {
            yield call(handleMappingsFailure, result.error);
        } else {
            yield put(fetchSoldToMappingsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleMappingsFailure, err);
    }
}

export function* handleFetchSoldTos(action: ActionType<typeof fetchSoldTosRequest>) {
    try {
        const fleetCustomerId = action.payload;
        const result: ApiResult<SoldToWithDivisions[]> = yield call(SoldToApi.getAllWithDepots, fleetCustomerId);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(fetchSoldTosSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchFetchRequest() {
    yield takeEvery(SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_REQUEST, handleFetchMappingsForFleetCustomer);
    yield takeEvery(SoldToActionTypes.FETCH_SOLD_TOS_REQUEST, handleFetchSoldTos);
}

function* depotsSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default depotsSaga;
