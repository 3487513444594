import {Frequency} from '../../../../store/analytics';

import {
    FleetSizeEvolutionRequest,
} from '../../../../store/analytics/types/fleet-size-evolution.types';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';

export function buildRequest(snapshot: ControlsSnapshot): FleetSizeEvolutionRequest {
    const request = Object.assign(
        createDateRangeWithFunctionalLocationsRequest(snapshot), {
            frequency: Frequency.WEEKLY,
            grouping: [],
        });

    return request;
}
