import { faSignOut, faSyncAlt, faTools } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WithTranslation, withTranslation } from 'react-i18next';
import {Component} from 'react';
import { User } from '../../store/authentication';
import { FleetCustomer } from '../../store/fleet-customers';
import { getInitialsFromUser } from '../../utils/user-helper';
import styles from './menu-panel.module.scss';

export class MenuPanel extends Component<AllProps> {
    public render(): JSX.Element {
        const { user, fleetCustomer, t, showAdminLink, showAnalyticsLink } = this.props;

        return (
            <div className={styles.profilePanel}>
                <div className={styles.topPanel}>
                    <div className={styles.leftTopPanel}>
                        <div className={styles.initialsBox}>{getInitialsFromUser(user)}</div>
                    </div>
                    <div className={styles.rightTopPanel}>
                        <div className={styles.nameField}>{user ? user.cognitoUsername : ''}</div>
                        <div className={styles.emailField}>{user ? user.email : ''}</div>
                        {fleetCustomer ? (
                            <div
                                className={styles.viewProfileButton}
                                onMouseDown={() => this.onDisableCloseOnBlur(true)}
                                onMouseUp={() => this.onDisableCloseOnBlur(false)}
                                onClick={() => this.onViewProfile()}>
                                {t('View Profile').toUpperCase()}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div>
                    {showAnalyticsLink ? (
                        <div
                            className={styles.menuItem}
                            onMouseDown={() => this.onDisableCloseOnBlur(true)}
                            onMouseUp={() => this.onDisableCloseOnBlur(false)}
                            onClick={() => this.onChangeFleetCustomer()}>
                            <FontAwesomeIcon className={styles.menuIcon} icon={faSyncAlt} />
                            {t('Change Fleet').toUpperCase()}
                        </div>
                    ) : null}
                    {showAdminLink ? (
                        <div>
                            <div
                                className={styles.menuItem}
                                onMouseDown={() => this.onDisableCloseOnBlur(true)}
                                onMouseUp={() => this.onDisableCloseOnBlur(false)}
                                onClick={() => this.onAdministration()}>
                                <FontAwesomeIcon className={styles.menuIcon} icon={faTools} />
                                {t('Administration').toUpperCase()}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div
                    className={styles.menuItem}
                    onMouseDown={() => this.onDisableCloseOnBlur(true)}
                    onMouseUp={() => this.onDisableCloseOnBlur(false)}
                    onClick={() => this.onSignOut()}>
                    <FontAwesomeIcon className={styles.menuIcon} icon={faSignOut} />
                    {t('Sign Out').toUpperCase()}
                </div>
            </div>
        );
    }

    private onDisableCloseOnBlur(disable: boolean): void {
        const { onDisableCloseOnBlur } = this.props;
        onDisableCloseOnBlur(disable);
    }

    private onSignOut(): void {
        const { onSignOut } = this.props;
        onSignOut();
    }

    private onViewProfile(): void {
        const { onViewProfile } = this.props;
        if (onViewProfile) {
            onViewProfile();
        }
    }

    private onChangeFleetCustomer(): void {
        const { onChangeFleetCustomer } = this.props;
        onChangeFleetCustomer();
    }

    private onAdministration(): void {
        const { onAdministration } = this.props;
        onAdministration();
    }
}

export default withTranslation()(MenuPanel);

interface OwnProps {
    user: User;
    fleetCustomer: FleetCustomer;
    onSignOut: () => void;
    onDisableCloseOnBlur: (boolean) => void;
    onViewProfile?: () => void;
    onChangeFleetCustomer: () => void;
    onAdministration: () => void;
    showAdminLink: boolean;
    showAnalyticsLink: boolean;
}

type AllProps = OwnProps & WithTranslation;
