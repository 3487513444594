import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ApplicationState, rootReducer, rootSaga } from '.';
import { initialAnalyticsState } from './analytics';
import { initialAuthenticationState } from './authentication';
import { initialBillingState } from './billing';
import { initialDashboardState } from './dashboard';
import { initialFleetCustomersState } from './fleet-customers';
import { initialJobsState } from './jobs';
import { initialLayoutState } from './layout';
import { initialPopupState } from './popup';
import { initialReportsState } from './reports';
import { initialSearchState } from './search';
import { initialServiceProvidersState } from './service-providers';
import { initialSoldToState } from './soldto';
import { initialToastState } from './toast';
import { initialUsersState } from './users';
import { initialVehiclesState } from './vehicles';
import {initialDepotSelectionState} from './depot-selection';
import { initialMaintenanceState} from './maintenance';
import { initialDataStatusState } from './data-status';
import {initialReportConfigurationState} from "./report-management";
import {initialScheduledReportsState} from './scheduled-report-management';

const initState: ApplicationState = {
    router: {
        location: {
            key: undefined,
            hash: '',
            pathname: '',
            search: '',
            state: '',
        },
        action: 'PUSH',
    },
    layout: initialLayoutState(),
    billing: initialBillingState(),
    authentication: initialAuthenticationState(),
    dashboard: initialDashboardState(),
    toast: initialToastState(),
    search: initialSearchState(),
    popup: initialPopupState(),
    soldTo: initialSoldToState(),
    serviceProviders: initialServiceProvidersState(),
    vehicles: initialVehiclesState(),
    jobs: initialJobsState(),
    users: initialUsersState(),
    maintenance: initialMaintenanceState(),
    fleetCustomers: initialFleetCustomersState(),
    analytics: initialAnalyticsState(),
    reports: initialReportsState(),
    depotSelection: initialDepotSelectionState(),
    dataStatus: initialDataStatusState(),
    reportConfigurations: initialReportConfigurationState(),
    scheduledReports:initialScheduledReportsState(),
};

export default function configureStore(
    history: History,
    initialState: ApplicationState = initState,
): Store<ApplicationState> {
    const composeEnhancers = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
}
