import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import {faCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import Dropdown, {DropdownOption} from '../../../components/dropdown/dropdown';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import {Permission, User} from '../../../store/authentication';
import {
    clearFleetCustomerWithConfigurationAndUsers,
    createFleetCustomerRequest,
    CreateFleetCustomerRequest,
    FleetCustomerWithConfigurationAndUsers,
    FleetType,
    getFleetCustomersRequest,
    getFleetCustomerWithConfigurationAndUsersRequest,
    InspectionPeriodTimeUnit,
    UpdatableConfiguration,
    UpdateFleetCustomerRequest,
    updateFleetCustomerRequest,
} from '../../../store/fleet-customers';
import * as layoutActions from '../../../store/layout/actions';
import {Popup, PopupType, showPopup} from '../../../store/popup';
import {fetchSoldToMappingsRequest, SoldToMapping, SoldToMappings} from '../../../store/soldto';
import {addToast} from '../../../store/toast';
import {AvailableCurrencies} from '../../../types/currencies';
import {getUserEmailDomain, hasPermission} from '../../../utils/user-helper';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import AllowedDomain from './components/allowed-domain';
import SoldToSelectionControl from './components/sold-to-selection-control';
import styles from './fleet-customer-details.module.scss';
import SoldToSelectionPopup from './popups/sold-to-selection-popup/sold-to-selection-popup';
import ToggleDisplay from '../../../utils/toggle-display';
import {conditionalClassLister} from '../../../utils/class-helpers';
import SoldToApi from '../../../api/sold-to-api';
import {getSupportedLanguageOptions} from '../../../utils/translations/translations-helper';

enum FleetCustomerDetailsPageMode {
    create,
    edit,
}

enum FleetCustomerDetailsTab {
    General,
    Configuration,
    Users,
}

class FleetCustomerDetailsPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {
            t,
            match,
            dispatchToggleSidebar,
            dispatchGetFleetCustomerWithConfigurationAndUsers,
            dispatchFetchSoldToMappings,
            loggedInUser,
        } = this.props;
        const {fleetCustomerId} = match.params;

        dispatchToggleSidebar(false);

        const currencyOptions: DropdownOption[] = [{id: undefined, key: 'none', label: t('None')}];
        currencyOptions.push(
            ...AvailableCurrencies.map((c) => ({id: c, label: c})),
        );

        const languageOptions: DropdownOption[] = [{id: undefined, key: 'none', label: t('None')}];
        languageOptions.push(...getSupportedLanguageOptions(t, true));

        const inspectionPeriodOptions: DropdownOption[] = [
            {id: InspectionPeriodTimeUnit.DAYS, label: InspectionPeriodTimeUnit.DAYS},
            {id: InspectionPeriodTimeUnit.MONTHS, label: InspectionPeriodTimeUnit.MONTHS},
        ];

        const availableTBUs: { id: number, name: string }[] = [
            {id: 40, name: t('Truck')},
            {id: 63, name: t('Light truck / van')},
            {id: 80, name: t('OTR')},
        ];

        const fleetTypeOptions: DropdownOption[] = [
            {id: FleetType.PayGo, label: FleetType.PayGo},
            {id: FleetType.PPK, label: FleetType.PPK},
        ];

        if (fleetCustomerId === 'new') {
            this.state = {
                currencyOptions,
                languageOptions,
                inspectionPeriodOptions,
                pageMode: FleetCustomerDetailsPageMode.create,
                updatedFleetCustomerConfiguration: this.getDefaultConfiguration(),
                fleetCustomerLinkedUserDomains: [],
                fleetCustomerId,
                updatedAllowedDomains: loggedInUser ? [getUserEmailDomain(loggedInUser)] : [],
                soldToMappings: [],
                tbuIds: [40],
                availableTBUs,
                activeTab: FleetCustomerDetailsTab.General,
                fleetTypeOptions,
            };
        } else {
            dispatchGetFleetCustomerWithConfigurationAndUsers(fleetCustomerId);
            this.state = {
                currencyOptions,
                languageOptions,
                inspectionPeriodOptions,
                pageMode: FleetCustomerDetailsPageMode.edit,
                fleetCustomerLinkedUserDomains: [],
                fleetCustomerId,
                updatedAllowedDomains: [],
                soldToMappings: [],
                tbuIds: [],
                availableTBUs,
                activeTab: FleetCustomerDetailsTab.General,
                fleetTypeOptions,
            };
        }
        dispatchFetchSoldToMappings(fleetCustomerId);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {fleetCustomerWithConfigurationAndUsers, loggedInUser, initialSoldToMappings} = this.props;
        const {pageMode, updatedAllowedDomains} = this.state;

        if (pageMode === FleetCustomerDetailsPageMode.edit) {
            if (fleetCustomerWithConfigurationAndUsers && initialSoldToMappings) {
                if (
                    fleetCustomerWithConfigurationAndUsers !== prevProps.fleetCustomerWithConfigurationAndUsers
                    || initialSoldToMappings !== prevProps.initialSoldToMappings
                ) {
                    const emailDomainsForUsersLinkedToFleetCustomer = fleetCustomerWithConfigurationAndUsers.users
                        .map((u) => getUserEmailDomain(u));
                    const fleetCustomerLinkedUserDomains = fleetCustomerWithConfigurationAndUsers.allowedDomains.filter((ad) =>
                        emailDomainsForUsersLinkedToFleetCustomer.includes(ad),
                    );

                    this.setState({
                        fleetCustomerLinkedUserDomains,
                        updatedFleetCustomerConfiguration: {...fleetCustomerWithConfigurationAndUsers.configuration},
                        fleetCustomerName: fleetCustomerWithConfigurationAndUsers!.name,
                        updatedAllowedDomains: fleetCustomerWithConfigurationAndUsers.allowedDomains,
                        soldToMappings: initialSoldToMappings.mappings,
                        tbuIds: initialSoldToMappings.tbuIds,
                    });
                }
            }
        } else if (loggedInUser && updatedAllowedDomains.length === 0) {
            this.setState({updatedAllowedDomains: [getUserEmailDomain(loggedInUser)]});
        } else if (initialSoldToMappings && prevProps.initialSoldToMappings !== initialSoldToMappings) {
            this.setState({
                soldToMappings: initialSoldToMappings.mappings,
                tbuIds: initialSoldToMappings.tbuIds
            });
        }
    }

    public render(): JSX.Element {
        const {t, fleetCustomerWithConfigurationAndUsers, loggedInUser} = this.props;
        const {
            fleetCustomerId,
            fleetCustomerName,
            pageMode,
            updatedFleetCustomerConfiguration,
            fleetCustomerLinkedUserDomains,
            updatedAllowedDomains,
            currencyOptions,
            languageOptions,
            inspectionPeriodOptions,
            soldToMappings,
            availableTBUs,
            tbuIds,
            activeTab,
            fleetTypeOptions,
        } = this.state;
        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageFleets)
        const initialized =
            updatedFleetCustomerConfiguration &&
            (pageMode === FleetCustomerDetailsPageMode.create || (fleetCustomerId && fleetCustomerWithConfigurationAndUsers));

        const titleKey =
            pageMode === FleetCustomerDetailsPageMode.create ? t('Create Fleet Customer') : t('Fleet Customer Details');

        const tbuStyles = conditionalClassLister(styles)({
            checkBoxItem: true,
            disabled: soldToMappings.filter(s => s.hasMapping).length === 0,
        });

        const usersForFleetCustomer = (fleetCustomerWithConfigurationAndUsers && fleetCustomerWithConfigurationAndUsers)?.users ? fleetCustomerWithConfigurationAndUsers.users : [];

        const generalTabStyles = conditionalClassLister(styles)({
            tab: true,
            active: activeTab === FleetCustomerDetailsTab.General,
        });

        const configurationTabStyles = conditionalClassLister(styles)({
            tab: true,
            active: activeTab === FleetCustomerDetailsTab.Configuration,
        });

        const usersTabStyles = conditionalClassLister(styles)({
            tab: true,
            active: activeTab === FleetCustomerDetailsTab.Users,
        });

        const soldToFieldStyles = conditionalClassLister(styles)({
            vField: true,
            unlimited: true
        });

        const contentPanelStyles = conditionalClassLister(styles)({
            contentPanel: true,
            isDisabled: !hasEditPermission
        });

        return initialized ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={titleKey}
                    onBack={() => this.onNavigateBack()}
                />
                <div className={styles.content}>
                    <div className={styles.tabs}>
                        <div className={generalTabStyles}
                             onClick={() => this.switchTab(FleetCustomerDetailsTab.General)}>{t('General')}
                        </div>
                        <div className={configurationTabStyles}
                             onClick={() => this.switchTab(FleetCustomerDetailsTab.Configuration)}>{t('Configuration')}
                        </div>
                        <div className={usersTabStyles}
                             onClick={() => this.switchTab(FleetCustomerDetailsTab.Users)}>{t('Users')}
                        </div>
                    </div>
                    {activeTab === FleetCustomerDetailsTab.General ? <div className={contentPanelStyles}>
                        <div className={styles.fields}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Name')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="text"
                                        name="fcName"
                                        defaultValue={fleetCustomerName}
                                        autoFocus
                                        className={styles.fieldInput}
                                        onChange={(e): void => this.onNameChanged(e)}
                                    />
                                </div>
                            </div>
                            <div className={styles.vFieldGroup}>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Base Currency 1')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={currencyOptions}
                                                selection={currencyOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.baseCurrency1,
                                                )}
                                                onSelectionChanged={(c) => this.onBaseCurrency1Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.baseCurrency1 !== undefined
                                                ? updatedFleetCustomerConfiguration!.baseCurrency1 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency1 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency1Processed
                                                    ? t('Data available')
                                                    : t('Data not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Base Currency 2')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={currencyOptions}
                                                selection={currencyOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.baseCurrency2,
                                                )}
                                                onSelectionChanged={(c): void => this.onBaseCurrency2Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.baseCurrency2 !== undefined
                                                ? updatedFleetCustomerConfiguration!.baseCurrency2 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency2 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency2Processed
                                                    ? t('Data available')
                                                    : t('Data not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Base Currency 3')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={currencyOptions}
                                                selection={currencyOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.baseCurrency3,
                                                )}
                                                onSelectionChanged={(c): void => this.onBaseCurrency3Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.baseCurrency3 !== undefined
                                                ? updatedFleetCustomerConfiguration!.baseCurrency3 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency3 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.baseCurrency3Processed
                                                    ? t('Data available')
                                                    : t('Data not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.vFieldGroup}>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Local Language 1')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={languageOptions}
                                                selection={languageOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.localLanguage1,
                                                )}
                                                onSelectionChanged={(c) => this.onLocalLanguage1Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.localLanguage1 !== undefined
                                                ? updatedFleetCustomerConfiguration!.localLanguage1 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage1 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage1Processed
                                                    ? t('Translations available')
                                                    : t('Translations not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Local Language 2')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={languageOptions}
                                                selection={languageOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.localLanguage2,
                                                )}
                                                onSelectionChanged={(c): void => this.onLocalLanguage2Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.localLanguage2 !== undefined
                                                ? updatedFleetCustomerConfiguration!.localLanguage2 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage2 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage2Processed
                                                    ? t('Translations available')
                                                    : t('Translations not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Local Language 3')}</div>
                                    <div className={styles.value}>
                                        <div className={styles.select}>
                                            <Dropdown
                                                options={languageOptions}
                                                selection={languageOptions.find(
                                                    (a) => a.id === updatedFleetCustomerConfiguration!.localLanguage3,
                                                )}
                                                onSelectionChanged={(c): void => this.onLocalLanguage3Changed(c)}
                                            />
                                        </div>
                                        <div className={styles.availableIndicator}>
                                            {updatedFleetCustomerConfiguration!.localLanguage3 !== undefined
                                                ? updatedFleetCustomerConfiguration!.localLanguage3 ===
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage3 &&
                                                fleetCustomerWithConfigurationAndUsers?.configuration?.localLanguage3Processed
                                                    ? t('Translations available')
                                                    : t('Translations not available')
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.vField}>
                            <div className={styles.label}>{t('Allowed Email Domains')}</div>
                            <div className={styles.domains}>
                                {updatedAllowedDomains.map((allowedDomain, index) => (
                                    <AllowedDomain
                                        key={index}
                                        value={allowedDomain}
                                        onChange={(val): void => this.onAllowedDomainChange(index, val)}
                                        onRemove={(): void => this.onAllowedDomainRemove(index)}
                                        onBlur={(): void => this.onAllowedDomainBlur()}
                                        canRemove={index > 0 && !fleetCustomerLinkedUserDomains.includes(allowedDomain)}
                                        isReadonly={fleetCustomerLinkedUserDomains.includes(allowedDomain)}
                                    />
                                ))}
                                {hasEditPermission
                                    ?<div className={styles.addDomain} onClick={(): void => this.onAllowedDomainAdd()}>{`${t(
                                    'Add',
                                )} ...`}</div>
                                    :null}

                            </div>
                        </div>
                        <div className={styles.vField}>
                            <div className={styles.label}>{t('Fleet Type')}</div>
                            <div className={styles.value}>
                                <div className={styles.select}>
                                    <Dropdown
                                        options={fleetTypeOptions}
                                        selection={fleetTypeOptions.find(
                                            (a) => a.id === updatedFleetCustomerConfiguration!.fleetType,
                                        )}
                                        onSelectionChanged={(c) => this.onFleetTypeChanged(c)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={soldToFieldStyles}>
                            <div className={styles.label}>{t('Sold To(s)')}
                            </div>
                            <div className={styles.control}>
                                <SoldToSelectionControl
                                    soldToMappings={soldToMappings}
                                    canChange={hasEditPermission}
                                    canDownloadStructure={fleetCustomerId !== undefined && fleetCustomerId !== 'new'}
                                    onChange={() => this.showSoldToMappingSelectionPopup(hasEditPermission)}
                                    onDownloadStructure={() => this.downloadSoldToStructure()}
                                />
                            </div>
                        </div>
                        <div className={styles.vField}>
                            <div className={styles.label}>{t('Business Units')}</div>
                            <div>
                                {availableTBUs.map((tbu) => (
                                    <div className={tbuStyles} key={tbu.id}
                                         onClick={() => this.onTBUsChanged(tbu.id)}>
                                        <div className={styles.checkBox}>
                                            <ToggleDisplay if={tbuIds.includes(tbu.id)}>
                                                <FontAwesomeIcon icon={faCheckSquare}/>
                                            </ToggleDisplay>
                                            <ToggleDisplay if={!tbuIds.includes(tbu.id)}>
                                                <FontAwesomeIcon icon={faSquare}/>
                                            </ToggleDisplay>
                                        </div>
                                        <div className={styles.checkBoxText}>
                                            {tbu.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> : null}
                    {activeTab === FleetCustomerDetailsTab.Configuration ? <div className={contentPanelStyles}>
                        <div className={styles.subTitle}>{t('Inspection configuration')}</div>
                        <div className={styles.vField}>
                            <div className={styles.label}>{t('Inspection Period')}</div>
                            <div className={styles.value}>
                                <input
                                    type="number"
                                    name="inspectionPeriod"
                                    defaultValue={updatedFleetCustomerConfiguration?.inspectionPeriod}
                                    autoFocus={false}
                                    className={`${styles.fieldInput} ${styles.small}`}
                                    onChange={(e): void => this.onInspectionPeriodChange(e)}
                                />
                                <div className={`${styles.select} ${styles.spaced}`}>
                                    <Dropdown
                                        options={inspectionPeriodOptions}
                                        selection={inspectionPeriodOptions.find(
                                            (a) => a.id === updatedFleetCustomerConfiguration?.inspectionPeriodTimeUnit,
                                        )}
                                        onSelectionChanged={(c): void => this.onInspectionPeriodTimeUnitChanged(c)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.subTitle}>{t('KPI configuration')}</div>
                        <div className={styles.sectionTitle}>{t('Retread Ratio KPI')}</div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Retread ratio target')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="retreadRatioTarget"
                                        defaultValue={updatedFleetCustomerConfiguration!.retreadRatioTarget * 100}
                                        min={0}
                                        max={100}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onRetreadRatioTargetChanged(e)}
                                    />
                                    <div className={styles.unit}>%</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionTitle}>{t('Avoidable Breakdowns KPI')}</div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Avoidable breakdowns using TPMS')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="avoidableBreakdownPercentage"
                                        defaultValue={updatedFleetCustomerConfiguration!.avoidableBreakdownPercentage * 100}
                                        min={0}
                                        max={100}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAvoidableBreakdownPercentageChanged(e)}
                                    />
                                    <div className={styles.unit}>%</div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average cost per breakdown resolution')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageCostPerBreakdown"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageCostPerBreakdown}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageCostPerBreakdownChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>{updatedFleetCustomerConfiguration?.baseCurrency1}</div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average cost per stop & go resolution')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageCostPerStopAndGo"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageCostPerStopAndGo}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageCostPerStopAndGoChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>{updatedFleetCustomerConfiguration?.baseCurrency1}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average cost per new tire')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageCostNewTire"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageCostNewTire}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageCostNewTireChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>{updatedFleetCustomerConfiguration?.baseCurrency1}</div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average cost per retread tire')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageCostRetreadTire"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageCostRetreadTire}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageCostRetreadTireChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>{updatedFleetCustomerConfiguration?.baseCurrency1}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.sectionTitle}>{t('Underinflation Effect KPI')}</div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Correct tire pressure target')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="tirePressureTarget"
                                        defaultValue={updatedFleetCustomerConfiguration!.tirePressureTarget}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onTirePressureTargetChanged(e)}
                                    />
                                    <div className={styles.unit}>bar</div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Underinflation threshold (15%)')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="underInflationThreshold15"
                                        defaultValue={updatedFleetCustomerConfiguration!.underInflationThreshold15}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onUnderInflationThreshold15Changed(e)}
                                    />
                                    <div
                                        className={styles.unit}>bar
                                    </div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Underinflation threshold (25%)')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="underInflationThreshold25"
                                        defaultValue={updatedFleetCustomerConfiguration!.underInflationThreshold25}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onUnderInflationThreshold25Changed(e)}
                                    />
                                    <div
                                        className={styles.unit}>km
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average mileage per year')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageMileagePerYear"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageMileagePerYear}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageMileagePerYearChanged(e)}
                                    />
                                    <div className={styles.unit}>km</div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average fuel consumption per year')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageFuelConsumptionPerYear"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageFuelConsumption}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageFuelConsumptionPerYearChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>l/100km
                                    </div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Average fuel cost per liter')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="averageFuelCostPerLiter"
                                        defaultValue={updatedFleetCustomerConfiguration!.averageFuelCostPerLiter}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onAverageFuelCostPerLiterChanged(e)}
                                    />
                                    <div
                                        className={styles.unit}>{updatedFleetCustomerConfiguration?.baseCurrency1}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.vFieldGroup}>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Expected mileage new tire')}</div>
                                <div className={styles.value}>
                                    <input
                                        type="number"
                                        name="expectedMileageNewTire"
                                        defaultValue={updatedFleetCustomerConfiguration!.expectedMileageNewTire}
                                        className={`${styles.fieldInput} ${styles.small}`}
                                        onChange={(e): void => this.onExpectedMileageNewTireChanged(e)}
                                    />
                                    <div className={styles.unit}>km</div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {activeTab === FleetCustomerDetailsTab.Users ? <div className={contentPanelStyles}>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('UMF Username')}
                                </div>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('Email')}
                                </div>
                                <div className={styles.cell}>{t('Last Login')}</div>
                            </div>
                            <div className={styles.rows}>
                                {
                                    usersForFleetCustomer.map((u) =>
                                        <div
                                            className={`${styles.row} ${!hasEditPermission?styles.isDisabled:''}`}
                                            key={u.email}>
                                            <div className={styles.cell} style={{flexGrow: 2}}>
                                                {u.cognitoUsername}
                                            </div>
                                            <div className={styles.cell} style={{flexGrow: 2}}>
                                                {u.email}
                                            </div>
                                            <div className={styles.cell}>
                                                {u.lastLogin
                                                    ? `${new Date(u.lastLogin).toLocaleDateString()} ${new Date(
                                                        u.lastLogin,
                                                    ).toLocaleTimeString()}`
                                                    : ''}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div> : null}
                </div>
                <div className={styles.buttons}>
                    <button
                        type="button"
                        className={`${styles.button} ${styles.isNegative}`}
                        onClick={(): void => {
                            this.onNavigateBack();
                        }}>
                        {t('Back')}
                    </button>
                    {hasEditPermission
                        ? <button
                            type="button"
                            className={`${styles.button} ${!this.canSave() ? styles.isDisabled : ''}`}
                            onClick={(): void => {
                                this.updateFleetCustomer();
                            }}>
                            {t('Save')}
                        </button>
                        : null}
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    private downloadSoldToStructure(): void {
        // eslint-disable-next-line react/destructuring-assignment
        SoldToApi.downloadFleetCustomerStructure(this.props.fleetCustomerWithConfigurationAndUsers!.id, this.props.fleetCustomerWithConfigurationAndUsers!.name).then(() => {
        });
    }

    public showSoldToMappingSelectionPopup(hasEditPermissions:boolean): void {
        const {dispatchShowPopup} = this.props;
        const {soldToMappings} = this.state;

        dispatchShowPopup({
            type: PopupType.SoldToMappingSelection,
            content: (
                <SoldToSelectionPopup
                    canChange={hasEditPermissions}
                    originalSoldToMappings={soldToMappings}
                    onSoldToMappingsChanged={(stm) => this.onSoldToMappingsChanged(stm)}
                />
            ),
        });
    }

    private switchTab(tab: FleetCustomerDetailsTab): void {
        this.setState({activeTab: tab});
    }

    private onSoldToMappingsChanged(soldToMappings: SoldToMapping[]): void {
        const {tbuIds, fleetCustomerId} = this.state;
        const newTbuIds = soldToMappings.filter(s => s.hasMapping).length === 0
            ? []
            : fleetCustomerId === 'new' && tbuIds.length === 0
                ? [40]
                : tbuIds;
        this.setState({soldToMappings: [...soldToMappings], tbuIds: newTbuIds});
    }

    private getDefaultConfiguration(): UpdatableConfiguration {
        return {
            baseCurrency1: 'EUR',
            baseCurrency2: undefined,
            baseCurrency3: undefined,
            inspectionPeriod: 90,
            inspectionPeriodTimeUnit: InspectionPeriodTimeUnit.DAYS,
            avoidableBreakdownPercentage: 0.8,
            averageCostPerBreakdown: 200,
            averageCostPerStopAndGo: 50,
            retreadRatioTarget: 0.5,
            averageCostNewTire: 272,
            averageCostRetreadTire: 233,
            tirePressureTarget: 8.5,
            underInflationThreshold15: 8,
            underInflationThreshold25: 7,
            averageMileagePerYear: 100000,
            averageFuelConsumption: 29,
            averageFuelCostPerLiter: 1.44,
            expectedMileageNewTire: 150000,
            fleetType: FleetType.PayGo,
        };
    }

    private onTirePressureTargetChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.tirePressureTarget = parseFloat(e.target.value);
        });
    }

    private onUnderInflationThreshold15Changed(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.underInflationThreshold15 = parseFloat(e.target.value);
        });
    }

    private onUnderInflationThreshold25Changed(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.underInflationThreshold25 = parseFloat(e.target.value);
        });
    }

    private onAverageMileagePerYearChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageMileagePerYear = parseFloat(e.target.value);
        });
    }

    private onAverageFuelConsumptionPerYearChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageFuelConsumption = parseFloat(e.target.value);
        });
    }

    private onAverageFuelCostPerLiterChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageFuelCostPerLiter = parseFloat(e.target.value);
        });
    }

    private onExpectedMileageNewTireChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.expectedMileageNewTire = parseFloat(e.target.value);
        });
    }

    private onNameChanged(e): void {
        this.setState({fleetCustomerName: e.target.value});
    }

    private onAvoidableBreakdownPercentageChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.avoidableBreakdownPercentage = parseInt(e.target.value, 10) / 100;
        });
    }

    private onAverageCostPerBreakdownChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageCostPerBreakdown = parseInt(e.target.value, 10);
        });
    }

    private onAverageCostPerStopAndGoChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageCostPerStopAndGo = parseInt(e.target.value, 10);
        });
    }

    private onRetreadRatioTargetChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.retreadRatioTarget = parseInt(e.target.value, 10) / 100;
        });
    }

    private onAverageCostNewTireChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageCostNewTire = parseInt(e.target.value, 10);
        });
    }

    private onAverageCostRetreadTireChanged(e): void {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.averageCostRetreadTire = parseInt(e.target.value, 10);
        });
    }

    private onInspectionPeriodChange(e) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.inspectionPeriod = e.target.value;
        });
    }

    private onInspectionPeriodTimeUnitChanged(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.inspectionPeriodTimeUnit = option.id as InspectionPeriodTimeUnit;
        });
    }

    private onBaseCurrency1Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency1 = option.id;
        });
    }

    private onBaseCurrency2Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency2 = option.id;
        });
    }

    private onBaseCurrency3Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency3 = option.id;
        });
    }

    private onLocalLanguage1Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage1 = option.id;
        });
    }

    private onLocalLanguage2Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage2 = option.id;
        });
    }

    private onLocalLanguage3Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage3 = option.id;
        });
    }

    private onFleetTypeChanged(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.fleetType = option.id as FleetType;
        })
    }

    private onAllowedDomainChange(index: number, allowedDomain: string) {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains[index] = allowedDomain;
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainAdd() {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains.push('');
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainRemove(index: number) {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains.splice(index, 1);
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainBlur() {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        this.setState({updatedAllowedDomains: newDomains});
    }

    private updateConfiguration(updateFunc: (config: UpdatableConfiguration) => void) {
        const {updatedFleetCustomerConfiguration} = this.state;
        if (updatedFleetCustomerConfiguration) {
            const newConfig = {...updatedFleetCustomerConfiguration};
            updateFunc(newConfig);
            this.setState({updatedFleetCustomerConfiguration: newConfig});
        }
    }

    public onTBUsChanged(tbuId: number): void {
        const {tbuIds} = this.state;
        const newTBUIds = tbuIds.includes(tbuId)
            ? tbuIds.filter(t => t !== tbuId)
            : tbuIds.concat([tbuId]);

        this.setState({tbuIds: newTBUIds});
    }

    private canSave(): boolean {
        const {fleetCustomerName, updatedFleetCustomerConfiguration, updatedAllowedDomains} = this.state;
        return (
            fleetCustomerName !== '' &&
            updatedFleetCustomerConfiguration !== undefined &&
            updatedFleetCustomerConfiguration.baseCurrency1 !== undefined &&
            updatedFleetCustomerConfiguration.baseCurrency1 !== '' &&
            updatedFleetCustomerConfiguration.inspectionPeriod >= 0 &&
            updatedFleetCustomerConfiguration.inspectionPeriodTimeUnit !== undefined &&
            updatedAllowedDomains.filter((d) => d !== undefined && d !== '').length !== 0 &&
            updatedFleetCustomerConfiguration.fleetType !== undefined
        );
    }

    public onNavigateBack(): void {
        const {dispatchNavigateTo,dispatchClearFleetCustomerWithConfigurationAndUsers} = this.props;
        dispatchClearFleetCustomerWithConfigurationAndUsers();
        dispatchNavigateTo({pathname: RouteUrl.FleetCustomersManagement});
    }

    private updateFleetCustomer(): void {
        const {dispatchUpdateFleetCustomer, dispatchCreateFleetCustomer} = this.props;
        if (this.canSave()) {
            const {
                fleetCustomerId,
                fleetCustomerName,
                updatedFleetCustomerConfiguration,
                updatedAllowedDomains,
                pageMode,
                soldToMappings,
                tbuIds,
            } = this.state;
            if (pageMode === FleetCustomerDetailsPageMode.create) {
                dispatchCreateFleetCustomer({
                    fleetCustomerName: fleetCustomerName!,
                    fleetCustomerAllowedDomains: updatedAllowedDomains.filter((d) => d !== undefined && d !== ''),
                    configuration: updatedFleetCustomerConfiguration!,
                    soldToMappings: {
                        mappings: soldToMappings.filter(stm => stm.hasMapping),
                        tbuIds
                    },
                });
            } else {
                dispatchUpdateFleetCustomer({
                    fleetCustomerId: fleetCustomerId!,
                    fleetCustomerName: fleetCustomerName!,
                    fleetCustomerAllowedDomains: updatedAllowedDomains.filter((d) => d !== undefined && d !== ''),
                    configuration: updatedFleetCustomerConfiguration!,
                    soldToMappings: {
                        mappings: soldToMappings.filter(stm => stm.hasMapping),
                        tbuIds
                    },
                });
            }
            this.onNavigateBack();
        }
    }
}

const mapStateToProps = ({authentication, fleetCustomers, users, soldTo}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    fleetCustomerWithConfigurationAndUsers: fleetCustomers.fleetCustomerWithConfigurationAndUsers,
    initialSoldToMappings: soldTo.soldToMappings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClearFleetCustomerWithConfigurationAndUsers: () =>
        dispatch(clearFleetCustomerWithConfigurationAndUsers()),
    dispatchGetFleetCustomerWithConfigurationAndUsers: (fleetCustomerId: string) =>
        dispatch(getFleetCustomerWithConfigurationAndUsersRequest(fleetCustomerId)),
    dispatchUpdateFleetCustomer: (request: UpdateFleetCustomerRequest) => dispatch(updateFleetCustomerRequest(request)),
    dispatchCreateFleetCustomer: (fleetCustomerRequest: CreateFleetCustomerRequest) =>
        dispatch(createFleetCustomerRequest(fleetCustomerRequest)),
    dispatchFetchSoldToMappings: (fleetCustomerId: string) => dispatch(fetchSoldToMappingsRequest(fleetCustomerId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FleetCustomerDetailsPage));

interface PropsFromState {
    loggedInUser?: User;
    fleetCustomerWithConfigurationAndUsers?: FleetCustomerWithConfigurationAndUsers;
    initialSoldToMappings?: SoldToMappings;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchAddToast: typeof addToast;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchClearFleetCustomerWithConfigurationAndUsers: typeof clearFleetCustomerWithConfigurationAndUsers;
    dispatchGetFleetCustomerWithConfigurationAndUsers: typeof getFleetCustomerWithConfigurationAndUsersRequest;
    dispatchGetFleetCustomers: typeof getFleetCustomersRequest;
    dispatchUpdateFleetCustomer: typeof updateFleetCustomerRequest;
    dispatchCreateFleetCustomer: typeof createFleetCustomerRequest;
    dispatchFetchSoldToMappings: typeof fetchSoldToMappingsRequest;
}

interface OwnProps {
}

export interface WorkspaceMatchParameters {
    fleetCustomerId: string;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    RouteComponentProps<WorkspaceMatchParameters> &
    WithTranslation &
    OwnProps;

interface OwnState {
    fleetCustomerId?: string;
    fleetCustomerName?: string;
    availableTBUs: { id: number, name: string }[],
    currencyOptions: DropdownOption[];
    languageOptions: DropdownOption[];
    inspectionPeriodOptions: DropdownOption[];
    updatedFleetCustomerConfiguration?: UpdatableConfiguration;
    fleetCustomerLinkedUserDomains: string[];
    updatedAllowedDomains: string[];
    pageMode: FleetCustomerDetailsPageMode;
    tbuIds: number[];
    soldToMappings: SoldToMapping[];
    activeTab: FleetCustomerDetailsTab;
    fleetTypeOptions: DropdownOption[];
}

type AllState = OwnState;
