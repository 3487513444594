import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import * as layoutActions from '../../../store/layout/actions';
import {RequestStatus} from '../../../store/shared/types';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './report-management.module.scss';
import {
    getReportConfigurationsRequest,
    ReportConfigurationContract,
    updateReportConfigurationRequest,
    UpdateReportConfigurationsRequest,
    UserGroup
} from '../../../store/report-management';
import {getReportName} from '../../reports/report-catalog';
import ToggleDisplay from '../../../utils/toggle-display';
import Dropdown, {DropdownOption} from '../../../components/dropdown/dropdown';
import {hasPermission} from '../../../utils/user-helper';
import {Permission, User} from '../../../store/authentication';

class ReportManagementPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchGetReportConfigurations, dispatchToggleSidebar, reportConfigurations} = this.props;
        dispatchToggleSidebar(false);

        const userGroupOptions: DropdownOption[] = [
            {id: UserGroup.AllUsers, label: UserGroup.AllUsers},
            {id: UserGroup.InternalUsers, label: UserGroup.InternalUsers},
        ];

        if (reportConfigurations && reportConfigurations.length > 0) {
            this.state = {
                updatedReportConfigurations: reportConfigurations,
                userGroupOptions,
            }
        } else {
            dispatchGetReportConfigurations();
            this.state = {
                updatedReportConfigurations: [],
                userGroupOptions,
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {reportConfigurations} = this.props;

        if (reportConfigurations && reportConfigurations !== prevProps.reportConfigurations) {
            this.setState({updatedReportConfigurations: reportConfigurations});
        }
    }

    public render(): JSX.Element {
        const {
            t,
            dispatchNavigateTo,
            getReportConfigurationsRequestStatus,
            loggedInUser
        } = this.props;
        const {updatedReportConfigurations, userGroupOptions} = this.state;
        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageReports)

        return updatedReportConfigurations && !getReportConfigurationsRequestStatus?.isInProgress ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('Report Management')}
                    onBack={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell}>{t('Report')}</div>
                                <div className={`${styles.cell} ${styles.centeredCell}`}>{t('Available for PayGo')}</div>
                                <div className={`${styles.cell} ${styles.centeredCell}`}>{t('Available for PPK')}</div>
                                <div className={`${styles.cell} ${styles.centeredCell}`}>{t('Available for users')}</div>
                                <div className={`${styles.cell} ${styles.centeredCell}`}>{t('Active')}</div>
                            </div>
                            <div className={styles.rows}>
                                {getReportConfigurationsRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    </div>
                                ) : (
                                    updatedReportConfigurations?.map((reportConfig) => (
                                        <div
                                            className={`${styles.row} ${!hasEditPermission?styles.isDisabled:''}`}
                                            key={reportConfig.reportType}>
                                            <div className={styles.cell}>
                                                {getReportName(reportConfig.reportType,t)}
                                            </div>
                                            <div className={`${styles.cell} ${styles.centeredCell}`} onClick={() => this.onAvailableForPayGoChanged(reportConfig)}>
                                                <ToggleDisplay if={reportConfig.availableForPayGo}>
                                                    <FontAwesomeIcon icon={faCheckSquare}/>
                                                </ToggleDisplay>
                                                <ToggleDisplay if={!reportConfig.availableForPayGo}>
                                                    <FontAwesomeIcon icon={faSquare}/>
                                                </ToggleDisplay>
                                            </div>
                                            <div className={`${styles.cell} ${styles.centeredCell}`} onClick={() => this.onAvailableForPPKChanged(reportConfig)}>
                                                <ToggleDisplay if={reportConfig.availableForPPK}>
                                                    <FontAwesomeIcon icon={faCheckSquare}/>
                                                </ToggleDisplay>
                                                <ToggleDisplay if={!reportConfig.availableForPPK}>
                                                    <FontAwesomeIcon icon={faSquare}/>
                                                </ToggleDisplay>
                                            </div>
                                            <div className={`${styles.cell} ${styles.centeredCell}`}>
                                                <Dropdown
                                                    options={userGroupOptions}
                                                    selection={userGroupOptions.find(option => option.id === reportConfig.availableForUserGroup)}
                                                    onSelectionChanged={(option) => this.onUserGroupChanged(reportConfig, option)}
                                                />
                                            </div>
                                            <div className={`${styles.cell} ${styles.centeredCell}`} onClick={() => this.onActiveChanged(reportConfig)}>
                                                <ToggleDisplay if={reportConfig.active}>
                                                    <FontAwesomeIcon icon={faCheckSquare}/>
                                                </ToggleDisplay>
                                                <ToggleDisplay if={!reportConfig.active}>
                                                    <FontAwesomeIcon icon={faSquare}/>
                                                </ToggleDisplay>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <button
                            type="button"
                            className={`${styles.button} ${styles.isNegative}`}
                            onClick={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}>
                            {t('Back')}
                        </button>
                        {hasEditPermission? <button
                            type="button"
                            className={`${styles.button}`}
                            onClick={() => this.onSaveClicked()}>
                            {t('Save')}
                        </button>:null}

                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    private onSaveClicked() {
        const {dispatchUpdateReportConfigurations, dispatchNavigateTo} = this.props;
        const {updatedReportConfigurations} = this.state;

        dispatchUpdateReportConfigurations({reportConfigurations: updatedReportConfigurations});
        dispatchNavigateTo({pathname: RouteUrl.Administration});
    }

    private onUserGroupChanged(reportConfiguration: ReportConfigurationContract, option: DropdownOption) {
        const {updatedReportConfigurations} = this.state;

        const index = updatedReportConfigurations.indexOf(reportConfiguration);
        if (index !== -1) {
            updatedReportConfigurations[index].availableForUserGroup = option.id as UserGroup;
            this.setState({updatedReportConfigurations});
        }
    }

    private onAvailableForPPKChanged(reportConfiguration: ReportConfigurationContract) {
        const {updatedReportConfigurations} = this.state;

        const index = updatedReportConfigurations.indexOf(reportConfiguration);
        if (index !== -1) {
            updatedReportConfigurations[index].availableForPPK = !updatedReportConfigurations[index].availableForPPK;
            this.setState({updatedReportConfigurations});
        }
    }

    private onAvailableForPayGoChanged(reportConfiguration: ReportConfigurationContract) {
        const {updatedReportConfigurations} = this.state;

        const index = updatedReportConfigurations.indexOf(reportConfiguration);
        if (index !== -1) {
            updatedReportConfigurations[index].availableForPayGo = !updatedReportConfigurations[index].availableForPayGo;
            this.setState({updatedReportConfigurations});
        }
    }

    private onActiveChanged(reportConfiguration: ReportConfigurationContract) {
        const {updatedReportConfigurations} = this.state;

        const index = updatedReportConfigurations.indexOf(reportConfiguration);
        if (index !== -1) {
            updatedReportConfigurations[index].active = !updatedReportConfigurations[index].active;
            this.setState({updatedReportConfigurations});
        }
    }
}

const mapStateToProps = ({reportConfigurations,authentication}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    reportConfigurations: reportConfigurations.reportConfigurations,
    getReportConfigurationsRequestStatus: reportConfigurations.getReportConfigurationsRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchGetReportConfigurations: () => dispatch(getReportConfigurationsRequest()),
    dispatchUpdateReportConfigurations: (updateRequest: UpdateReportConfigurationsRequest) => dispatch(updateReportConfigurationRequest(updateRequest)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportManagementPage));

interface PropsFromState {
    loggedInUser?: User;
    reportConfigurations: ReportConfigurationContract[];
    getReportConfigurationsRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchGetReportConfigurations: typeof getReportConfigurationsRequest;
    dispatchUpdateReportConfigurations: typeof updateReportConfigurationRequest;
}

interface OwnProps {
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    updatedReportConfigurations: ReportConfigurationContract[];
    userGroupOptions: DropdownOption[];
}

type AllState = OwnState;
