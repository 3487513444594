import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import DashboardsApi from '../../api/dashboards-api';
import {ApiResult} from '../../types/api-result';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApplicationState} from '../index';
import {
    createDashboardRequest,
    dashboardsActionFailure,
    deleteDashboardRequest,
    fetchDashboardsRequest, restoreDefaultDashboards,
    setDashboards,
    updateDashboardRequest,
} from './actions';
import {Dashboard, DashboardActionTypes} from './types';

const fetchFleetCustomerIdFromState = () => select((state: ApplicationState) => state.authentication.fleetCustomer!.id);
const fetchDashboardsFromState = () => select((state: ApplicationState) => state.dashboard.dashboards);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetchDashboard(action: ActionType<typeof fetchDashboardsRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();

        if (fleetCustomerId) {
            const result: ApiResult<Dashboard[]> = yield call(DashboardsApi.getDashboards, fleetCustomerId);

            if (result.error) {
                yield call(handleFailure, result.error);
            } else {
                yield put(setDashboards(result.data!));
            }
        } else {
            yield call(handleFailure, 'Trying to fetch dashboards without selecting a fleet customer first');
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleDeleteDashboard(action: ActionType<typeof deleteDashboardRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const dashboards = [...(yield fetchDashboardsFromState())];

        if (fleetCustomerId) {
            const dashboard = action.payload;
            const deletedIndex = dashboards.findIndex((d) => d.id === dashboard.id);
            dashboards.splice(deletedIndex, 1);
            dashboards.forEach((d, index) => (d.index = index));
            const result: ApiResult<Dashboard[]> = yield call(
                DashboardsApi.updateDashboards,
                fleetCustomerId,
                dashboards,
            );

            if (result.error) {
                yield call(handleFailure, result.error);
            } else {
                yield put(setDashboards(dashboards));
            }
        } else {
            yield call(handleFailure, 'Trying to delete a dashboard without selecting a fleet customer first');
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleUpdateDashboard(action: ActionType<typeof updateDashboardRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const dashboards = [...(yield fetchDashboardsFromState())];

        if (fleetCustomerId) {
            const dashboard = action.payload;
            const updatedIndex = dashboards.findIndex((d) => d.id === dashboard.id);
            dashboards.splice(updatedIndex, 1, dashboard);
            const result: ApiResult<Dashboard[]> = yield call(
                DashboardsApi.updateDashboards,
                fleetCustomerId,
                dashboards,
            );

            if (result.error) {
                yield call(handleFailure, result.error);
            } else {
                yield put(setDashboards(dashboards));
            }
        } else {
            yield call(handleFailure, 'Trying to update a dashboard without selecting a fleet customer first');
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleCreateDashboard(action: ActionType<typeof createDashboardRequest>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const dashboards = [...(yield fetchDashboardsFromState())];
        const dashboard = action.payload;

        if (fleetCustomerId) {
            dashboards.push(dashboard);
            dashboards.forEach((d, index) => (d.index = index));
            const result: ApiResult<Dashboard[]> = yield call(
                DashboardsApi.updateDashboards,
                fleetCustomerId,
                dashboards,
            );

            if (result.error) {
                yield call(handleFailure, result.error);
            } else {
                yield put(setDashboards(dashboards));
            }
        } else {
            yield call(handleFailure, 'Trying to create a dashboard without selecting a fleet customer first');
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleRestoreDefaultDashboards(action: ActionType<typeof restoreDefaultDashboards>) {
    try {
        const fleetCustomerId = yield fetchFleetCustomerIdFromState();
        const dashboards = [];
        if (fleetCustomerId) {
            const result: ApiResult<Dashboard[]> = yield call(
                DashboardsApi.updateDashboards,
                fleetCustomerId,
                dashboards,
            );

            if (result.error) {
                yield call(handleFailure, result.error);
            } else {
                yield put(setDashboards(dashboards));
            }
        } else {
            yield call(handleFailure, 'Trying to reset dashboards without selecting a fleet customer first');
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

function* handleFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
    yield put(dashboardsActionFailure(err));
}

function* watchRequests() {
    yield takeEvery(DashboardActionTypes.FETCH_DASHBOARDS_REQUEST, handleFetchDashboard);
    yield takeEvery(DashboardActionTypes.DELETE_DASHBOARD_REQUEST, handleDeleteDashboard);
    yield takeEvery(DashboardActionTypes.CREATE_DASHBOARD_REQUEST, handleCreateDashboard);
    yield takeEvery(DashboardActionTypes.UPDATE_DASHBOARD_REQUEST, handleUpdateDashboard);
    yield takeEvery(DashboardActionTypes.RESTORE_DEFAULT_DASHBOARDS, handleRestoreDefaultDashboards);
}

function* dashboardSaga() {
    yield all([fork(watchRequests)]);
}

export default dashboardSaga;
