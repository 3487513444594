import {TFunction} from 'i18next';
import {BillingItemDetailsType, BillingItemType} from '../../store/analytics/types/invoice-items-distribution.types';

export function getBillingItemTypeLabel(billingItemType: BillingItemType, t: TFunction): string {
    switch (billingItemType) {
        case BillingItemType.Materials: {
            return t('billingItemType_Tires');
        }
        case BillingItemType.Services: {
            return t('billingItemType_Services');
        }
        default:
            throw new Error(`Billing Item  Type ${billingItemType} is not supported.`);
    }
}

export function getBillingItemDetailsTypeLabel(bilingItemDetailsType: BillingItemDetailsType, t: TFunction): string {
    switch (bilingItemDetailsType) {
        case BillingItemDetailsType.BreakdownServices: {
            return t('billingItemDetailsType_BreakdownServices');
        }
        case BillingItemDetailsType.RegularServices: {
            return t('billingItemDetailsType_RegularServices');
        }
        case BillingItemDetailsType.NewTires: {
            return t('billingItemDetailsType_NewTires');
        }
        case BillingItemDetailsType.RetreadTires: {
            return t('billingItemDetailsType_RetreadTires');
        }
        default:
            throw new Error(`Billing Item Details Type ${bilingItemDetailsType} is not supported.`);
    }
}
