import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons';
import {faCheckCircle, faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TFunction} from 'i18next';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {Dispatch} from 'redux';
import {Component} from 'react';
import {ApplicationState} from '../../../../store';
import {AlertTypes, getAlertsWidgetDataRequest} from '../../../../store/analytics';
import {AlertData, AlertStatus, AlertsWidgetDataRequest} from '../../../../store/analytics/types/alert.types';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import WidgetLoading from '../widget-loading';
import styles from './alert-box.module.scss';
import {alertsMetaStore} from './alert-meta-store';
import {buildRequest, isValidSnapshot} from './request-builder';
import {conditionalClassList} from '../../../../utils/class-helpers';

class AlertBox extends Component<AllProps> {
    public constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        const {controlsSnapshot, dispatchGetAlertsWidgetDataRequest, configuration} = this.props;
        if (controlsSnapshot && isValidSnapshot(controlsSnapshot)) {
            dispatchGetAlertsWidgetDataRequest(buildRequest(controlsSnapshot, configuration.type));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {controlsSnapshot, dispatchGetAlertsWidgetDataRequest, configuration} = this.props;

        if (
            controlsSnapshot &&
            !controlsSnapshot.equals(prevProps.controlsSnapshot) &&
            isValidSnapshot(controlsSnapshot)
        ) {
            dispatchGetAlertsWidgetDataRequest(buildRequest(controlsSnapshot, configuration.type));
        }
    }

    public render(): JSX.Element {
        const {t, configuration, controlsSnapshot, data} = this.props;

        const alertData = data.find((d) => d.type === configuration.type);
        let trafficColor;
        if (alertData) {
            switch (alertData.alertStatus) {
                case AlertStatus.Good:
                    trafficColor = styles.green;
                    break;
                case AlertStatus.Warning:
                    trafficColor = styles.orange;
                    break;
                case AlertStatus.Risk:
                    trafficColor = styles.red;
                    break;
                default:
                    trafficColor = styles.green;
            }
        }

        const orangeClasses = conditionalClassList(styles, {
            orangeSection: true,
            leftBorderRadius: alertData ? alertData.goodPercentage < 0.01 && alertData.riskPercentage > 0.01 : false,
            rightBorderRadius: alertData ? alertData.goodPercentage > 0.01 && alertData.riskPercentage < 0.01 : false,
            allBorderRadius: alertData ? alertData.goodPercentage < 0.01 && alertData.riskPercentage < 0.01 : false,
        });

        const redClasses = conditionalClassList(styles, {
            redSection: true,
            allBorderRadius: alertData ? alertData.warningPercentage < 0.01 && alertData.goodPercentage < 0.01 : false,
        });

        const greenClasses = conditionalClassList(styles, {
            greenSection: true,
            allBorderRadius: alertData ? alertData.warningPercentage < 0.01 && alertData.riskPercentage < 0.01 : false,
        })

        return (
            <div className={styles.widgetContainer}>
                {alertData ? (
                    <>
                        <ReactTooltip className={styles.infoTooltip} backgroundColor='#9FB3C8' id="info"/>
                        <ReactTooltip className={styles.alertTooltip} backgroundColor='#57cc3f' id="good"/>
                        <ReactTooltip className={styles.alertTooltip} backgroundColor='#F7C948' id="warning"/>
                        <ReactTooltip className={styles.alertTooltip} backgroundColor='#de5a41' id="risk"/>
                        <div className={styles.chartView}>
                            <div className={styles.headerPanel}>
                                <div className={styles.title}>
                                    {this.getTitle(configuration.type, controlsSnapshot, t).toUpperCase()}
                                </div>
                                <div className={styles.subtitle}>
                                    {this.getSubtitle(configuration.type, controlsSnapshot, t)}
                                </div>
                            </div>
                            <div className={styles.contentPanel}>
                                <div className={`${styles.trafficLight} ${trafficColor}`}>
                                    {this.getTrafficIcon(trafficColor)}
                                </div>
                                <div className={styles.graphContainer}>
                                    <div className={styles.barGraph}>
                                        <div
                                            style={{width: `${alertData.goodPercentage > 0.01 ? alertData.goodPercentage * 100 : 0}%`}}
                                            data-tip={`${alertData.goodLabel}: ${this.formatValue(alertData.goodPercentage)}%`}
                                            data-for="good">
                                            <div className={greenClasses}>
                                                {alertData.goodPercentage > 0.15
                                                    ? `${this.formatValue(alertData.goodPercentage)}%`
                                                    : ``}
                                            </div>
                                        </div>
                                        <div
                                            style={{width: `${alertData.warningPercentage > 0.01 ? alertData.warningPercentage * 100 : 0}%`}}
                                            data-tip={`${alertData.warningLabel}: ${this.formatValue(alertData.warningPercentage)}%`}
                                            data-for="warning">
                                            <div className={orangeClasses}>
                                                {alertData.warningPercentage > 0.15
                                                    ? `${this.formatValue(alertData.warningPercentage)}%`
                                                    : ``}
                                            </div>
                                        </div>
                                        <div
                                            style={{width: `${alertData.riskPercentage > 0.01 ? alertData.riskPercentage * 100 : 0}%`}}
                                            data-tip={`${alertData.riskLabel}: ${this.formatValue(alertData.riskPercentage)}%`}
                                            data-for="risk">
                                            <div className={redClasses}>
                                                {alertData.riskPercentage > 0.15
                                                    ? `${this.formatValue(alertData.riskPercentage)}%`
                                                    : ``}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.legend}>
                                        {alertData.goodLabel ? <div className={styles.sectionLabel}><span
                                            className={styles.greenDot}/>{alertData.goodLabel}</div> : null}
                                        {alertData.warningLabel ? <div className={styles.sectionLabel}><span
                                            className={styles.orangeDot}/>{alertData.warningLabel}</div> : null}
                                        {alertData.riskLabel ? <div className={styles.sectionLabel}><span
                                            className={styles.redDot}/>{alertData.riskLabel}</div> : null}
                                    </div>
                                </div>
                                <div className={styles.questionIcon} data-tip={this.getTooltip(configuration.type, t)}
                                     data-for="info">
                                    <FontAwesomeIcon size='lg' icon={faQuestionCircle}/>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <WidgetLoading/>
                )}
            </div>
        );
    }

    private getTrafficIcon(style: string) {
        switch (style) {
            case styles.red:
            case styles.orange:
                return <FontAwesomeIcon className={styles.icon} size='3x' icon={faExclamationCircle}/>;
            case styles.green:
            default:
                return <FontAwesomeIcon className={styles.icon} size='3x' icon={faCheckCircle}/>;
        }
    }

    private getTooltip(type: AlertTypes, t: TFunction): string {
        return alertsMetaStore.get(type)!.tooltipFunction(t);
    }

    private getTitle(type: AlertTypes, controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        return alertsMetaStore.get(type)!.titleFunction(controlsSnapshot, t);
    }

    private getSubtitle(type: AlertTypes, controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        return alertsMetaStore.get(type)!.subtitleFunction(controlsSnapshot, t);
    }

    private formatValue(num: number): string {
        return (num * 100).toFixed(1);
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.alertData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetAlertsWidgetDataRequest: (parameters: AlertsWidgetDataRequest) =>
        dispatch(getAlertsWidgetDataRequest(parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlertBox));

interface PropsFromState {
    data: AlertData[];
}

interface PropsFromDispatch {
    dispatchGetAlertsWidgetDataRequest: typeof getAlertsWidgetDataRequest;
}

export interface OwnProps {
    configuration: Configuration;
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;

export interface Configuration {
    type: AlertTypes;
}
