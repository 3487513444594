
import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import {SearchMatchType, SearchResult, SearchResultType} from '../../store/search';
import styles from './header-search-result.module.scss';

export class HeaderSearchResult extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {t, searchResult} = this.props;

        return (
            <div
                className={styles.searchResult}
                onClick={(): void => this.onResultSelected(searchResult)}
                onMouseDown={(): void => this.onDisableCloseOnBlur(true)}
                onMouseUp={(): void => this.onDisableCloseOnBlur(false)}
                key={searchResult.type + searchResult.id}>
                <div className={styles.resultType}>
                    {((): string => {
                        switch (searchResult.type) {
                            case SearchResultType.BillingDocument:
                                return t('Billing Detail');
                            case SearchResultType.Invoice:
                                return t('Invoice');
                            case SearchResultType.Vehicle:
                                return t('Vehicle');
                            case SearchResultType.ServiceProvider:
                                return t('Service Provider');
                            case SearchResultType.Job:
                                return t('Job Detail');
                            case SearchResultType.Ejob:
                                return t('eJob Sheet');
                            default:
                                return '';
                        }
                    })()}
                </div>
                <div className={styles.resultName}>
                    {((): string => {
                        switch (searchResult.matchType) {
                            case SearchMatchType.Name:
                                return `${t('Name')}: ${searchResult.name.toUpperCase()}`;
                            case SearchMatchType.LicensePlate:
                                return `${t('License Plate')}: ${searchResult.name.toUpperCase()}`;
                            case SearchMatchType.FleetReference:
                                return `${t('Fleet Reference')}: ${searchResult.name.toUpperCase()}`;
                            case SearchMatchType.Reference:
                                return `${t('Reference')}: ${searchResult.name.toUpperCase()}`;
                            default:
                                return '';
                        }
                    })()}
                </div>
            </div>
        );
    }

    private onDisableCloseOnBlur(disable: boolean): void {
        const {onDisableCloseOnBlur} = this.props;
        onDisableCloseOnBlur(disable);
    }

    private onResultSelected(result: SearchResult): void {
        const {onResultSelected} = this.props;
        onResultSelected(result);
    }
}

export default withTranslation()(HeaderSearchResult);

interface OwnProps {
    searchResult: SearchResult;
    onResultSelected: (searchResult: SearchResult) => void;
    onDisableCloseOnBlur: (disable: boolean) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
