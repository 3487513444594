import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './unmounted-tire-details.module.scss';
import {
    ReportPeriodType,
    ScheduledReportPeriod,
    UnmountedTiresCustomReportParameters,
    UnmountedTiresScheduledReportParameters,
} from '../../../../../../store/reports';
import {FunctionalLocationSelection} from '../../../../../../store/analytics';
import {FunctionalLocationsSelectionsControlValue} from '../../../../../analytics/dashboard-controls/functional-locations-selector/types/functional-locations-selections-control-value';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import LocationSelectorComponent from '../../../../components/location-selector.component';
import {FleetCustomerWithConfiguration} from '../../../../../../store/fleet-customers';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';

class UnmountedTireDetailsComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        let locations: FunctionalLocationSelection[] = [];
        if (parameters && parameters.locations) {
            locations = parameters.locations;
        }
        if (parameters && parameters.period) {
            period = parameters.period;
        }

        this.state = {
            period,
            functionalLocationSelection: new FunctionalLocationsSelectionsControlValue('', locations),
        };
    }

    public render(): JSX.Element {
        const {periodType, fleetCustomer, t} = this.props;
        const {period, functionalLocationSelection} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.info}>{t('Select a customer')}</div>
                        <LocationSelectorComponent
                            defaultValue={functionalLocationSelection && functionalLocationSelection.value.length > 0 ? functionalLocationSelection : undefined}
                            onFunctionalLocationChange={(controlValue) => this.onFunctionalLocationChanged(controlValue)}/>
                    </div>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.formColumn}>
                                <div className={styles.info}>{t('Specify a period')}</div>
                                <DateRangeSelectorComponent
                                    earliestDate={fleetCustomer?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}
                                />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }

    private onFunctionalLocationChanged(controlValue: FunctionalLocationsSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection, period, fromDate, toDate} = this.state;

        if (!functionalLocationSelection || (functionalLocationSelection && !controlValue.equals(functionalLocationSelection))) {
            if (periodType === ReportPeriodType.Custom) {
                onCustomParametersChanged({
                    functionalLocationSelection: controlValue,
                    fromDate,
                    toDate,
                });
            }
            if (periodType === ReportPeriodType.Scheduled) {
                onScheduledParametersChanged({
                    period,
                    locations: controlValue.value,
                });
            }

            this.setState({
                functionalLocationSelection: controlValue,
            });
        }
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {onScheduledParametersChanged} = this.props;
        const {functionalLocationSelection} = this.state;

        onScheduledParametersChanged({
            period,
            locations: functionalLocationSelection!.value,
        });

        this.setState({
            period,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, toDate} = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {functionalLocationSelection, fromDate} = this.state;

            onCustomParametersChanged({
                functionalLocationSelection,
                fromDate,
                toDate,
            });
        });
    }
}

export default withTranslation()(UnmountedTireDetailsComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    fleetCustomer?: FleetCustomerWithConfiguration;
    parameters?: UnmountedTiresScheduledReportParameters;
    onCustomParametersChanged: (parameters: UnmountedTiresCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: UnmountedTiresScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    period: ScheduledReportPeriod;
    functionalLocationSelection?: FunctionalLocationsSelectionsControlValue;
    fromDate?: Date;
    toDate?: Date;
}

type AllState = OwnState;


