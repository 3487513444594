import {TFunction} from 'i18next';
import {RouteUrl} from '../../routes';

export interface ReportCatalogEntry {
    type: ReportType;
    route: RouteUrl;
    icon: ReportIcon;
    order: number;
}

export enum ReportType {
    EnhancedActiveFleetList = 'EnhancedActiveFleetList',
    MasterFleetList = 'MasterFleetList',
    TireHistory = 'TireHistory',
    VehicleJobList = 'VehicleJobList',
    UnbilledJobs = 'UnbilledJobs',
    VehiclesDueForInspectionDetails = 'VehiclesDueForInspectionDetails',
    DamagedTireDetails = 'DamagedTireDetails',
    MountedTireDetails = 'MountedTireDetails',
    UnmountedTireDetails = 'UnmountedTireDetails',
    RemovedAndFittedTires = 'RemovedAndFittedTires',
    MileageBilling = 'MileageBilling',
    BreakdownAndServicesBilling = 'BreakdownAndServicesBilling',
    DamagedTiresBilling = 'DamagedTiresBilling',
    TireStock = 'TireStock',
    ExtendedInspection = 'ExtendedInspection',
    ExtendedService = 'ExtendedService',
    VehiclesCurrentStatus = 'VehiclesCurrentStatus',
    InvoiceByJob = 'InvoiceByJob',
    InvoiceByVehicle = 'InvoiceByVehicle',
    InvoiceItemByVehicle = 'InvoiceItemByVehicle',
    BreakdownList = 'BreakdownList',
    InspectionDetails = 'InspectionDetails',
    TCUSensorFitments = 'TCUSensorFitments',
    TirePerformance = 'TirePerformance',
    JobVerification = 'JobVerification',
    OdometerList = 'OdometerList',
}

export enum ReportIcon {
    truck,
    tire,
    inspection,
    jobs,
    due,
    stock,
    billing
}

export class ReportCatalog {
    private entries: ReportCatalogEntry[] = [
        {
            type: ReportType.BreakdownList,
            route: RouteUrl.BreakdownListReport,
            icon: ReportIcon.jobs,
            order: 0
        },
        {
            type: ReportType.DamagedTireDetails,
            route: RouteUrl.DamagedTiresReport,
            icon: ReportIcon.tire,
            order: 1
        },
        {
            type: ReportType.EnhancedActiveFleetList,
            route: RouteUrl.EnhancedActiveFleetListReport,
            icon: ReportIcon.truck,
            order: 2
        },
        {
            type: ReportType.ExtendedInspection,
            route: RouteUrl.ExtendedInspectionReport,
            icon: ReportIcon.inspection,
            order: 3
        },
        {
            type: ReportType.ExtendedService,
            route: RouteUrl.ExtendedServiceReport,
            icon: ReportIcon.inspection,
            order: 4
        },
        {
            type: ReportType.InspectionDetails,
            route: RouteUrl.InspectionDetailsReport,
            icon: ReportIcon.inspection,
            order: 5
        },
        {
            type: ReportType.InvoiceByJob,
            route: RouteUrl.InvoiceByJobReport,
            icon: ReportIcon.jobs,
            order: 6
        },
        {
            type: ReportType.InvoiceByVehicle,
            route: RouteUrl.InvoiceByVehicleReport,
            icon: ReportIcon.jobs,
            order: 7
        },
        {
            type: ReportType.InvoiceItemByVehicle,
            route: RouteUrl.InvoiceItemByVehicleReport,
            icon: ReportIcon.jobs,
            order: 8
        },
        {
            type: ReportType.UnbilledJobs,
            route: RouteUrl.UnbilledJobsReport,
            icon: ReportIcon.jobs,
            order: 9
        },
        {
            type: ReportType.MasterFleetList,
            route: RouteUrl.MasterFleetListReport,
            icon: ReportIcon.truck,
            order: 10
        },
        {
            type: ReportType.MountedTireDetails,
            route: RouteUrl.MountedTiresReport,
            icon: ReportIcon.tire,
            order: 11
        },
        {
            type: ReportType.RemovedAndFittedTires,
            route: RouteUrl.RemovedAndFittedTiresReport,
            icon: ReportIcon.tire,
            order: 12
        },
        {
            type: ReportType.TireHistory,
            route: RouteUrl.TireHistoryReport,
            icon: ReportIcon.tire,
            order: 13
        },
        {
            type: ReportType.TirePerformance,
            route: RouteUrl.TirePerformanceReport,
            icon: ReportIcon.tire,
            order: 14
        },
        {
            type: ReportType.TireStock,
            route: RouteUrl.TireStockReport,
            icon: ReportIcon.stock,
            order: 15
        },
        {
            type: ReportType.UnmountedTireDetails,
            route: RouteUrl.UnmountedTiresReport,
            icon: ReportIcon.tire,
            order: 16
        },
        {
            type: ReportType.VehicleJobList,
            route: RouteUrl.VehicleJobListReport,
            icon: ReportIcon.jobs,
            order: 17
        },
        {
            type: ReportType.VehiclesCurrentStatus,
            route: RouteUrl.VehiclesCurrentStatusReport,
            icon: ReportIcon.truck,
            order: 18
        },
        {
            type: ReportType.VehiclesDueForInspectionDetails,
            route: RouteUrl.VehiclesDueForInspectionReport,
            icon: ReportIcon.due,
            order: 19
        },
        {
            type: ReportType.JobVerification,
            route: RouteUrl.JobVerificationReport,
            icon: ReportIcon.jobs,
            order: 20
        },
        {
            type: ReportType.MileageBilling,
            route: RouteUrl.MileageBillingReport,
            icon: ReportIcon.billing,
            order: 21
        },
        {
            type: ReportType.BreakdownAndServicesBilling,
            route: RouteUrl.BreakdownAndServicesBilling,
            icon: ReportIcon.billing,
            order: 22
        },
        {
            type: ReportType.DamagedTiresBilling,
            route: RouteUrl.DamagedTiresBilling,
            icon: ReportIcon.billing,
            order: 23
        },
        {
            type: ReportType.TCUSensorFitments,
            route: RouteUrl.TCUSensorFitmentsReport,
            icon: ReportIcon.tire,
            order: 24
        },
        {
            type: ReportType.OdometerList,
            route: RouteUrl.OdometerListReport,
            icon: ReportIcon.jobs,
            order: 25
        },
    ];

    public getAllReports(): ReportCatalogEntry[] {
        return this.entries.sort((a, b) => a.order - b.order);
    }

    public getCatalogEntry(type: ReportType): ReportCatalogEntry {
        const entry = this.entries.find((i) => i.type === type);
        if (!entry) throw new Error(`No report catalog item found for ${type}`);
        return entry;
    }
}

export function getReportName(reportType: ReportType, t: TFunction): string {
    switch (reportType) {
        case ReportType.DamagedTireDetails:
            return t('Damaged Tire Details');
        case ReportType.TireHistory:
            return t('Tire History');
        case ReportType.TirePerformance:
            return t('Tire Performance');
        case ReportType.MasterFleetList:
            return t('Master Fleet List');
        case ReportType.EnhancedActiveFleetList:
            return t('Enhanced Active Fleet List');
        case ReportType.ExtendedInspection:
            return t('Extended Inspection');
        case ReportType.ExtendedService:
            return t('Extended Service');
        case ReportType.MountedTireDetails:
            return t('Mounted Tire Details');
        case ReportType.RemovedAndFittedTires:
            return t('Removed And Fitted Tires');
        case ReportType.MileageBilling:
            return t('Mileage Billing');
        case ReportType.BreakdownAndServicesBilling:
            return t('Breakdown And Services Billing');
        case ReportType.DamagedTiresBilling:
            return t('Damaged Tires Billing');
        case ReportType.TireStock:
            return t('Tire Stock');
        case ReportType.UnmountedTireDetails:
            return t('Unmounted Tire Details');
        case ReportType.VehicleJobList:
            return t('Vehicle Job List');
        case ReportType.UnbilledJobs:
            return t('Job Verification - Unbilled Jobs');
        case ReportType.VehiclesDueForInspectionDetails:
            return t('Vehicles Due For Inspection');
        case ReportType.VehiclesCurrentStatus:
            return t('Vehicles Current Status');
        case ReportType.InvoiceByJob:
            return t('Invoice By Job');
        case ReportType.InvoiceByVehicle:
            return t('Invoice By Vehicle');
        case ReportType.InvoiceItemByVehicle:
            return t('Invoice Item By Vehicle');
        case ReportType.BreakdownList:
            return t('Breakdown List');
        case ReportType.InspectionDetails:
            return t('Inspection Details');
        case ReportType.JobVerification:
            return t('Job Verification');
        case ReportType.TCUSensorFitments:
            return t('TCU & Sensor Fitments');
        case ReportType.OdometerList:
            return t('Odometer List');
        default:
            throw new Error(`No mapping found for ReportType: ${reportType}`);
    }
}

export function getReportDescription(reportType: ReportType, t: TFunction): string {
    switch (reportType) {
        case ReportType.DamagedTireDetails:
            return t('Details on damaged tires');
        case ReportType.TireHistory:
            return t('Tire history');
        case ReportType.TirePerformance:
            return t('Tire Performance');
        case ReportType.MasterFleetList:
            return t('Fleet details');
        case ReportType.EnhancedActiveFleetList:
            return t('Fleet details, vehicle configurations & tire sizes');
        case ReportType.ExtendedInspection:
            return t('Details on vehicle inspection');
        case ReportType.ExtendedService:
            return t('Details on vehicle inspection & service');
        case ReportType.MountedTireDetails:
            return t('Details on mounted tires');
        case ReportType.RemovedAndFittedTires:
            return t('Details by task reason');
        case ReportType.MileageBilling:
            return t('Details for mileage billing');
        case ReportType.BreakdownAndServicesBilling:
            return t('Details for breakdown and services billing');
        case ReportType.DamagedTiresBilling:
            return t('Details for damaged tires billing');
        case ReportType.TireStock:
            return t('Reusable tire stock per dealer');
        case ReportType.UnmountedTireDetails:
            return t('Details on unmounted tires');
        case ReportType.VehicleJobList:
            return t('Details on jobs carried out');
        case ReportType.UnbilledJobs:
            return t('Verification of unbilled jobs');
        case ReportType.VehiclesDueForInspectionDetails:
            return t('Details on vehicles due for inspection');
        case ReportType.VehiclesCurrentStatus:
            return t('Details on current tires on vehicles');
        case ReportType.InvoiceByJob:
            return t('Details on the invoices by job');
        case ReportType.InvoiceByVehicle:
            return t('Details on the invoices by vehicle');
        case ReportType.InvoiceItemByVehicle:
            return t('Details on the invoices items by vehicle');
        case ReportType.BreakdownList:
            return t('Details on the breakdowns');
        case ReportType.InspectionDetails:
            return t('Details on the inspections');
        case ReportType.JobVerification:
            return t('Details on job tasks and invoice items');
        case ReportType.TCUSensorFitments:
            return t('Details on TCU and sensor fitments');
        case ReportType.OdometerList:
            return t('Details on odometer readings');
        default:
            throw new Error(`No mapping found for ReportType: ${reportType}`);
    }
}
