import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import i18n, {TFunction} from 'i18next';
import FleetCustomersApi from '../../api/fleet-customers-api';
import { ApiResult } from '../../types/api-result';
import { generateUUID } from '../../utils/uuid-helpers';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { addToast, ToastType } from '../toast';
import {
    addFleetCustomerSuccess,
    createFleetCustomerRequest,
    deleteFleetCustomerRequest,
    deleteFleetCustomerSuccess,
    getFleetCustomersRequest,
    getFleetCustomersSuccess,
    getFleetCustomerWithConfigurationAndUsersRequest,
    getFleetCustomerWithConfigurationSuccess,
    searchFleetCustomersFailure,
    searchFleetCustomersRequest,
    searchFleetCustomersSuccess,
    updateFleetCustomerRequest,
    updateFleetCustomerSuccess,
} from './actions';
import {
    FleetCustomer,
    FleetCustomerActionTypes,
    FleetCustomerConfiguration, FleetCustomersSearchRequest,
    FleetCustomerWithConfigurationAndUsers,
    UpdateFleetCustomerRequest,
} from './types';
import {Paged} from '../shared/types';

function* handleFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleCreate(action: ActionType<typeof createFleetCustomerRequest>) {
    try {
        const fleetCustomerRequest = action.payload;
        const result: ApiResult<FleetCustomer> = yield call(
            FleetCustomersApi.createFleetCustomer,
            fleetCustomerRequest,
        );
        const t:TFunction = i18n.t.bind(i18n);
        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(addFleetCustomerSuccess(result.data!));
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Success,
                    messages: [t('Successfully added Fleet Customer')],
                }),
            );
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleDelete(action: ActionType<typeof deleteFleetCustomerRequest>) {
    try {
        const fleetCustomer: FleetCustomer = action.payload;
        const result: ApiResult<FleetCustomer> = yield call(FleetCustomersApi.deleteFleetCustomer, fleetCustomer);

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(deleteFleetCustomerSuccess(fleetCustomer));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleUpdate(action: ActionType<typeof updateFleetCustomerRequest>) {
    try {
        const request: UpdateFleetCustomerRequest = action.payload;

        const result: ApiResult<FleetCustomerConfiguration> = yield call(
            FleetCustomersApi.updateFleetCustomer,
            request.fleetCustomerId,
            request,
        );
        const t:TFunction = i18n.t.bind(i18n);
        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(updateFleetCustomerSuccess(request));
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Success,
                    messages: [t('Successfully updated Fleet Customer')],
                }),
            );
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleGetWithConfigurationAndUsers(action: ActionType<typeof getFleetCustomerWithConfigurationAndUsersRequest>) {
    try {
        const fleetCustomerId: string = action.payload;

        const result: ApiResult<FleetCustomerWithConfigurationAndUsers> = yield call(
            FleetCustomersApi.getFleetCustomerWithConfiguration,
            fleetCustomerId,
        );

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(getFleetCustomerWithConfigurationSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleGetAll(action: ActionType<typeof getFleetCustomersRequest>) {
    try {
        const result: ApiResult<FleetCustomer[]> = yield call(FleetCustomersApi.getFleetCustomers);

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(getFleetCustomersSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleSearch(action: ActionType<typeof searchFleetCustomersRequest>) {
    try {
        const request: FleetCustomersSearchRequest = action.payload;
        const result: ApiResult<Paged<FleetCustomer>> = yield call(FleetCustomersApi.searchFleetCustomers, request);

        if (result.error) {
            yield call(searchFleetCustomersFailure, result.error);
        } else {
            yield put(searchFleetCustomersSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

function* watchRequests() {
    yield takeEvery(FleetCustomerActionTypes.CREATE_FLEET_CUSTOMER_REQUEST, handleCreate);
    yield takeEvery(FleetCustomerActionTypes.DELETE_FLEET_CUSTOMER_REQUEST, handleDelete);
    yield takeEvery(FleetCustomerActionTypes.GET_FLEET_CUSTOMERS_REQUEST, handleGetAll);
    yield takeEvery(FleetCustomerActionTypes.UPDATE_FLEET_CUSTOMER_REQUEST, handleUpdate);
    yield takeEvery(FleetCustomerActionTypes.GET_FLEET_CUSTOMER_WITH_CONFIGURATION_AND_USERS_REQUEST, handleGetWithConfigurationAndUsers);
    yield takeEvery(FleetCustomerActionTypes.SEARCH_FLEET_CUSTOMERS_REQUEST, handleSearch);
}

function* fleetCustomersSaga() {
    yield all([fork(watchRequests)]);
}

export default fleetCustomersSaga;
