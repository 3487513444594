import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {faCheckSquare, faSquare, faMinusSquare} from '@fortawesome/pro-light-svg-icons';
import {closePopup} from '../../../../../store/popup';
import {SoldToMapping} from '../../../../../store/soldto';
import {conditionalClassLister} from '../../../../../utils/class-helpers';
import SoldToSelectionItem from './sold-to-selection-item';
import styles from './sold-to-selection-popup.module.scss';

class SoldToSelectionPopup extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {originalSoldToMappings} = this.props;

        this.state = {
            soldToMappings: [...originalSoldToMappings],
            visibleSoldToMappings: [...originalSoldToMappings],
            showSelectedOnly: false,
            lastSearchQuery: '',
        };
    }

    public render(): JSX.Element {
        const {t, canChange} = this.props;
        const {visibleSoldToMappings, showSelectedOnly} = this.state;

        const showSelectedOnlyClass = conditionalClassLister(styles)({
            link: true,
            active: showSelectedOnly,
        });

        const showAllClass = conditionalClassLister(styles)({
            link: true,
            active: !showSelectedOnly,
        });

        const indicatorClass = conditionalClassLister(styles)({
            indicator: true,
            item: true,
        });

        const codeClass = conditionalClassLister(styles)({
            item: true,
            small: true
        });

        const nameClass = conditionalClassLister(styles)({
            item: true,
            large: true,
        });

        const cityClass = conditionalClassLister(styles)({
            item: true,
            medium: true
        });

        const countryClass = conditionalClassLister(styles)({
            item: true,
            small: true
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}/>
                <div className={styles.popupBody}>
                    <div className={styles.popupBodyHeader}>
                        <div className={styles.title}>{t('Make your selection')}</div>
                        <div className={styles.searchContainer}>
                            <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                            <input
                                className={styles.searchInput}
                                type="text"
                                placeholder={t('Filter')}
                                autoFocus
                                onChange={(event) => this.filterMappings(event)}
                            />
                        </div>
                    </div>
                    <div className={styles.toolbar}>
                        <div className={styles.toolbarItem}>
                            Show{' '}
                            <div className={showAllClass} onClick={() => this.onShowSelectedOnly(false)}>
                                All
                            </div>
                            |
                            <div className={showSelectedOnlyClass} onClick={() => this.onShowSelectedOnly(true)}>
                                Selected Only
                            </div>
                        </div>
                    </div>
                    <div className={styles.selectionItemsHeader}>
                        <div className={indicatorClass} onClick={() => this.onSelectAllNoneVisible()}>
                            {visibleSoldToMappings.length > 0 && visibleSoldToMappings.every(vsm => vsm.hasMapping)
                                ? <FontAwesomeIcon icon={faCheckSquare}/>
                                : visibleSoldToMappings.some(vsm => vsm.hasMapping)
                                    ? <FontAwesomeIcon icon={faMinusSquare}/>
                                    : <FontAwesomeIcon icon={faSquare}/>}</div>
                        <div className={codeClass}>{t('Code')}</div>
                        <div className={nameClass}>{t('Name')}</div>
                        <div className={codeClass}>{t('Customer')}</div>
                        <div className={cityClass}>{t('City')}</div>
                        <div className={countryClass}>{t('Country')}</div>
                    </div>
                    <div className={styles.selectionItems}>
                        {visibleSoldToMappings && visibleSoldToMappings.length > 0 ? (
                            visibleSoldToMappings.map((stm) => {
                                const includes = stm.hasMapping;
                                return (
                                    <SoldToSelectionItem
                                        soldTo={stm}
                                        selected={includes}
                                        canSelect={canChange}
                                        onSelect={(): void => this.onToggleSoldToMapping(!includes, stm)}
                                        key={stm.soldToId}
                                    />
                                );
                            })
                        ) : (
                            <div className={styles.emptyResults}>{t('No data found.')}</div>
                        )}
                    </div>
                    <div className={styles.buttons}>
                        <button type="button" className={styles.button} onClick={() => this.closePopup()}>
                            {t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private filterMappings(event: any): void {
        const {showSelectedOnly} = this.state;
        this.onUpdateVisibleMappings(event.target.value, showSelectedOnly);
    }

    private onSelectAllNoneVisible(): void {
        const {onSoldToMappingsChanged} = this.props;
        const {soldToMappings, visibleSoldToMappings} = this.state;
        if (!visibleSoldToMappings.every(vsm => vsm.hasMapping)) {
            soldToMappings.forEach((stm) => stm.hasMapping = true);
            visibleSoldToMappings.forEach((stm) => stm.hasMapping = true);
        } else {
            soldToMappings.forEach((stm) => stm.hasMapping = false);
            visibleSoldToMappings.forEach((stm) => stm.hasMapping = false);
        }

        onSoldToMappingsChanged(soldToMappings);
        this.setState({soldToMappings, visibleSoldToMappings});
    }

    private onShowSelectedOnly(showSelectedOnly: boolean): void {
        const {lastSearchQuery} = this.state;
        this.onUpdateVisibleMappings(lastSearchQuery, showSelectedOnly);
    }

    private onUpdateVisibleMappings(query: string, showSelectedOnly: boolean): void {
        const {soldToMappings} = this.state;
        let visibleSoldToMappings: SoldToMapping[];
        if (query) {
            if (query.includes(',')) {
                const searchValues = query.split(',').map(sv => sv.trim()).filter(sv => sv !== '');
                visibleSoldToMappings = soldToMappings.filter((stm) => searchValues.some(sv => this.soldToMappingMatchesFilter(stm, sv)));
            } else {
                const searchValue = query;
                visibleSoldToMappings = soldToMappings.filter((stm) => this.soldToMappingMatchesFilter(stm, searchValue));
            }
        } else {
            visibleSoldToMappings = soldToMappings;
        }

        if (showSelectedOnly) {
            this.setState({
                showSelectedOnly,
                visibleSoldToMappings: visibleSoldToMappings.filter((m) => m.hasMapping),
                lastSearchQuery: query,
            });
        } else {
            this.setState({showSelectedOnly, visibleSoldToMappings, lastSearchQuery: query});
        }
    }

    private soldToMappingMatchesFilter(soldToMapping: SoldToMapping, searchValue: string): boolean {
        return (soldToMapping.name ? soldToMapping.name.toUpperCase().includes(searchValue.toUpperCase()) : false) ||
            (soldToMapping.code ? soldToMapping.code.toUpperCase().includes(searchValue.toUpperCase()) : false) ||
            (soldToMapping.soldToCustomer ? soldToMapping.soldToCustomer.toString().toUpperCase().includes(searchValue.toUpperCase()) : false) ||
            (soldToMapping.city ? soldToMapping.city.toUpperCase().includes(searchValue.toUpperCase()) : false) ||
            (soldToMapping.country ? soldToMapping.country.toUpperCase().includes(searchValue.toUpperCase()) : false)
    }

    private onToggleSoldToMapping(select: boolean, soldToMapping: SoldToMapping): void {
        const {soldToMappings, visibleSoldToMappings} = this.state;
        const {onSoldToMappingsChanged} = this.props;

        if (select) {
            soldToMappings.find((stm) => stm.soldToId === soldToMapping.soldToId)!.hasMapping = true;
            visibleSoldToMappings.find((stm) => stm.soldToId === soldToMapping.soldToId)!.hasMapping = true;
        } else {
            soldToMappings.find((stm) => stm.soldToId === soldToMapping.soldToId)!.hasMapping = false;
            visibleSoldToMappings.find((stm) => stm.soldToId === soldToMapping.soldToId)!.hasMapping = false;
        }
        onSoldToMappingsChanged(soldToMappings);
        this.setState({soldToMappings, visibleSoldToMappings});
    }

    private closePopup(): void {
        const {dispatchClosePopup} = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = (): PropsFromState => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SoldToSelectionPopup));

interface PropsFromState {
}

interface PropsFromDispatch {
    dispatchClosePopup: typeof closePopup;
}

interface OwnProps {
    canChange: boolean;
    originalSoldToMappings: SoldToMapping[];
    onSoldToMappingsChanged: (soldToMappings: SoldToMapping[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    showSelectedOnly: boolean;
    soldToMappings: SoldToMapping[];
    visibleSoldToMappings: SoldToMapping[];
    lastSearchQuery: string;
}

type AllState = OwnState;
