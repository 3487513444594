import {DateWithFunctionalLocationsRequest} from '../types';

export interface InspectionStatusRequest extends DateWithFunctionalLocationsRequest {
    grouping: InspectionStatusGroupingType[];
}

export interface InspectionStatusData {
    readonly vehiclesInspectedOnTime: number;
    readonly vehiclesLessThanOneMonthOverdueForInspection: number;
    readonly vehiclesLessThanTwoMonthsOverdueForInspection: number;
    readonly vehiclesLessThanThreeMonthsOverdueForInspection: number;
    readonly vehiclesMoreThanThreeMonthsOverdueForInspection: number;
}

export enum InspectionStatus {
    vehiclesInspectedOnTime = 'vehiclesInspectedOnTime',
    vehiclesLessThanOneMonthOverdueForInspection = 'vehiclesLessThanOneMonthOverdueForInspection',
    vehiclesLessThanTwoMonthsOverdueForInspection = 'vehiclesLessThanTwoMonthsOverdueForInspection',
    vehiclesLessThanThreeMonthsOverdueForInspection = 'vehiclesLessThanThreeMonthsOverdueForInspection',
    vehiclesMoreThanThreeMonthsOverdueForInspection = 'vehiclesMoreThanThreeMonthsOverdueForInspection',
}

export enum InspectionStatusGroupingType {
    GLOBAL = 'global',
    SOLD_TO = 'soldTo',
    DIVISION = 'division',
    DEPOT = 'depot',
    VEHICLE_TYPE = 'vehicleType',
}
