import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {FunctionalLocationType} from '../../../../store/analytics';
import {VehicleTypesDistributionRequest} from '../../../../store/analytics/types/vehicle-types-distribution.types';
import {FleetCustomerWithConfiguration} from '../../../../store/fleet-customers';

export function buildRequest(snapshot: ControlsSnapshot, locationGrouping: FunctionalLocationType,fleetCustomerWithConfiguration:FleetCustomerWithConfiguration): VehicleTypesDistributionRequest {
    return Object.assign(
        createDateWithFunctionalLocationsRequest(snapshot), {
            grouping: [locationGrouping],
            language:fleetCustomerWithConfiguration.activeLanguage.type
        });
}
