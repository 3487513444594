import * as am4core from '@amcharts/amcharts4/core';
// tslint:disable-next-line:no-duplicate-imports
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './billing-evolution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import WidgetLoading from '../widget-loading';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {fetchBillingItemsEvolutionRequest} from '../../../../store/analytics';
import {
    breakdownServicesNetPriceField,
    buildStackedChartData,
    monthField,
    newTiresNetPriceField,
    regularServicesNetPriceField,
    retreadTiresNetPriceField,
    totalNetPricePreviousYearField,
} from './chart-data-adapter';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {
    BillingEvolutionRequest,
    BillingItemsEvolutionData,
} from '../../../../store/analytics/types/invoice-items-evolution.types';
import {colorBlue5, colorBlue8, colorYellow5, colorYellow8} from '../utils/chart-colors';
import {addStandardTooltipStyle, addStrokeToColumnChart, createXYChart, rotateAxisLabels} from '../utils/chart-utils';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {buildRequest} from './request-builder';
import {getBillingItemDetailsTypeLabel} from '../../../../utils/translations/billing-type-translation-helper';
import {BillingItemDetailsType} from '../../../../store/analytics/types/invoice-items-distribution.types';
import {CurrencyInfo} from '../../../../store/fleet-customers';

am4core.useTheme(am4themes_animated);

class BillingEvolutionWidget extends SingleChartWidgetBase<AllProps, AllState, BillingItemsEvolutionData[]> {

    private chartId: string;

    private chartColors: Map<string, Color>;

    private hiddenFields: string[];

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.chartColors = new Map<string, Color>();
        this.chartColors.set(newTiresNetPriceField, am4core.color(colorYellow8));
        this.chartColors.set(retreadTiresNetPriceField, am4core.color(colorYellow5));
        this.chartColors.set(regularServicesNetPriceField, am4core.color(colorBlue8));
        this.chartColors.set(breakdownServicesNetPriceField, am4core.color(colorBlue5));

        this.hiddenFields = [totalNetPricePreviousYearField];
    }

    public render(): JSX.Element {
        const {t, data} = this.props;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Billing Evolution')}
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        const {activeCurrency} = this.props;
        this.props.fetchBillingEvolutionRequest(buildRequest(this.props.controlsSnapshot, activeCurrency));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: BillingItemsEvolutionData[]) {
        const chart = createXYChart(this.chartId);
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = monthField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        if (data.length > 60) {
            rotateAxisLabels(categoryAxis);
        }
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.activeCurrency.name;

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';
        chart.legend.itemContainers.template.events.on('hit', (event) => {
            this.onLegendClick(event);
        });

        chart.data = buildStackedChartData(data, this.props.t, this.hiddenFields);

        this.addStackedSeries(chart, regularServicesNetPriceField, BillingItemDetailsType.RegularServices);
        this.addStackedSeries(chart, breakdownServicesNetPriceField, BillingItemDetailsType.BreakdownServices);
        this.addStackedSeries(chart, newTiresNetPriceField, BillingItemDetailsType.NewTires);
        this.addStackedSeries(chart, retreadTiresNetPriceField, BillingItemDetailsType.RetreadTires);
        this.addLineSeries(chart);

        return chart;
    }

    private onLegendClick(event: any) {
        this.updateFilteredFields(event);
        this.redraw(this.props.data);
    }

    private updateFilteredFields(event: any) {
        const fieldValue = event.target.dataItem!.dataContext.dataFields.valueY;
        if (!this.hiddenFields.includes(fieldValue)) {
            this.hiddenFields.push(fieldValue);
        } else {
            const index = this.hiddenFields.indexOf(fieldValue);
            this.hiddenFields.splice(index, 1);
        }
    }

    public addLineSeries(chart: any) {
        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = totalNetPricePreviousYearField;
        lineSeries.dataFields.categoryX = monthField;
        lineSeries.label = this.props.t('YoY Net price');
        lineSeries.name = this.props.t('YoY Comparison');
        lineSeries.stroke = am4core.color(colorBlue5);
        lineSeries.strokeWidth = 1.5;
        lineSeries.tensionX = 0.75;
        if (this.hiddenFields.includes(totalNetPricePreviousYearField)) {
            lineSeries.hidden = true;
        }

        const bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color(colorBlue5);
        bullet.tooltipText = `{categoryX}\n{label}: [bold]{valueY} ${this.props.activeCurrency.name}[/]`;
        const circle = bullet.createChild(am4core.Circle);
        circle.radius = 3;
    }

    public addStackedSeries(chart: any, seriesField: string, type: BillingItemDetailsType) {
        const stackedSeries = chart.series.push(new am4charts.ColumnSeries());
        stackedSeries.name = getBillingItemDetailsTypeLabel(type, this.props.t);
        stackedSeries.stacked = true;
        stackedSeries.dataFields.valueY = seriesField;
        stackedSeries.dataFields.categoryX = monthField;
        stackedSeries.columns.template.fill = this.chartColors.get(seriesField);
        stackedSeries.columns.template.tooltipText = `{categoryX}\n{name}: [bold]{valueY} ${this.props.activeCurrency.name}[/]`;
        stackedSeries.columns.template.fillOpacity = .8;
        stackedSeries.columns.template.maxWidth = 100;
        if (this.hiddenFields.includes(seriesField)) {
            stackedSeries.hidden = true;
        }

        addStrokeToColumnChart(stackedSeries);
        addStandardTooltipStyle(stackedSeries);
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.invoiceItemsEvolutionData,
    activeCurrency: authentication.fleetCustomer!.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchBillingEvolutionRequest: (request: BillingEvolutionRequest) => dispatch(fetchBillingItemsEvolutionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingEvolutionWidget));

interface PropsFromState {
    data: BillingItemsEvolutionData[];
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    fetchBillingEvolutionRequest: typeof fetchBillingItemsEvolutionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    filteredFields: string[];
}

type AllState = OwnState;
