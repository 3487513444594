import { Http } from '../http/http';
import {UpdateCurrentUserSettingsRequest, User, UserSummary} from '../store/authentication';
import { ApiResult } from '../types/api-result';
import { ApiUrl, UrlParamBuilder } from './api-urls';
import {performBase64Download} from '../utils/download-helper';
import {Base64Data, Paged} from '../store/shared/types';
import {UsersRequest} from '../store/users';

class UsersApi {
    public static async getCurrentUser(): Promise<ApiResult<User>> {
        const currentUser = await Http.get<User>(ApiUrl.CurrentUser);
        return { data: currentUser };
    }

    public static async updateCurrentUserSettings(
        updateCurrentUserSettingsRequest: UpdateCurrentUserSettingsRequest,
    ): Promise<ApiResult<User>> {
        const currentUser = await Http.put<UpdateCurrentUserSettingsRequest, User>(
            ApiUrl.CurrentUserSettings,
            updateCurrentUserSettingsRequest,
        );
        return { data: currentUser };
    }

    public static async searchUsers(request: UsersRequest): Promise<ApiResult<Paged<UserSummary>>> {
        const users = await Http.post<UsersRequest, Paged<UserSummary>>(ApiUrl.UsersSearch, request);
        return { data: users };
    }

    public static async getUser(userId: string): Promise<ApiResult<User>> {
        const user = await Http.get<User>(ApiUrl.UsersGet, new UrlParamBuilder().addUserId(userId).build());
        return { data: user };
    }

    public static async updateUsersSet(users: User[]): Promise<ApiResult<User[]>> {
        const data = await Http.post<User[], User[]>(ApiUrl.UsersWithFleetCustomers, users);
        return { data };
    }

    public static async updateUser(user: User): Promise<ApiResult<User>> {
        const data = await Http.put<User, User>(ApiUrl.Users, user);
        return { data };
    }

    public static async deleteUser(userId: string): Promise<ApiResult<User>> {
        const data = await Http.delete<User>(ApiUrl.UsersDelete, new UrlParamBuilder().addUserId(userId).build());
        return { data };
    }

    public static async downloadUserStructure(): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.UsersUserStructure);
        performBase64Download(result.data,'vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Users structure.xlsx`);
    }
}

export default UsersApi;
