import {OrderType, TopServiceProvidersData} from '../../../../store/analytics/types/top-service-providers-types';
import {onlyUnique} from '../../../../utils/collection-helper';

export const serviceProviderIdField = 'serviceProviderIdField';
export const serviceProviderField = 'serviceProviderField';
export const orderTypeField = 'orderTypeField';

export function buildChartData(data: TopServiceProvidersData[], orderType: OrderType): any[] {
    const datapoints: any[] = [];
    const duplicateNames = data.filter(d => data.filter(dd => dd.name.toUpperCase() === d.name.toUpperCase()).length > 1)
        .map(d => d.name.toUpperCase())
        .filter(onlyUnique);

    data.forEach((row) => {
        const datapoint = {};
        datapoint[serviceProviderIdField] = row.id;
        datapoint[serviceProviderField] = duplicateNames.includes(row.name.toUpperCase())
            ? `${row.name.toUpperCase()}, ${row.city.toUpperCase()}`
            : row.name.toUpperCase();
        datapoint[orderTypeField] = row[orderType];
        datapoints.push(datapoint);
    });
    return datapoints.reverse();
}
