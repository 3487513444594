import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import NumberFormat from 'react-number-format';
import {TFunction} from 'i18next';
import {ApplicationState} from '../../../../store';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    DateRange,
    fetchRetreadRatioKPIRequest,
} from '../../../../store/analytics';
import {buildRequest, isValidSnapshot} from './request-builder';
import styles from './retread-ratio-widget.module.scss';
import WidgetLoading from '../widget-loading';
import {NumberFormatter} from '../kpi-box/kpi-meta-store';
import {ParamKey} from '../../../../store/dashboard';
import {toBetweenMonthRangeDisplay} from '../../dashboard-controls/date-range-selector/helpers/date-range-helper';
import {
    RetreadRatioKPIData,
    RetreadRatioKPIRequest
} from '../../../../store/analytics/types/key-performance-indicator.types';
import {CurrencyInfo} from '../../../../store/fleet-customers';
import {getMonthLabel} from '../../../../utils/translations/date-translation-helper';
import {conditionalClassLister} from '../../../../utils/class-helpers';

class RetreadRatioWidget extends React.Component<AllProps> {
    public constructor(props) {
        super(props);
    }

    public componentDidMount(): void {
        const { controlsSnapshot, dispatchRetreadRatioRequest } = this.props;
        if (controlsSnapshot && isValidSnapshot(controlsSnapshot)) {
            dispatchRetreadRatioRequest(buildRequest(controlsSnapshot));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { controlsSnapshot, dispatchRetreadRatioRequest } = this.props;
        if (controlsSnapshot && !controlsSnapshot.equals(prevProps.controlsSnapshot) && isValidSnapshot(controlsSnapshot)) {
            dispatchRetreadRatioRequest(buildRequest(controlsSnapshot));
        }
    }

    public render(): JSX.Element {
        const { t, controlsSnapshot, data, activeCurrency } = this.props;

        const formatter = new NumberFormatter('.', ',', true, 1);
        const currency = activeCurrency ? activeCurrency.name : '';
        const targetMet = data && data.retreadRatioTarget < data.retreadRatio;

        const comparisonValueClasses = conditionalClassLister(styles)({
            comparisonValue: true,
            positive: targetMet,
            negative: !targetMet,
        })

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartColumn}>
                            <div className={styles.titlePanel}>
                                {t('Retread Ratio').toUpperCase()}
                            </div>
                            <div className={styles.valuePanel}>
                                <div className={styles.ratioValues}>
                                    <div className={styles.value}>
                                        <NumberFormat
                                            value={data.retreadRatio * 100}
                                            displayType='text'
                                            decimalSeparator={formatter.decimalSeparator}
                                            thousandSeparator={formatter.thousandsSeparator}
                                            fixedDecimalScale={formatter.fixedDecimalScale}
                                            decimalScale={formatter.decimalScale}
                                        />
                                        <span className={styles.unit}>%</span>
                                    </div>
                                    <div className={comparisonValueClasses}>
                                        <NumberFormat
                                            value={Math.abs(data.retreadRatioDifference * 100)}
                                            displayType='text'
                                            decimalSeparator={formatter.decimalSeparator}
                                            thousandSeparator={formatter.thousandsSeparator}
                                            fixedDecimalScale={formatter.fixedDecimalScale}
                                            decimalScale={formatter.decimalScale}
                                        />
                                        <span>% {t(targetMet ? 'above target' : 'below target')}</span>
                                    </div>
                                </div>
                                <div className={styles.label}>
                                    {this.getSubtitle(controlsSnapshot, t)}
                                </div>
                            </div>
                        </div>
                        <div className={styles.chartColumn}>
                            <div className={styles.titlePanel}>
                                {targetMet ? t('Currently Saving').toUpperCase() : t('Savings Potential').toUpperCase()}
                            </div>
                            <div className={styles.valuePanel}>
                                <div className={styles.value}>
                                    <NumberFormat
                                        value={Math.abs(data.savingsPotential)}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={2}
                                    />
                                    <span className={styles.unit}>{currency}</span>
                                </div>
                                <div className={styles.label}>
                                    {this.getSecondSubtitle(controlsSnapshot, t, targetMet)}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        )
    }

    private getSubtitle(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
            const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
            return toBetweenMonthRangeDisplay(dateRange, t);
        }
        return '';
    }

    private getSecondSubtitle(controlsSnapshot: ControlsSnapshot, t: TFunction, targetMet: boolean): string {
        if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
            const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
            const startMonth = dateRange.start.getMonth();
            const startYear = dateRange.start.getFullYear();
            const endMonth = dateRange.end.getMonth();
            const endYear = dateRange.end.getFullYear();
            let display = '';
            if ((startMonth === endMonth) && (startYear === endYear)) {
                if (targetMet) {
                    display = t('Estimated saving for {{date}} for reaching the target ratio', {date: `${getMonthLabel(startMonth, t)} ${startYear}`});
                } else {
                    display = t('Estimated saving for {{date}} when we meet the target ratio', {date: `${getMonthLabel(startMonth, t)} ${startYear}`});
                }
            } else if (startYear === endYear) {
                if (targetMet) {
                    display = t('Estimated saving between {{dateA}} and {{dateB}} for reaching the target ratio', {
                        dateA: `${getMonthLabel(startMonth, t)}`,
                        dateB: `${getMonthLabel(endMonth, t)} ${startYear}`,
                    });
                } else {
                    display = t('Estimated saving between {{dateA}} and {{dateB}} when we meet the target ratio', {
                        dateA: `${getMonthLabel(startMonth, t)}`,
                        dateB: `${getMonthLabel(endMonth, t)} ${startYear}`,
                    });
                }
            } else if (targetMet) {
                    display = t('Estimated saving between {{dateA}} and {{dateB}} for reaching the target ratio', {
                        dateA: `${getMonthLabel(startMonth, t)} ${startYear}`,
                        dateB: `${getMonthLabel(endMonth, t)} ${endYear}`,
                    });
                } else {
                    display = t('Estimated saving between {{dateA}} and {{dateB}} when we meet the target ratio', {
                        dateA: `${getMonthLabel(startMonth, t)} ${startYear}`,
                        dateB: `${getMonthLabel(endMonth, t)} ${endYear}`,
                    });
                }
            return display;
        }
        return '';
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.retreadRatioKPI,
    activeCurrency: authentication.fleetCustomer?.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchRetreadRatioRequest: (request: RetreadRatioKPIRequest) =>
        dispatch(fetchRetreadRatioKPIRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RetreadRatioWidget));

interface PropsFromState {
    data: RetreadRatioKPIData;
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    dispatchRetreadRatioRequest: typeof fetchRetreadRatioKPIRequest;
}

export interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;
