import {FleetSizeEvolutionData} from '../../../../store/analytics/types/fleet-size-evolution.types';
import {formatStringInBrusselsTime} from '../../../../utils/date-helper';

export const vehicleCountField = 'scheduledCount';
export const dateField = 'date';
export const dateFormatMoment = 'YYYY-MM-DD';
export const dateFormatAmcharts = 'yyyy-MM-dd';

export function buildChartData(data: FleetSizeEvolutionData[]): any[] {
    return data.map((d) => {
        const result = {};
        result[dateField] = formatStringInBrusselsTime(d.date, dateFormatMoment);
        result[vehicleCountField] = d.vehicleCount;
        return result;
    });
}
