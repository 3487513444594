import {TFunction} from 'i18next';
import {FleetMovementData, FleetMovementGroupingEntity} from '../../../../store/analytics/types/fleet-movement.types';
import {FunctionalLocation} from '../../../../store/soldto';

export const groupingField = 'grouping';
export const groupingIdField = 'groupingId';
export const commissionedField = 'commissioned';
export const decommissionedField = 'decommissioned';

export function buildChartData(data: FleetMovementData[], currentGrouping: FleetMovementGroupingEntity, locations: Map<string, FunctionalLocation>, t: TFunction): any[] {
    const datapoints: any[] = [];
    data.forEach((row) => {
        let location = '';
        if (currentGrouping !== FleetMovementGroupingEntity.VEHICLE_TYPE) {
            location = t('unknown');
            const locationId = row[currentGrouping];
            if (locations.has(locationId)) {
                location = locations.get(locationId)!.name.toUpperCase();
            }
        }
        const datapoint = {};
        datapoint[commissionedField] = row.commissioned;
        datapoint[decommissionedField] = -row.decommissioned;
        datapoint[groupingField] = currentGrouping;
        datapoint[groupingIdField] = location || row[currentGrouping];
        datapoints.push(datapoint);
    });

    datapoints.sort((a, b) => b[commissionedField] - a[commissionedField]);

    return datapoints;
}
