export enum SharedActionTypes {
    UNSELECT_FLEET_CUSTOMER = '@@shared/UNSELECT_FLEET_CUSTOMER',
    LOGOUT_SUCCESS = '@@shared/LOGOUT_SUCCESS',
}

export enum RequestStatusType {
    New,
    InProgress,
    Success,
    Failed
}

export interface Paged<T> {
    pageData: T[];
    totalCount: number;
}

export enum FileType {
    ZIP = 'ZIP',
    XLSX = 'XLSX'
}

export enum SortDirection {
    None = 'none',
    Ascending = 'asc',
    Descending = 'desc',
}

export enum LocalLanguageType {
    DEFAULT_LANGUAGE = 'defaultLanguage',
    LOCAL_LANGUAGE_1 = 'localLanguage1',
    LOCAL_LANGUAGE_2 = 'localLanguage2',
    LOCAL_LANGUAGE_3 = 'localLanguage3',
}

export class RequestStatus {
    constructor(public type: RequestStatusType, public message?: string) {
    }

    public get isNew():boolean {
        return this.type === RequestStatusType.New;
    }

    public get isInProgress():boolean {
        return this.type === RequestStatusType.InProgress;
    }

    public get isSuccess():boolean {
        return this.type === RequestStatusType.Success;
    }

    public get isFailed():boolean{
        return this.type === RequestStatusType.Failed;
    }
}

export interface Base64Data {
    data: string;
}
