import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Color} from "@amcharts/amcharts4/core";
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import WidgetHeaderDropdown, {
    DropdownOption,
} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetTable, {WidgetTableColumnType} from '../../../../components/widget-table/widget-table';

import {ApplicationState} from '../../../../store';
import {
    fetchTopRetreadTiresFittedByServiceProviderRequest,
} from '../../../../store/analytics';
import {CurrencyInfo, FleetCustomerWithConfiguration, FleetType} from '../../../../store/fleet-customers';
import {ViewMode} from '../../../../types/view-mode';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {addStandardTooltipStyle, addStrokeToColumnChart, createXYChart} from '../utils/chart-utils';
import {hasInspectionPeriod} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import {
    buildRetreadChartData, retreadNewTiresField,
    retreadTiresField,
    serviceProviderField
} from './chart-data-adapter';
import {buildRetreadRequest} from './request-builder';
import styles from './top-retread-tires-fitted-by-service-providers-widget.module.scss';
import {
    RetreadOrderType, TopRetreadTiresFittedByServiceProvidersData,
    TopRetreadTiresFittedByServiceProvidersRequest
} from "../../../../store/analytics/types/top-tires-fitted-by-service-providers-types";
import {colorBlue4, colorBlue8} from "../utils/chart-colors";

am4core.useTheme(am4themes_animated);

class TopRetreadTiresFittedByServiceProvidersWidget extends SingleChartWidgetBase<AllProps, AllState, TopRetreadTiresFittedByServiceProvidersData[]> {
    private chartId: string;

    private readonly OPTION_NEW_TIRES = {id: '1', key: DropdownOptionKey.ByNewTires};

    private readonly OPTION_RETREAD_TIRES = {id: '2', key: DropdownOptionKey.ByRetreadTires};

    constructor(props) {
        super(props);
        const {fleetCustomer} = this.props;
        this.chartId = generateUUID();

        this.state = {
            options: [this.OPTION_NEW_TIRES, this.OPTION_RETREAD_TIRES],
            selectedOption: fleetCustomer.configuration.fleetType === FleetType.PayGo ? this.OPTION_NEW_TIRES : this.OPTION_RETREAD_TIRES,
            viewMode: ViewMode.chart,
        };
    }

    public render(): JSX.Element {
        const {t, data, fleetCustomer} = this.props;
        const {selectedOption, options, viewMode} = this.state;

        const columns = [
            {
                title: 'Name',
                property: 'name',
                type: WidgetTableColumnType.upperText,
                width: '480px',
            },
            {
                title: '# New Tires',
                property: 'newCount',
                type: WidgetTableColumnType.number,
            }, {
                title: `# Retread Tires`,
                property: 'retreadCount',
                type: WidgetTableColumnType.number,
            }
        ]

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div
                                className={styles.headerTitle}>{t('Top 20 (Retread) Tires Fitted By Service Providers')}</div>
                            <div className={styles.interactions}>
                                {fleetCustomer.configuration.fleetType === FleetType.PayGo ? <WidgetHeaderDropdown
                                    options={options}
                                    selection={selectedOption}
                                    onSelectionChanged={(selection) => this.onSelect(selection)}
                                /> : ''}
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable}/>
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{width: '100%'}}/>
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{columns}}
                                    data={data}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading/>
                )}
            </div>
        );
    }

    private onSelect(option: DropdownOption): void {
        this.setState({selectedOption: option, viewMode: ViewMode.chart}, () => {
            this.fetchData(this.props.controlsSnapshot);
        });
    }

    private toggleViewMode(): void {
        const {viewMode} = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({viewMode: updatedViewMode}, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        this.props.fetchTopRetreadTiresFittedByServiceProvider(
            buildRetreadRequest(this.props.controlsSnapshot, this.getOrderType(this.state.selectedOption)),
        );
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TopRetreadTiresFittedByServiceProvidersData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = serviceProviderField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        // categoryAxis.renderer.labels.template.truncate = false;
        categoryAxis.renderer.labels.template.wrap = true;
        categoryAxis.renderer.labels.template.maxWidth = 320;
        categoryAxis.renderer.labels.template.tooltipText = "{category}";

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Tires');
        chart.data = buildRetreadChartData(data);
        this.addAllSeries(chart);

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';
        chart.legend.reverseOrder = true;

        return chart;
    }

    private addAllSeries(chart: any): void {
        this.addSeries(chart, this.props.t('Retread Tires'), am4core.color(colorBlue4), retreadTiresField);
        this.addSeries(chart, this.props.t('New Tires'), am4core.color(colorBlue8), retreadNewTiresField);
    }

    private addSeries(chart: any, seriesName: string, color: Color, valueXField: string): void {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = seriesName;
        series.dataFields.categoryY = serviceProviderField;
        series.dataFields.valueX = valueXField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${
            seriesName
        }[/]`;
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.maxHeight = 100;
        series.columns.template.adapter.add('fill', (fill, target) => color);

        const seriesValueLabel = series.bullets.push(new am4charts.LabelBullet());
        seriesValueLabel.label.text = '{valueX}';
        seriesValueLabel.label.horizontalCenter = 'left';
        seriesValueLabel.label.dx = 10;
        seriesValueLabel.label.hideOversized = false;
        seriesValueLabel.label.truncate = false;

        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);

    }

    private getOrderType(option: DropdownOption): RetreadOrderType {
        switch (option) {
            case this.OPTION_NEW_TIRES:
                return RetreadOrderType.NEW_TIRES;
            case this.OPTION_RETREAD_TIRES:
                return RetreadOrderType.RETREAD_TIRES;
            default:
                throw Error('Can not determine service provider order type');
        }
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.topRetreadTiresByServiceProvidersData,
    fleetCustomer: authentication.fleetCustomer,
    activeCurrency: authentication.fleetCustomer!.activeCurrency,
    fleetCustomerId: authentication.fleetCustomer?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchTopRetreadTiresFittedByServiceProvider: (request: TopRetreadTiresFittedByServiceProvidersRequest) =>
        dispatch(fetchTopRetreadTiresFittedByServiceProviderRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopRetreadTiresFittedByServiceProvidersWidget));

interface PropsFromState {
    data: TopRetreadTiresFittedByServiceProvidersData[];
    fleetCustomer: FleetCustomerWithConfiguration;
    fleetCustomerId: string;
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    fetchTopRetreadTiresFittedByServiceProvider: typeof fetchTopRetreadTiresFittedByServiceProviderRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
    viewMode: ViewMode;
}

type AllState = OwnState;
