import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {faCog} from '@fortawesome/pro-light-svg-icons/faCog';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import styles from './controls-container.module.scss';
import ControlsSummaryItem from './controls-summary-item/controls-summary-item';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {ControlType, DashboardControl, ParamKey} from '../../../store/dashboard';
import {renderControl} from '../../../utils/control-render-helper';
import ToggleDisplay from '../../../utils/toggle-display';
import {Control} from './control';
import {ControlValue} from './controls-snapshot';
import ControlsSettingsItem from './controls-settings-item/controls-settings-item';

class ControlsContainer extends Component<AllProps, AllState> {

    private controlsAlreadyInitialized = false;

    private controls = new Map<ParamKey, Control>();

    constructor(props) {
        super(props);

        this.state = {
            openIndex: -1,
            controlValues: new Map<ParamKey, ControlValue>(),
        };
    }

    public componentDidMount() {
        const {setControlsContainerRef} = this.props;
        setControlsContainerRef(this);
    }

    public componentWillUnmount() {
        const {setControlsContainerRef} = this.props;
        setControlsContainerRef(undefined);
    }

    public render(): JSX.Element {
        const {openIndex, controlValues} = this.state;
        const {controls, dashboardId, defaultControlValues} = this.props;

        const selectorPanelClasses = conditionalClassLister(styles)({
            selectorPanel: true,
            isOpen: openIndex !== -1,
            isLarge: openIndex === 1,
        });

        const summaryPanelClasses = conditionalClassLister(styles)({
            controlsContainerPanel: true,
            isOpen: openIndex !== -1,
        });

        return (
            <div className={styles.controlsContainer}>
                <div className={summaryPanelClasses}>
                    {controls.map(
                        (c: DashboardControl, index: number) => {
                            const value = controlValues.get(c.key);
                            switch (c.type) {
                                default:
                                case ControlType.DateRange:
                                case ControlType.FunctionalLocations:
                                    return <ControlsSummaryItem key={c.key}
                                                                isOpen={openIndex === index}
                                                                title={c.summaryTitle}
                                                                isFirst={index === 0}
                                                                isLast={index === controls.length - 1}
                                                                isAnyOpen={openIndex !== -1}
                                                                displayValue={value ? value.displayValue : undefined}
                                                                onToggleOpen={() => this.onToggleOpen(index, c)}/>;
                                case ControlType.AdditionalOptions:
                                    return <ControlsSettingsItem key={c.key}
                                                                 isOpen={openIndex === index}
                                                                 isFirst={index === 0}
                                                                 isLast={index === controls.length - 1}
                                                                 isAnyOpen={openIndex !== -1}
                                                                 icon={faCog}
                                                                 onToggleOpen={() => this.onToggleOpen(index, c)}/>;
                            }

                        },
                    )}
                </div>
                <div className={selectorPanelClasses}>
                    {controls.map(
                        (controlDef: DashboardControl, index: number) => {
                            const controlRendering = renderControl(dashboardId, controlDef,
                                control => this.onSetControlRef(controlDef.key, control),
                                value => this.onValueChange(controlDef.key, value),
                                defaultControlValues.get(controlDef.key));
                            return <ToggleDisplay key={index}
                                                  show={openIndex === index}>{controlRendering}</ToggleDisplay>;
                        },
                    )}
                </div>
            </div>
        );
    }

    public onSetControlRef(key: ParamKey, control?: Control) {
        if (control) {
            this.controls.set(key, control)
        } else {
            this.controls.delete(key)
        }
    }

    public forceRefresh(): void {
        const {defaultControlValues} = this.props;
        this.controlsAlreadyInitialized = false;
        Array.from(this.controls.keys()).forEach((key: ParamKey) => {
            const control = this.controls.get(key);
            if (control) {
                control.forceRefresh(defaultControlValues.get(key));
            }
        });
    }

    public beforeRedraw(callback: () => void): void {
        const {openIndex} = this.state;
        if (openIndex === -1) {
            callback();
        } else {
            Array.from(this.controls.values()).forEach(c => c.beforeRedraw());
            this.setState({openIndex: -1});
            // wait for animation to finish before executing callback
            setTimeout(() => callback(), 300);
        }
    }

    private onValueChange(key: ParamKey, value: ControlValue) {
        const {controlValues} = this.state;
        const {controls, onControlsStateChanged, onControlsInitialized} = this.props;
        /*  #Sam: ideally we should clone the map but that results in timing
            issues because this method is called by a
            few children before the actual setState is inacted. */
        controlValues.set(key, value);
        // you don't want to keep firing during initialization
        if (controlValues.size === controls.length) {
            this.setState({controlValues}, () => {
                if (this.controlsAlreadyInitialized) {
                    onControlsStateChanged(controlValues);
                } else {
                    this.controlsAlreadyInitialized = true;
                    onControlsInitialized(controlValues);
                }
            });
        }
    }

    private onToggleOpen(index: number, control: DashboardControl) {
        const {openIndex} = this.state;
        if (index !== openIndex) {
            this.setState({openIndex: index});
        } else {
            this.setState({openIndex: -1});
        }
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ControlsContainer));

interface OwnProps {
    setControlsContainerRef: (ref: any) => void;
    dashboardId: string;
    controls: DashboardControl[];
    defaultControlValues: Map<ParamKey, ControlValue>;
    onControlsStateChanged: (values: Map<ParamKey, ControlValue>) => void;
    onControlsInitialized: (values: Map<ParamKey, ControlValue>) => void;
}

interface PropsFromState {
}

interface PropsFromDispatch {
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    openIndex: number;
    controlValues: Map<ParamKey, ControlValue>;
}

type AllState = OwnState;
