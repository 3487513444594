import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    FleetMovementData,
    FleetMovementGroupingEntity,
    FleetMovementRequest,
} from '../../../../store/analytics/types/fleet-movement.types';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {FunctionalLocation, SoldToWithDivisions} from '../../../../store/soldto';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {ApplicationState} from '../../../../store';
import {fetchFleetMovementRequest, updateSingleDefaultControlValue} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import styles from './fleet-movement-widget.module.scss';
import {getDropDownOptionLabel} from '../../../../utils/translations/dropdown-option-translation-helper';
import WidgetLoading from '../widget-loading';
import {buildRequest} from './request-builder';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {buildChartData, commissionedField, decommissionedField, groupingIdField} from './chart-data-adapter';
import {colorLime6, colorRed6} from '../utils/chart-colors';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    customizeGrip,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {getVehicleCommissionFieldLabel} from '../../../../utils/translations/vehicle-translation-helper';
import {ViewMode} from '../../../../types/view-mode';
import WidgetTable, {WidgetTableColumnType} from '../../../../components/widget-table/widget-table';

am4core.useTheme(am4themes_animated);

class FleetMovementWidget extends SingleChartWidgetBase<AllProps, AllState, FleetMovementData[]> {
    private chartId: string;

    private readonly OPTION_DEPOT = {id: '1', key: DropdownOptionKey.ByDepot};

    private readonly OPTION_DIVISION = {id: '2', key: DropdownOptionKey.ByDivision};

    private readonly OPTION_SOLD_TO = {id: '3', key: DropdownOptionKey.ByCompany};

    private readonly OPTION_VEHICLE_TYPE = {id: '4', key: DropdownOptionKey.ByVehicleType};

    private locations: Map<string, FunctionalLocation> = new Map<string, FunctionalLocation>();

    private chartColors: Map<string, Color>;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.chartColors = new Map<string, Color>();
        this.chartColors.set(decommissionedField, am4core.color(colorRed6));
        this.chartColors.set(commissionedField, am4core.color(colorLime6));

        this.state = {
            options: [this.OPTION_DEPOT, this.OPTION_DIVISION, this.OPTION_SOLD_TO, this.OPTION_VEHICLE_TYPE],
            selectedOption: this.OPTION_DEPOT,
            viewMode: ViewMode.chart,
        };

        if (this.props.soldToWithDivisions && this.props.soldToWithDivisions.length > 0) {
            this.updateLocations();
        }
    }

    protected afterComponentDidUpdate(prevProps) {
        if (prevProps.soldToWithDivisions !== this.props.soldToWithDivisions) {
            this.updateLocations();
        }
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options, viewMode} = this.state;

        const currentGrouping = this.getGroupingEntity(selectedOption);
        let listData: any[] = [];
        if (data) {
            listData = buildChartData(data, currentGrouping, this.locations, t);
        }

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {`${t('Fleet Movement')} `}{getDropDownOptionLabel(selectedOption.key, t)}
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                            <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                {viewMode === ViewMode.chart ? <FontAwesomeIcon icon={faTable}/> :
                                    <FontAwesomeIcon icon={faChartBar}/>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.chartContent}
                         style={{display: viewMode === ViewMode.chart ? 'flex' : 'none'}}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                    {viewMode === ViewMode.table ?
                        <div className={styles.tableContent}>
                            <WidgetTable config={{
                                columns: [
                                    {
                                        title: 'Name',
                                        property: 'groupingId',
                                        type: WidgetTableColumnType.upperText,
                                        width: '50%',
                                    },
                                    {
                                        title: 'Commissioned',
                                        property: 'commissioned',
                                        type: WidgetTableColumnType.number,
                                        width: '25%',
                                    },
                                    {
                                        title: 'Decommissioned',
                                        property: 'decommissioned',
                                        type: WidgetTableColumnType.number,
                                        width: '25%',
                                    },
                                ],
                            }} data={listData}/></div> : ''
                    }
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    private updateLocations() {
        this.locations.clear();

        if (this.props.soldToWithDivisions) {
            const soldTos = this.props.soldToWithDivisions;
            const divisions = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions);
            const depots = this.props.soldToWithDivisions!.flatMap(soldTo => soldTo.divisions.flatMap(div => div.depots));
            soldTos.forEach(st => this.locations.set(st.id, st));
            divisions.forEach(st => this.locations.set(st.id, st));
            depots.forEach(st => this.locations.set(st.id, st));
        }
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchFleetMovement(buildRequest(this.props.controlsSnapshot, this.getGroupingEntity(this.state.selectedOption)));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    private onSelect(option: DropdownOption): void {
        this.setState({selectedOption: option}, () => {
            this.fetchData(this.props.controlsSnapshot);
        });
    }

    private toggleViewMode() {
        const {viewMode} = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({viewMode: updatedViewMode}, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            }
        });
    }

    protected createChart(data: FleetMovementData[]): am4charts.XYChart {
        const {t} = this.props;
        const chart = createXYChart(this.chartId);
        const currentGrouping = this.getGroupingEntity(this.state.selectedOption);

        chart.data = buildChartData(data, currentGrouping, this.locations, t);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = groupingIdField;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.minGridDistance = 10;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.extraMin = 0.1;
        valueAxis.extraMax = 0.1;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.minGridDistance = 40;

        this.addSeries(chart, decommissionedField, 'right', -10);
        this.addSeries(chart, commissionedField, 'left', 10);

        if (chart.data.length > 5) {
            chart.scrollbarY = new am4charts.XYChartScrollbar();
            chart.scrollbarY.minWidth = 30;
            chart.zoomOutButton.disabled = true;
            chart.events.on('ready', () => {
                const category1 = chart.data[0].groupingId;
                const category5 = chart.data[4].groupingId;
                categoryAxis.zoomToCategories(category1, category5);
            });
            customizeGrip(chart.scrollbarY.startGrip);
            customizeGrip(chart.scrollbarY.endGrip);
        }

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';

        return chart;
    }

    private addSeries(chart: any, field: string, labelHorizontalCenter: string, labelDx: number) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = groupingIdField;
        series.name = getVehicleCommissionFieldLabel(field, this.props.t);
        series.clustered = false;
        series.columns.template.tooltipText = `{valueX} ${this.props.t('vehicles')}`;
        series.columns.template.maxHeight = 100;
        series.fillOpacity = 0.8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);

        dynamicallySetColorByType(series, field, () => this.chartColors.get(field)!);

        const seriesLabel = series.bullets.push(new am4charts.LabelBullet());
        seriesLabel.label.text = '{valueX}';
        seriesLabel.label.hideOversized = false;
        seriesLabel.label.truncate = false;
        seriesLabel.label.horizontalCenter = labelHorizontalCenter;
        seriesLabel.label.dx = labelDx;
    }

    private getGroupingEntity(option: DropdownOption): FleetMovementGroupingEntity {
        switch (option) {
            case this.OPTION_DEPOT:
                return FleetMovementGroupingEntity.DEPOT;
            case this.OPTION_DIVISION:
                return FleetMovementGroupingEntity.DIVISION;
            case this.OPTION_SOLD_TO:
                return FleetMovementGroupingEntity.SOLD_TO;
            case this.OPTION_VEHICLE_TYPE:
                return FleetMovementGroupingEntity.VEHICLE_TYPE;
            default:
                throw Error('Can not determine vehicle grouping option');
        }
    }
}

const mapStateToProps = ({analytics, authentication, soldTo}: ApplicationState) => ({
    data: analytics.fleetMovementData,
    soldToWithDivisions: soldTo.soldToWithDivisions,
    fleetCustomerId: authentication.fleetCustomer!.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFleetMovement: (request: FleetMovementRequest) => dispatch(fetchFleetMovementRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    updateSingleDefaultControlValue: (key, value) => dispatch(updateSingleDefaultControlValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FleetMovementWidget));

interface PropsFromState {
    data: FleetMovementData[];
    soldToWithDivisions: SoldToWithDivisions[];
    fleetCustomerId: string;
}

interface PropsFromDispatch {
    updateSingleDefaultControlValue: typeof updateSingleDefaultControlValue;
    fetchFleetMovement: typeof fetchFleetMovementRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
    viewMode: ViewMode;
}

type AllState = OwnState;
