import {action} from 'typesafe-actions';
import {SoldToActionTypes, SoldToWithDivisions, SoldToMappings} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchSoldToMappingsRequest = (fleetCustomerId: string) =>
    action(SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_REQUEST, fleetCustomerId);
export const fetchSoldToMappingsSuccess = (soldtos: SoldToMappings) =>
    action(SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_SUCCESS, soldtos);
export const fetchSoldToMappingsFailure = (message: string) =>
    action(SoldToActionTypes.FETCH_SOLD_TO_MAPPINGS_FAILURE, message);

export const fetchSoldTosRequest = (fleetCustomerId: string) =>
    action(SoldToActionTypes.FETCH_SOLD_TOS_REQUEST, fleetCustomerId);
export const fetchSoldTosSuccess = (soldtos: SoldToWithDivisions[]) =>
    action(SoldToActionTypes.FETCH_SOLD_TOS_SUCCESS, soldtos);
