import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './job-verification.module.scss';
import {
    JobVerificationCustomReportParameters,
    JobVerificationScheduledReportParameters,
    ReportPeriodType,
} from '../../../../../../store/reports';

class JobVerificationComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let invoiceNumber;
        if (parameters && parameters.invoiceNumber) {
            invoiceNumber = parameters.invoiceNumber;
        }

        this.state = {
            invoiceNumber,
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {invoiceNumber,jobNumber} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.formColumn}>
                            <div className={styles.textFilter}>
                                <label htmlFor="invoiceNumber">
                                    {t('Invoice Number')}:
                                    <input type="text" id="invoiceNumber"
                                           value={invoiceNumber || ''}
                                           className={styles.fieldInput}
                                           onChange={(event: any) => this.onInvoiceNumberChanged(event)}/>
                                </label>
                            </div>
                            <div className={styles.textFilter}>
                                <label htmlFor="jobNumber">
                                    {t('Job Number')}:
                                    <input type="text" id="jobNumber"
                                           value={jobNumber || ''}
                                           className={styles.fieldInput}
                                           onChange={(event: any) => this.onJobNumberChanged(event)}/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onInvoiceNumberChanged(event: any) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {jobNumber} = this.state;
        const invoiceNumber = event.target.value;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                invoiceNumber,
                jobNumber
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                invoiceNumber,
                jobNumber
            });
        }

        this.setState({
            invoiceNumber,
        });
    }

    private onJobNumberChanged(event: any) {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {invoiceNumber} = this.state;
        const jobNumber = event.target.value;

        if (periodType === ReportPeriodType.Custom) {
            onCustomParametersChanged({
                jobNumber,
                invoiceNumber
            });
        }
        if (periodType === ReportPeriodType.Scheduled) {
            onScheduledParametersChanged({
                jobNumber,
                invoiceNumber
            });
        }

        this.setState({
            jobNumber,
        });
    }
}

export default withTranslation()(JobVerificationComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: JobVerificationScheduledReportParameters;
    onCustomParametersChanged: (parameters: JobVerificationCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: JobVerificationScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    invoiceNumber?: string;
    jobNumber?: string;
}

type AllState = OwnState;
