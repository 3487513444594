import {ControlValue} from '../../controls-snapshot';

export class BillingControlValue implements ControlValue {
    constructor(public displayValue: string,
                public value: boolean) {
    }

    public equals(other: ControlValue): boolean {
        if (other && other instanceof BillingControlValue) {
            return this.value === other.value;
        }
        return false;
    }
}
