import {FunctionalLocation} from '../../../../store/soldto';
import {FunctionalLocationType} from '../../../../store/analytics';
import {VehiclesDistributionData} from '../../../../store/analytics/types/vehicle-types-distribution.types';

export const functionalLocationField = 'location';
export const functionalLocationIdField = 'locationId';

export function buildChartData(data: VehiclesDistributionData[], functionalLocation: FunctionalLocationType, locations: Map<string, FunctionalLocation>): any[]  {
    const datapointsMap = new Map<string, any>();
    const vehicleTypes: string[] = [];

    if (data) {
        data.forEach((row) => {
            const locationId = row[functionalLocation];
            let location = '';
            if (locations.has(locationId)) {
                location = locations.get(locationId)!.name.toUpperCase();
            }

            if (!datapointsMap.has(location)) {
                const datapoint = {};
                datapoint[functionalLocationField] = location;
                datapoint[functionalLocationIdField] = locationId;
                datapointsMap.set(location, datapoint);
            }
            const {vehicleType} = row;
            const datapoint = datapointsMap.get(location);
            datapoint[vehicleType] = row.vehicleCount;
            if (!vehicleTypes.includes(vehicleType)) {
                vehicleTypes.push(vehicleType);
            }
        });
    }

    const datapoints = Array.from(datapointsMap.values());
    datapoints.sort((a, b) => calculateTotalVehicleCount(b, vehicleTypes) - calculateTotalVehicleCount(a, vehicleTypes));

    addZeroValues(datapoints, vehicleTypes);

    return datapoints;
}

function addZeroValues(datapoints: any, vehicleTypes: string[]) {
    datapoints.forEach((datapoint) => {
        vehicleTypes.forEach((type) => {
            if (!datapoint[type]) {
                datapoint[type] = 0;
            }
        });
    });
}

function calculateTotalVehicleCount(datapoint: any, vehicleTypes: string[]) {
    let count = 0;
    vehicleTypes.forEach((type) => {
        if (datapoint[type]) {
            count += datapoint[type];
        }
    });
    return count;
}

export function getVehiclesTypes(data: VehiclesDistributionData[]): string[] {
    const types: string[] = [];
    if (data) {
        data.forEach((row) => {
            if (!types.includes(row.vehicleType)) {
                types.push(row.vehicleType);
            }
        });
    }
    return types;
}
