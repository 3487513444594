import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import NumberFormat from 'react-number-format';
import {TFunction} from 'i18next';
import {ApplicationState} from '../../../../store';
import {CurrencyInfo} from '../../../../store/fleet-customers';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    DateRange,
    DateRangeWithFunctionalLocationsRequest,
    fetchAvoidableBreakdownsKPIRequest,
} from '../../../../store/analytics';
import {buildRequest, isValidSnapshot} from './request-builder';
import styles from './avoidable-breakdowns-widget.module.scss';
import WidgetLoading from '../widget-loading';
import {NumberFormatter} from '../kpi-box/kpi-meta-store';
import {ParamKey} from '../../../../store/dashboard';
import {
    toEndDateDisplay
} from '../../dashboard-controls/date-range-selector/helpers/date-range-helper';
import {AvoidableBreakdownsKPIData} from '../../../../store/analytics/types/key-performance-indicator.types';


class CostSavingByBreakdownWidget extends React.Component<AllProps, AllState> {
    public constructor(props) {
        super(props);

        this.state = {
            costSavingByBreakdown: 0,
        }
    }

    public componentDidMount(): void {
        const { controlsSnapshot, dispatchAvoidableBreakdownsRequest } = this.props;
        if (controlsSnapshot && isValidSnapshot(controlsSnapshot)) {
            dispatchAvoidableBreakdownsRequest(buildRequest(controlsSnapshot));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { controlsSnapshot, dispatchAvoidableBreakdownsRequest, data } = this.props;
        if (controlsSnapshot && !controlsSnapshot.equals(prevProps.controlsSnapshot) && isValidSnapshot(controlsSnapshot)) {
            dispatchAvoidableBreakdownsRequest(buildRequest(controlsSnapshot));
        }

        if (data && data !== prevProps.data) {
            this.setState({
                costSavingByBreakdown: data.averageCostPerBreakdown - data.averageCostPerStopAndGo,
            })
        }
    }

    public render(): JSX.Element {
        const { t, controlsSnapshot, data, activeCurrency } = this.props;
        const { costSavingByBreakdown } = this.state;

        const formatter = new NumberFormatter('.', ',', true, 2);
        const currency = activeCurrency ? activeCurrency.name : '';

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartColumn}>
                            <div className={styles.titlePanel}>
                                {t('Cost Saving by Breakdown').toUpperCase()}
                            </div>
                            <div className={styles.valuePanel}>
                                <div className={styles.value}>
                                    <NumberFormat
                                        value={costSavingByBreakdown}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    />
                                    <span className={styles.unit}>{currency}</span>
                                </div>
                                <div className={styles.label}>
                                    {this.getSubtitle(controlsSnapshot, t)}
                                </div>
                            </div>
                        </div>
                        <div className={styles.chartColumn}>
                            <div className={styles.infoSecondary}>
                                <div className={styles.labelSecondary}>{t('Typical Cost for Breakdown Resolution')}</div>
                                <div className={styles.valueSecondary}>
                                    <NumberFormat
                                        value={data.averageCostPerBreakdown}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    /><span className={styles.unit}>{currency}</span>
                                </div>
                            </div>
                            <div className={styles.infoSecondary}>
                                <div className={styles.labelSecondary}>{t('Typical Cost for Stop & Go Resolution')}</div>
                                <div className={styles.valueSecondary}>
                                    <NumberFormat
                                        value={data.averageCostPerStopAndGo}
                                        displayType='text'
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    />
                                    <span className={styles.unit}>{currency}</span>
                                </div>
                            </div>
                            <div className={styles.infoText}>{t('The difference in cost is mainly driven by Downtime mitigation and avoiding Call-out Fees.')}</div>
                        </div>
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        )
    }

    private getSubtitle(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
            const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
            return toEndDateDisplay(dateRange, t);
        }
        return '';
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.avoidableBreakdownsKPI,
    activeCurrency: authentication.fleetCustomer?.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchAvoidableBreakdownsRequest: (request: DateRangeWithFunctionalLocationsRequest) =>
        dispatch(fetchAvoidableBreakdownsKPIRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CostSavingByBreakdownWidget));

interface PropsFromState {
    data: AvoidableBreakdownsKPIData;
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    dispatchAvoidableBreakdownsRequest: typeof fetchAvoidableBreakdownsKPIRequest;
}

export interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;

export interface OwnState {
    costSavingByBreakdown: number;
}

type AllState = OwnState;

