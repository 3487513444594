import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {SoldToMapping} from '../../../../store/soldto';
import styles from './sold-to-selection-control.module.scss';

class SoldToSelectionControl extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);
        const {soldToMappings} = this.props;

        this.state = {
            caption: this.getCaption(soldToMappings),
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {soldToMappings} = this.props;

        if (soldToMappings !== prevProps.soldToMappings) {
            this.setState({caption: this.getCaption(soldToMappings)});
        }
    }

    public render(): JSX.Element {
        const {canDownloadStructure, onChange, canChange, onDownloadStructure, t} = this.props;
        const {caption} = this.state;

        return (
            <>
                <div className={styles.soldToSelectionControl} onClick={() => onChange()}>
                    {caption}
                </div>
                <div className={styles.soldToLinks}>
                    <div className={styles.soldToLink} onClick={() => onChange()}>
                        {canChange ? t('Change') : t('View')}
                    </div>
                    {canDownloadStructure ?
                        <div className={styles.soldToDownloadStructure}>
                            <div className={styles.soldToDivider}>/</div>
                            <div className={styles.soldToLink} onClick={() => onDownloadStructure()}>
                                {t('Download structure')}
                            </div>
                        </div>
                        : null
                    }
                </div>
            </>
        );
    }

    private getCaption(soldToMappings?: SoldToMapping[]): string {
        const {t} = this.props;

        if (!soldToMappings || soldToMappings.filter(stm => stm.hasMapping).length === 0) {
            return t('None');
        } else if (soldToMappings.filter(stm => stm.hasMapping).length === 1) {
            return soldToMappings.find(stm => stm.hasMapping)!.name;
        } else {
            return t('{{itemCount}} items selected', {itemCount: soldToMappings.filter(stm => stm.hasMapping).length});
        }
    }
}

export default withTranslation()(SoldToSelectionControl);

interface OwnProps {
    canChange: boolean;
    canDownloadStructure: boolean;
    soldToMappings?: SoldToMapping[];
    onChange: () => void;
    onDownloadStructure: () => void;
}

interface OwnState {
    caption: string;
}

type AllProps = OwnProps & WithTranslation;
