
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import i18next from 'i18next';
import {push} from "connected-react-router";
import {LocationDescriptorObject} from "history";
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './jobs-evolution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import {DateRange, fetchJobEvolutionRequest, updateSingleDefaultControlValue} from '../../../../store/analytics';
import {buildRequest} from './request-builder';
import {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import WidgetLoading from '../widget-loading';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {JobsEvolutionRequest, JobsEvolutionData} from '../../../../store/analytics/types/jobs-evolution.types';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {colorCyan6} from '../utils/chart-colors';
import {buildChartData, dateField, dateFormatAmcharts, jobCountField} from './chart-data-adapter';
import {addLineSeriesClickEvent, addStandardTooltipStyle, createXYChart, rotateAxisLabels} from '../utils/chart-utils';
import {getChartLanguage} from '../../../../utils/translations/chart-translation-helper';
import {createSingleMonthRange} from "../../dashboard-controls/date-range-selector/helpers/date-range-helper";
import {DateRangeControlValue} from "../../dashboard-controls/date-range-selector/types/date-range-control-value";
import {ParamKey} from "../../../../store/dashboard";
import {RouteUrl} from "../../../../routes";

am4core.useTheme(am4themes_animated);

class JobsEvolutionWidget extends SingleChartWidgetBase<AllProps, AllState, JobsEvolutionData[]> {

    private chartId: string;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
    }

    public render(): JSX.Element {
        const {t, data} = this.props;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Job History')}
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading />}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.fetchJobsEvolution(buildRequest(this.props.controlsSnapshot));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: JobsEvolutionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);
        chart.data = buildChartData(data);

        chart.dateFormatter.inputDateFormat = dateFormatAmcharts;
        chart.language.locale = getChartLanguage(i18next.language);
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 100;

        if (data.length > 60) {
            rotateAxisLabels(dateAxis);
        }

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Jobs');
        valueAxis.tooltip!.disabled = true;

        if (data.length !== 1) {
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
        } else {
            const range = valueAxis.axisRanges.create();
            range.value = data[0].jobCount;
            range.endValue = 0;
            range.axisFill.fill = am4core.color(colorCyan6);
            range.axisFill.fillOpacity = 0.6;
            range.grid.strokeOpacity = 0;
        }

        const fleetSeries = chart.series.push(new am4charts.LineSeries());
        fleetSeries.dataFields.valueY = jobCountField;
        fleetSeries.dataFields.dateX = dateField;
        fleetSeries.name = 'Jobs';
        fleetSeries.fill = am4core.color(colorCyan6);
        fleetSeries.fillOpacity = 0.8;
        fleetSeries.strokeWidth = 0;
        fleetSeries.tensionX = 0.77;
        fleetSeries.tooltipText = `{valueY.value} ${this.props.t('jobs')}`;
        fleetSeries.clickable = true;

        const eventHandler = addLineSeriesClickEvent(fleetSeries, (datapoint) => {
            setTimeout(() => {
                const dateRange: DateRange = createSingleMonthRange(datapoint.date);
                const controlValue = new DateRangeControlValue('', dateRange);
                this.props.updateSingleDefaultControlValue(ParamKey.InspectionPeriod, controlValue);
                this.props.navigateTo({
                    pathname: `/${this.props.fleetCustomerId}${RouteUrl.Jobs}`,
                });
            });
        });
        this.disposibles.push(eventHandler);

        addStandardTooltipStyle(fleetSeries);

        /* Create a cursor */
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.fullWidthLineX = true;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = am4core.color('#000');
        chart.cursor.lineX.fillOpacity = 0.1;
        chart.cursor.behavior = 'selectX';
        chart.cursor.lineY.disabled = true;

        return chart;
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.jobsEvolutionData,
    fleetCustomerId: authentication.fleetCustomer?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchJobsEvolution:(request: JobsEvolutionRequest) => dispatch(fetchJobEvolutionRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    updateSingleDefaultControlValue: (key, value) => dispatch(updateSingleDefaultControlValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsEvolutionWidget));

interface PropsFromState {
    data: JobsEvolutionData[];
    fleetCustomerId: string;
}

interface PropsFromDispatch {
    fetchJobsEvolution: typeof fetchJobEvolutionRequest;
    navigateTo: typeof push;
    updateSingleDefaultControlValue: typeof updateSingleDefaultControlValue;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
