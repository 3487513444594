import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import logo from '../../../assets/images/logo-yellow.png';
import PrivacyPolicyPopup from '../../privacy-policy/privacy-policy-popup';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {
    AuthenticationRequest,
    FederatedAuthenticationRequest,
    federatedLoginRequest,
    loginRequest,
    resetForgotPasswordForm,
} from '../../store/authentication';
import * as layoutActions from '../../store/layout/actions';
import {closePopup, Popup, PopupType, showPopup} from '../../store/popup';
import TermsOfUsePopup from '../../terms-of-use/terms-of-use-popup';
import {Regex} from '../../types/regex';
import {conditionalClassLister} from '../../utils/class-helpers';
import {getLastPrivacyPolicy} from '../../utils/privacy-policy-helper';
import ToggleDisplay from '../../utils/toggle-display';
import styles from './login.module.scss';
import SupportPopup from '../../support/support-popup';
import InformationPopup from '../../components/information-popup/information-popup';

class LoginPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {toggleSidebar} = this.props;

        toggleSidebar(false);

        this.state = {
            email: '',
            password: '',
            showErrors: true,
            inProgress: false,
            newPassword: '',
            newPasswordConfirm: '',
            newPasswordValid: true,
            newPasswordMatch: true,
            externalSignin: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const {authenticating, newPasswordRequired, passwordExpired, cognitoErrors, userErrors} = this.props;
        const {inProgress} = this.state;
        if (!authenticating && (cognitoErrors || userErrors || newPasswordRequired || passwordExpired) && inProgress) {
            this.setState({inProgress: false});
        }

        if (passwordExpired && !prevProps.passwordExpired) {
            this.openResetPasswordPopup();
        }
    }

    public render(): JSX.Element {
        const {t, newPasswordRequired, authenticated, cognitoErrors, userErrors} = this.props;
        const {
            inProgress,
            showErrors,
            password,
            newPasswordValid,
            newPasswordMatch,
            email,
            externalSignin
        } = this.state;

        let errorMessage = showErrors && !inProgress ? cognitoErrors : '';
        if (!errorMessage) {
            errorMessage = showErrors && !inProgress ? userErrors : '';
        }
        if (!errorMessage && newPasswordRequired) {
            errorMessage =
                showErrors && !newPasswordValid
                    ? 'Password should have a minimum length of 10, contain at least one uppercase, one lowercase, one numeric and one special character'
                    : showErrors && !newPasswordMatch
                        ? 'Passwords don\'t match'
                        : '';
        }

        const selectionButtonClasses = conditionalClassLister(styles)({
            loginButton: true,
            loginButtonHigher: true,
        });

        const buttonClasses = conditionalClassLister(styles)({
            loginButton: true,
            loginButtonDisabled: inProgress,
            loginButtonInactive: !email || !password,
        });

        const confirmButtonClasses = conditionalClassLister(styles)({
            loginButton: true,
            loginButtonDisabled: inProgress,
            loginButtonInactive: !this.isNewPasswordValid(),
        });

        return (
            <div className={styles.pageContainer}>
                <img className={styles.logo} src={logo} alt={t('Goodyear logo')}/>
                <div className={styles.formContainer}>
                    <div className={styles.formPanel}>
                        {!authenticated ? (
                            <>
                                <div className={styles.title}>{t('Sign in to')}</div>
                                <div className={styles.subtitle}>{t('Fleet Online Solutions')}</div>
                                {newPasswordRequired ?
                                    <div className={styles.form} onKeyDown={(e) => this.onKeyPressed(e)}>
                                        <div className={styles.field}>
                                            <div className={styles.fieldLabel}>{t('New password')}</div>
                                            <input
                                                type="password"
                                                key="NewPasswordField"
                                                className={styles.fieldInput}
                                                pattern={Regex.Password}
                                                placeholder={t('Enter your new password')}
                                                onChange={(e) => this.onNewPasswordChange(e)}
                                                onBlur={() => this.onCheckPasswordPattern()}
                                            />
                                        </div>
                                        <div className={styles.field}>
                                            <div className={styles.fieldLabel}>{t('Confirm new password')}</div>
                                            <input
                                                type="password"
                                                key="NewPasswordConfirmField"
                                                className={styles.fieldInput}
                                                pattern={Regex.Password}
                                                placeholder={t('Repeat your new password')}
                                                onChange={(e) => this.onNewPasswordConfirmChange(e)}
                                                onBlur={() => this.onCheckPasswordMatch()}
                                            />
                                        </div>
                                        <div className={styles.errorMessage}>{errorMessage}</div>
                                        <button
                                            type="submit"
                                            className={confirmButtonClasses}
                                            disabled={!this.isNewPasswordValid()}
                                            onClick={() => this.onConfirmButtonClick()}>
                                            <ToggleDisplay if={inProgress}>
                                                <FontAwesomeIcon icon={faSpinner} spin/>
                                            </ToggleDisplay>
                                            <ToggleDisplay if={!inProgress}>{t('Confirm')}</ToggleDisplay>
                                        </button>
                                    </div>
                                    :
                                    <>
                                        <div className={styles.form}>
                                            <button
                                                type="button"
                                                className={selectionButtonClasses}
                                                onClick={() => this.onEnableExternalLoginButtonClick()}>{t('User Login')}
                                            </button>
                                            <button
                                                type="button"
                                                className={selectionButtonClasses}
                                                onClick={() => this.onPerformGYLoginButtonClick()}>{t('Goodyear User Login')}
                                            </button>
                                        </div>
                                        {externalSignin ?
                                            <div className={styles.form} onKeyDown={(e) => this.onKeyPressed(e)}>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('Email')}</div>
                                                    <input
                                                        type="text"
                                                        key="EmailField"
                                                        autoFocus
                                                        className={styles.fieldInput}
                                                        placeholder="james@organization.com"
                                                        onChange={(e) => this.onEmailChange(e)}
                                                    />
                                                </div>
                                                <div className={styles.field}>
                                                    <div className={styles.fieldLabel}>{t('Password')}</div>
                                                    <input
                                                        type="password"
                                                        key="PasswordField"
                                                        className={styles.fieldInput}
                                                        placeholder={t('Enter your password')}
                                                        onChange={(e) => this.onPasswordChange(e)}
                                                    />
                                                </div>
                                                <div className={styles.errorMessage}>{errorMessage}</div>
                                                <button
                                                    type="submit"
                                                    className={buttonClasses}
                                                    onClick={() => this.onPerformExternalLoginButtonClick()}>
                                                    <ToggleDisplay if={inProgress}>
                                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                                    </ToggleDisplay>
                                                    <ToggleDisplay if={!inProgress}>{t('Login')}</ToggleDisplay>
                                                </button>
                                                <div
                                                    onClick={() => this.onForgotPasswordClick()}
                                                    className={styles.forgotPassword}>
                                                    Forgot Password?
                                                </div>
                                            </div>
                                            : ''}
                                    </>
                                }
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={styles.footerPanel}>
                        <div className={styles.knowMore}>
                            <span onClick={() => this.openPrivacyPolicyPopup()} className={styles.highlight}>
                                    {' '}
                                {t('Privacy Policy')}
                                </span>
                            {' - '}
                            <span onClick={() => this.openTermsOfUsePopup()} className={styles.highlight}>
                                    {' '}
                                {t('Terms of use')}
                                </span>
                            {' - '}
                            <span onClick={() => this.openSupportPopup()} className={styles.highlight}>
                                    {' '}
                                {t('Support')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onKeyPressed(e) {
        const {email, password} = this.state;
        if (email && password && e.keyCode === 13) {
            this.onPerformExternalLoginButtonClick();
        }
    }

    private onEmailChange(e) {
        this.setState({showErrors: false, email: e.target.value});
    }

    private onPasswordChange(e) {
        this.setState({showErrors: false, password: e.target.value});
    }

    private onPerformGYLoginButtonClick() {
        const {location} = this.props;
        const {dispatchFederatedLoginRequest} = this.props;
        this.setState({showErrors: true, inProgress: true});
        dispatchFederatedLoginRequest({
            searchUrl: location.search
        });
    }

    private onEnableExternalLoginButtonClick() {
        this.setState({externalSignin: true});
    }

    private onPerformExternalLoginButtonClick() {
        const {dispatchLoginRequest} = this.props;
        const {email, password} = this.state;
        this.setState({showErrors: true, inProgress: true});
        dispatchLoginRequest({credentials: {email, password}});
    }

    private onForgotPasswordClick(): void {
        const {dispatchNavigateTo, dispatchResetForgotPasswordForm} = this.props;
        dispatchResetForgotPasswordForm();
        dispatchNavigateTo({pathname: RouteUrl.ResetPassword});
    }

    private onNewPasswordChange(e) {
        this.setState({showErrors: false, newPassword: e.target.value});
    }

    private onCheckPasswordPattern() {
        const {newPassword} = this.state;
        const regex = new RegExp(Regex.Password);
        this.setState({
            newPasswordValid: regex.test(newPassword),
            showErrors: true,
        });
    }

    private onCheckPasswordMatch() {
        const {newPassword, newPasswordConfirm} = this.state;
        this.setState({
            newPasswordMatch: newPassword === newPasswordConfirm,
            showErrors: true,
        });
    }

    private onNewPasswordConfirmChange(e) {
        this.setState({showErrors: false, newPasswordConfirm: e.target.value});
    }

    private isNewPasswordValid() {
        const {newPassword, newPasswordConfirm, newPasswordValid} = this.state;
        return (
            newPassword !== '' && newPasswordConfirm !== '' && newPasswordValid && newPassword === newPasswordConfirm
        );
    }

    private openPrivacyPolicyPopup(): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;

        getLastPrivacyPolicy('EN').then((policyDocument) => {
            if (policyDocument) {
                dispatchShowPopup({
                    type: PopupType.PrivacyPolicy,
                    content: (
                        <PrivacyPolicyPopup
                            shouldConfirm={false}
                            showTermsOfUse={false}
                            policyDocument={policyDocument}
                            onConfirm={async (): Promise<void> => {
                                dispatchClosePopup();
                            }}
                            onClose={(): void => {
                                dispatchClosePopup();
                            }}
                        />
                    ),
                });
            }
        });
    }

    private openSupportPopup(): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.Support,
            content: (
                <SupportPopup
                    onClose={async (): Promise<void> => {
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private openResetPasswordPopup(): void {
        const {dispatchShowPopup, dispatchClosePopup, t} = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.FleetCustomerCreation,
            topOffset:300,
            content: (
                <InformationPopup
                    headerText={t('Password expired')}
                    message={t('Your password has expired, please reset it.')}
                    confirmButtonText={t('Reset password')}
                    onConfirm={() => {
                        this.onForgotPasswordClick();
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private openTermsOfUsePopup(): void {
        const {dispatchShowPopup, dispatchClosePopup} = this.props;
        dispatchShowPopup({
            hideCloseButton: true,
            type: PopupType.TermsOfUse,
            content: (
                <TermsOfUsePopup
                    onClose={async (): Promise<void> => {
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private onConfirmButtonClick() {
        const {dispatchLoginRequest} = this.props;
        const {email, password, newPassword} = this.state;
        this.setState({showErrors: true, inProgress: true});
        dispatchLoginRequest({
            credentials: {
                email,
                password,
            },
            newPassword,
        });
    }
}

const mapStateToProps = ({authentication, router}: ApplicationState) => ({
    authenticating: authentication.authenticating,
    cognitoErrors: authentication.loginFailureMessage,
    userErrors: authentication.userRetrievalFailureMessage,
    newPasswordRequired: authentication.newPasswordRequired,
    passwordExpired: authentication.passwordExpired,
    authenticated: authentication.authenticated,
    location: router.location
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchLoginRequest: (request: AuthenticationRequest) => dispatch(loginRequest(request)),
    dispatchFederatedLoginRequest: (request: FederatedAuthenticationRequest) => dispatch(federatedLoginRequest(request)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchResetForgotPasswordForm: () => dispatch(resetForgotPasswordForm()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LoginPage));

interface PropsFromState {
    authenticating: boolean;
    cognitoErrors?: string;
    userErrors?: string;
    newPasswordRequired: boolean;
    passwordExpired: boolean;
    authenticated: boolean;
    location: LocationDescriptorObject;
}

interface PropsFromDispatch {
    toggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchLoginRequest: typeof loginRequest;
    dispatchFederatedLoginRequest: typeof federatedLoginRequest;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchResetForgotPasswordForm: typeof resetForgotPasswordForm;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    email: string;
    password: string;
    showErrors: boolean;
    externalSignin: boolean;
    inProgress: boolean;
    newPassword: string;
    newPasswordConfirm: string;
    newPasswordValid: boolean;
    newPasswordMatch: boolean;
}

type AllState = OwnState;
