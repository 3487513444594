import { TFunction } from 'i18next';
import { AlertTypes, DateRange } from '../../../../store/analytics';
import { ParamKey } from '../../../../store/dashboard';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import {
    toBetweenMonthRangeDisplay,
    toEndDateDisplay,
} from '../../dashboard-controls/date-range-selector/helpers/date-range-helper';

export interface AlertsMetaData {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
    subtitleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
    tooltipFunction: (t: TFunction) => string;
}

function onEndDateDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return `${t('{{displayDate}}', { displayDate: toEndDateDisplay(dateRange, t) })}`;
    }
    return '';
}

function betweenMonthRangeDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return toBetweenMonthRangeDisplay(dateRange, t);
    }
    return '';
}

export const alertsMetaStore = new Map<AlertTypes, AlertsMetaData>();
alertsMetaStore.set(AlertTypes.TirePressure, {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Tire Pressure Distribution'),
    subtitleFunction: betweenMonthRangeDisplay,
    tooltipFunction: (t: TFunction) =>
        t(
            'Shows the distribution of tire pressure measured at the start of each inspection that took place within the selected period',
        ),
});
alertsMetaStore.set(AlertTypes.TreadDepth, {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Tread Depth Distribution'),
    subtitleFunction: betweenMonthRangeDisplay,
    tooltipFunction: (t: TFunction) =>
        t(
            'Shows the distribution of every tread depth measured at the start of each inspection that took place within the selected period',
        ),
});
alertsMetaStore.set(AlertTypes.RetreadRatio, {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Retread Ratio'),
    subtitleFunction: betweenMonthRangeDisplay,
    tooltipFunction: (t: TFunction) =>
        t('Shows the distribution of new tires mounted vs retreads that took place within the selected period'),
});
alertsMetaStore.set(AlertTypes.BreakdownRatio, {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Breakdown Frequency'),
    subtitleFunction: onEndDateDisplay,
    tooltipFunction: (t: TFunction) =>
        t(
            'Shows the latest breakdown distribution for the fleet. Only vehicles that have been active for more than six months are considered.',
        ),
});
alertsMetaStore.set(AlertTypes.ServiceFrequency, {
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Service Frequency'),
    subtitleFunction: onEndDateDisplay,
    tooltipFunction: (t: TFunction) =>
        t(
            'Shows the latest service distribution for the entire fleet. All vehicles are considered serviced upon activation.',
        ),
});
