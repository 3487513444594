import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {
    FleetMovementGroupingEntity,
    FleetMovementRequest,
} from '../../../../store/analytics/types/fleet-movement.types';

export function buildRequest(snapshot: ControlsSnapshot, grouping: FleetMovementGroupingEntity): FleetMovementRequest {
    const request = Object.assign(
        createDateRangeWithFunctionalLocationsRequest(snapshot), {
            grouping: [grouping],
        });

    return request;
}
