import {Reducer} from 'redux';
import {SharedActionTypes} from '../shared/types';
import {BillingAction, BillingActionTypes, BillingState} from './types';

function initialState(): BillingState {
    return {
        inProgress: false,
    };
}

const reducer: Reducer<BillingState, BillingAction> = (state = initialState(), action): BillingState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_FLEET_CUSTOMER: {
            return initialState();
        }
        case BillingActionTypes.GET_BILLING_DOCUMENT_REQUEST: {
            return {...state, inProgress: true};
        }
        case BillingActionTypes.GET_BILLING_DOCUMENT_SUCCESS: {
            return {...state, inProgress: false, billingDocument: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as billingReducer};
export {initialState as initialBillingState};
