import {InspectionHistogramData} from '../../../../store/analytics/types/inspection-histogram.types';

export const bucketField = 'bucketField';
export const bucketIdField = 'bucketIdField';
export const inspectionCountField = 'inspectionCountField';

export function buildChartData(data: InspectionHistogramData, bucketCount: number, bucketSize: number): any[] {
    const datapoints: any[] = [];

    let count = 1;
    while (count <= bucketCount) {
        const datapoint = {};
        const bucketId = `bucket_${count}`;
        let bucket = '';
        if (count !== bucketCount) {
            bucket = `[[${(count - 1) * bucketSize} - ${count * bucketSize}]]`;
        } else {
            bucket = `[[${(count - 1) * bucketSize}+]]`;
        }
        datapoint[bucketField] = bucket;
        datapoint[bucketIdField] = bucketId;
        datapoint[inspectionCountField] = data[bucketId];
        datapoints.push(datapoint);

        // tslint:disable-next-line:no-increment-decrement
        count++;
    }
    return datapoints;
}
