import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { goBack } from 'connected-react-router';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Dropdown, { DropdownOption } from '../../components/dropdown/dropdown';
import PageHeader from '../../components/page-header/page-header';
import { ApplicationState } from '../../store';
import {updateCurrentUserSettingsRequest, User, UserSettings} from '../../store/authentication';
import { FleetCustomerWithConfiguration } from '../../store/fleet-customers';
import * as layoutActions from '../../store/layout/actions';
import { RequestStatus } from '../../store/shared/types';
import { AvailableCurrencies } from '../../types/currencies';
import { conditionalClassLister } from '../../utils/class-helpers';
import { getInitialsFromUser } from '../../utils/user-helper';
import styles from './profile.module.scss';
import {getSupportedLanguageOptions} from '../../utils/translations/translations-helper';

class ProfilePage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const { dispatchToggleSidebar } = this.props;
        dispatchToggleSidebar(true);

        const { user } = this.props;
        this.state = {
            preferredCurrency: user.preferredCurrency,
            language: user.language,
        };
    }

    public render(): JSX.Element {
        const { t, user, fleetCustomer, location, requestStatus } = this.props;
        const { preferredCurrency,language } = this.state;

        const backButtonClasses = conditionalClassLister(styles)({
            button: true,
            isNegative: true,
        });

        const saveButtonClasses = conditionalClassLister(styles)({
            button: true,
        });

        const currencyOptions = AvailableCurrencies.map((c) => ({
                id: c,
                label: c,
            }));

        const languageOptions = getSupportedLanguageOptions(t,false);
        const fleetCustomerCurrencies: string[] = this.buildFleetCustomerCurrencies(fleetCustomer);

        return (
            <div className={styles.pageContainer}>
                <PageHeader location={location} />
                <div className={styles.pageContent}>
                    <div className={styles.profilePanel}>
                        <div className={styles.titlePanel}>
                            <div className={styles.leftTitlePanel}>
                                <div className={styles.initialsBox}>{getInitialsFromUser(user)}</div>
                            </div>
                            <div className={styles.rightTitlePanel}>
                                <div className={styles.nameField}>{user ? user.cognitoUsername : ''}</div>
                                <div className={styles.emailField}>{user ? user.email : ''}</div>
                            </div>
                        </div>
                        <div className={styles.contentPanel}>
                            <div className={styles.field}>
                                <div className={styles.fieldRow}>
                                    <div className={styles.fieldLabel}>{t('Language')}:</div>
                                    <Dropdown
                                        options={languageOptions}
                                        selection={languageOptions.find((a) => a.id === language)}
                                        onSelectionChanged={(c) => this.onLanguageChanged(c)}
                                    />
                                </div>
                            </div>
                            <div className={styles.field}>
                                <div className={styles.fieldRow}>
                                    <div className={styles.fieldLabel}>{t('Preferred currency')}:</div>
                                    <Dropdown
                                        options={currencyOptions}
                                        selection={currencyOptions.find((a) => a.id === preferredCurrency)}
                                        onSelectionChanged={(c) => this.onPreferredCurrencyChanged(c)}
                                    />
                                </div>
                                <div className={styles.fieldRow}>
                                    {fleetCustomerCurrencies.length > 0 ? (
                                        <div className={styles.fieldInfo}>
                                            {`${t("Your current fleet customer's available currencies are" )  } : ${  
                                            fleetCustomerCurrencies.join(', ')}`}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button
                                type="button"
                                disabled={requestStatus.isInProgress}
                                className={backButtonClasses}
                                onClick={() => this.onBack()}>
                                {t('Back')}
                            </button>
                            <button
                                type="button"
                                disabled={requestStatus.isInProgress}
                                className={saveButtonClasses}
                                onClick={() => this.onSave()}>
                                {requestStatus?.isInProgress ? <FontAwesomeIcon icon={faSpinner} spin /> : t('Save')}{' '}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private buildFleetCustomerCurrencies(fleetCustomer: FleetCustomerWithConfiguration): string[] {
        const fleetCustomerCurrencies: string[] = [];
        if (fleetCustomer && fleetCustomer.configuration) {
            if (fleetCustomer.configuration.baseCurrency1 && fleetCustomer.configuration.baseCurrency1Processed) {
                fleetCustomerCurrencies.push(fleetCustomer.configuration.baseCurrency1);
            }
            if (fleetCustomer.configuration.baseCurrency2 && fleetCustomer.configuration.baseCurrency2Processed) {
                fleetCustomerCurrencies.push(fleetCustomer.configuration.baseCurrency2);
            }
            if (fleetCustomer.configuration.baseCurrency3 && fleetCustomer.configuration.baseCurrency3Processed) {
                fleetCustomerCurrencies.push(fleetCustomer.configuration.baseCurrency3);
            }
        }
        return fleetCustomerCurrencies;
    }

    private onLanguageChanged(option: DropdownOption): void {
        this.setState({ language: option.id! });
    }

    private onPreferredCurrencyChanged(option: DropdownOption): void {
        this.setState({ preferredCurrency: option.id! });
    }

    private onBack(): void {
        const { dispatchGoBack } = this.props;
        dispatchGoBack();
    }

    private onSave(): void {
        const { fleetCustomer, dispatchUpdateCurrentUserSettings } = this.props;
        const { preferredCurrency,language } = this.state;

        dispatchUpdateCurrentUserSettings(fleetCustomer.id, {preferredCurrency,language});
    }
}

const mapStateToProps = ({ authentication }: ApplicationState) => ({
    user: authentication.user,
    fleetCustomer: authentication.fleetCustomer,
    requestStatus: authentication.updateCurrentUserSettingsRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchUpdateCurrentUserSettings: (fleetCustomerId: string, settings:UserSettings) =>
        dispatch(updateCurrentUserSettingsRequest(fleetCustomerId, settings)),
    dispatchGoBack: () => dispatch(goBack()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));

interface PropsFromState {
    user: User;
    fleetCustomer: FleetCustomerWithConfiguration;
    requestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchUpdateCurrentUserSettings: typeof updateCurrentUserSettingsRequest;
    dispatchGoBack: typeof goBack;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    language: string;
    preferredCurrency: string;
}

type AllState = OwnState;
