import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../../../store';
import {FleetCustomer, getFleetCustomersRequest} from '../../../../../store/fleet-customers';
import {closePopup} from '../../../../../store/popup';
import {RequestStatus} from '../../../../../store/shared/types';
import {conditionalClassLister} from '../../../../../utils/class-helpers';
import {getEmailDomain} from '../../../../../utils/user-helper';
import FleetCustomerSelectionItem from './fleet-customer-selection-item';
import styles from './fleet-customer-selection-popup.module.scss';

class FleetCustomerSelectionPopup extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchGetAllFleetCustomers, originalSelectedFleetCustomers} = this.props;
        dispatchGetAllFleetCustomers();

        this.state = {
            selectedFleetCustomers: [...originalSelectedFleetCustomers],
            visibleFleetCustomers: [],
            allowedFleetCustomers: [],
            showSelectedOnly: false,
            lastSearchQuery: '',
            hiddenCount: 0,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {fleetCustomers, email} = this.props;
        if (fleetCustomers && prevProps && prevProps.fleetCustomers && fleetCustomers !== prevProps.fleetCustomers) {
            const userDomain = getEmailDomain(email);
            const allowedFleetCustomers = fleetCustomers.filter((fc) => fc.allowedDomains.includes(userDomain));
            const hiddenCount = fleetCustomers.length - allowedFleetCustomers.length;
            this.setState({
                hiddenCount,
                allowedFleetCustomers,
                visibleFleetCustomers: allowedFleetCustomers,
            });
        }
    }

    public render(): JSX.Element {
        const {email, t, fleetCustomersRequestStatus, canChange} = this.props;
        const {selectedFleetCustomers, visibleFleetCustomers, showSelectedOnly, hiddenCount} = this.state;
        const userDomain = getEmailDomain(email);

        const showSelectedOnlyClass = conditionalClassLister(styles)({
            link: true,
            active: showSelectedOnly,
        });

        const showAllClass = conditionalClassLister(styles)({
            link: true,
            active: !showSelectedOnly,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}/>
                <div className={styles.popupBody}>
                    <div className={styles.popupBodyHeader}>
                        <div className={styles.title}>{t('Make your selection')}</div>
                        <div className={styles.searchContainer}>
                            <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                            <input
                                className={styles.searchInput}
                                type="text"
                                placeholder={t('Filter')}
                                autoFocus
                                onChange={(event) => this.filterFleetCustomers(event)}
                            />
                        </div>
                    </div>
                    <div className={styles.toolbar}>
                        <div className={styles.toolbarItem}>
                            {t('Select')}{' '}
                            <div className={styles.link} onClick={() => this.selectAll()}>
                                {t('All')}
                            </div>
                            |
                            <div className={styles.link} onClick={() => this.selectNone()}>
                                {t('None')}
                            </div>
                        </div>
                        <div className={styles.toolbarItem}>
                            {t('Show')}{' '}
                            <div className={showAllClass} onClick={() => this.onShowSelectedOnly(false)}>
                                {t('All')}
                            </div>
                            |
                            <div className={showSelectedOnlyClass} onClick={() => this.onShowSelectedOnly(true)}>
                                {t('Selected Only')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.selectionItems}>
                        {fleetCustomersRequestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            </div>
                        ) : visibleFleetCustomers && visibleFleetCustomers.length > 0 ? (
                            visibleFleetCustomers.map((fc) => {
                                const includes = selectedFleetCustomers.map((f) => f.id).includes(fc.id);
                                return (
                                    <FleetCustomerSelectionItem
                                        canSelect={fc.allowedDomains.includes(userDomain) && canChange}
                                        fleetCustomer={fc}
                                        selected={includes}
                                        onSelect={(): void => this.onToggleFleetCustomer(!includes, fc)}
                                        key={fc.id}
                                    />
                                );
                            })
                        ) : (
                            <div className={styles.emptyResults}>{t('No data found.')}</div>
                        )}
                    </div>
                    <div className={styles.buttons}>
                        <button type="button" className={styles.button} onClick={() => this.closePopup()}>
                            {t('Close')}
                        </button>
                        {hiddenCount > 0 ? (
                            <div className={styles.footnote}>
                                {t('Hiding {{itemCount}} fleets that do not correspond with the user\'s domain', {itemCount: hiddenCount})}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    private filterFleetCustomers(event: any): void {
        const {showSelectedOnly} = this.state;
        this.onUpdateVisibleFleetCustomers(event.target.value, showSelectedOnly);
    }

    private onUpdateVisibleFleetCustomers(query: string, showSelectedOnly: boolean): void {
        const {allowedFleetCustomers, selectedFleetCustomers} = this.state;

        const searchValue = query;
        let visibleFleetCustomers: FleetCustomer[];
        if (searchValue) {
            visibleFleetCustomers = allowedFleetCustomers.filter((fc) => fc.name ? fc.name.toUpperCase().includes(searchValue.toUpperCase()) : true);
        } else {
            visibleFleetCustomers = allowedFleetCustomers;
        }

        if (showSelectedOnly) {
            this.setState({
                showSelectedOnly,
                visibleFleetCustomers: visibleFleetCustomers.filter(
                    (v) => selectedFleetCustomers.findIndex((s) => s.id === v.id) !== -1,
                ),
                lastSearchQuery: query,
            });
        } else {
            this.setState({showSelectedOnly, visibleFleetCustomers, lastSearchQuery: query});
        }
    }

    private selectAll(): void {
        const {visibleFleetCustomers, selectedFleetCustomers} = this.state;

        const updatedFleetCustomers = [...selectedFleetCustomers, ...visibleFleetCustomers].filter(
            (v, i, a) => a.indexOf(v) === i,
        );

        this.updateFleet(updatedFleetCustomers);
    }

    private selectNone(): void {
        this.updateFleet([]);
    }

    private onShowSelectedOnly(showSelectedOnly: boolean): void {
        const {lastSearchQuery} = this.state;
        this.onUpdateVisibleFleetCustomers(lastSearchQuery, showSelectedOnly);
    }

    private onToggleFleetCustomer(select: boolean, fleetCustomer: FleetCustomer): void {
        const {canChange} = this.props;
        const {selectedFleetCustomers} = this.state;

        if (canChange) {
            const updatedFleetCustomers = [...selectedFleetCustomers];

            if (select) {
                updatedFleetCustomers.push(fleetCustomer);
            } else {
                const index = updatedFleetCustomers.findIndex((f) => f.id === fleetCustomer.id);
                if (index !== -1) {
                    updatedFleetCustomers.splice(index, 1);
                }
            }
            this.updateFleet(updatedFleetCustomers);
        }
    }

    private updateFleet(fleetCustomers: FleetCustomer[]): void {
        const {onFleetCustomersChanged} = this.props;

        onFleetCustomersChanged(fleetCustomers);
        this.setState({selectedFleetCustomers: fleetCustomers});
    }

    private closePopup(): void {
        const {dispatchClosePopup} = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = ({fleetCustomers}: ApplicationState): PropsFromState => ({
    fleetCustomers: fleetCustomers.fleetCustomers,
    fleetCustomersRequestStatus: fleetCustomers.getFleetCustomersRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetAllFleetCustomers: () => dispatch(getFleetCustomersRequest()),
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FleetCustomerSelectionPopup));

interface PropsFromState {
    fleetCustomers: FleetCustomer[];
    fleetCustomersRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchGetAllFleetCustomers: typeof getFleetCustomersRequest;
    dispatchClosePopup: typeof closePopup;
}

interface OwnProps {
    canChange: boolean;
    originalSelectedFleetCustomers: FleetCustomer[];
    email: string;
    onFleetCustomersChanged: (fleetCustomers: FleetCustomer[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    allowedFleetCustomers: FleetCustomer[];
    selectedFleetCustomers: FleetCustomer[];
    visibleFleetCustomers: FleetCustomer[];
    showSelectedOnly: boolean;
    lastSearchQuery: string;
    hiddenCount: number;
}

type AllState = OwnState;
