import { TFunction } from 'i18next';
import { TopTirePatternsData } from '../../../../store/analytics/types/top-tire-patterns.types';

export const descriptionField = 'description';
export const unitsField = 'tireCount';

export function buildChartData(data: TopTirePatternsData[], translate: TFunction): any[] {
    const result: any[] = [];
    data.forEach((row) => {
        const r = {};
        r[descriptionField] = row.description;
        r[unitsField] = row.tireCount;
        result.push(r);
    });

    return result.reverse();
}
