import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import {FleetCustomer} from '../../../../store/fleet-customers';
import * as layoutActions from '../../../../store/layout/actions';
import {FleetCustomerMatchParameters} from '../../../../types/fleet-customer';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './job-verification-report.module.scss';
import {
    fetchJobVerificationReportRequest,
    JobVerificationCustomReportParameters,
    JobVerificationReportRequest,
    ReportPeriodType,
} from '../../../../store/reports';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import JobVerificationComponent
    from "../../report-scheduler/popup/report-data-components/job-verification/job-verification.component";

class JobVerificationReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {};
    }

    public render(): JSX.Element {
        const { t, location, reportRequestStatus } = this.props;
        const { invoiceNumber ,jobNumber} = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.JobVerification);

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div />} />
                <div className={styles.pageContent}>
                    <JobVerificationComponent
                        periodType={ReportPeriodType.Custom}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {}}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={(!invoiceNumber&&!jobNumber) || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={(!invoiceNumber&&!jobNumber) || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: JobVerificationCustomReportParameters) {
        this.setState({
            invoiceNumber: parameters.invoiceNumber,
            jobNumber: parameters.jobNumber
        });
    }

    private download(fileType: FileType) {
        const {invoiceNumber,jobNumber} = this.state;
        const {dispatchJobVerificationReport} = this.props;
        if (invoiceNumber||jobNumber) {
            dispatchJobVerificationReport({
                fileType,
                invoiceNumber,
                jobNumber
            });
        }
    }

    private goBack(): void {
        const { dispatchNavigateTo, fleetCustomer } = this.props;
        dispatchNavigateTo({ pathname: `/${fleetCustomer?.id}${RouteUrl.Reports}` });
    }
}

// eslint-disable-next-line
const mapStateToProps = ({ authentication, reports }: ApplicationState) => ({
    fleetCustomer: authentication.fleetCustomer,
    reportRequestStatus: reports.jobVerificationRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchJobVerificationReport: (request: JobVerificationReportRequest) => dispatch(fetchJobVerificationReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobVerificationReportPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomer;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchJobVerificationReport: typeof fetchJobVerificationReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    invoiceNumber?: string;
    jobNumber?: string;
}

type AllState = OwnState;
