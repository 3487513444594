import {TFunction} from 'i18next';
import {
    InspectionStatus,
    InspectionStatusData,
    InspectionStatusGroupingType,
} from '../../../../store/analytics/types/inspection-status.types';
import {getInspectionStatusLabel} from '../../../../utils/translations/inspection-status-translation-helper';
import {FunctionalLocation} from '../../../../store/soldto';

export const inspectionStatusField = 'inspectionStatus';
export const vehicleCountField = 'vehicleCount';
export const typeField = 'type';

export function buildChartDataWithoutGroupings(data: InspectionStatusData[], translate: TFunction): any[]  {
    const datapoints: any[] = [];
    data.forEach((row: InspectionStatusData) => {
        datapoints.push(createDatapoint(row, InspectionStatus.vehiclesMoreThanThreeMonthsOverdueForInspection, translate));
        datapoints.push(createDatapoint(row, InspectionStatus.vehiclesLessThanThreeMonthsOverdueForInspection, translate));
        datapoints.push(createDatapoint(row, InspectionStatus.vehiclesLessThanTwoMonthsOverdueForInspection, translate));
        datapoints.push(createDatapoint(row, InspectionStatus.vehiclesLessThanOneMonthOverdueForInspection, translate));
        datapoints.push(createDatapoint(row, InspectionStatus.vehiclesInspectedOnTime, translate));
    });
    return datapoints;
}

export function buildChartDataWithGroupings(data: InspectionStatusData[], currentGrouping: InspectionStatusGroupingType, locations: Map<string, FunctionalLocation>, translate: TFunction): any[]  {
    const datapoints = new Map<string, any>();
    const inspectionStatuses = [InspectionStatus.vehiclesMoreThanThreeMonthsOverdueForInspection,
        InspectionStatus.vehiclesLessThanThreeMonthsOverdueForInspection,
        InspectionStatus.vehiclesLessThanTwoMonthsOverdueForInspection,
        InspectionStatus.vehiclesLessThanOneMonthOverdueForInspection,
        InspectionStatus.vehiclesInspectedOnTime];
    inspectionStatuses.forEach((status) => {
        const datapoint = {};
        datapoint[inspectionStatusField] = getInspectionStatusLabel(status, translate);
        datapoints.set(status, datapoint);
    });

    data.forEach((row) => {
        let groupingId = row[currentGrouping];
        if (currentGrouping !== InspectionStatusGroupingType.VEHICLE_TYPE
            && locations.has(groupingId)) {
            groupingId = locations.get(groupingId)!.name.toUpperCase();
        }
        inspectionStatuses.forEach((status) => {
            const vehicleCount = row[status];
            const datapoint = datapoints.get(status);
            datapoint[groupingId] = vehicleCount;
        });
    });

    return Array.from(datapoints.values());
}

function createDatapoint(row: InspectionStatusData, status: InspectionStatus, translate: TFunction): any {
    const dp = {};
    dp[inspectionStatusField] = getInspectionStatusLabel(status, translate);
    dp[vehicleCountField] = row[status];
    dp[typeField] = status;
    return dp;
}

export function getGroupings(data: InspectionStatusData[], groupingType: InspectionStatusGroupingType, locations: Map<string, FunctionalLocation>): string[] {
    const groupings: string[] = [];
    if (data) {
        data.forEach((row) => {
            let groupingId = row[groupingType];
            if (groupingType !== InspectionStatusGroupingType.VEHICLE_TYPE
                && locations.has(groupingId)) {
                groupingId = locations.get(groupingId)!.name.toUpperCase();
            }
            if (!groupings.includes(groupingId)) {
                groupings.push(groupingId);
            }
        });
    }
    return groupings;
}
