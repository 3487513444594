import {Frequency} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {createDateRangeWithFunctionalLocationsRequest} from '../utils/widget-helper';
import {BillingEvolutionRequest} from '../../../../store/analytics/types/invoice-items-evolution.types';
import {CurrencyInfo} from '../../../../store/fleet-customers';

export function buildRequest(snapshot: ControlsSnapshot, currency:CurrencyInfo): BillingEvolutionRequest {
    const request = Object.assign(
        createDateRangeWithFunctionalLocationsRequest(snapshot), {
            currencyType:currency.type,
            frequency: Frequency.MONTHLY,
        });

    return request;
}
