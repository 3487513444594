import {Component} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import {IDisposer} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {buildSearchParameters} from '../../utils/query-parameter-helpers';
import {closePopup, PopupType} from '../../store/popup';
import styles from './service-provider-details-popup.module.scss';
import {ApplicationState} from '../../store';
import {fetchServiceProviderRequest, ServiceProvider} from '../../store/service-providers';
import {
    DateRange,
    fetchServiceProvidersMonthlyOverviewRequest,
    FunctionalLocationSelection,
    FunctionalLocationType,
    updateSingleDefaultControlValue,
} from '../../store/analytics';
import {endOfMonth, forceDateToBrusselsTime, startOfMonth, subMonths} from '../../utils/date-helper';
import {
    ServiceProvidersMonthlyOverviewData,
    ServiceProvidersMonthlyOverviewRequest,
} from '../../store/analytics/types/service-providers-monthly-overview.types';
import {SoldToWithDivisions} from '../../store/soldto';
import {generateUUID} from '../../utils/uuid-helpers';
import {
    addClickEvent,
    addStrokeToColumnChart,
    rotateAxisLabels,
} from '../../pages/analytics/dashboard-widgets/utils/chart-utils';
import {buildChartData, jobsCountField, monthField, netPriceField} from './chart-data-adapter';
// tslint:disable-next-line:no-duplicate-imports
import {ParamKey} from '../../store/dashboard';
import {DateRangeControlValue} from '../../pages/analytics/dashboard-controls/date-range-selector/types/date-range-control-value';
import {createSingleMonthRange} from '../../pages/analytics/dashboard-controls/date-range-selector/helpers/date-range-helper';
import {colorYellow5} from '../../pages/analytics/dashboard-widgets/utils/chart-colors';
import {CurrencyInfo, FleetCustomerWithConfiguration} from '../../store/fleet-customers';
import {OwnState} from '../../pages/analytics/dashboard-widgets/traffic-lights-widget/traffic-lights-widget';

class ServiceProviderDetailsPopup extends Component<AllProps> {

    private chartId: string;

    private chart?: am4charts.Chart = undefined;

    protected disposibles: IDisposer[] = [];

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        const {
            serviceProviderId,
            fetchServiceProvidersMonthlyOverview,
            fetchServiceProvider,
            soldTos,
            fleetCustomer
        } = this.props;
        if (serviceProviderId && fleetCustomer) {
            fetchServiceProvider(serviceProviderId);
        }

        if (soldTos && soldTos.length > 0) {
            fetchServiceProvidersMonthlyOverview(this.buildRequest());
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const {
            serviceProvider,
            fetchServiceProvidersMonthlyOverview,
            soldTos,
            fleetCustomer,
            data,
            serviceProviderId,
            fetchServiceProvider
        } = this.props;
        if ((soldTos && serviceProvider !== prevProps.serviceProvider) || (serviceProvider && soldTos !== prevProps.soldTos)) {
            fetchServiceProvidersMonthlyOverview(this.buildRequest());
        }

        if (serviceProviderId && fleetCustomer !== prevProps.fleetCustomer) {
            fetchServiceProvider(serviceProviderId);
        }

        if (data && data !== prevProps.data) {
            this.redraw(data, serviceProviderId!);
        }
    }

    public componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
        this.disposibles.forEach(d => d.dispose());
    }

    public render(): JSX.Element {
        const {serviceProvider, data, t} = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupContent}>
                    <div className={styles.contentTitle}>
                        <div className={styles.type}>{t('Service Provider')}</div>
                        <div className={styles.id}>{serviceProvider ? serviceProvider.name : ''}</div>
                    </div>
                    <div className={styles.contentBody}>
                        <div className={styles.addressContainer}>
                            <div className={styles.label}>{t('Address')}</div>
                            <div>
                                <div>{serviceProvider ? serviceProvider.streetAndNumber : ''}</div>
                                <div>{serviceProvider ? this.formatAddressLocation(serviceProvider) : ''}</div>
                                <div>{serviceProvider ? serviceProvider.countryKey : ''}</div>
                            </div>
                        </div>
                        <div className={styles.chartContainer}>
                            <div className={styles.chartTitle}>
                                {t('Services rendered by Service Provider')}
                            </div>
                            <div className={styles.chartContent}>
                                {data ? null : <div className={styles.loading}>
                                    <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}}
                                                     spin/> {t('loading')}
                                </div>}
                                <div id={this.chartId} style={{width: '100%'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public redraw(data: ServiceProvidersMonthlyOverviewData[], serviceProviderId: string): void {
        if (this.chart) {
            this.chart.dispose();
        }
        this.chart = this.createChart(data, serviceProviderId);
    }

    protected createChart(data: ServiceProvidersMonthlyOverviewData[], serviceProviderId: string): am4charts.XYChart {
        const chart = am4core.create(this.chartId, am4charts.XYChart);

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = monthField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        rotateAxisLabels(categoryAxis);

        this.addColumnAxisAndSeries(chart);
        this.addLineAxisAndSeries(chart);

        const {t} = this.props;
        chart.data = buildChartData(data, serviceProviderId, t);
        return chart;
    }

    public addLineAxisAndSeries(chart: any) {
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        const {t, fleetCustomer} = this.props;
        valueAxis.title.text = t('Net price');
        valueAxis.min = 0;
        valueAxis.renderer.opposite = true;
        valueAxis.hidden = true;
        if (chart.yAxes.indexOf(valueAxis) !== 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = netPriceField;
        lineSeries.dataFields.categoryX = monthField;
        lineSeries.label = t('Net price');
        lineSeries.name = t('Net price line');
        lineSeries.yAxis = valueAxis;
        lineSeries.stroke = am4core.color(colorYellow5);

        const bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color(colorYellow5);
        bullet.tooltipText = `{categoryX}:\n{label}: [bold]{valueY} ${fleetCustomer?.configuration.baseCurrency1}[/]`;

        const circle = bullet.createChild(am4core.Circle);
        circle.radius = 3;
    }

    public addColumnAxisAndSeries(chart: any) {
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        const {
            serviceProvider,
            t,
            navigateTo,
            closePopup: closePopup1,
            currentLocation,
            updateSingleDefaultControlValue: updateSingleDefaultControlValue1
        } = this.props;
        valueAxis.title.text = t('# Jobs');
        valueAxis.min = 0;
        if (chart.yAxes.indexOf(valueAxis) !== 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }

        const columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.dataFields.valueY = jobsCountField;
        columnSeries.dataFields.categoryX = monthField;
        columnSeries.label = t('Jobs');
        columnSeries.name = t('# Jobs');
        columnSeries.columns.template.tooltipText = '{categoryX}: {label}: [bold]{valueY}[/]';
        columnSeries.columns.template.fillOpacity = .8;
        columnSeries.yAxis = valueAxis;

        const eventHandler = addClickEvent(columnSeries, ((datapoint) => {
            closePopup1();
            const dateRange: DateRange = createSingleMonthRange(datapoint[monthField]);
            const controlValue = new DateRangeControlValue('', dateRange);
            updateSingleDefaultControlValue1(ParamKey.InspectionPeriod, controlValue);
            const parameters = {
                reload: true,
                serviceProviderNameContains: serviceProvider!.name,
                popup: undefined,
                serviceProviderId: undefined,
            };
            navigateTo({
                    search: buildSearchParameters(parameters, currentLocation.search),
                },
            );
        }));
        addStrokeToColumnChart(columnSeries);
        this.disposibles.push(eventHandler);
    }

    private buildRequest(): ServiceProvidersMonthlyOverviewRequest {
        const start = subMonths(startOfMonth(new Date()), 11);
        const end = endOfMonth(new Date());
        const locationSelections: FunctionalLocationSelection[] = [];
        const {activeCurrency, serviceProviderId, soldTos} = this.props;
        locationSelections.push({
            locationType: FunctionalLocationType.SOLD_TO,
            includes: soldTos!.map(s => s.id),
        });

        return {
            start: forceDateToBrusselsTime(start).toISOString(),
            end: forceDateToBrusselsTime(end).toISOString(),
            serviceProviders: [serviceProviderId!],
            locations: locationSelections,
            currencyType: activeCurrency!.type,
        };
    }

    private formatAddressLocation(serviceProvider: ServiceProvider): string {
        const {region} = serviceProvider;
        const {district} = serviceProvider;
        const {postalCode} = serviceProvider;
        const {city} = serviceProvider;
        const partOne = (region || district) ? `${region || ''} ${district || ''}`.trim() : null;
        const partTwo = (postalCode || city) ? `${postalCode || ''} ${city || ''}`.trim() : null;
        return [partOne, partTwo].filter(p => p !== null).join(', ');
    }
}

const mapStateToProps = ({router, serviceProviders, soldTo, analytics, authentication}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    serviceProvider: serviceProviders.serviceProvider,
    activeCurrency: authentication.fleetCustomer!.activeCurrency,
    inProgress: serviceProviders.inProgress,
    soldTos: soldTo.soldToWithDivisions,
    data: analytics.serviceProvidersMonthlyOverviewData,
    fleetCustomer: authentication.fleetCustomer ? authentication.fleetCustomer : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateSingleDefaultControlValue: (key, value) => dispatch(updateSingleDefaultControlValue(key, value)),
    fetchServiceProvider: serviceProviderId => dispatch(fetchServiceProviderRequest(serviceProviderId)),
    fetchServiceProvidersMonthlyOverview: request => dispatch(fetchServiceProvidersMonthlyOverviewRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    closePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProviderDetailsPopup));

interface OwnProps {
    serviceProviderId?: string;
}

interface PropsFromState {
    currentLocation: Location;
    inProgress: boolean;
    serviceProvider?: ServiceProvider;
    activeCurrency?: CurrencyInfo;
    soldTos?: SoldToWithDivisions[];
    data?: ServiceProvidersMonthlyOverviewData[];
    fleetCustomer?: FleetCustomerWithConfiguration;
}

interface PropsFromDispatch {
    updateSingleDefaultControlValue: typeof updateSingleDefaultControlValue;
    fetchServiceProvider: typeof fetchServiceProviderRequest;
    fetchServiceProvidersMonthlyOverview: typeof fetchServiceProvidersMonthlyOverviewRequest;
    navigateTo: typeof push;
    closePopup: typeof closePopup;
}

type AllProps =
    PropsFromState
    & PropsFromDispatch
    & WithTranslation
    & OwnProps;

export function updateServiceProviderDetailsQueryParameters(serviceProviderId: string, search: string): string {
    return buildSearchParameters(
        {
            serviceProviderId,
            popup: PopupType.ServiceProviderDetails,
            billingDocumentId: undefined,
            vehicleId: undefined,
            jobId: undefined,
        },
        search,
    );
}
