import {BaseCurrencyType, DateRangeRequest} from '../types';

export interface TopServiceProvidersRequest extends DateRangeRequest {
    orderType: OrderType;
    currencyType: BaseCurrencyType;
}

export interface TopServiceProvidersData {
    readonly id: string;
    readonly name: string;
    readonly city: string;
}

export enum OrderType {
    NET_PRICE = 'netPrice',
    JOB_COUNT = 'jobCount',
}
