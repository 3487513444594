import {ApiResult} from '../types/api-result';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {ServiceProvider} from '../store/service-providers';

class ServiceProvidersApi {

    public static async getServiceProvider(serviceProviderId: string, fleetCustomerId: string): Promise<ApiResult<ServiceProvider>> {
        const result = await Http.get<ServiceProvider>(ApiUrl.ServiceProvider,
            new UrlParamBuilder().addFleetCustomerId(fleetCustomerId).addServiceProviderId(serviceProviderId).build());
        return {data: result};
    }
}

export default ServiceProvidersApi;
