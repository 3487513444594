import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import * as layoutActions from '../../../store/layout/actions';
import {FleetCustomerMatchParameters} from '../../../types/fleet-customer';
import {fetchReportDownloadRequest, fetchReportInfoRequest, ReportInfo, ReportStatus} from '../../../store/reports';
import {ApplicationState} from '../../../store';
import {FleetCustomer} from '../../../store/fleet-customers';
import styles from './report-download.module.scss';
import {isBefore} from '../../../utils/date-helper';
import PageHeader from '../../../components/page-header/page-header';
import {RequestStatus} from '../../../store/shared/types';

class ReportDownloadPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;

        dispatchToggleSidebar(true);

        this.state = {
            isExpired: false,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any) {
        const {dispatchFetchReportInfo, dispatchDownloadReport, match, fleetCustomer, reportInfo} = this.props;
        const {reportId} = match.params;

        if (prevProps.fleetCustomer === undefined && fleetCustomer && reportId) {
            dispatchFetchReportInfo(reportId);
        }

        if (prevProps.reportInfo !== reportInfo && reportInfo) {
            if (reportInfo.status === ReportStatus.SUCCEEDED && !reportInfo.deletedOn && reportInfo.expiresOn && isBefore(new Date(), new Date(reportInfo.expiresOn))) {
                dispatchDownloadReport(reportInfo);
            } else {
                this.setState({isExpired: true});
            }

        }
    }

    public render(): JSX.Element {
        const {t, location, reportInfo, downloadRequestStatus} = this.props;
        const {isExpired} = this.state;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={t('Report download')}
                            location={location}
                />
                <div className={styles.pageContent}>
                    {!reportInfo && <div>{t('Your download will start soon.')}</div>}
                    {reportInfo && <>
                        {isExpired && <div>{t('The report you are trying to download has expired.')}</div>}
                        {!isExpired && (downloadRequestStatus?.isInProgress) && <>
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            </div>
                            <div>{t('Your report is being downloaded, please wait.')}</div>
                        </>}
                        {!isExpired && (downloadRequestStatus?.isSuccess) && <>
                            <div>{t('Report successfully downloaded.')}</div>
                        </>}
                        {!isExpired && downloadRequestStatus?.isFailed &&
                            <div>{t('Something went wrong while downloading your report.')}</div>}
                    </>}
                </div>
            </div>
        )
    }
}

// eslint-disable-next-line
const mapStateToProps = ({authentication, reports}: ApplicationState): PropsFromState => ({
    fleetCustomer: authentication.fleetCustomer,
    reportInfo: reports.reportInfo,
    downloadRequestStatus: reports.reportDownloadRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchFetchReportInfo: (reportId: string) => dispatch(fetchReportInfoRequest(reportId)),
    dispatchDownloadReport: (reportInfo: ReportInfo) => dispatch(fetchReportDownloadRequest(reportInfo)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportDownloadPage));

interface PropsFromState {
    fleetCustomer?: FleetCustomer;
    reportInfo?: ReportInfo
    downloadRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchReportInfo: typeof fetchReportInfoRequest;
    dispatchDownloadReport: typeof fetchReportDownloadRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<FleetCustomerMatchParameters>;

interface OwnState {
    isExpired: boolean;
}

type AllState = OwnState;
